import React from 'react';
import {Button, Input} from '@mui/material';
import styled from 'styled-components';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './FileUpload.module.scss';

const FileInput = styled.input`
    position: absolute;
    left: -99999px;
    margin: 0;
    padding: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    line-height: normal;
    color: inherit;
`;

class FileUpload extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { enabled: false, filename: '' };
    }

    onUpload = (file) => {
        this.props.onUpload(file);
    };

    onChange = (val) => {
        if (val) {
            this.setState({
                enabled: true,
                filename: val.target.value.replace('C:\\fakepath\\', ''),
                file: val.target.files[0],
            });
            this.onUpload(val.target.files[0]);
        } else {
            this.setState({ enabled: false, filename: '' });
        }
    };

    render() {
        const { t  } = this.props;
        return (
            <form style={this.props.style} action="#">
                <div className="FileUpload-input file-field input-field">
                    <FileInput
                        id="fileinputfield"
                        ref="file"
                        type="file"
                        onChange={this.onChange}
                        accept="text/csv,.xls, .csv"
                        style={{ cursor: 'pointer' }}
                    />

                    <Button
                        className={
                            this.props.filled ? classes.uploadButton : "secondaryButton"
                        }
                        onClick={() => {
                            document.getElementById('fileinputfield').click();
                        }}
                    >
                        {this.props.title ? this.props.title : t('Choose file')}
                    </Button>

                    <div className="file-path-wrapper">
                        <Input
                            className="file-path validate"
                            type="text"
                            value={this.state.filename}
                            style={{ opacity: 0 }}
                        />
                    </div>
                </div>
            </form>
        );
    }
}

export default compose(withTranslation(), )(FileUpload);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import classnames from 'classnames';
import {
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    Icon,
    IconButton,
    InputAdornment,
    Switch,
    TextField,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {editContentBlockDraft, hasVideoToolboxAddOn, showSnackbar} from '../../actions';
import TextBlockForm from './TextBlockForm';
import VideoBlockForm from './VideoBlockForm';
import PhotosBlockForm from './PhotosBlockForm';
import ListBlockForm from './ListBlockForm';
import CheckListBlockForm from './CheckListBlockForm';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './ContentBlock.module.scss';
import VideoToolboxBlockForm from "./VideoToolboxBlockForm";

type Props = {
    contentBlock: any,
    update: () => any,
    onChange: () => any,
};

type State = {
    isExpanded: boolean,
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        subscription: subscription,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editContentBlockDraft(props.contentBlock.id, key, value)),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};

class ContentBlock extends React.Component {
    props: Props;
    state: State = {
        isExpanded: false,
        focus: {},
        title: this.props.contentBlock.title,
        subtitle: this.props.contentBlock.subtitle,
    };
    timer = null;

    // Expand PhotosBlockItem when initially created and when it is empty
    // Note this trick also prevents a crash when adding a photosBlock and then dragging the image icon
    componentDidMount() {
        if (!this.state.isExpanded) {
            if (this.props.contentBlock && this.props.contentBlock.photosBlock && this.props.contentBlock.photosBlock.photos.length === 0) {
                this.setState({isExpanded: true});
            }
        }
    }

    handleHeaderClick = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    };

    handleDeleteClick = () => {
        this.props.onDelete(this.props.contentBlock.id);
    };

    handleFormChange = (key) => (event) => {
        const {t} = this.props;
        const value = event.target.value;

        if (key === 'title' && value.length > 120) {
            this.props.textfull(t('Text cannot be longer than 120 characters'));
            return;
        }

        if (key === 'subtitle' && value.length > 255) {
            this.props.textfull(t('Text cannot be longer than 255 characters'));
            return;
        }

        this.setState({[key]: value});

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange();
            }
            let val = value;
            if (key === 'highlight') {
                val = !this.props.contentBlock.highlight;
            }

            if (key === 'subtitle') {
                this.props.update(key, val.replace(/(\r\n|\n|\r)/gm, ''));
            } else {
                this.props.update(key, val);
            }
        }, 400);
    };

    getInputId(path) {
        return this.props.contentBlock.id + '-' + [].concat(path).join('-');
    }

    renderIcon() {
        for (let i = 0; i < this.props.contentBlockTypes.length; i++) {
            if (
                this.props.contentBlock.type ===
                this.props.contentBlockTypes[i].id
            ) {
                return (
                    <Icon className="material-icons">
                        {this.props.contentBlockTypes[i].icon}
                    </Icon>
                );
            }
        }
        return <div>?</div>;
    }

    render() {
        const {
            contentBlock,
            connectDragPreview,
            connectDragSource,
            t,
        } = this.props;

        return (
            <div
                className={classnames({
                    [classes.root]: true,
                    [classes.highlight]: contentBlock.highlight,
                })}
            >
                {connectDragPreview(
                    connectDragSource(
                        <div>
                            <CardHeader
                                onClick={this.handleHeaderClick}
                                className={classes.cardHeader}
                                avatar={this.renderIcon()}
                                title={contentBlock.title}
                            />
                        </div>
                    )
                )}

                <CardActions className={classes.cardActions}>
                    <IconButton onClick={this.handleDeleteClick} size="large">
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton
                        onClick={this.handleHeaderClick}
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.isExpanded,
                        })}
                        size="large">
                        <ExpandMore/>
                    </IconButton>
                </CardActions>

                <Collapse in={this.state.isExpanded} transitionduration="auto">
                    {(contentBlock.type !== 'videoToolboxBlock' || hasVideoToolboxAddOn(this.props.subscription)) &&
                        <CardContent className={classes.cardContent}>
                            <FormControl>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id={this.getInputId('title')}

                                    onChange={this.handleFormChange('title')}
                                    label={t('Title')}
                                    value={this.state.title}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            style={{
                                                fontSize: '14px',
                                                opacity: '0.2',
                                            }}
                                        >
                                            {120 - contentBlock.title.length}
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            {contentBlock.type !== 'checkListBlock' && (
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    contentBlock.highlight === true
                                                }
                                                onChange={this.handleFormChange(
                                                    'highlight'
                                                )}
                                            />
                                        }
                                        label={t('Highlight')}
                                    />
                                </FormControl>
                            )}

                            {contentBlock.type === 'textBlock' && (
                                <TextBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}
                            {contentBlock.type === 'videoBlock' && (
                                <VideoBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}
                            {contentBlock.type === 'videoToolboxBlock' && (
                                <VideoToolboxBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}
                            {contentBlock.type === 'listBlock' && (
                                <ListBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}
                            {contentBlock.type === 'photosBlock' && (
                                <PhotosBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}
                            {contentBlock.type === 'checkListBlock' && (
                                <CheckListBlockForm
                                    onChange={this.props.onChange}
                                    contentBlock={contentBlock}
                                />
                            )}

                            {contentBlock.type !== 'checkListBlock' && (
                                <FormControl>
                                    <TextField
                                        variant="standard"
                                        fullWidth={true}
                                        id={this.getInputId('subtitle')}

                                        onChange={this.handleFormChange('subtitle')}
                                        label={t('Footer')}
                                        multiline
                                        value={this.state.subtitle}
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                style={{
                                                    fontSize: '14px',
                                                    opacity: '0.2',
                                                }}
                                            >
                                                {255 - contentBlock.subtitle.length}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}
                        </CardContent>
                    }
                    {contentBlock.type === 'videoToolboxBlock' && !hasVideoToolboxAddOn(this.props.subscription) &&
                        <CardContent className={classes.cardContent}>
                            {t('Video Toolbox subscription is not active')}
                        </CardContent>
                    }
                </Collapse>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ContentBlock);

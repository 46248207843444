// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import moment from '../../lib/moment';
import TitleDescription from '../TitleDescription';
import TitleWeatherIcon from '../TitleWeatherIcon';
import Slide from '@mui/material/Slide';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    Switch,
} from '@mui/material';
import AutoCompleteView from '../AutoCompleteView';
import {changeInspectionProject, fetchProjects, updateInspectionAssign} from '../../actions';
import {goBack, push} from 'connected-react-router';
import {parseClassesForAutocompleteView} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './InspectionAssignInformation.module.scss';

type Props = {
    toolboxAssignRow?: any,
    loading: boolean,
};

type State = {
    showEditProject: boolean,
    showResolveDialog: boolean,
    inspectionToResolve: any,
    kamComment: string,
    kamResolved: boolean,
    inspectionToResolveComment: string,
    inspectionToResolveSwitch: boolean,

    project: any,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (store) => {
    return {
        projects: store.entities.projects,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        goBack: () => {
            dispatch(goBack());
        },
        goto: (url: string) => {
            dispatch(push(url));
        },
        updateInspectionAssign: (id, kamData: any) => {
            dispatch(updateInspectionAssign(id, kamData));
        },
        onSave: (inspectionAssign, project) => {
            changeInspectionProject(inspectionAssign, project)(dispatch);
            // dispatch(push('/werkplekinspectie/details/'+inspectionAssign.id+'?from=uitgestuurd#informatie'));
        },
    };
};

class InspectionAssignInformation extends React.Component<Props, State> {
    props: Props;
    state: State = {
        address: '-',
        showEditProject: false,
        showResolveDialog: false,
        project: null,
        kamComment: undefined,
        kamResolved: false
    };

    componentDidMount() {
        this.props.fetchProjects();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.kamComment === undefined) {
            this.setState({
                kamComment: this.props.inspectionAssign.kamComment,
                kamResolved: this.props.inspectionAssign.kamResolved
            });
        }
    }

    formatLocation = (location) => {
        const formatted = [
            location.street,
            location.zip,
            location.city,
            location.country,
        ]
            .filter((i) => i && i !== '')
            .join(', ');

        if (!formatted || formatted === '') {
            return '-';
        }

        return formatted;
    };

    editProjectClick() {
        this.setState({
            showEditProject: true,
        });
    }

    handleChangeSingleProject = (single) => {
        this.setState({
            project: single,
        });
    };

    cancelEdit = (id) => {
        this.props.goto(
            '/werkplekinspectie/details/' + id + '?from=uitgestuurd#informatie'
        );
        this.setState({
            showEditProject: false,
        });
    };

    saveInspectionProject = (project) => {
        this.props.onSave(this.props.inspectionAssign, project);
    };

    showResolveDialog = () => {
        this.setState({
            showResolveDialog: true,
            inspectionToResolveSwitch: this.state.kamResolved,
            inspectionToResolveComment: this.state.kamComment
        });
    };

    editKamStatus = (key) => (event) => {
        if (key === 'kamComment') {
            this.setState({
                inspectionToResolveComment: event.target.value
            });
        } else if (key === 'kamSwitch') {
            this.setState({
                inspectionToResolveSwitch: !this.state.inspectionToResolveSwitch
            });

        } else if (key === 'kamSave') {
            const id = this.props.inspectionAssign.id;

            let kamData = {
                kamResolved: this.state.inspectionToResolveSwitch,
                kamComment: this.state.inspectionToResolveComment
            };

            this.props.updateInspectionAssign(id, kamData);

            this.setState({
                showResolveDialog: false,
                kamResolved: this.state.inspectionToResolveSwitch,
                kamComment: this.state.inspectionToResolveComment
            });
        }
    }

    render() {
        const {inspectionAssign, allEmployees, projects, t,} = this.props;
        const {
            kamComment,
            kamResolved,
            showResolveDialog,
            inspectionToResolveComment,
            inspectionToResolveSwitch
        } = this.state;
        let theInspection;
        let insideInspection;
        let user;
        let result;
        let employees;
        if (!inspectionAssign) {
            return <div/>;
        } else {
            if (inspectionAssign.inspection) {
                theInspection = inspectionAssign.inspection;
            }
            if (inspectionAssign.user) {
                user = inspectionAssign.user;
            }
            if (inspectionAssign.result) {
                result = inspectionAssign.result;
                if (result.employees) {
                    employees = result.employees
                        .map((id) =>
                            allEmployees.byId[id]
                                ? allEmployees.byId[id].fullname
                                : ''
                        )
                        .join(', ');
                } else {
                    employees = '-';
                }
            }
            if (inspectionAssign.inspection.inspection) {
                insideInspection = inspectionAssign;
            }
        }

        let projectEmpty = false;
        if (inspectionAssign.result) {
            projectEmpty =
                inspectionAssign.result.complete &&
                (!inspectionAssign.project ||
                    typeof inspectionAssign.project.omschrijving ===
                    'undefined' ||
                    (inspectionAssign.project &&
                        inspectionAssign.project.omschrijving === ''));
        }
        if (!inspectionAssign.project) {
            projectEmpty = true
        }

        return (
            <div>
                <div className={classes.container}>
                    <h2>{t('General information')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Description')}
                        itemDescription={
                            inspectionAssign.note ? inspectionAssign.note : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={`${t('Project')}/${t('workfield')}`}
                        itemDescription={
                            projectEmpty ? (
                                <div className={'tooltip'} style={{padding: 0}}>
                                    <Button
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            backgroundColor: 'Transparent',
                                            marginLeft: '-30px',
                                        }}
                                        onClick={() => this.editProjectClick()}
                                    >
                                        <span
                                            className={'tooltiptext'}
                                            style={{left: '100px'}}
                                        >
                                            {t('Choose project')}
                                        </span>
                                        <img
                                            src="/assets/images/edit_icon.png"
                                            alt="edit"
                                        />
                                    </Button>
                                </div>
                            ) : inspectionAssign.project.omschrijving ? (
                                inspectionAssign.project.omschrijving
                            ) : (
                                '-'
                            )
                        }
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Author')}
                        itemDescription={theInspection.author}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Project number')}
                        itemDescription={
                            inspectionAssign.project &&
                            inspectionAssign.project.externalId
                                ? inspectionAssign.project.externalId
                                : '-'
                        }
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Deadline')}
                        itemDescription={
                            inspectionAssign.deadline
                                ? moment(inspectionAssign.deadline).format(
                                    'L HH:mm'
                                )
                                : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Carried out by')}
                        itemDescription={user.firstname + ' ' + user.middlename + ' ' + user.lastname}
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Date sent')}
                        itemDescription={
                            inspectionAssign.deployed
                                ? moment(inspectionAssign.deployed).format(
                                    'L HH:mm'
                                )
                                : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Date entered')}
                        itemDescription={
                            inspectionAssign.result &&
                            inspectionAssign.result.date
                                ? moment(inspectionAssign.result.date).format(
                                    'L HH:mm'
                                )
                                : '-'
                        }
                    />
                </div>
                <div className={classes.container}>
                    <h2>{t('Details')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Customer')}
                        itemDescription={
                            inspectionAssign.project
                                ? inspectionAssign.project.opdrachtgever
                                : '-'
                        }
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Work type')}
                        itemDescription={
                            inspectionAssign.activity
                                ? inspectionAssign.activity
                                : '-'
                        }
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Location')}
                        itemDescription={
                            insideInspection.location
                                ? this.formatLocation(insideInspection.location)
                                : '-'
                        }
                    />

                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Completed on location')}
                        itemDescription={this.props.inspectionLocation ? this.props.inspectionLocation : '-'}
                    />
                </div>
                <div className={classes.container}>
                    <h2>{t('Attendees')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Employees present')}
                        itemDescription={result && employees ? employees : '-'}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Other attendees')}
                        itemDescription={
                            result && result.externalEmployees
                                ? result.externalEmployees
                                : '-'
                        }
                    />
                </div>
                <div className={classes.container}>
                    <h2>{t('Others')}</h2>
                    <TitleWeatherIcon
                        itemTitle={t('Weather conditions')}
                        itemIconName={
                            result && result.weatherType
                                ? result.weatherType
                                : '-'
                        }
                    />
                </div>
                <div className={classes.container}>
                    <h2>{t('KAM Status')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('KAM Comment')}
                        itemDescription={kamComment ? kamComment : '-'}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Resolved')}
                        itemDescription={
                            kamResolved
                                ? t('YES')
                                : t('NO')
                        }
                    />

                </div>
                <div className={classes.container}>
                    <Button
                        className={classes.button}
                        onClick={
                            this.showResolveDialog
                        }

                        variant="contained"
                        color="primary"
                    >
                        {t('Enter KAM Comment')}
                    </Button>
                </div>

                <Dialog
                    onClose={() => {
                        this.setState({
                            showResolveDialog: false,
                        });
                    }}
                    open={showResolveDialog}
                >
                    <DialogTitle>{t('Mark as done')}</DialogTitle>
                    <DialogContent>
                        <InputLabel htmlFor="wpi-kam-comment">
                            {t('Enter KAM Comment')}
                        </InputLabel>
                        <Input
                            fullWidth={true}

                            id="kamComment"
                            value={inspectionToResolveComment}
                            onChange={this.editKamStatus('kamComment')}
                            multiline
                        />
                        <p style={{marginBottom: '-12px'}}>
                            {t('Resolved by KAM?')}{' '}
                            <div
                                style={{
                                    float: 'right',
                                    marginTop: '-12px',
                                    marginRight: '50px',
                                }}
                            >
                                <Switch
                                    checked={inspectionToResolveSwitch}
                                    onChange={this.editKamStatus('kamSwitch')}
                                    id="kamSwitch"
                                />
                            </div>
                        </p>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    showResolveDialog: false,
                                    inspectionToResolveComment: kamComment,
                                    inspectionToResolveSwitch: kamResolved
                                });
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.editKamStatus('kamSave')}
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    style={{height: ' auto', overflow: 'visible'}}
                    open={this.state.showEditProject}
                    onClose={() => this.cancelEdit(inspectionAssign.id)}
                    TransitionComponent={Transition}
                    className="victor"
                >
                    <DialogTitle className="title">
                        {t('Choose project')}
                    </DialogTitle>
                    <DialogContent
                        style={{height: ' auto', overflow: 'visible'}}
                    >
                        {/*<InputLabel  style={{display:'block', marginTop:'15px',marginBottom:'-20px'}}>Project/werkgebied</InputLabel>*/}
                        <FormControl
                            style={{
                                width: 'calc(100% - 30px)',
                                display: 'inline-block',
                                height: 'auto',
                            }}
                        >
                            <Input
                                className={classes.sectorInput}
                                fullWidth={true}
                                inputComponent={(props) => (
                                    <AutoCompleteView
                                        {...props}
                                        classes={parseClassesForAutocompleteView(
                                            props
                                        )}
                                        isToolbox={false}
                                        filterOption={(option, filterString) =>
                                            option.title.includes(filterString)
                                        }
                                    />
                                )}
                                value={this.state.project}
                                placeholder={t('Select project')}
                                onChange={this.handleChangeSingleProject}
                                // id="react-select-single"
                                inputProps={{
                                    classes,
                                    instanceId: 'react-select-single',
                                    name: 'react-select-single',
                                    simpleValue: true,
                                    options: projects.allIds
                                        .map((id) => projects.byId[id])
                                        .sort(function (a, b) {
                                            if (
                                                a.id.includes(
                                                    'InvullenTijdenInspectie'
                                                )
                                            )
                                                return -1;
                                            if (
                                                b.id.includes(
                                                    'InvullenTijdenInspectie'
                                                )
                                            ) {
                                                return 1;
                                            }

                                            const numberA = parseInt(
                                                a.externalId,
                                                10
                                            );
                                            const numberB = parseInt(
                                                b.externalId,
                                                10
                                            );

                                            if (
                                                isNaN(numberA) &&
                                                !isNaN(numberB)
                                            ) {
                                                return 1;
                                            } else if (
                                                !isNaN(numberA) &&
                                                isNaN(numberB)
                                            ) {
                                                return -1;
                                            } else if (
                                                !isNaN(numberA) &&
                                                !isNaN(numberB)
                                            ) {
                                                return numberA - numberB;
                                            } else {
                                                return a.externalId >
                                                b.externalId
                                                    ? 1
                                                    : -1;
                                            }
                                        })
                                        .filter(
                                            (item) =>
                                                !item.id.includes(
                                                    'InvullenTijdenInspectie'
                                                )
                                        )
                                        .map((project) => ({
                                            title: project.omschrijving,
                                            value: project.id,
                                            label: project.id.includes(
                                                'InvullenTijdenInspectie'
                                            )
                                                ? t('Complete during inspection')
                                                : project.externalId +
                                                ': ' +
                                                project.omschrijving,
                                        })),
                                }}
                            />
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            color="secondary"
                            className="button cancel"
                            onClick={() => this.cancelEdit(inspectionAssign.id)}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className="button add"
                            onClick={() =>
                                this.saveInspectionProject(this.state.project)
                            }
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(InspectionAssignInformation);

// @flow
import React from 'react';
import {AppBar, IconButton, Paper, Toolbar, Typography} from '@mui/material';
import {connect} from 'react-redux';
import AppModule from '../../AppModule';
import {getAppToolboxes} from '../../../actions';
import BlockPreview from '../../ToolboxPreview/BlockPreview';
import classes from './ToolboxOpdrachtFinished.module.scss';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {push} from 'connected-react-router';
import {inProgress} from '../../../selectors';
import moment from 'moment';
import {isUndefinedOrNull} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    toolboxId: string,
    toolbox: Array<any>,
};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        toolbox:
            store.entities.appToolboxes &&
            store.entities.appToolboxes.byId[props.match.params.toolboxId],
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppToolboxes: () => {
            dispatch(getAppToolboxes());
        },
    };
};

class ToolboxOpdrachtFinished extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        tab: 0,
        outcomeText: '',
    };

    componentDidMount() {
        this.props.getAppToolboxes();
    }

    toNextPage = () => {
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    toSelfTest = () => {
        const { t } = this.props;

        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.setState(
            {
                tab: 0,
            },
            () => {
                //Get results
                const currentQuestion = this.props.toolbox.toolbox.questions[this.state.tab];
                const correctAnswers = this.props.toolbox.toolbox.questions[this.state.tab].correctAnswers;
                const selected = this.props.toolbox.toolbox.results.answers[this.state.tab].answers;
                let isAllCorrect = true;

                for (let i = 0; selected.length !== i; i++) {
                    this.refs['divAnswer' + selected[i]].style.backgroundColor =
                        '#D7D7D7';
                }

                for (
                    let i = 0;
                    i !== currentQuestion.possibleAnswers.length;
                    i++
                ) {
                    this.refs['divAnswerIcon' + i].style.visibility = 'visible';
                }

                if (currentQuestion.type === 'singleResponse') {
                    if (! correctAnswers.includes(selected[0])) {
                        isAllCorrect = false;
                    }
                } else {
                    for (let i = 0; i < selected.length; i++) {
                        if (selected[i] !== correctAnswers[i]) {
                            //this.paintFatWrongAnswer(selected[i]);
                            isAllCorrect = false;
                        }
                    }
                }

                if (isAllCorrect) {
                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({
                        outcomeText: t('Your answer (s) are correct'),
                    });
                } else {
                    this.refs['outcomeText'].style.color = 'red';
                    this.setState({
                        outcomeText:
                            t('Your answer (s) are incorrect.')
                    });
                }
                this.setState({ explanation: currentQuestion.explanationAnswers });
            }
        );
    };

    backToOverview = () => {
        const searchQuery = new URLSearchParams(window.location.search).get(
            'searchQuery'
        );
        const query =
            !isUndefinedOrNull(searchQuery) && searchQuery.length > 0
                ? `?searchQuery=${searchQuery}`
                : '';
        this.props.goTo(`/web/afgerond${query}`);
    };

    handleTabChange = (event: any, value: number) => {
        const { t } = this.props;

        this.setState(
            {
                tab: value,
            },
            () => {
                //Get results
                const currentQuestion = this.props.toolbox.toolbox.questions[this.state.tab];
                const correctAnswers = this.props.toolbox.toolbox.questions[this.state.tab].correctAnswers;
                const selected = this.props.toolbox.toolbox.results.answers[this.state.tab].answers;

                let isAllCorrect = true;

                this.setState({explanation: currentQuestion.explanationAnswers});

                for (let i = 0; selected.length !== i; i++) {
                    this.refs['divAnswer' + selected[i]].style.backgroundColor =
                        '#D7D7D7';
                }

                for (
                    let i = 0;
                    i !== currentQuestion.possibleAnswers.length;
                    i++
                ) {
                    this.refs['divAnswerIcon' + i].style.visibility = 'visible';
                }

                if (currentQuestion.type === 'singleResponse') {
                    if (! correctAnswers.includes(selected[0])) {
                        isAllCorrect = false;
                    }
                } else {
                    for (let i = 0; i < selected.length; i++) {
                        if (selected[i] !== correctAnswers[i]) {
                            //this.paintFatWrongAnswer(selected[i]);
                            isAllCorrect = false;
                        }
                    }
                }

                if (isAllCorrect) {
                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({outcomeText: t('Your answer (s) are correct') });
                } else {
                    this.refs['outcomeText'].style.color = 'red';
                    this.setState({
                        outcomeText: t('Your answer (s) are incorrect.') });
                }
            }
        );
    };

    handlePageBack = () => {
        if (this.state.stepIndex === 0) {
            this.backToOverview();
        } else {
            this.setState({
                stepIndex: this.state.stepIndex - 1,
            });
        }
    };

    render() {
        const { toolbox, loading, t } = this.props;
        const { stepIndex, tab } = this.state;

        // window.$crisp.push(['do', 'chat:hide']);

        if (
            !toolbox ||
            toolbox.toolbox.results == null ||
            (toolbox.toolbox.results &&
                toolbox.toolbox.results.complete === false)
        ) {
            return (
                <AppModule className="appModule" loading={loading}>
                    <div>
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t('This toolbox is no longer available')}
                            </p>
                            <br />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.backToOverview}
                                    id="sendresultsButton"
                                    style={{
                                        margin: 'auto',
                                        marginBottom: '100px',
                                    }}
                                >
                                    {t('Back to the overview')}
                                </Button>
                            </div>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/facebook.png"
                                        alt={t('Veilig Vakwerk')}
                                    />
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/twitter.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img
                                        src="/assets/images/linkedin.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </AppModule>
            );
        }

        const tabSize =
            toolbox.toolbox.questions.length > 5
                ? 100 / 5
                : 100 / toolbox.toolbox.questions.length;

        return (
            <div
                className="App"
                style={{
                    minWidth: '128px',
                    maxWidth: '768px',
                    margin: '0 auto',
                }}
            >
                <AppBar position="fixed">
                    <Toolbar disableGutters={true}>
                        {stepIndex === 0 ||
                        stepIndex === 1 ||
                        stepIndex === 2 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon className={classes.backButton}/>
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            variant="h2"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Completed assignments')}
                            {/*window.innerWidth >= 768 ? "Veilig Vakwerk - " + this.props.toolbox.title : "Veilig Vakwerk"*/}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <AppModule className="appModule" loading={loading}>
                    {stepIndex === 0 && (
                        <div className={classes.paper} id="tbExternPaperMain">
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = 'white')
                                }
                            </div>
                            {t('Author')}: {toolbox.author}
                            {toolbox.toolbox.blocks[0] !== undefined ? (
                                toolbox.toolbox.blocks[0].type ===
                                'meetingBlock' ? (
                                    <div className={classes.toolboxMeetingBox}>
                                        <Typography
                                            className={
                                                classes.toolboxMeetingBoxH3
                                            }
                                        >
                                            {t('Toolbox meeting')}
                                        </Typography>

                                        <div
                                            className={
                                                classes.toolboxMeetingLeft
                                            }
                                        >
                                            {t('Date')}
                                        </div>
                                        <div
                                            className={
                                                classes.toolboxMeetingRight
                                            }
                                        >
                                            {moment(
                                                toolbox.toolbox.blocks[0]
                                                    .meetingBlock.meetingDate
                                            ).format(t('DD-MM-YYYY'))}
                                        </div>
                                        <div style={{ clear: 'both' }}></div>

                                        <div
                                            className={
                                                classes.toolboxMeetingLeft
                                            }
                                        >
                                            {t('Time of the day')}
                                        </div>
                                        <div
                                            className={
                                                classes.toolboxMeetingRight
                                            }
                                        >
                                            {moment(
                                                toolbox.toolbox.blocks[0]
                                                    .meetingBlock.meetingDate
                                            ).format(t('hourMinute'))}
                                        </div>
                                        <div style={{ clear: 'both' }}></div>

                                        <div
                                            className={
                                                classes.toolboxMeetingLeft
                                            }
                                        >
                                            {t('Location')}
                                        </div>
                                        <div
                                            className={
                                                classes.toolboxMeetingRight
                                            }
                                            style={{ paddingBottom: '0px' }}
                                        >
                                            {
                                                toolbox.toolbox.blocks[0]
                                                    .meetingBlock.location
                                            }
                                        </div>
                                        <div style={{ clear: 'both' }}></div>

                                        <div
                                            className={
                                                classes.toolboxMeetingLeft
                                            }
                                        >
                                            {t('Subject')}
                                        </div>
                                        <div
                                            className={
                                                classes.toolboxMeetingRight
                                            }
                                            style={{ paddingBottom: '0px' }}
                                        >
                                            {
                                                toolbox.toolbox.blocks[0]
                                                    .meetingBlock.description
                                            }
                                        </div>
                                        <div
                                            style={{
                                                clear: 'both',
                                                paddingBottom: '16px',
                                            }}
                                        ></div>
                                    </div>
                                ) : (
                                    false
                                )
                            ) : (
                                false
                            )}
                            {toolbox.title && (
                                <h1 style={{ wordWrap: 'break-word' }}>
                                    {toolbox.title}
                                </h1>
                            )}
                            {toolbox.toolbox.blocks.map((blockId) => {
                                return (
                                    <BlockPreview
                                        block={blockId}
                                        key={blockId.id}
                                    />
                                );
                            })}
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.toSelfTest}
                                style={{ width: '100%', height: '25px' }}
                            >
                                {t('To the self-test')}
                            </Button>
                        </div>
                    )}
                    {stepIndex === 1 && (
                        <div>
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = '#f5f5f5')
                                }
                            </div>
                            {toolbox.toolbox.questions.length === 0 && (
                                <p>{t('The questionnaire is empty')}</p>
                            )}
                            {toolbox.toolbox.questions.length > 0 && (
                                <div>
                                    {toolbox.toolbox.questions.length > 5 ? (
                                        <Tabs
                                            scrollable
                                            value={tab}
                                            onChange={this.handleTabChange}
                                            className={classes.root}
                                            style={{ padding: '0' }}
                                        >
                                            {toolbox.toolbox.questions.map(
                                                (questionId, i) => {
                                                    return (
                                                        <Tab
                                                            style={{
                                                                width:
                                                                    tabSize +
                                                                    '%',
                                                            }}
                                                            label={
                                                                `${t(
                                                                    'Question'
                                                                )} ` +
                                                                (i + 1)
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                    ) : (
                                        <Tabs
                                            value={tab}
                                            onChange={this.handleTabChange}
                                            className={classes.root}
                                            style={{ padding: '0' }}
                                        >
                                            {toolbox.toolbox.questions.map(
                                                (questionId, i) => {
                                                    return (
                                                        <Tab
                                                            style={{
                                                                width:
                                                                    tabSize +
                                                                    '%',
                                                            }}
                                                            label={
                                                                `${t(
                                                                    'Question'
                                                                )} ` +
                                                                (i + 1)
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </Tabs>
                                    )}

                                    <Paper elevation={4}
                                        className={classes.paper}
                                        id="tbExternPaper"
                                    >
                                        {toolbox.toolbox.questions.map(
                                            (questionId, i) => {
                                                return (
                                                    tab === i && (
                                                        <div
                                                            className={
                                                                classes.root
                                                            }
                                                        >
                                                            {questionId.title}
                                                            {questionId
                                                                .blocks[0] &&
                                                                questionId
                                                                    .blocks[0]
                                                                    .type ===
                                                                    'photosBlock' &&
                                                                questionId
                                                                    .blocks[0]
                                                                    .photosBlock &&
                                                                questionId
                                                                    .blocks[0]
                                                                    .photosBlock
                                                                    .photos &&
                                                                questionId
                                                                    .blocks[0]
                                                                    .photosBlock
                                                                    .photos
                                                                    .length >
                                                                    0 &&
                                                                questionId.blocks[0].photosBlock.photos.map(
                                                                    (photo) => (
                                                                        <div
                                                                            style={{
                                                                                textAlign:
                                                                                    'center',
                                                                                marginTop:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    maxWidth: 300,
                                                                                    maxHeight: 300,
                                                                                }}
                                                                                src={
                                                                                    photo.url
                                                                                }
                                                                                alt={
                                                                                    'foto'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                            {questionId.possibleAnswers.map(
                                                                (answer, i) => (
                                                                    <div
                                                                        key={
                                                                            answer.id
                                                                        }
                                                                        className={
                                                                            classes.unselectedAnswer
                                                                        }
                                                                        ref={
                                                                            'divAnswer' +
                                                                            i
                                                                        }
                                                                    >
                                                                        <div
                                                                            ref={
                                                                                'divAnswerIcon' +
                                                                                i
                                                                            }
                                                                            style={{
                                                                                visibility:
                                                                                    'visible',
                                                                            }}
                                                                            align="right"
                                                                        >
                                                                            {questionId.correctAnswers.includes(
                                                                                i
                                                                            ) ? (
                                                                                <CheckCircleIcon
                                                                                    style={{
                                                                                        right:
                                                                                            '52px',
                                                                                        marginTop:
                                                                                            '-12px',
                                                                                        position:
                                                                                            'absolute',
                                                                                        color:
                                                                                            'green',
                                                                                    }}
                                                                                    align="right"
                                                                                />
                                                                            ) : (
                                                                                <CancelIcon
                                                                                    style={{
                                                                                        right:
                                                                                            '52px',
                                                                                        marginTop:
                                                                                            '-12px',
                                                                                        position:
                                                                                            'absolute',
                                                                                        color:
                                                                                            'red',
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <b>
                                                                            {t(
                                                                                'Your answer'
                                                                            )}{' '}
                                                                            {String.fromCharCode(
                                                                                65 +
                                                                                    i
                                                                            )}
                                                                        </b>
                                                                        <br />
                                                                        {
                                                                            answer.text
                                                                        }
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            }
                                        )}

                                        <div className={classes.root}>
                                            {
                                                <p
                                                    className={
                                                        classes.outcomeText
                                                    }
                                                    ref="outcomeText"
                                                >
                                                    {this.state.outcomeText}
                                                </p>
                                            }
                                            {
                                                <p
                                                    className={
                                                        classes.explanationText
                                                    }
                                                    ref="explanationText"
                                                >
                                                    {this.state.explanation}
                                                </p>
                                            }
                                            <Button
                                                
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                onClick={this.toNextPage}
                                                id="answerButton"
                                            >
                                                {t('Towards closure')}
                                            </Button>
                                        </div>
                                    </Paper>
                                </div>
                            )}
                        </div>
                    )}
                    {stepIndex === 2 && (
                        <div
                            className={classes.paper}
                            id="tbExternPaperFeedback"
                        >
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = 'white')
                                }
                            </div>
                            <h2 style={{ textAlign: 'center' }}>
                                {t('Close toolbox')}
                            </h2>
                            <p>
                                {t(
                                    'This is the end page of the toolbox, here you can see what feedback you have given during completion'
                                )}
                            </p>
                            <br />
                            <p>{t('Feedback')}:</p>
                            <p>
                                {toolbox.toolbox.results.feedback
                                    ? toolbox.toolbox.results.feedback
                                    : '-'}
                            </p>
                            <br />
                            <Button
                                
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.backToOverview}
                                id="sendresultsButton"
                                style={{ marginTop: '20px' }}
                            >
                                {t('Close toolbox')}
                            </Button>
                            <br />
                            <br />
                        </div>
                    )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(ToolboxOpdrachtFinished);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import clsx from 'clsx';
import {connect} from 'react-redux';
import {
    AppBar,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    InputLabel,
    ListItemIcon,
    Radio,
    RadioGroup,
    Toolbar,
    Typography,
} from '@mui/material';
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Slide from '@mui/material/Slide';
import ProjectEdit from '../Project/ProjectEdit';
import {bindActionCreators} from 'redux';
import TitleDescription from '../TitleDescription/TitleDescription';
import classnames from 'classnames';
import moment from '../../lib/moment';
import {
    closeDialog,
    deployInspection,
    editProject,
    fetchEmployees,
    fetchInspectionAssigns,
    fetchInspections,
    fetchProjects,
    fetchUsergroups,
    processForm,
    processFormFailure,
} from '../../actions';
import {getForm, getVisibleInspectionVersions} from '../../selectors';
import LegacyThemeProvider from '../LegacyThemeProvider';
import Step from '@mui/material/Step/Step';
import Stepper from '@mui/material/Stepper/Stepper';
import StepLabel from '@mui/material/StepLabel/StepLabel';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {DatePicker, DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import LocationPicker from '../Util/Maps/LocationPicker';
import nl from 'date-fns/locale/nl'
import en from 'date-fns/locale/en-US'
import {setTimeout} from 'timers';
import AutoCompleteView from '../AutoCompleteView';
import {parseClassesForAutocompleteView} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './DialogInspectionDeploy.module.scss';
import colors from "../../lib/themes/theme.scss";
import Loader from '../Loader';

type Props = {
    open: boolean,
    processing: boolean,
    error: any,
    editproject: any,
};

type State = {
    form: any,
    showAddProjectDialog: boolean,
    submitting: boolean,
    groups: Array<any>,
};

// For react-select
const customStyles = {
    menu: provided => ({...provided, zIndex: 9999}),
    option: (provided, state) => {
        return {
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? colors.primaryColor : "white",
            "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};

const mapStateToProps = (store) => {
    return {
        ...getForm('inspectionDeploy')(store),
        open: store.ui.dialogs.inspectionDeploy.open,
        initialId: store.ui.dialogs.inspectionDeploy.data.id,
        initialScheduled:
        store.ui.dialogs.inspectionDeploy.data.initialScheduled,
        inspections: getVisibleInspectionVersions(store),
        inspectionsById: store.entities.inspections.byId,
        // inspectionAssigns:store.entities.inspectionAssigns,
        employees: store.entities.employees,
        projects: store.entities.projects,
        editproject: store.drafts.projects.editproject,
        usergroups: store.entities.usergroups,
        currentUser: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
        fetchInspections: () => {
            dispatch(fetchInspections());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees());
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        onSubmit: (
            inspection,
            project,
            employee,
            deadline,
            note,
            activity,
            projectLocation,
            projectAddress,
            scheduledDate,
            groups,
            deployedBy
        ) => {
            dispatch(
                processForm(
                    'inspectionDeploy',
                    deployInspection(
                        inspection,
                        project,
                        employee,
                        deadline,
                        note,
                        activity,
                        projectLocation,
                        projectAddress,
                        scheduledDate,
                        groups,
                        deployedBy
                    )
                )
            );
        },
        onClose: () => {
            dispatch(closeDialog('inspectionDeploy'));
        },
        onError: (message) => {
            dispatch(processFormFailure('inspectionDeploy', {message}));
        },
        editProject: bindActionCreators(editProject, dispatch),
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DialogInspectionDeploy extends React.Component<Props, State> {
    props: Props;
    state: State = {
        form: {
            inspection: '',
            employee: '',
            project: '',
            deadline: undefined,
            note: '',
            activity: '',
            projectLocation: undefined,
            projectAddress: '',
        },
        employeeItems: {
            manual: [],
        },
        stepIndex: 0,
        showAddProjectDialog: false,
        submitting: false,
        emailAlert: false,
        groups: [],
    };

    handleClose = (event) => {
        this.props.onClose();
        this.setState({
            employeeItems: {
                manual: [],
            },
            groups: [],
        });
    };

    handleDeadlineChange = (date) => {
        const formState = {...this.state.form};
        this.setState({form: {...formState, deadline: date}});
    };

    handleEmailAlertChange = (event) => {
        const formState = {...this.state.form};
        this.setState({
            form: {...formState, emailAlert: event.target.checked},
        });
    };

    handleChange = (inputId) => (event) => {
        const formState = {...this.state.form};

        if (inputId === 'note') {
            if (event && event.target.value.length > 105) {
                return null;
            }
        }

        if (inputId === 'activity') {
            if (event && event.target.value.length > 105) {
                return null;
            }
        }

        this.setState({
            form: {...formState, [inputId]: event.target.value},
        });
    };

    handleSubmit = (event) => {
        this.setState({
            submitting: true,
        });

        setTimeout(() => this.setState({submitting: false}), 5000);

        let project = this.state.form.project.value;
        if (this.state.form.project.value === '999InvullenTijdenInspectie') {
            project = '999InvullenTijdenInspectie';
        }

        // Submit for each individual employee
        this.employeeList().map((emp) =>
            this.props.onSubmit(
                this.state.form.inspection,
                project,
                emp.id,
                this.state.form.deadline.toISOString(),
                this.state.form.note,
                this.state.form.activity,
                this.state.form.projectLocation,
                this.state.form.projectAddress,
                this.state.form.scheduledDate,
                [],
                this.state.form.emailAlert ? this.props.currentUser.id : null
            )
        );

        // Submit for all selected groups
        if (this.state.groups.length !== 0) {
            this.props.onSubmit(
                this.state.form.inspection,
                project,
                null,
                this.state.form.deadline.toISOString(),
                this.state.form.note,
                this.state.form.activity,
                this.state.form.projectLocation,
                this.state.form.projectAddress,
                this.state.form.scheduledDate,
                this.state.groups,
                this.state.form.emailAlert ? this.props.currentUser.id : null
            );
        }

        this.emptyUsergroup();
    };

    handleStepSet = (index) => {
        this.setState({
            stepIndex: index,
        });
    };

    handleStepSubmit = () => {
        this.props.fetchUsergroups();

        if (this.state.stepIndex === 0) {
            let inspection = this.props.inspectionsById[this.state.form.inspection];

            // Check eerst of er wel categorien zijn
            if (!inspection.inspection.categories.length) {
                this.showInspectioncategoryCountError();
            } else {
                // Check of alle vragen ook mogelijke antwoorden hebben
                let categoriesWithoutAnswers = inspection.inspection.categories
                    .map((c, i) => {
                        return {index: i, items: c.items};
                    })
                    .filter((c) => c.items.length < 1)
                    .map((c) => c.index + 1);

                if (categoriesWithoutAnswers.length) {
                    this.showInspectionCategoriesItemsError(
                        categoriesWithoutAnswers
                    );
                } else {
                    this.setState({stepIndex: this.state.stepIndex + 1});
                }
            }
        } else {
            this.setState({stepIndex: this.state.stepIndex + 1});
        }
    };

    handleScheduledDateChange = (date) => {
        const formState = {...this.state.form};
        date = new Date(date);
        this.setState({form: {...formState, scheduledDate: date}});
    };

    handleScheduledChange = (event, scheduled) => {
        const formState = {...this.state.form};
        this.setState({form: {...formState, scheduled: scheduled}});
    };

    showInspectioncategoryCountError = () => {
        const {t} = this.props;
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText: t(
                'Workplace inspection must have at least one checklist'
            ),
        });
    };

    showInspectionCategoriesItemsError = (emptyCategories) => {
        const {t} = this.props;
        let text =
            `${'Workplace Inspection checklists error'}, ${'At checklist number'} ` +
            emptyCategories
                .map((c, i) => {
                    let count = emptyCategories.length;
                    if (i === count - 1) {
                        return c;
                    } else if (i === count - 2) {
                        return c + ' ' + t('and') + ' ';
                    } else {
                        return c + ', ';
                    }
                })
                .join('') +
            ' ' +
            t('not enough inspection parts have been added');
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText: text,
        });
    };

    combineDateAndTime = (date, time) => {
        let momentDate = moment(date);
        let momentTime = moment(time);

        const hours = momentTime.hours();
        const minutes = momentTime.minutes();

        var year = momentDate.year();
        var month = momentDate.month();
        var day = momentDate.date();
        var combined = moment()
            .year(year)
            .month(month)
            .date(day)
            .hours(hours)
            .minutes(minutes);

        return new Date(combined.valueOf());
    };

    handleStepBack = () => {
        this.setState({stepIndex: this.state.stepIndex - 1});
    };

    handleLocationChange = (latlon, address) => {
        const formState = {...this.state.form};

        if (!latlon) {
            latlon = this.state.form.projectLocation;
        }

        if (!address) {
            address = this.state.form.projectAddress;
        }
        this.setState({
            form: {
                ...formState,
                projectAddress: address,
                projectLocation: latlon,
            },
        });
    };

    formatDate = () => {
        return moment(this.state.form.scheduledDate).format('LLL');
    };

    componentDidMount() {
        this.props.fetchEmployees();
        this.props.fetchProjects();
        this.props.fetchInspections();
        this.props.fetchInspectionAssigns();
        this.props.fetchUsergroups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState({
                form: {
                    inspection: this.props.initialId || '',
                    employee: '',
                    note: '',
                    deadline: undefined,
                    project: '',
                    scheduled: this.props.initialScheduled ? 'scheduled' : 'now',
                },
                stepIndex: this.props.initialId ? 1 : 0,
            });
        }
    }

    showAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: true,
        });
        this.cleanEditProject(this.props);
    };

    cleanEditProject = (props: Props) => {
        props.editProject({
            omschrijving: '',
            opdrachtgever: '',
            importType: 'MANUAL',
        });
    };

    hideAddProjectDialog = () => {
        this.setState({
            showAddProjectDialog: false,
        });
    };

    employeeList = () => {
        const list = [];
        for (var key in this.state.employeeItems) {
            if (this.state.employeeItems[key]) {
                this.state.employeeItems[key].forEach((user) => {
                    let contains = false;
                    list.forEach((addedItem) => {
                        if (user && addedItem && addedItem.id === user.id) {
                            contains = true;
                        }
                    });
                    if (!contains) {
                        list.push(user);
                    }
                });
            }
        }
        if (list.length !== 0) {
            return list
                .filter(this.noDuplicates)
                .filter(
                    (user) =>
                        (user && user.state === 'ACTIVE') ||
                        (user &&
                            user.roles.find((element) => element === 'EXTERN'))
                );
        } else return list;
    };

    handleUsergroupClick = (id) => () => {
        let groups = [];

        if (this.state.groups) {
            groups = this.state.groups;
        }

        groups.push(id);

        this.setState({
            groups: groups,
        });
    };

    handleUsergroupDelete = (id) => () => {
        let groups = [];

        if (this.state.groups) {
            groups = this.state.groups;
        }

        groups = groups.filter((g) => g !== id);

        this.setState({
            groups: groups,
        });
    };

    emptyUsergroup = () => {
        const items = this.state.employeeItems;
        for (let key in items) {
            items[key] = null;
        }

        this.setState({employeeItems: items, groups: []});
    };

    employeeClicked = (user) => (event) => {
        const items = this.state.employeeItems;
        items.manual = [];

        if (event && event.length > 0) {
            event.forEach((employee) => {
                items.manual.push(this.props.employees.byId[employee.value]);
            });
        }

        this.setState({
            employeeItems: items,
        });
    };

    filterActiveEmpl = (usergroups) => {
        if (usergroups) {
            return usergroups.allIds
                .map((id) => usergroups.byId[id])
                .filter((usergroup) => !this.allUsersInactive(usergroup.users));
        }

        return usergroups;
    };

    allUsersInactive = (users) => {
        let inactive = true;
        if (users) {
            if (users.length < 1) {
                return true;
            } else {
                users.forEach((user) => {
                    if (
                        user.state === 'ACTIVE' ||
                        user.roles.find((element) => {
                            return element === 'EXTERN';
                        })
                    ) {
                        inactive = false;
                    }
                });
            }
        }
        return inactive;
    };

    noDuplicates = (employee, index, array) => {
        let occurrences = 0;
        array.forEach((element) => {
            if (element && employee && element.id === employee.id)
                occurrences++;
        });
        return occurrences < 2;
    };

    handleChangeSingle = (single) => {
        const formState = {...this.state.form};
        this.setState({form: {...formState, inspection: single.value}});
    };

    handleChangeSingleProject = (single) => {
        const formState = {...this.state.form};
        this.setState({form: {...formState, project: single}});
    };

    render() {
        const {
            open,
            inspections,
            employees,
            projects,
            editproject,
            usergroups,
            t,
        } = this.props;
        const {
            stepIndex,
            inspectionErrorDialogOpen,
            inspectionErrorDialogText,
            showAddProjectDialog,
        } = this.state;

        let filteredUsergroups = [];
        filteredUsergroups = this.filterActiveEmpl(usergroups);

        let usergrouplist = [];

        const defaultProject = {
            id: '999InvullenTijdenInspectie',
            omschrijving: '',
            externalId: '',
            opdrachtgever: '',
            isTemplate: true,
            active: true,
        };

        let employeesInGroups = 0;
        if (this.state.groups && this.state.groups.length > 0) {
            this.state.groups.forEach((id) => {
                let group = this.props.usergroups.byId[id];
                if (group) {
                    employeesInGroups = employeesInGroups + group.users.length;
                }
            });
        }

        // Only show the activeProjects in the Project list.
        const activeProjects = projects.allIds.map((id) => projects.byId[id]).filter(project => project.active === true);
        activeProjects.push(defaultProject);

        let myLocale = nl;
        if (t('LOCALE') === 'en') {
            myLocale = en;
        }

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                scroll="paper"
                open={open ?? false}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                className={classes.root}
                PaperProps={{
                    sx: {
                        p: 0, // sets padding to 0
                    },
                }}
            >
                <ProjectEdit
                    open={showAddProjectDialog}
                    editproject={editproject}
                    backOnCancel={false}
                    onCancel={this.hideAddProjectDialog}
                />
                <AppBar className={classes.appBar}>
                    <Toolbar disableGutters style={{paddingRight: '8px'}}>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Workplace inspection')}{' '}
                            {this.state.form.scheduled === 'now'
                                ? t('Send out')
                                : t('Schedule')}
                        </Typography>
                        {/*{stepIndex === 3 && (*/}
                        {/*    <Button*/}

                        {/*        variant="contained"*/}
                        {/*        color="primary"*/}
                        {/*        onClick={this.handleSubmit}*/}
                        {/*        disabled={this.state.submitting}*/}
                        {/*    >*/}
                        {/*        {this.state.form.scheduledDate*/}
                        {/*            ? t('Schedule')*/}
                        {/*            : t('Send out')}*/}
                        {/*    </Button>*/}
                        {/*)}*/}
                    </Toolbar>
                    <div className={classes.stepperWrapper}>
                        <div className={classes.stepper}>
                            <LegacyThemeProvider>
                                <Stepper activeStep={stepIndex}>
                                    <Step>
                                        <StepLabel>
                                            {t('Choose workplace inspection')}
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            {t('Enter details')}
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Schedule')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            {`${t('Check')} / ${t('Send out')}`}
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </LegacyThemeProvider>
                        </div>
                    </div>
                </AppBar>

                <Dialog
                    open={inspectionErrorDialogOpen ?? false}
                    onClose={() => {
                        this.setState({inspectionErrorDialogOpen: false});
                    }}
                >
                    <DialogTitle>
                        {t('Workplace inspection cannot be send out yet')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {inspectionErrorDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    inspectionErrorDialogOpen: false,
                                });
                            }}
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>

                {inspections && (
                    <Loader loading={this.state.submitting}>
                        <div className={classes.content}>
                            <div className={classes.formWrapper}>
                                {stepIndex === 0 && (
                                    <div className={classes.innerDialog}>

                                        {/*<DialogContent className={classes.step}>*/}
                                        {/*<Typography type="title" align="center">*/}
                                        {/*    {t('Choose workplace inspection')}*/}
                                        {/*</Typography>*/}

                                        <Typography
                                            className={classes.subheader}
                                            type="subheading"
                                            align="center"
                                        >
                                            {t(
                                                'Select workplace inspection to send out'
                                            )}
                                        </Typography>

                                        <FormControl style={{marginTop: '32px'}}>
                                            <p
                                                style={{
                                                    fontSize: '12px',
                                                    color: 'rgba(0,0,0,0.54)',
                                                    textAlign: 'left',
                                                    lineHeight: '16px',
                                                }}
                                            >
                                                {t('Workplace inspection')}
                                            </p>

                                            <Select
                                                styles={customStyles}
                                                isMulti={false}
                                                fullWidth
                                                onChange={this.handleChangeSingle}
                                                options={inspections
                                                    .filter((inspection) => !inspection.deleted)
                                                    .sort((a, b) => {
                                                        if (a.title.toLowerCase() < b.title.toLowerCase())
                                                            return -1;
                                                        if (a.title.toLowerCase() > b.title.toLowerCase())
                                                            return 1;
                                                        if (a.version < b.version)
                                                            return -1;
                                                        if (a.version > b.version)
                                                            return 1;
                                                        return 0;
                                                    })
                                                    .map((c) => ({
                                                        label: c.title,
                                                        value: c.id,
                                                        htmlLabel: (
                                                            <div
                                                                key={c.id}
                                                                value={c.id}
                                                                className={classes.menuItem}
                                                            >
                                                                <ListItemIcon style={{marginLeft: '10px',}}>
                                                                    <img
                                                                        src={
                                                                            c.source === 'cumela' ? '/assets/images/cumela-logo.png' : c.source === 'global'
                                                                                ? '/assets/images/vvw-admin-logo.svg'
                                                                                : c.rootInspectionSource === 'global' || c.rootInspectionSource === 'cumela'
                                                                                    ? '/assets/images/aangepast.svg'
                                                                                    : '/assets/images/vvw-logo.svg'
                                                                        }
                                                                        alt={t('logo')}
                                                                    />
                                                                </ListItemIcon>
                                                                <div style={{display: 'inline',}}>
                                                                    <div style={{display: 'inline',}}>
                                                                        {c.title}{' '}
                                                                    </div>
                                                                    <div style={{
                                                                        display: 'inline',
                                                                        color: '#616161',
                                                                        fontSize: '12px',
                                                                    }}>
                                                                        {'v' + c.version}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ),
                                                    }))
                                                }
                                            />
                                        </FormControl>
                                        {/*</DialogContent>*/}

                                        <Divider className={classes.divider}/>
                                        {/*<DialogActions className={classes.stepFooter}>*/}
                                        <div className={classes.stepFooter}>
                                            <Button
                                                disabled={this.state.form.inspection === ''}
                                                className={classes.stepButton}

                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleStepSubmit}
                                            >
                                                {t('Continue')}
                                            </Button>
                                        </div>
                                        {/*</DialogActions>*/}
                                    </div>
                                )}
                                {stepIndex === 1 && (
                                    <div className={classes.innerDialog}>
                                        {/*<DialogContent className={classes.step}>*/}
                                        {/*    <Typography type="title" align="center">*/}
                                        {/*        {t('Enter details')}*/}
                                        {/*    </Typography>*/}
                                        <InputLabel
                                            style={{
                                                display: 'block',
                                                marginTop: '20px',
                                                // marginBottom: '-15px',
                                            }}
                                        >
                                            {`${t('Project')}/${t('workfield')}`}
                                        </InputLabel>

                                        <FormControl
                                            style={{
                                                width: 'calc(100% - 30px)',
                                                display: 'inline-block',
                                                paddingBottom: '15px',
                                            }}
                                        >
                                            <Input
                                                multiline
                                                fullWidth
                                                className={classes.projectDropDown}
                                                inputComponent={(props) => (
                                                    <AutoCompleteView
                                                        {...props}
                                                        classes={parseClassesForAutocompleteView(props)}
                                                        isToolbox={true}
                                                        filterOption={(
                                                            option,
                                                            filterString
                                                        ) =>
                                                            option.label
                                                                .toLowerCase()
                                                                .includes(
                                                                    filterString.toLowerCase()
                                                                )
                                                        }
                                                    />
                                                )}
                                                value={this.state.form.project}
                                                placeholder=" "
                                                onChange={this.handleChangeSingleProject}
                                                inputProps={{
                                                    classes,
                                                    instanceId: 'react-select-single',
                                                    name: 'react-select-single',
                                                    simpleValue: true,
                                                    options: activeProjects
                                                        .sort(function (a, b) {
                                                            if (
                                                                a.id.includes(
                                                                    '999InvullenTijdenInspectie'
                                                                )
                                                            )
                                                                return -1;
                                                            if (
                                                                b.id.includes(
                                                                    '999InvullenTijdenInspectie'
                                                                )
                                                            ) {
                                                                return 1;
                                                            }

                                                            const numberA = parseInt(
                                                                a.externalId, 10
                                                            );
                                                            const numberB = parseInt(
                                                                b.externalId, 10
                                                            );

                                                            if (
                                                                isNaN(numberA) &&
                                                                !isNaN(numberB)
                                                            ) {
                                                                return -1;
                                                            } else if (
                                                                !isNaN(numberA) &&
                                                                isNaN(numberB)
                                                            ) {
                                                                return 1;
                                                            } else if (
                                                                !isNaN(numberA) &&
                                                                !isNaN(numberB)
                                                            ) {
                                                                return (
                                                                    numberB -
                                                                    numberA
                                                                );
                                                            } else {
                                                                return a.externalId >
                                                                b.externalId
                                                                    ? -1
                                                                    : 1;
                                                            }
                                                        })
                                                        .map((project) => ({
                                                            title: project.omschrijving,
                                                            value: project.id,
                                                            label: project.id.includes(
                                                                '999InvullenTijdenInspectie'
                                                            )
                                                                ? t(
                                                                    'Complete during inspection'
                                                                )
                                                                : project.externalId +
                                                                ': ' +
                                                                project.omschrijving,
                                                        })),
                                                }}
                                            />
                                        </FormControl>

                                        <IconButton
                                            className={'tooltip'}
                                            aria-label="Add"
                                            onClick={this.showAddProjectDialog}
                                            style={{
                                                verticalAlign: 'top',
                                                marginTop: '14px',
                                                width: '30px',
                                            }}
                                        >
                                        <span
                                            className={'tooltiptext'}
                                            style={{
                                                left: '10px',
                                                top: '-10px',
                                            }}
                                        >
                                            {t('Add project')}
                                        </span>
                                            <AddCircleOutline/>
                                        </IconButton>

                                        {filteredUsergroups.length > 0 ? (
                                            <div style={{marginBottom: '10px'}}>
                                                <InputLabel>
                                                    {t('Employee groups')}{' '}
                                                </InputLabel>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {filteredUsergroups.map(
                                            (usergroup, index) => {
                                                const selected = this.state.groups
                                                    ? this.state.groups.includes(
                                                        usergroup.id
                                                    )
                                                    : false;
                                                return (
                                                    <div
                                                        className={classes.chip}
                                                        key={index}
                                                    >
                                                        <Chip
                                                            className={classnames({[classes.selectedChip]: selected,})}
                                                            label={usergroup.name}
                                                            onClick={this.handleUsergroupClick(usergroup.id)}
                                                            onDelete={selected ? this.handleUsergroupDelete(usergroup.id) : undefined}
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}

                                        {filteredUsergroups.length > 0 &&
                                            this.state.groups &&
                                            this.state.groups.length > 0 && (
                                                <InputLabel
                                                    style={{
                                                        display: 'block',
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    {t('Employees in groups')}
                                                </InputLabel>
                                            )}

                                        {filteredUsergroups.length > 0 &&
                                            this.state.groups &&
                                            this.state.groups.length > 0 &&
                                            filteredUsergroups.map(
                                                (usergroup, index) => {
                                                    let users = '';
                                                    if (this.state.groups.includes(usergroup.id)) {
                                                        usergroup.users.forEach(
                                                            (user) => {
                                                                if (!usergrouplist.includes(user.id) && user.dtype !== 'Extern') {
                                                                    usergrouplist.push(user.id);
                                                                    users = users + user.fullname + ', ';
                                                                }
                                                            }
                                                        );
                                                    }
                                                    return users;
                                                }
                                            )}

                                        <InputLabel
                                            style={{
                                                display: 'block',
                                                marginTop: '20px',
                                                // marginBottom: '-15px',
                                            }}
                                        >
                                            {t('Employees')}
                                        </InputLabel>
                                        <FormControl>
                                            <Select
                                                styles={customStyles}
                                                closeOnSelect={false}
                                                disabled={false}
                                                isMulti
                                                onChange={this.employeeClicked(0)}
                                                options={employees.allIds
                                                    .map((id) => employees.byId[id])
                                                    .filter((empl) => empl.state === 'ACTIVE')
                                                    .filter((empl) => !(usergrouplist.includes(empl.id)))
                                                    .sort(function (a, b) {
                                                        if (a.firstname.toLowerCase() < b.firstname.toLowerCase())
                                                            return -1;
                                                        if (a.firstname.toLowerCase() > b.firstname.toLowerCase())
                                                            return 1;
                                                        if (a.lastname.toLowerCase() < b.lastname.toLowerCase())
                                                            return -1;
                                                        if (a.lastname.toLowerCase() > b.lastname.toLowerCase())
                                                            return 1;
                                                        return 0;
                                                    })
                                                    .map((c) => ({
                                                        value: c.id,
                                                        label: c.fullname.replace('  ', ' '),
                                                    }))}
                                                rtl={false}
                                            />
                                            <Divider/>
                                        </FormControl>

                                        <FormControl className="deadline-datepicker">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}
                                                                  adapterLocale={myLocale}>
                                                <DatePicker
                                                    id="DialogInspectionDeploy-deadline"
                                                    label={t('Choose date')}
                                                    okLabel={t('Ok')}
                                                    cancelLabel={t('Cancel')}
                                                    onChange={this.handleDeadlineChange}
                                                    inputFormat={t('DateFormat')}
                                                    value={this.state.form.deadline}
                                                />

                                            </LocalizationProvider>
                                            <FormHelperText
                                                className={classes.dateHelperText}
                                            >
                                                {t(
                                                    'Workplace inspection ready date'
                                                )}
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checkedIcon={
                                                        <span
                                                            className={clsx(
                                                                classes.icon,
                                                                classes.checkedIcon
                                                            )}
                                                        />
                                                    }
                                                    disableRipple={true}
                                                    checked={
                                                        this.state.form.emailAlert
                                                    }
                                                    onChange={
                                                        this.handleEmailAlertChange
                                                    }
                                                    value="emailAlert"
                                                    color="primary"
                                                />
                                            }
                                            style={{
                                                opacity: '0.8',
                                                marginBottom: '0px',
                                                marginTop: '10px',
                                                lineHeight: '12px',
                                            }}
                                            label={` ${t(
                                                'Receive email when workplace inspection is incorrect'
                                            )}`}
                                        />

                                        <FormControl>
                                            <InputLabel htmlFor="DialogInspectionDeploy-note-input">
                                                {t('Description')}
                                            </InputLabel>
                                            <Input
                                                fullWidth={true}
                                                margin="dense"
                                                id="DialogInspectionDeploy-note"
                                                multiline
                                                maxrows="4"
                                                value={this.state.form.note}
                                                onChange={this.handleChange('note')}
                                            />
                                            <FormHelperText>
                                                {t(
                                                    'Short description of workplace inspection'
                                                )}
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl>
                                            <InputLabel htmlFor="DialogInspectionDeploy-note-input">
                                                {t('Work type')}
                                            </InputLabel>
                                            <Input
                                                fullWidth={true}

                                                id="DialogInspectionDeploy-activity"
                                                value={this.state.form.activity}
                                                onChange={this.handleChange('activity')}
                                            />
                                            <FormHelperText>
                                                {`${t('Description work type')} ${t(
                                                    'Can be changed in app'
                                                )}`}
                                            </FormHelperText>
                                        </FormControl>

                                        <div
                                            style={{
                                                width: '100%',
                                                height: '460px',
                                            }}
                                        >
                                            <LocationPicker
                                                location={
                                                    this.state.form.projectLocation
                                                }
                                                hintText={t('Address where the inspection will take place')}
                                                onChange={this.handleLocationChange}
                                                address={
                                                    this.state.form.projectAddress
                                                }
                                            />
                                        </div>

                                        {/*</DialogContent>*/}
                                        <Divider className={classes.divider} style={{marginTop: '50px'}}/>
                                        {/*<DialogActions className={classes.stepFooter}>*/}
                                        <div className={classes.stepFooter}>
                                            <Button
                                                color="secondary"
                                                className={classes.stepButton}
                                                onClick={this.handleStepBack}
                                            >
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                disabled={
                                                    this.state.form.project ===
                                                    '' ||
                                                    this.state.form.project ===
                                                    null ||
                                                    (this.employeeList().length ===
                                                        0 &&
                                                        this.state.groups.length ===
                                                        0) ||
                                                    this.state.form.deadline ===
                                                    undefined
                                                }
                                                className={classes.stepButton}

                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleStepSubmit}
                                            >
                                                {t('Continue')}
                                            </Button>
                                        </div>
                                        {/*</DialogActions>*/}
                                    </div>
                                )}
                                {stepIndex === 2 && (
                                    <div className={classes.innerDialog}>

                                        {/*<DialogContent className={classes.step}>*/}
                                        {/*    <Typography type="title" align="center">*/}
                                        {/*        {t('Schedule')}*/}
                                        {/*    </Typography>*/}
                                        <Typography
                                            className={classnames({
                                                [classes.subheader]: true,
                                                [classes.padBottom]: true,
                                            })}
                                            type="subheading"
                                            align="center"
                                        >
                                            {t(
                                                'When can workplace inspection be send out'
                                            )}
                                        </Typography>
                                        <FormControl>
                                            <RadioGroup
                                                name="scheduled"
                                                value={this.state.form.scheduled}
                                                onChange={this.handleScheduledChange}
                                            >
                                                <FormControlLabel
                                                    value="now"
                                                    control={<Radio selected color="primary"/>}
                                                    label={t('Send out now')}
                                                />
                                                <FormControlLabel
                                                    style={{width: '100%'}}
                                                    value="scheduled"
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                        />
                                                    }
                                                    label={
                                                        <div style={{width: '100%',}}>
                                                        <span
                                                            className={classes.radioTitle}>
                                                            {t('Send out on')}
                                                        </span>
                                                            <FormControl
                                                                className={classnames(
                                                                    {
                                                                        [classes.datePickerControl]: true,
                                                                        [classes.datePickerVisible]:
                                                                        this.state.form.scheduled === 'scheduled',
                                                                    }
                                                                )}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                                                                      adapterLocale={myLocale}>
                                                                    <DateTimePicker
                                                                        id="DialogInspectionDeploy-schedule"
                                                                        ampm={false}
                                                                        okLabel={t('Ok')}
                                                                        cancelLabel={t('Cancel')}
                                                                        label={t('Choose date')}
                                                                        fullWidth={true}
                                                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                                                        inputFormat={t('DateTimeFormat')}
                                                                        onChange={this.handleScheduledDateChange}
                                                                        minDate={new Date()}
                                                                        maxDate={this.state.form.deadline}
                                                                        value={this.state.form.scheduledDate}
                                                                    />
                                                                </LocalizationProvider>
                                                                {/*<FormHelperText className={classes.dateHelperText}>De datum waarop de werkplekinspectie gereed moet zijn</FormHelperText>*/}
                                                            </FormControl>
                                                        </div>
                                                    }
                                                />
                                            </RadioGroup>
                                        </FormControl>

                                        {this.state.form.scheduled ===
                                            'scheduled' &&
                                            moment(
                                                this.state.form.scheduledDate
                                            ).diff(moment()) < 0 && (
                                                <div style={{color: 'red'}}>
                                                    {t('Selected date is expired')}
                                                </div>
                                            )}

                                        {/*</DialogContent>*/}
                                        <Divider className={classes.divider}/>
                                        {/*<DialogActions className={classes.stepFooter}>*/}
                                        <div className={classes.stepFooter}>
                                            <Button
                                                color="secondary"
                                                className={classes.stepButton}
                                                onClick={this.handleStepBack}
                                            >
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                disabled={
                                                    (this.state.form.scheduled ===
                                                        'scheduled' &&
                                                        !this.state.form
                                                            .scheduledDate) ||
                                                    (this.state.form.scheduled ===
                                                        'scheduled' &&
                                                        moment(
                                                            this.state.form
                                                                .scheduledDate
                                                        ).diff(moment()) < 0)
                                                }
                                                className={classes.stepButton}

                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleStepSubmit}
                                            >
                                                {t('Continue')}
                                            </Button>
                                        </div>
                                        {/*</DialogActions>*/}
                                    </div>
                                )}
                                {stepIndex === 3 && (
                                    <div className={classes.innerDialog}>

                                        {/*<DialogContent className={classes.step}>*/}
                                        <Typography
                                            className={classes.padBottom}
                                            type="body1"
                                            align="center"
                                            paragraph={true}
                                        >
                                            {t(
                                                'Check data and send out workplace inspection'
                                            )}
                                        </Typography>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{float: 'left'}}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                1.{' '}
                                                {t('Choose workplace inspection')}
                                            </Typography>

                                            <Button
                                                color="secondary"
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(0)
                                                }
                                            >
                                                {t('Edit')}
                                            </Button>
                                            <div style={{clear: 'both'}}></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t(
                                                    'Workplace inspection'
                                                )}
                                                itemDescription={
                                                    this.props.inspections.find(
                                                        (e) => e.id === this.state.form.inspection).title !== undefined &&
                                                    this.props.inspections.find(
                                                        (e) => e.id === this.state.form.inspection).title
                                                }
                                            />
                                        </div>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{float: 'left'}}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                2. {t('Enter data')}
                                            </Typography>

                                            <Button
                                                color="secondary"
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(1)
                                                }
                                            >
                                                {t('Edit')}
                                            </Button>
                                            <div style={{clear: 'both'}}></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={`${t('Project')}/${t(
                                                    'workfield'
                                                )}`}
                                                itemDescription={
                                                    this.props.projects.byId[this.state.form.project.value]
                                                        ? !this.props.projects.byId[this.state.form.project.value].id.includes(
                                                            '999InvullenTijdenInspectie'
                                                        )
                                                            ? this.props.projects.byId[this.state.form.project.value].omschrijving
                                                            : t('Complete during inspection') : ''
                                                }
                                                width={450}
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t(
                                                    'Receive email when incorrect inspection'
                                                )}
                                                itemDescription={
                                                    this.state.form.emailAlert
                                                        ? t('Yes')
                                                        : t('No')
                                                }
                                                width={450}
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Description')}
                                                itemDescription={
                                                    this.state.form.note
                                                        ? this.state.form.note
                                                        : '-'
                                                }
                                                width={450}
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Work type')}
                                                itemDescription={
                                                    this.state.form.activity
                                                        ? this.state.form.activity
                                                        : '-'
                                                }
                                                width={450}
                                            />

                                            <Typography
                                                style={{float: 'left'}}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                {t(
                                                    'Workplace inspection recipients'
                                                )}
                                            </Typography>
                                            <div style={{clear: 'both'}}></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Performer(s)')}
                                                itemDescription={
                                                    this.state.employeeItems.manual
                                                        ? this.state.employeeItems.manual
                                                            .map(
                                                                (e) => e.fullname
                                                            )
                                                            .join(', ')
                                                        : '-' /* Bugfix */
                                                }
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Total employees')}
                                                itemDescription={
                                                    this.state.employeeItems.manual
                                                        ? this.state.employeeItems
                                                            .manual.length +
                                                        employeesInGroups
                                                        : employeesInGroups /* Bugfix */
                                                }
                                            />

                                            <Typography
                                                style={{float: 'left'}}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                {`${t('Location')} / ${t(
                                                    'Deadline'
                                                )}`}
                                            </Typography>
                                            <div style={{clear: 'both'}}></div>

                                            <div className={classes.item}>
                                                <TitleDescription
                                                    className={classes.item}
                                                    itemTitle={t('Location')}
                                                    itemDescription={
                                                        this.state.form
                                                            .projectAddress !==
                                                        undefined
                                                            ? this.state.form
                                                                .projectAddress
                                                            : '-'
                                                    }
                                                />
                                            </div>

                                            <div className={classes.item}>
                                                <TitleDescription
                                                    className={classes.item}
                                                    itemTitle={t(
                                                        'Deadline for export'
                                                    )}
                                                    itemDescription={moment(
                                                        this.state.form.deadline
                                                    ).format('L')}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{float: 'left'}}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                3. {t('Send date')}
                                            </Typography>

                                            <Button
                                                color="secondary"
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(2)
                                                }
                                            >
                                                {t('Edit')}
                                            </Button>
                                            <div style={{clear: 'both'}}></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('When')}
                                                itemDescription={
                                                    this.state.form.scheduledDate
                                                        ? moment(
                                                            this.state.form
                                                                .scheduledDate
                                                        ).format('LLL')
                                                        : t('Send out now')
                                                }
                                            />
                                        </div>

                                        {/*</DialogContent>*/}
                                        <Divider className={classes.divider}/>
                                        {/*<DialogActions className={classes.stepFooter}>*/}
                                        <div className={classes.stepFooter}>
                                            <Button
                                                color="secondary"
                                                className={classes.stepButton}
                                                onClick={this.handleStepBack}
                                            >
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                className={classes.stepButton}

                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmit}
                                                disabled={this.state.submitting}
                                            >
                                                {this.state.form.scheduledDate
                                                    ? t('Schedule')
                                                    : t('Send out')}
                                            </Button>
                                        </div>
                                        {/*</DialogActions>*/}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Loader>
                )}
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogInspectionDeploy);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Button} from '@mui/material';
import classes from './DashboardTableCardEmptyState.module.scss';

type Props = {};

type State = {};

class DashboardTableCardEmptyState extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const {text, actionTitle, action} = this.props;
        return (
            <div className={classes.root}>
                <p className={classes.text}>
                    {text}
                </p>
                <Button  className={classes.emptyButton} variant="contained" color="primary" onClick={action}>{actionTitle}</Button>
            </div>
        );
    }
}

export default (DashboardTableCardEmptyState);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {inviteEmployee, setCrumbPath} from '../../actions/index';
import {inProgress} from '../../selectors/index';
import TitleDescription from '../TitleDescription/TitleDescription';
import classes from './SubscriptionGeneral.module.scss';
import AppModule from '../AppModule';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {Divider} from '@mui/material';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    loading: boolean,
    cumela?: boolean,
    columns?: any,
};

type State = {};

const mapDispatchToProps = (dispatch) => {
    return {
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Abonnee',
                    crumbs: [{ name: abonneeCompanyName, link: '/abonnee' }],
                })
            );
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
        showDetailDialog: window.location.href.endsWith('dialog'),
    };
};

class SubscriptionGeneral extends React.Component<Props, State> {
    state: State = {
        showDetailDialog: true,
    };

    render() {
        const { style, abonnee, t } = this.props;

        if (!abonnee) {
            return <div />;
        }
        const test =
            window.location.origin.includes('test') ||
            window.location.origin.includes('3000');
        const stripeUrl = (
            <a
                style={{ color: 'black' }}
                target="_blank"
                rel="noopener noreferrer"
                href={
                    'https://dashboard.stripe.com' +
                    (test ? '/test/' : '/') +
                    'customers/' +
                    abonnee.stripeId
                }
            >
                {t('Stripe')}
            </a>
        );

        const tableActionHeader = (
            <TableActionHeader
                title={
                    <span style={{ paddingLeft: '20px' }}>
                        {t('General information')}
                    </span>
                }
            />
        );

        return (
            <div>
                <AppModule overrideWidth={1260} hasTabs prepend={tableActionHeader}>
                    <div style={{ style }} className={classes.root}>
                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Company information')}
                        </div>
                        <Divider className={classes.divider} />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Company name')}
                            itemDescription={abonnee.companyName}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Contact person')}
                            itemDescription={abonnee.fullNameSubscriptionHolder}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Email address')}
                            itemDescription={
                                abonnee.emailAdressSubscriptionHolder
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Telephone number')}
                            itemDescription={
                                abonnee.phoneNumberSubscriptionHolder
                                    ? abonnee.phoneNumberSubscriptionHolder
                                    : '-'
                            }
                        />

                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Payment information')}
                        </div>
                        <Divider className={classes.divider} />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Name / Iban')}
                            itemDescription={
                                (abonnee.bic ? abonnee.bic : '-') +
                                ' / ' +
                                (abonnee.iban ? abonnee.iban : '-')
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Can adjust payment details')}
                            itemDescription={
                                abonnee.changePayment ? t('YES') : t('NO')
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Billing email address')}
                            itemDescription={
                                abonnee.factuurEmail
                                    ? abonnee.factuurEmail
                                    : '-'
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Stripe link')}
                            itemDescription={abonnee.stripeId ? stripeUrl : '-'}
                        />

                        <div
                            style={{
                                fontSize: '18px',
                                marginTop: '40px',
                                color: 'rgba(0,0,0,0.8)',
                            }}
                        >
                            {t('Address data')}
                        </div>
                        <Divider className={classes.divider} />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Country')}
                            itemDescription={
                                abonnee.country ? abonnee.country : '-'
                            }
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('City')}
                            itemDescription={abonnee.city ? abonnee.city : '-'}
                        />
                        {abonnee.street && (
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Street')}
                                itemDescription={
                                    abonnee.street
                                        ? abonnee.street
                                        : abonnee.address
                                        ? abonnee.address
                                        : '-'
                                }
                            />
                        )}
                        {abonnee.houseNumber && (
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Home number')}
                                itemDescription={
                                    abonnee.houseNumber
                                        ? abonnee.houseNumber
                                        : '-'
                                }
                            />
                        )}
                        {abonnee.street == null && (
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Address')}
                                itemDescription={
                                    abonnee.address ? abonnee.address : '-'
                                }
                            />
                        )}
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Postal')}
                            itemDescription={
                                abonnee.zipcode ? abonnee.zipcode : '-'
                            }
                        />
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SubscriptionGeneral);

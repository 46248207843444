// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {inviteEmployee, setCrumbPath} from '../../actions/index';
import {inProgress} from '../../selectors/index';
import moment from '../../lib/moment';
import classes from './SubscriptionActivity.module.scss';
import EnhancedTable from '../EnhancedTable';
import AppModule from '../AppModule';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';

type Props = {
    loading: boolean,
    cumela?: boolean,
    history?: any,
};

type State = {
    columns: Array<any>,
};

const mapDispatchToProps = (dispatch) => {
    return {
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: i18n.t('Subscriber'),
                    crumbs: [{name: abonneeCompanyName, link: '/abonnee'}],
                })
            );
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
        showDetailDialog: window.location.href.endsWith('dialog'),
    };
};

class SubscriptionActivity extends React.Component<Props, State> {
    state: State = {
        showDetailDialog: true,
        columns: [
            // { id: 'subscriptionId', numeric:true, size: '10%', label: 'Abonnee id' },
            {id: 'activatedOn', numeric: false, size: '15%', label: 'Date'},
            {
                id: 'maxEmployees',
                numeric: true,
                size: '15%',
                label: 'Max subscribers',
            },
            {
                id: 'type',
                numeric: false,
                size: '15%',
                label: 'Subscription type',
            },
            {
                id: 'description',
                numeric: false,
                size: '20%',
                label: 'Description',
            },
            {
                id: 'changedByPerson',
                numeric: true,
                size: '15%',
                label: 'By',
            },
            {
                id: 'state',
                numeric: false,
                size: '120px',
                label: 'Status',
                classes: 'Status',
            },
        ],
    };

    abonneeStateToText = (state, trial) => {
        const {t} = this.props;
        let text = '';
        let class_type = '';

        switch (state) {
            case 'REQUESTED':
                text = t('Inactive');
                class_type = 'INACTIVE';
                break;
            case 'CONFIRMED':
                text = t('Active');
                class_type = 'ACTIVE';
                break;
            case 'DISABLED':
                text = t('Inactive');
                class_type = 'INACTIVE';
                break;
            default:
        }
        //check if the subscription is just a trial
        if (text === t('Active') && trial === true) {
            text = t('Trial');
            class_type = 'TRIAL';
        }

        return <div className={'border-text ' + class_type}>{text}</div>;
    };

    mapHistoryToRow = (h, index) => ({
        ...h,
        maxEmployees:
            h.maxEmployees !== -1 ? 'Max ' + h.maxEmployees : 'Oneindig',
        activatedOn: moment(h.activatedOn).format('DD/MM/YYYY'),
        type:
            h.credits_setup && !h.vv_functions
                ? 'Sneldelen only'
                : h.cumela === true
                    ? h.credits_setup
                        ? 'Cumela (+ Sneldelen)'
                        : 'Cumela'
                    : h.caoLeo === true
                        ? h.credits_setup
                            ? 'cao Groen, Grond en Infrastructuur (+ Sneldelen)'
                            : 'cao Groen, Grond en Infrastructuur'
                        : h.credits_setup
                            ? 'Standaard (+ Sneldelen)'
                            : 'Standaard',
        description: h.description ? h.description : '-',
        changedByPerson: h.changedByPerson ? h.changedByPerson : '-',
        state: h.state ? this.abonneeStateToText(h.state, h.trial) : 'Unknown',
    });

    historySorter = () => {
        return {
            activatedOn: (a, b) => {
                if (a && b) {
                    if (a.activatedOn > b.activatedOn) {
                        return -1;
                    }
                    if (a.activatedOn < b.activatedOn) {
                        return 1;
                    }
                }
                return 0;
            },
            maxEmployees: (a, b) => {
                if (a && b) {
                    if (a.maxEmployees === -1 && b.maxEmployees === -1) {
                        return 0;
                    }
                    if (a.maxEmployees === -1) {
                        return 1;
                    }
                    if (b.maxEmployees === -1) {
                        return -1;
                    }
                    if (a.maxEmployees > b.maxEmployees) {
                        return 1;
                    }
                    if (a.maxEmployees < b.maxEmployees) {
                        return -1;
                    }
                }
                return 0;
            },
            description: (a, b) => {
                if (a && b && a.description && b.description) {
                    if (
                        a.description.toLowerCase() >
                        b.description.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.description.toLowerCase() <
                        b.description.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                if (a.description) {
                    return 1;
                }
                if (b.description) {
                    return -1;
                }
                return 0;
            },
            changedByPerson: (a, b) => {
                if (a && b && a.changedByPerson && b.changedByPerson) {
                    if (
                        a.changedByPerson.toLowerCase() >
                        b.changedByPerson.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.changedByPerson.toLowerCase() <
                        b.changedByPerson.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                if (a.changedByPerson) {
                    return 1;
                }
                if (b.changedByPerson) {
                    return -1;
                }
                return 0;
            },
            state: (a, b) => {
                if (a && b) {
                    if (a.state === 'CONFIRMED') {
                        if (b.state === 'CONFIRMED') {
                            if (a.trial === false) {
                                if (b.trial === false) return 0;
                                else return 1;
                            } else if (b.trial === false) {
                                return -1;
                            } else return 0;
                        } else if (a.trial === false) {
                            return 1;
                        } else return -1;
                    } else if (b.state === 'CONFIRMED') {
                        if (b.trial === false) {
                            return -1;
                        } else return 1;
                    } else if (a.trial === false) {
                        if (b.trial === false) return 0;
                        else return 1;
                    } else if (b.trial === false) return -1;
                    else return 0;
                }
                return 0;
            },
        };
    };

    render() {
        const {style, abonnee, loading, t} = this.props;
        const {columns} = this.state;

        if (!abonnee) {
            return <div/>;
        }

        const tableActionHeader = (
            <TableActionHeader
                title={
                    <span style={{paddingLeft: '20px'}}>
                        {t('Activity information')}
                    </span>
                }
            />
        );

        return (
            <div>
                <AppModule
                    hasTabs
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    <div style={style} className={classes.root}>
                        <EnhancedTable
                            hover
                            defaultOrderBy={'activatedOn'}
                            defaultOrder="desc"
                            columns={columns}
                            rows={this.props.history || []}
                            formatter={this.mapHistoryToRow}
                            actions={[]}
                            sorter={this.historySorter()}
                        />
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SubscriptionActivity);

import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Chip from '@mui/material/Chip';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import AutoCompleteOption from './AutoCompleteOption';
import {MenuItem} from '@mui/material';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type State = {
    inputValue: string,
};

class AutoCompleteView extends React.Component {
    state: State = {
        inputValue: '',
    };

    handleAddNew = () => {
        let sector = { name: this.state.inputValue, subscriptionHolder: null };
        if (this.props.isToolbox === false) this.props.createSector(sector);
    };

    handleInputChange = (text) => {
        this.setState({
            inputValue: text,
        });
    };

    render() {
        const { classes, t, ...other } = this.props;
        let hasSector = false;
        let isToolbox = this.props.isToolbox;
        if (isToolbox === false) {
            this.props.options.forEach((option) => {
                if (
                    this.state.inputValue &&
                    option.label &&
                    option.label.toLowerCase() ===
                        this.state.inputValue.toLowerCase()
                ) {
                    hasSector = true;
                }
            });
        }

        return (
            <Select
                optionComponent={AutoCompleteOption}
                noResultsText={
                    this.props.isToolbox ? (
                        t('No result')
                    ) : this.props.category && this.props.category === true ? (
                        <MenuItem>{t('No results')}</MenuItem>
                    ) : !hasSector ? (
                        <MenuItem onClick={this.handleAddNew}>
                            '{this.state.inputValue}' {t('add')}
                        </MenuItem>
                    ) : (
                        <MenuItem>{t('Sector already added')}</MenuItem>
                    )
                }
                //noResultsText={this.props.isToolbox ? 'Geen resultaat' : !hasSector ? <MenuItem onClick={this.handleAddNew}>'{this.state.inputValue}' toevoegen</MenuItem> : <MenuItem >Deze sector is al toegevoegd</MenuItem>}
                arrowRenderer={(arrowProps) => {
                    return arrowProps.isOpen ? (
                        <ArrowDropUpIcon />
                    ) : (
                        <ArrowDropDownIcon />
                    );
                }}
                onInputChange={this.handleInputChange}
                valueComponent={(valueProps) => {
                    const { value, children, onRemove } = valueProps;

                    const onDelete = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemove(value);
                    };

                    if (onRemove) {
                        return (
                            <Chip
                                tabIndex={-1}
                                label={children}
                                className={classes.chip}
                                deleteIcon={
                                    <CancelIcon onTouchEnd={onDelete} />
                                }
                                onRequestDelete={onDelete}
                            />
                        );
                    }

                    return (
                        <div
                            className={
                                isToolbox === true
                                    ? 'Select-value-toolbox'
                                    : 'Select-value'
                            }
                        >
                            {children}
                        </div>
                    );
                }}
                {...other}
            />
        );
    }
}

export default compose(withTranslation())(AutoCompleteView);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Dialog, DialogTitle, Fab} from '@mui/material';
import {goBack, push} from 'connected-react-router';
import {connect} from 'react-redux';
import EnhancedTable from '../EnhancedTable';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import AppModule from '../AppModule';
import Slide from '@mui/material/Slide';
import {setCrumbPath, showSnackbar} from '../../actions';
import AddIcon from '@mui/icons-material/Add';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';
import {addPromoCode, deletePromoCode, fetchPromoCodes} from "../../lib/api";
import AddPromoCodeEditCard from "./AddPromoCodeEditCard";

type Props = {
    setCrumbPath: () => void,
    promoCodes: Array<any>,
    open: boolean,
    goBack: () => mixed,
    onEdit: (abonnee: TSubscription) => mixed,
};

type State = {
    searchtext: string,
};

const mapStateToProps = (store) => {

    return {
        currentuser: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchPromoCodes: () => {
            dispatch(fetchPromoCodes());
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: i18n.t('Promotion Codes')}));
        },
        goBack: () => {
            dispatch(goBack());
        },
        editPromoCode: (id) => {
            dispatch(push('/promocode/' + id));
        },
        deletePromoCode: (code) => {
            dispatch(deletePromoCode(code));
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class PromoCodesOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        showAddDialog: false,
        actions: [
            {
                id: 'delete',
                label: 'Remove',
                isVisible: true,
            },
        ],
        columns: [
            {
                id: 'code',
                numeric: false,
                size: '25%',
                label: 'Promo Code',
            },
            //{ id: 'nrEmployees', numeric: true, size: '14%', label: 'Medewerkers' },
            {
                id: 'startDate',
                numeric: true,
                size: '25%',
                label: 'Start Date',
            },
            {
                id: 'endDate',
                numeric: true,
                size: '25%',
                label: 'End Date',
            },
            {
                id: 'discount',
                numeric: true,
                size: '25%',
                label: 'Discount',
            },

        ],
    };

    onRowClick = () => {
        // Do Nothing
    }

    handleAction = (event, action) => {
        const promoCode = action.rowId;

        if (action.id === 'edit') {
            this.props.editPromoCode(promoCode);
        }
        if (action.id === 'delete') {

            deletePromoCode(promoCode)
                .then((entities) => {
                    this.setState({
                        promoCodes: entities.body
                    });

                })
                .catch((error) => {
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while deleting Promo Codes'
                        )}: ` + error.message
                    )
                    throw error;
                });
        }
    };

    hideAddDialog = () => {
        this.setState({
            showAddDialog: false
        });
    }

    goShowAddDialog = () => {
        this.setState({
            showAddDialog: true
        });
    }

    onSave = (code, startDate, endDate, discount) => {

        const data = {
            id: code,
            startDate: startDate,
            endDate: endDate,
            discount: discount
        }
        addPromoCode(data)
            .then((entities) => {
                this.setState({
                    showAddDialog: false,
                    promoCodes: entities.body
                });
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while adding Promo Codes'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    componentDidMount() {
        this.props.setCrumbPath();

        fetchPromoCodes()
            .then((entities) => {
                this.setState({
                    promoCodes: entities.body
                });
                // this.props.setCrumbPath(entities.body._embedded.trendinspection);
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching Promo Codes'
                    )}: ` + error.message
                )
                throw error;
            });
    }

    mapPromoCodeToRow = (promocode, index) => {
        return {
            ...promocode,
            code: promocode.id,
            startDate: promocode.startDate,
            endDate: promocode.endDate,
            discount: promocode.discount,
        };
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    promoCodeSorter = () => {
        return {
            code: (a, b) => {
                if (a && b) {
                    if (
                        a.code.toLowerCase() >
                        b.code.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.companyName.toLowerCase() <
                        b.companyName.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            },
            startDate: (a, b) => {
                if (a && b) {
                    if (a.startDate > b.startDate) {
                        return 1;
                    }
                    if (a.startDate < b.startDate) {
                        return -1;
                    }
                }
                return 0;
            },
            endDate: (a, b) => {
                if (a && b) {
                    if (a.endDate > b.endDate) {
                        return 1;
                    }
                    if (a.endDate < b.endDate) {
                        return -1;
                    }
                }
                return 0;
            },
            discount: (a, b) => {
                if (a && b) {
                    if (a.discount > b.discount) {
                        return 1;
                    }
                    if (a.discount < b.discount) {
                        return -1;
                    }
                }
                return 0;
            },

        };
    };

    render() {
        const {t} = this.props;
        if (
            this.state && this.props.currentuser &&
            this.props.currentuser.roles.includes('ADMIN')
        ) {
            const {promoCodes, actions, columns, showAddDialog} = this.state;

            console.log('promoCodes: ', promoCodes);
            const tableActionHeader = (
                <TableActionHeader
                    searchPlaceholder={t('Search promo codes')}
                    searchAlwaysOpen={true}
                    onSearchChange={this.handleSearch}
                    title={'Codes '}
                />
            );

            return (
                <div>
                    <AppModule prepend={tableActionHeader}>
                        <EnhancedTable
                            hover
                            columns={columns}
                            onClick={this.onRowClick}
                            rows={promoCodes}
                            formatter={this.mapPromoCodeToRow}
                            actions={actions} //actions={[]}
                            onAction={this.handleAction}
                            sorter={this.promoCodeSorter()}
                            emptyState={t('There are no subscribers yet.')}
                        />

                        <div
                            style={{
                                position: 'fixed',
                                top: '84px',
                                right: '36px',
                                zIndex: 1500,
                            }}
                        >
                            <div className={'tooltip'} style={{padding: 0}}>
                                <span
                                    className={'tooltiptext'}
                                    style={{top: '60px'}}
                                >
                                    {t('Add Promo Code')}
                                </span>
                                <Fab

                                    onClick={this.goShowAddDialog}
                                >
                                    <AddIcon/>
                                </Fab>
                            </div>
                        </div>

                        <Dialog
                            open={showAddDialog}
                            onClose={this.hideAddDialog}
                            TransitionComponent={Transition}
                            className="victor"
                        >
                            <DialogTitle className="title">
                                {t('Create Promotion Code')}
                            </DialogTitle>
                            <AddPromoCodeEditCard
                                hideParents={true}
                                onSave={this.onSave}
                                goBack={this.hideAddDialog}
                            />

                        </Dialog>
                    </AppModule>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(PromoCodesOverview);

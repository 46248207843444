// @flow
import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {push} from 'connected-react-router';
import {connect} from "react-redux";
import {fetchPDF} from "../../../lib/api";
import moment from "moment";
import {percentColors4} from "../../../lib/utils";
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    toolbox: any,
    toolboxAssign: any,
    key?: number,
    fetchSectors: () => mixed,
    goToDetailView: (toolboxId: string, deployedDate: Date) => mixed,
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        goToDetailView: (toolboxAssign) => {
            dispatch(push('/toolbox/details/' + toolboxAssign.toolbox.id + '/' + toolboxAssign.deploymentId + '/results/' + toolboxAssign.user.id + '?from=rapportages'));
        },
        goToDetailViewToolbox: (toolboxId, deployedDate) => {
            dispatch(push('/toolbox/details/' + toolboxId + '/' + deployedDate + '?from=rapportagesTM#informatie'));
        },
        goTo: path => {
            dispatch(push(path));
        },

    };
};

const mapStateToProps = (store, props) => {

    return {
        allSectors: store.entities.sectors,
    };
};

class StatisticsRowToolbox extends React.Component<Props, State> {

    state: State = {
        expanded: false
    };

    onItemClicked = clickedToolboxAssign => {
        this.props.goToDetailView(clickedToolboxAssign);
    };

    getTMPDF = (toolboxAssign) => {
        let data=[];
        toolboxAssign.listParticipants.map (p=> data.push(p));

        data.map(p=> p.listParticipants=[]);

        let pdfToolbox=this.props.toolbox;
        pdfToolbox.sectors = pdfToolbox.sectors.map((id, index) => {return this.props.allSectors.byId[id]? this.props.allSectors.byId[id].name: id;});

        data[0].toolbox=pdfToolbox;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOXMEETING_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek
            }
        })
            .then(res => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', 'toolboxMeetingRapportage_' + toolboxAssign.toolbox.title + '.pdf');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch(err => console.log(err));

        toolboxAssign.listParticipants=data;
    };

    checkNrFilledOutToolboxes(toolboxAssign){

        let filledOut=0;
        // if (toolboxAssign.listParticipants !== 0){
        //     toolboxAssign.listParticipants.forEach(p=> {
        //         if (p.result && p.result.answers) {
        //             filledOut++;
        //         }
        //     });
        // }

        return filledOut;
    }

    getStateToolboxMeeting(toolboxAssign){

        let completed=0;
        if (toolboxAssign.listParticipants !== 0){
            toolboxAssign.listParticipants.forEach(p=> {
                let nrC=this.getNrCompletedInspections(p);
                if (nrC===p.toolbox.toolbox.questions.length){
                    completed++;
                }
            })
        }

        return completed;
    }

    getNrCompletedInspections(toolboxAssign){
        let numberCompleted = 0;
        if (toolboxAssign.result && toolboxAssign.result.answers) {
            toolboxAssign.result.answers.forEach(answer => {
                if (answer && answer.correct) numberCompleted++;
            });
        }

        return numberCompleted;
    }

    render() {
        let {toolboxAssign , key, t, handleRowClick, ...childProps} = this.props;

        // let groupedToolboxMeeting=groupedToolboxAssign.filter(ta=> ta.id=toolboxAssign.id);
        let nrCompleted= this.getNrCompletedInspections(toolboxAssign);

        return (
            <React.Fragment>
                <TableRow {...childProps} key={this.props.key} onClick={handleRowClick}>
                    <TableCell
                        style={{width: '35%',borderTop:'1px solid rgba(235, 235, 235, 1)'}}>{toolboxAssign.deployed ? moment(toolboxAssign.deployed).format(t('dateTime')) : '-'}</TableCell>
                   <TableCell
                       style={{width: '35%'}}>{(toolboxAssign.user.roles.includes("EXTERN")) ? toolboxAssign.user.fullname + '(' + t('external') + ')' : toolboxAssign.user.fullname}</TableCell>
                    <TableCell
                        style={{width: '20%'}}>{toolboxAssign.toolboxMeeting === false ? (toolboxAssign.result && toolboxAssign.result.date ? moment(toolboxAssign.result.date).format(t('dateTime')) : '-') : (toolboxAssign.result && toolboxAssign.result.date ? moment(toolboxAssign.result.date).format(t('dateTime')) : "-")}</TableCell>
                    <TableCell style={{width: '10%'}}>
                        <div
                            className={'border-text'}
                            style={toolboxAssign.result ? {
                                backgroundColor: getColorForPercentage(nrCompleted / toolboxAssign.toolbox.toolbox.questions.length),
                                borderColor: getColorForPercentage(nrCompleted / toolboxAssign.toolbox.toolbox.questions.length),
                                width: '60px'
                            } : {
                                borderColor:'Transparent', backgroundColor:'Transparent', color:'black', width: '30px'
                            }}
                        >
                            {toolboxAssign.result ? nrCompleted + '/' + toolboxAssign.toolbox.toolbox.questions.length : "-"}
                        </div>
                        

                    </TableCell>
                </TableRow>

                {toolboxAssign.toolboxMeeting === true && (toolboxAssign.listParticipants.map(p => {

                    let nrCompletedP=this.getNrCompletedInspections(p);
                    return (
                        <TableRow  {...childProps} key={p.id} style={this.state.expanded===true? {}: {display:'none'}} onClick={() => this.onItemClicked(p)} >
                            <TableCell style={{width: '5%', borderBottom:'0px'}}></TableCell>
                            <TableCell style={{width: '10%', borderBottom:'0px'}}></TableCell>
                            <TableCell
                                style={{width: '35%'}}>{p.extern ? p.user.fullname + '(' + t('external') + ')' : p.user.fullname}</TableCell>
                            <TableCell style={{ width: '15%' }}></TableCell>
                            <TableCell
                                style={{width: '15%'}}>{p.result && p.result.date ? moment(p.result.date).format(t('dateTime')) : '-'}</TableCell>
                            <TableCell style={{width: '15%'}}>
                                 <div
                                    className={'border-text'}
                                    style={p.result ?{
                                        backgroundColor: getColorForPercentage(nrCompletedP/ p.toolbox.toolbox.questions.length),
                                        borderColor: getColorForPercentage(nrCompletedP/ p.toolbox.toolbox.questions.length),
                                        width: '60px'
                                    }:{borderColor:'Transparent', backgroundColor:'Transparent', color:'black'}}
                                >
                                    {p.result ? nrCompletedP + '/' + p.toolbox.toolbox.questions.length : '-'}
                                </div>

                            </TableCell>
                            <TableCell style={{width: '1%', borderBottom:'0px'}}></TableCell>
                            <TableCell style={{width: '1%', borderBottom:'0px'}}></TableCell>
                        </TableRow>)
                    }))}
            </React.Fragment>

        );
    }
};

var percentColors = percentColors4;

var getColorForPercentage = function(pct) {
    let color = percentColors[1];

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

// export default StatisticsRowToolbox;

export default compose(withTranslation(),connect(mapStateToProps, mapDispatchToProps))(StatisticsRowToolbox);

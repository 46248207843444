// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';
import classnames from 'classnames';
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import classes from './GuideShortcut.module.scss';

type Props = {
    loading: boolean,
    title: string,
    icon: mixed, //icon component
    onClick: () => mixed,
    disabled: boolean
};

type State = {};

const mapStateToProps = store => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

class GuideShortcut extends React.Component {
    props: Props;
    state: State = {};

    render() {
        const { disabled, className, onClick, } = this.props;

        classnames({
            [classes.root]: true,
            [className]: true,
            [classes.disabled]: disabled
        });

        return (
            <ButtonBase
                disabled={disabled}
                className={classes.guideButton}
                onClick={() => {
                    if (onClick && !disabled) onClick();
                }}
            >
                <div className={classes.title}>{this.props.title}</div>
                <div className={classes.icon}>{this.props.icon}</div>
            </ButtonBase>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(GuideShortcut);

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import muiTheme from './lib/theme';
import middlewares from './lib/middlewares.js';
import createRootReducer from "./reducers";
import Root from './components/Root';
import './index.css';
import './i18n';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const history = createBrowserHistory();

// no longer needed? https://github.com/callemall/material-ui/blob/master/CHANGELOG.md v0.19.0
//injectTapEventPlugin(); // Needed for onTouchTap, see: http://stackoverflow.com/a/34015469/988941

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
    createRootReducer(history),
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
);

const container = document.getElementById('Root');
const root = createRoot(container);
root.render(<Root store={store} theme={muiTheme} />)
// @flow
import React from 'react';
import {AppBar, IconButton, ListItem, Toolbar, Typography} from '@mui/material';
import {connect} from 'react-redux';
import AppModule from '../../AppModule';
import {getAppInspections, getAppToolboxes, sendAssignResult,} from '../../../actions';
import BlockPreview from '../../ToolboxPreview/BlockPreview';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import './ToolboxOpdracht.css';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RadioUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckboxUncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckboxCheckedIcon from '@mui/icons-material/CheckBox';
import {push} from 'connected-react-router';
import {inProgress} from '../../../selectors';
import moment from 'moment';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';
import classes from './ToolboxOpdracht.module.scss';

moment.locale(i18n.t('LOCALE'));

type Props = {
    toolboxId: string,
    toolbox: Array<any>,
};

type State = {
    feedbackText: string,
    questionsFinished: any,
    finished: boolean,
};

const mapStateToProps = (store, props) => {
    return {
        toolbox:
            store.entities.appToolboxes &&
            store.entities.appToolboxes.byId[props.match.params.toolboxId],
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendAssignResult: (result) => {
            dispatch(sendAssignResult(ownProps.match.params.toolboxId, result));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppToolboxes: () => {
            dispatch(getAppToolboxes());
        },
        getAppInspections: () => {
            dispatch(getAppInspections());
        },
    };
};

function checkAnswers(type, arr1, arr2) {
    if (type === 'singleResponse') {
        if (arr2.includes(arr1[0])) {
            return true;
        }
        return false;
    } else {
        if (arr1.length !== arr2.length) return false;
        for (var i = arr1.length; i--; ) {
            if (arr1[i] !== arr2[i]) return false;
        }
    }

    return true;
}

class ToolboxOpdracht extends React.Component<Props, State> {
    props: Props;
    state: State = {
        stepIndex: 0,
        tab: 0,
        tabsCounter: 0,
        selectedAnswers: [],
        buttonText: i18n.t('Answer'),
        buttonDisabled: true,
        buttonNextQuestion: false,
        outcomeText: '',
        explanation: '',
        feedbackText: '',
        finished: false,
    };

    componentDidMount() {
        this.props.getAppToolboxes();
    }

    handleFormChange = (key) => (event) => {
        if (event.target.value.length < 255) {
            this.setState({
                feedbackText: event.target.value,
            });
        }
    };

    toSelfTest = () => {
        const { t } = this.props;
        this.setState({
            stepIndex: this.state.stepIndex + 1,
        });

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (
            localStorage.getItem('answers' + this.props.match.params.toolboxId)
        ) {
            this.setState({
                selectedAnswers: JSON.parse(
                    localStorage.getItem(
                        'answers' + this.props.match.params.toolboxId
                    )
                ),
            });
        }
        if (
            localStorage.getItem(
                'questionsfinished' + this.props.match.params.toolboxId
            )
        ) {
            this.setState({
                questionsFinished: JSON.parse(
                    localStorage.getItem(
                        'questionsfinished' + this.props.match.params.toolboxId
                    )
                ),
            });
        }
        if (
            localStorage.getItem(
                'tabscounter' + this.props.match.params.toolboxId
            )
        ) {
            this.setState({
                tabsCounter: JSON.parse(
                    localStorage.getItem(
                        'tabscounter' + this.props.match.params.toolboxId
                    )
                ),
                tab: JSON.parse(
                    localStorage.getItem(
                        'tabscounter' + this.props.match.params.toolboxId
                    )
                ),
            });
        }
        if (
            localStorage.getItem('finished' + this.props.match.params.toolboxId) &&
            localStorage.getItem('finished' + this.props.match.params.toolboxId) === 'true'
        ) {
            this.setState({
                buttonText: t('Continue'),
                buttonNextQuestion: true,
                buttonDisabled: false,
            });
        }
        this.handleTabChange(null, this.state.tab);
    };

    sendAssignResult = () => {
        this.setState({
            finished: true,
        });

        localStorage.removeItem('answers' + this.props.match.params.toolboxId);
        localStorage.removeItem(
            'questionsfinished' + this.props.match.params.toolboxId
        );
        localStorage.removeItem(
            'tabscounter' + this.props.match.params.toolboxId
        );
        localStorage.removeItem('finished' + this.props.match.params.toolboxId);

        let answers = [];
        this.props.toolbox.toolbox.questions.map((question, index) =>
            answers.push({
                id: question.id,
                answers: this.state.selectedAnswers[index],
                correct: checkAnswers(
                    question.type,
                    this.state.selectedAnswers[index],
                    question.correctAnswers
                ),
            })
        );
        let results;
        if (this.state.feedbackText.length > 0) {
            results = {
                answers: answers,
                complete: true,
                date: Date.now(),
                feedback: this.state.feedbackText,
            };
        } else {
            results = {
                answers: answers,
                complete: true,
                date: Date.now(),
                feedback: '',
            };
        }
        this.props.sendAssignResult(results);
    };

    backToOverview = () => {
        this.props.goTo('/web/opdrachten');
        this.props.getAppToolboxes();
        this.props.getAppInspections();
    };

    handleTabChange = (event: any, value: number) => {
        const { t } = this.props;
        this.setState(
            {
                tab: value,
            },
            () => {
                const pos = this.state.selectedAnswers[this.state.tab];
                if (pos || pos === 0) {
                    if (!(this.state.questionsFinished >= this.state.tab)) {
                        //Question not finished

                        this.setState({
                            outcomeText: '',
                        });
                    } else {
                        //Question finished
                        const currentQuestion = this.props.toolbox.toolbox
                            .questions[this.state.tab];
                        const question = this.props.toolbox.toolbox.questions[this.state.tab].correctAnswers;
                        const selected = this.state.selectedAnswers[this.state.tab];

                        let explanation = currentQuestion.explanationAnswers;
                        if (explanation) {
                            this.setState({
                                explanation: explanation,
                            });
                        }

                        let isAllCorrect = true;

                        for (
                            let i = 0;
                            currentQuestion.possibleAnswers.length !== i;
                            i++
                        ) {
                            this.refs[
                                'divAnswerBoxChecked' + i
                            ].style.visibility = 'hidden';
                            this.refs['divAnswerBox' + i].style.visibility =
                                'hidden';
                        }

                        for (
                            let i = 0;
                            i !== currentQuestion.possibleAnswers.length;
                            i++
                        ) {
                            this.refs['divAnswerIcon' + i].style.visibility =
                                'visible';
                        }

                        for (let i = 0; i < selected.length; i++) {
                            if (currentQuestion.type === 'singleResponse') {
                                this.refs[
                                    'divAnswer' + selected
                                ].style.backgroundColor = '#D7D7D7';
                                if (selected[i] !== question[i]) {
                                    isAllCorrect = false;
                                }
                            } else {
                                this.refs[
                                    'divAnswer' + selected[i]
                                ].style.backgroundColor = '#D7D7D7';
                                if (selected !== question) {
                                    isAllCorrect = false;
                                }
                            }
                        }

                        if (isAllCorrect) {
                            this.refs['outcomeText'].style.color = 'green';
                            this.setState({
                                outcomeText: t('Your answer (s) are correct'),
                            });
                        } else {
                            this.refs['outcomeText'].style.color = 'red';
                            this.setState({
                                outcomeText: t('Your answer (s) are incorrect.')
                            });
                        }
                    }
                }

                if (value < this.state.tabsCounter) {
                    this.setState({
                        buttonDisabled: true,
                    });
                } else {
                    if (pos || pos === 0) {
                        this.setState({
                            buttonDisabled: false,
                        });
                    } else {
                        this.setState({
                            buttonDisabled: true,
                            outcomeText: null,
                            explanation: null,

                        });
                    }
                }
            }
        );
    };

    clearRefsStyle = () => {
        const { tab } = this.state;
        for (let key in this.refs) {
            if (this.refs.hasOwnProperty(key)) {
                let element = this.refs[key];

                if (element.innerHTML) {
                    element.style.backgroundColor = '#FFFFFF';
                }
            }
        }
        for (
            let i = 0;
            this.props.toolbox.toolbox.questions[tab].possibleAnswers.length !==
            i;
            i++
        ) {
            this.refs['divAnswerBoxChecked' + i].style.visibility = 'hidden';
            this.refs['divAnswerBox' + i].style.visibility = 'visible';
        }
    };

    handleAnswerClick = (selected) => {
        const { tab } = this.state;

        if (this.state.tabsCounter === tab && !this.state.buttonNextQuestion) {
            let newArray = this.state.selectedAnswers.slice();

            // Single response
            if (
                this.props.toolbox.toolbox.questions[tab].type ===
                'singleResponse'
            ) {
                newArray[tab] = [selected];

                this.setState(
                    {
                        selectedAnswers: newArray,
                    },
                    () => {
                        this.clearRefsStyle();
                        this.refs[
                            'divAnswer' + selected
                        ].style.backgroundColor = '#D7D7D7';
                        this.refs['divAnswerBox' + selected].style.visibility =
                            'hidden';
                        this.refs[
                            'divAnswerBoxChecked' + selected
                        ].style.visibility = 'visible';
                        this.setState({
                            buttonDisabled: false,
                        });
                    }
                );
            } else {
                // Multi Response
                // Empty array for this question
                if (!newArray[tab]) {
                    newArray[tab] = [];
                    newArray[tab].push(selected);

                    this.setState(
                        {
                            selectedAnswers: newArray,
                        },
                        () => {
                            this.refs[
                                'divAnswer' + selected
                            ].style.backgroundColor = '#D7D7D7';
                            this.refs[
                                'divAnswerBox' + selected
                            ].style.visibility = 'hidden';
                            this.refs[
                                'divAnswerBoxChecked' + selected
                            ].style.visibility = 'visible';
                            this.setState({
                                buttonDisabled: false,
                            });
                        }
                    );
                } else {
                    let doPush = true;
                    let externalElement = 0;

                    newArray[tab].forEach(function (element) {
                        if (element === selected) {
                            doPush = false;

                            let index = newArray[tab].indexOf(element);
                            if (index > -1) {
                                newArray[tab].splice(index, 1);
                                externalElement = element;
                            }

                            if (newArray[tab].length === 0) {
                                delete newArray[tab];
                            }
                        }
                    });

                    if (doPush) {
                        newArray[tab].push(selected);
                    } else {
                        this.refs[
                            'divAnswer' + externalElement
                        ].style.backgroundColor = '#F5F5F5';
                        this.refs['divAnswerBox' + selected].style.visibility =
                            'visible';
                        this.refs[
                            'divAnswerBoxChecked' + selected
                        ].style.visibility = 'hidden';

                        if (!newArray[tab]) {
                            this.setState({
                                buttonDisabled: true,
                            });
                        }
                    }

                    this.setState(
                        {
                            selectedAnswers: newArray,
                        },
                        () => {
                            if (doPush) {
                                this.refs[
                                    'divAnswer' + selected
                                ].style.backgroundColor = '#D7D7D7';
                                this.refs[
                                    'divAnswerBox' + selected
                                ].style.visibility = 'hidden';
                                this.refs[
                                    'divAnswerBoxChecked' + selected
                                ].style.visibility = 'visible';

                                this.setState({
                                    buttonDisabled: false,
                                });
                            }
                        }
                    );
                }
            }
        }
    };

    handlePageBack = () => {
        if (this.state.stepIndex === 0) {
            this.backToOverview();
        } else {
            this.setState({
                stepIndex: this.state.stepIndex - 1,
            });
        }
    };

    handleButtonAnswer = () => {
        const { t } = this.props;
        if (this.state.buttonNextQuestion) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            if (
                this.props.toolbox.toolbox.questions.length ===
                this.state.tabsCounter + 1
            ) {
                this.setState({
                    stepIndex: 2,
                });
            } else {

                this.setState({
                    tab: this.state.tab + 1,
                    buttonNextQuestion: false,
                    buttonText: i18n.t('Answer'),
                    buttonDisabled: true,
                    outcomeText: '',
                    explanation: null,
                });

                if (
                    this.state.tabsCounter <
                    this.props.toolbox.toolbox.questions.length
                ) {
                    localStorage.setItem(
                        'tabscounter' + this.props.match.params.toolboxId,
                        JSON.stringify(this.state.tabsCounter + 1)
                    );

                    localStorage.setItem(
                        'answers' + this.props.match.params.toolboxId,
                        JSON.stringify(this.state.selectedAnswers)
                    );

                    localStorage.setItem(
                        'questionsfinished' + this.props.match.params.toolboxId,
                        JSON.stringify(this.state.tab)
                    );

                    this.setState({
                        tabsCounter: this.state.tabsCounter + 1,
                    });
                }
            }
        } else {
            const { tab } = this.state;
            const question = this.props.toolbox.toolbox.questions[tab];
            const selected = this.state.selectedAnswers[tab];

            let explanation = question.explanationAnswers;

            if (explanation) {
                this.setState({
                    explanation: explanation,
                });
            }

            window.scrollTo(0, document.body.scrollHeight);

            for (var i = 0; i !== question.possibleAnswers.length; i++) {
                this.refs['divAnswerIcon' + i].style.visibility = 'visible';
                this.refs['divAnswerBox' + i].style.visibility = 'hidden';
                this.refs['divAnswerBoxChecked' + i].style.visibility =
                    'hidden';
            }

            this.setState({
                questionsFinished: tab,
            });

            // Single response
            if (
                this.props.toolbox.toolbox.questions[tab].type ===
                'singleResponse'
            ) {
                if (question.correctAnswers.includes(selected[0])) {
                    //this.paintCorrectAnswer(selected);

                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({
                        outcomeText: t('Your answer is correct'),
                    });
                } else {
                    this.refs['outcomeText'].style.color = 'red';
                    this.setState({
                        outcomeText:
                            t(
                                'Your answer is not correct.'
                            ),
                    });
                }
            } else {
                // Multiresponse
                let isAllCorrect = true;

                selected.sort(function (a, b) {
                    return a - b;
                });

                if (selected.length !== question.correctAnswers.length) {
                    isAllCorrect = false;
                }

                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] !== question.correctAnswers[i]) {
                        isAllCorrect = false;
                    }
                }

                if (isAllCorrect) {
                    this.refs['outcomeText'].style.color = 'green';
                    this.setState({
                        outcomeText: t('Youe answers are correct'),
                    });
                } else {
                    this.refs['outcomeText'].style.color = 'red';
                    this.setState({
                        outcomeText: t('Your answer(s) are not correct.'),
                    });
                }
            }

            // Change button state
            if (
                this.props.toolbox.toolbox.questions.length ===
                this.state.tabsCounter + 1
            ) {
                localStorage.setItem(
                    'questionsfinished' + this.props.match.params.toolboxId,
                    JSON.stringify(tab)
                );
                localStorage.setItem(
                    'finished' + this.props.match.params.toolboxId,
                    true
                );
                localStorage.setItem(
                    'answers' + this.props.match.params.toolboxId,
                    JSON.stringify(this.state.selectedAnswers)
                );
                this.setState({
                    buttonText: t('Complete self-test'),
                    buttonNextQuestion: true,
                });
            } else {
                this.setState({
                    buttonText: t('Next question'),
                    buttonNextQuestion: true,
                });
            }
        }
    };

    render() {
        const { toolbox, loading, t } = this.props;
        const {
            stepIndex,
            tab,
            tabsCounter,
            buttonText,
            buttonDisabled,
        } = this.state;

        let subjects = [];
        let meetingDate = null;
        if (toolbox) {
            if (toolbox.toolbox.blocks[0]) {
                if (toolbox.toolbox.blocks[0].meetingBlock) {
                    if (toolbox.toolbox.blocks[0].meetingBlock.meetingDate) {
                        meetingDate = new Date(
                            toolbox.toolbox.blocks[0].meetingBlock.meetingDate
                        );
                    }

                    if (toolbox.toolbox.blocks[0].meetingBlock.description) {
                        toolbox.toolbox.blocks[0].meetingBlock.description
                            .split(/\r\n|\r|\n/g)
                            .forEach((e) => {
                                if (e !== '') {
                                    subjects.push(
                                        <ListItem style={{ paddingBottom: 1 }}>
                                            &#8226; {e}
                                        </ListItem>
                                    );
                                }
                            });
                    }
                }
            }
        }

        //const monthNames = ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];

        // window.$crisp.push(['do', 'chat:hide']);
        const tableActionHeader = (
            <TableActionHeader
                title={stepIndex === 0 ? t('Toolbox information') : ''}
            />
        );
        if (
            !toolbox ||
            (toolbox &&
                toolbox.toolbox.results &&
                toolbox.toolbox.results.complete === true)
        ) {
            return (
                <AppModule className="appModule" loading={loading}>
                    <div>
                        <div
                            className={classes.tbExternPaperFeedback}
                            id="tbExternPaperFeedback"
                        >
                            {window.innerWidth >= 500 ? (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    />
                                </div>
                            ) : (
                                <div style={{ marginTop: '50px' }}>
                                    <img
                                        src="/assets/images/vvw.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </div>
                            )}
                            <p style={{ textAlign: 'center' }}>
                                <br />
                                {t('This toolbox is no longer available')}
                            </p>
                            <br />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    onClick={this.backToOverview}
                                    id="sendresultsButton"
                                    style={{
                                        margin: 'auto',
                                        marginBottom: '100px',
                                    }}
                                >
                                    {t('Back to the overview')}
                                </Button>
                            </div>
                            <br />
                            <div style={{ width: '100px', margin: 'auto' }}>
                                <a href="https://www.facebook.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/facebook.png"
                                        alt={t('Veilig Vakwerk')}
                                    />
                                </a>
                                <a href="https://twitter.com/VeiligVakwerk">
                                    <img
                                        src="/assets/images/twitter.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                                <a href="https://www.linkedin.com/company/fiftytwodegreesnorth/">
                                    <img
                                        src="/assets/images/linkedin.png"
                                        alt={t('Veilig Vakwerk')}
                                        style={{ paddingLeft: '16px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </AppModule>
            );
        }

        const tabSize =
            toolbox.toolbox.questions.length > 5
                ? 100 / 5
                : 100 / toolbox.toolbox.questions.length;

        return (
            <div className="App">
                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        {stepIndex === 0 ||
                        stepIndex === 1 ||
                        stepIndex === 2 ? (
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon className={classes.backButton}/>
                            </IconButton>
                        ) : (
                            <div style={{ width: '48px' }}></div>
                        )}
                        <Typography
                            type="title"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig Vakwerk')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <AppModule
                    className="appModule"
                    loading={loading}
                    prepend={tableActionHeader}
                >
                    <div className={classes.root}
                        style={{
                            minWidth: '128px',
                            maxWidth: '768px',
                            margin: '32px auto',
                            padding: '32px',
                        }}
                    >
                        {stepIndex === 0 && (
                            <div
                                className={classes.paper}
                                id="tbExternPaperMain"
                            >
                                <div>
                                    <span className="material-light-caption">
                                        {t('Author')}: {toolbox.author}
                                    </span>
                                    <span
                                        className="material-light-caption medium-italic"
                                        style={{ float: 'right' }}
                                    >
                                        {Math.abs(
                                            moment(toolbox.date).diff(
                                                moment(),
                                                'days'
                                            )
                                        ) > 7
                                            ? moment(toolbox.date).format(
                                                  t('dateTime')
                                              )
                                            : moment().to(moment(toolbox.date))}
                                    </span>
                                </div>

                                {toolbox.toolbox.blocks[0] !== undefined ? (
                                    toolbox.toolbox.blocks[0].type ===
                                    'meetingBlock' ? (
                                        <div
                                            className={classes.toolboxMeetingBox}
                                        >
                                            <Typography
                                                className={classes.toolboxMeetingBoxH3 }
                                            >
                                                {t('Toolbox meeting')}
                                            </Typography>

                                            <div
                                                className={
                                                    classes.toolboxMeetingLeft
                                                }
                                            >
                                                {t('Date')}
                                            </div>
                                            <div
                                                className={
                                                    classes.toolboxMeetingRight
                                                }
                                            >
                                                {moment(
                                                    toolbox.toolbox.blocks[0]
                                                        .meetingBlock
                                                        .meetingDate
                                                ).format(t('DD-MM-YYYY'))}
                                            </div>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <div
                                                className={
                                                    classes.toolboxMeetingLeft
                                                }
                                            >
                                                {t('Time of the day')}
                                            </div>
                                            <div
                                                className={
                                                    classes.toolboxMeetingRight
                                                }
                                            >
                                                {moment(
                                                    toolbox.toolbox.blocks[0]
                                                        .meetingBlock
                                                        .meetingDate
                                                ).format(t('hourMinute'))}
                                            </div>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <div
                                                className={
                                                    classes.toolboxMeetingLeft
                                                }
                                            >
                                                {t('Location')}
                                            </div>
                                            <div
                                                className={
                                                    classes.toolboxMeetingRight
                                                }
                                                style={{ paddingBottom: '0px' }}
                                            >
                                                {
                                                    toolbox.toolbox.blocks[0]
                                                        .meetingBlock.location
                                                }
                                            </div>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <div
                                                className={
                                                    classes.toolboxMeetingLeft
                                                }
                                                style={{
                                                    width: '100%',
                                                    paddingBottom: '0px',
                                                }}
                                            >
                                                {t('Topics to be discussed')}
                                            </div>

                                            <div
                                                className={
                                                    classes.toolboxMeetingRight
                                                }
                                                style={{ paddingBottom: '0px' }}
                                            >
                                                {subjects}
                                            </div>

                                            <div
                                                style={{
                                                    clear: 'both',
                                                    paddingBottom: '16px',
                                                }}
                                            ></div>
                                        </div>
                                    ) : (
                                        false
                                    )
                                ) : (
                                    false
                                )}

                                {toolbox.title && (
                                    <h1 style={{ wordWrap: 'break-word' }}>
                                        {toolbox.title}
                                    </h1>
                                )}
                                {toolbox.toolbox.blocks.map((blockId) => {
                                    return (
                                        <BlockPreview
                                            block={blockId}
                                            key={blockId.id}
                                        />
                                    );
                                })}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        meetingDate !== null
                                            ? meetingDate.getTime() >=
                                              new Date().getTime()
                                            : false
                                    }
                                    onClick={this.toSelfTest}
                                    style={{ marginTop: '33px' }}
                                >
                                    {meetingDate !== null
                                        ? meetingDate.getTime() >=
                                          new Date().getTime()
                                            ? t('AVAILABLE SOON')
                                            : t('TO SELF-TEST')
                                        : t('TO SELF-TEST')}{' '}
                                </Button>
                            </div>
                        )}
                        {stepIndex === 1 && (
                            <div>
                                {toolbox.toolbox.questions.length === 0 && (
                                    <p>{t('The questionnaire is empty')}</p>
                                )}
                                {toolbox.toolbox.questions.length > 0 && (
                                    <div>
                                        {toolbox.toolbox.questions.length >
                                        5 ? (
                                            <Tabs
                                                scrollable
                                                value={tab}
                                                onChange={this.handleTabChange}
                                                className={classes.tabs}
                                                style={{ padding: '0' }}
                                            >
                                                {toolbox.toolbox.questions.map(
                                                    (questionId, i) => {
                                                        return (
                                                            <Tab
                                                                disabled={!( tabsCounter >= i)}
                                                                style={{width: tabSize +'%',}}
                                                                label={t('Question') +' ' +(i + 1)}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Tabs>
                                        ) : (
                                            <Tabs
                                                value={tab}
                                                onChange={this.handleTabChange}
                                                className={classes.tabs}
                                                style={{ padding: '0' }}
                                            >
                                                {toolbox.toolbox.questions.map(
                                                    (questionId, i) => {
                                                        return (
                                                            <Tab disabled={!(tabsCounter >= i)}
                                                                style={{width: tabSize + '%',}}
                                                                label={t('Question') + ' ' + (i + 1)}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Tabs>
                                        )}

                                        <div id="tbExternPaper">
                                            {toolbox.toolbox.questions.map(
                                                (questionId, i) => {
                                                    return (
                                                        tab === i && (
                                                            <div className={classes.root}>
                                                                <h1>{ questionId.title }</h1>
                                                                {questionId.blocks[0] && questionId.blocks[0].type === 'photosBlock' && questionId.blocks[0].photosBlock &&
                                                                    questionId.blocks[0].photosBlock.photos &&
                                                                    questionId.blocks[0].photosBlock.photos.length >0 &&
                                                                    questionId.blocks[0].photosBlock.photos.map((photo) => (
                                                                            <div
                                                                                style={{ textAlign: 'center', marginTop: '10px',}}
                                                                            >
                                                                                <img
                                                                                    style={{ maxWidth: 300, maxHeight: 300,}}
                                                                                    src={ photo.url }
                                                                                    alt={ 'foto' }
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )}

                                                                <span className="material-light-caption">
                                                                    {t(
                                                                        'Select the correct answer'
                                                                    )}
                                                                </span>
                                                                {questionId.possibleAnswers.map(( answer,  i) => (
                                                                        <div
                                                                            key={answer.id}
                                                                            className={classes.unselectedAnswer}
                                                                            onClick={() => this.handleAnswerClick(i)}
                                                                            ref={'divAnswer' + i}
                                                                        >
                                                                            <div
                                                                                ref={'divAnswerIcon' + i}
                                                                                style={
                                                                                    this.state.questionsFinished && this.state.tab <= this.state.questionsFinished
                                                                                        ? { visibility: 'visible', } : { visibility: 'hidden', }
                                                                                }
                                                                                align="right"
                                                                            >
                                                                                {questionId.correctAnswers.includes(i) ? (
                                                                                    <CheckCircleIcon
                                                                                        style={{ right: '42px', marginTop: '-5px', position: 'absolute',  color: 'green', }}
                                                                                        align="right"
                                                                                        className="checkIcon"
                                                                                    />
                                                                                ) : (
                                                                                    <CancelIcon
                                                                                        style={{ right: '42px', marginTop: '-5px', position: 'absolute', color: 'red', }}
                                                                                        className="checkIcon"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            {questionId.type === 'singleResponse' && (
                                                                                <div
                                                                                    ref={'divAnswerBox' + i}
                                                                                    align="right"
                                                                                >
                                                                                    <RadioUncheckedIcon
                                                                                        style={this.state.questionsFinished && this.state.tab <= this.state.questionsFinished
                                                                                                ? { right:'42px', marginTop: '-5px', position: 'absolute', visibility:'hidden',}
                                                                                                : { right:'420px', marginTop:'-5px', position:'absolute',}
                                                                                        }
                                                                                        className="checkIcon"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {questionId.type === 'singleResponse' && (
                                                                                <div
                                                                                    ref={'divAnswerBoxChecked' + i}
                                                                                    style={{visibility:'hidden',}}
                                                                                    align="right"
                                                                                >
                                                                                    <RadioCheckedIcon
                                                                                        style={{right:'42px',marginTop:'-5px',position:'absolute',}}
                                                                                        className="checkIcon"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {questionId.type !== 'singleResponse' && (
                                                                                <div ref={'divAnswerBox' + i} align="right" >
                                                                                    <CheckboxUncheckedIcon
                                                                                        style={
                                                                                            this.state.questionsFinished && this.state.tab <= this.state.questionsFinished
                                                                                                ? {
                                                                                                      right:'42px',
                                                                                                      marginTop:'-5px',
                                                                                                      position:'absolute',
                                                                                                      visibility:'hidden',
                                                                                                  }
                                                                                                : {
                                                                                                      right:'42px',
                                                                                                      marginTop:'-5px',
                                                                                                      position:'absolute',
                                                                                                  }
                                                                                        }
                                                                                        className="checkIcon"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {questionId.type !== 'singleResponse' && (
                                                                                <div ref={'divAnswerBoxChecked' + i }
                                                                                    style={{visibility:'hidden',}}
                                                                                    align="right"
                                                                                >
                                                                                    <CheckboxCheckedIcon
                                                                                        style={{
                                                                                            right: '42px',
                                                                                            marginTop: '-5px',
                                                                                            position: 'absolute',
                                                                                        }}
                                                                                        className="checkIcon"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <b>
                                                                                {t('Your answer')}{' '}
                                                                                {String.fromCharCode(65 + i)}
                                                                            </b>
                                                                            <br />
                                                                            { answer.text}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )
                                                    );
                                                }
                                            )}

                                            <div className={classes.root}>
                                                {
                                                    <p  className={classes.outcomeText}
                                                        ref="outcomeText"
                                                    >
                                                        {this.state.outcomeText}
                                                    </p>

                                                }
                                                {
                                                    <p
                                                        className={
                                                            classes.explanationText
                                                        }
                                                        ref="explanationText"
                                                    >
                                                        {this.state.explanation}
                                                    </p>

                                                }
                                                <Button
                                                    
                                                    variant="contained"
                                                    disabled={buttonDisabled}
                                                    color="primary"
                                                    size="large"
                                                    onClick={
                                                        this.handleButtonAnswer
                                                    }
                                                    id="answerButton"
                                                >
                                                    {buttonText}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {stepIndex === 2 && (
                            <div
                                className={classes.paper}
                                id="tbExternPaperFeedback"
                            >
                                <h1>{t('Complete toolbox')}</h1>
                                <p>
                                    {t(
                                        'Thanks for filling in, finish the toolbox here. After you have completed the toolbox, you can find the results under Completed assignments in the menu.'
                                    )}
                                </p>
                                <br />
                                <p>
                                    {t(
                                        'Did you notice anything about the toolbox? Enter your feedback here.'
                                    )}
                                </p>
                                <textarea
                                    className="textarea"
                                    name="feedbackfield"
                                    cols="120"
                                    rows="4"
                                    value={this.state.feedbackText}
                                    onChange={this.handleFormChange('text')}
                                />
                                <br />
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    onClick={this.sendAssignResult}
                                    disabled={this.state.finished}
                                    id="sendresultsButton"
                                    style={{ marginTop: '20px' }}
                                >
                                    {t('Complete toolbox')}
                                </Button>
                                <br />
                                <br />
                            </div>
                        )}
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(ToolboxOpdracht);

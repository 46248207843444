// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from '@mui/material';
import {closeDialog} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './DialogError.module.scss';

type Props = {
    open: boolean,
    onClose: Function,
};

type State = {};

const mapStateToProps = (store) => {
    return {
        open: store.ui.dialogs.error.open,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(closeDialog('error'));
        },
    };
};

class DialogError extends React.Component {
    props: Props;
    state: State = {};

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    render() {
        const {open, t} = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('Temporarily unavailable')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <p>
                        {`${t('Try again in a few minutes')}, ${t(
                            'If problem persists, contact us'
                        )}`}
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            color="primary"
                            onClick={this.handleCancelClick}>{t('Ok')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogError);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
} from '@mui/material';
import {addMultipleListItemBlockDraft, closeDialog} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './DialogPastListItems.module.scss';

type Props = {
    open: boolean,
    onClose: Function,
};

type State = {};

const mapStateToProps = (store) => {
    return {
        open: store.ui.dialogs.pasteListItems.open,
        contentBlockId: store.ui.dialogs.pasteListItems.data.contentBlockId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(closeDialog('pasteListItems'));
        },
        onSubmit: (contentBlockId, items) => {
            dispatch(addMultipleListItemBlockDraft(contentBlockId, items));
        },
    };
};

class DialogPasteListItems extends React.Component {
    props: Props;
    state: State = {
        itemText: '',
    };

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    handleSubmit = (event) => {
        this.props.onSubmit(
            this.props.contentBlockId,
            this.state.itemText.split('\n').filter((line) => line.length > 0)
        );
        this.props.onClose();
    };

    handleChange = (event) => {
        this.setState({itemText: event.target.value});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState({
                itemText: '',
            });
        }
    }

    render() {
        const {open, t} = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('Add multiple parts')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form onSubmit={this.handleSubmit}>
                        <p>{t('Copy/paste text from next input field')}</p>
                        <FormControl>
                            <InputLabel htmlFor="pasteListItems-input">
                                {t('Parts list')}
                            </InputLabel>
                            <Input
                                fullWidth={true}

                                id="pasteListItems-input"
                                multiline
                                maxrows="20"
                                value={this.state.itemText}
                                onChange={this.handleChange}
                            ></Input>
                            <FormHelperText>
                                {t('For each line a list will be created')}
                            </FormHelperText>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.handleCancelClick}>{t('Cancel')}</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}>
                        {t('Create parts')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

/*
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogPasteListItems);

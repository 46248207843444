// @flow

type TSetViewkey = {
    type: 'SET_VIEWKEY',
    viewkey: string
};
type TClearFilters = {
    type: 'CLEAR_ALL_FILTERS'
};
type TFilterPeriodType = {
    type: 'FILTER_PERIOD_TYPE',
    periodType: string
};
type TFilterPeriodYear = {
    type: 'FILTER_PERIOD_YEAR',
    periodYear: number
};
type TFilterPeriodMonth = {
    type: 'FILTER_PERIOD_MONTH',
    periodMonth: number
};
type TFilterPeriodWeek = {
    type: 'FILTER_PERIOD_WEEK',
    periodWeek: number
};
type TFilterPeriodFrom = {
    type: 'FILTER_PERIOD_FROM',
    periodFrom: Date
};
type TFilterPeriodTill = {
    type: 'FILTER_PERIOD_TILL',
    periodTill: Date
};

type TFilterSector = {
    type: 'FILTER_SECTOR',
    sector: number
};

type TFilterCategory = {
    type: 'FILTER_CATEGORY',
    category: String
};

type TFilterGroup = {
    type: 'FILTER_GROUP',
    group: number
};

type TFilterAfgerond = {
    type: 'FILTER_AFGEROND',
    afgerond: number
};

type TFilterProject = {
    type: 'FILTER_PROJECT',
    project: String
};

type TFilterUitvoerder = {
    type: 'FILTER_UITVOERDER',
    uitvoerder: number
};

type TFilterResultaat = {
    type: 'FILTER_RESULTAAT',
    resultaat: number
};

type TFilterSituatie = {
    type: 'FILTER_SITUATIE',
    situatie: number
};

type TLAFilterProcessPeriod = {
    type: 'FILTER_PROCESS_DATE',
    payload: {
        beginDate: ?Date,
        endDate: ?Date
    }
};

type TCurrentPageNumber = {
    type: 'CURRENT_PAGENUMBER',
    payload: number
};

export type TFilterAction = TSetViewkey | TClearFilters | TLAFilterProcessPeriod | TFilterPeriodType | TFilterPeriodYear | TFilterPeriodMonth | TFilterPeriodWeek | TCurrentPageNumber | TFilterPeriodFrom | TFilterPeriodTill | TFilterSector | TFilterCategory | TFilterGroup | TFilterAfgerond | TFilterProject | TFilterUitvoerder | TFilterResultaat | TFilterSituatie;

export const setViewkey = (viewkey: string) => {
    return {
        type: 'SET_VIEWKEY',
        viewkey: viewkey
    };
};

export const clearAllFilters = () => {
    return {
        type: 'CLEAR_ALL_FILTERS'
    };
};

export const filterPeriodType = (periodType: string) => {
    return {
        type: 'FILTER_PERIOD_TYPE',
        periodType: periodType
    };
};
export const filterPeriodYear = (periodYear: number) => {
    return {
        type: 'FILTER_PERIOD_YEAR',
        periodYear: periodYear
    };
};

export const filterPeriodMonth = (periodMonth: number) => {
    return {
        type: 'FILTER_PERIOD_MONTH',
        periodMonth: periodMonth
    };
};

export const filterPeriodWeek = (periodWeek: number) => {
    return {
        type: 'FILTER_PERIOD_WEEK',
        periodWeek: periodWeek
    };
};

export const filterPeriodFrom = (periodFrom: Date) => {
    return {
        type: 'FILTER_PERIOD_FROM',
        periodFrom: periodFrom
    };
};

export const filterPeriodTill = (periodTill: Date) => {
    return {
        type: 'FILTER_PERIOD_TILL',
        periodTill: periodTill
    };
};

export const filterIsToolboxMeetingChecked = (isToolboxMeetingChecked:boolean) => {
    return {
        type: 'FILTER_TOOLBOX_MEETING',
        isToolboxMeetingChecked:isToolboxMeetingChecked
    };
};

export const filterIsNoToolboxMeetingChecked = (isNoToolboxMeetingChecked:boolean) => {
    return {
        type: 'FILTER_NO_TOOLBOX_MEETING',
        isNoToolboxMeetingChecked:isNoToolboxMeetingChecked
    };
};

export const filterSector = (sector:number) => {
    return {
        type: 'FILTER_SECTOR',
        sector:sector
    };
};

export const filterCategory = (category:String) => {
    return {
        type: 'FILTER_CATEGORY',
        category:category
    };
};

export const filterAfgerond = (afgerond:number) => {
    return {
        type: 'FILTER_AFGEROND',
        afgerond:afgerond
    };
};

export const filterProject = (project:String) => {
    return {
        type: 'FILTER_PROJECT',
        project:project
    };
};

export const filterResultaat = (resultaat:number) => {
    return {
        type: 'FILTER_RESULTAAT',
        resultaat:resultaat
    };
};

export const filterSituatie = (situatie:number) => {
    return {
        type: 'FILTER_SITUATIE',
        situatie:situatie
    };
};

export const filterGroup = (group:number) => {
    return {
        type: 'FILTER_GROUP',
        group:group
    };
};

export const filterUitvoerder = (uitvoerder:number) => {
    return {
        type: 'FILTER_UITVOERDER',
        uitvoerder:uitvoerder
    };
};

export const setCurrentPageNumber = (pageNumber: number) => {
    return {
        type: 'CURRENT_PAGENUMBER',
        payload: pageNumber
    };
};


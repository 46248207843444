// @flow
import React from 'react';
import {List} from 'immutable';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import classes from './InspectionTrendProjectList.module.scss';

type Props = {
    projects: [],
};

type State = {
};

class InspectionTrendProjectList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        page: 0,
        rowsPerPage: 100,

        actions: [],
        columns: [],
        confirmDialogOpen: false,
    };

    componentDidMount() {
    }

    renderRow = (trendData) => {
        const { projects } = this.props;

        let projectName = '-'
        if (projects && projects.byId[trendData.projectId] !== undefined && trendData.projectId !== '999InvullenTijdenInspectie') {
            projectName = projects.byId[trendData.projectId].externalId + ' - ' + projects.byId[trendData.projectId].omschrijving
            return (
                <TableRow key={trendData.projectId}>
                    <TableCell>{projectName}</TableCell>
                    <TableCell>{trendData.correctCnt}</TableCell>
                    <TableCell>{trendData.incorrectCnt}</TableCell>
                    <TableCell>{trendData.unsafeCnt}</TableCell>
                </TableRow>
            )
        }
    }

    render() {
        const data = List( this.props.trendprojects );

        return (
            <div className={classes.projectdiv}>
                <Table >
                    <TableHead className={classes.head}>
                        <TableRow>
                            <TableCell>Project</TableCell>
                            <TableCell>Correct</TableCell>
                            <TableCell>Incorrect</TableCell>
                            <TableCell>Unsafe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(project =>(
                            this.renderRow(project)
                        ))}
                    </TableBody>

                </Table>
            </div>
        );
    }
}

export default withRouter(
    connect(
    )(compose(
        
        withTranslation())(InspectionTrendProjectList))
);

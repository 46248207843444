/**
 * Created by MarkBuikema on 6/28/2017.
 */
// @flow

import {connect} from 'react-redux';
import React from 'react';
import type {StoreState} from '../../../reducers/index';
import StatisticsInspectionCard from '../StatisticsCard/StatisticsInspectionCard';
import type {Dispatch as ReduxDispatch} from 'redux';
import {bindActionCreators} from 'redux';
import {Map} from 'immutable';
import {push} from 'connected-react-router';
import {
    clearAllFilters,
    fetchInspections,
    filterAfgerond,
    filterPeriodFrom,
    filterPeriodMonth,
    filterPeriodTill,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    filterProject,
    filterResultaat,
    filterSituatie,
    filterUitvoerder,
    getCurrentEmployee,
    pushCrumbPath,
    setCrumbPath,
    setViewkey,
} from '../../../actions';
import type {TStateStatisticsChartData} from '../../../reducers/StatisticsReducer';
import {loadInspectionData} from '../../../actions/statisticsActions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import {getCurrentFilterSet} from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListInspections from '../StatisticsList/StatisticsListInspections';
import {getFilteredAndGroupedInspectionAssigns} from '../../../selectors';
import _ from 'lodash';
import {fetchCSV, fetchPDF} from '../../../lib/api';
import {Menu, MenuItem} from '@mui/material';
import getRoles from '../../../selectors/getRoles';
import FilterHeader from '../../FilterHeader';
import $ from 'jquery';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';
import {withRouter} from "react-router";

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {
    setViewkey: () => mixed,
    chartData: TStateStatisticsChartData,
    loadChartData: Function,
    params: any,
    periodType: string,
    periodYear: number,
    periodMonth: number,
    periodWeek: number,
    periodFrom: any,
    periodTill: any,
    period?: string,
    progtypes: Map<string, boolean>,
    minDuration: number,
    maxDuration: number,
    distributorFilters: Map<number, boolean>,
    deviceFilters: Map<string, boolean>,
    loadDistributors: () => mixed,
    loadDevices: () => mixed,
    loadProgtypes: () => mixed,
    goTo: (path) => mixed,
    filterPeriodType: Function,
    filterPeriodYear: Function,
    filterPeriodMonth: Function,
    filterPeriodWeek: Function,
    filterProject: Function,
    filterUitvoerder: Function,
    filterAfgerond: Function,
    filterResultaat: Function,
    filterSituatie: Function,
    inspectionAssigns: Array<any>,
    clearAllFilters: Function,
    projectFilter: String,
    uitvoerderFilter: number,
    afgerondFilter: number,
    resultaatFilter: number,
    situatieFilter: number,
};

type State = {
    openProject: boolean,
    openUitvoerder: boolean,
    openAfgerond: boolean,
    openResultaat: boolean,
    openSituatie: boolean,
    anchorEl?: any,
    exportOpen: boolean,
};

const mapStateToProps = (state: StoreState) => {
    let filters = getCurrentFilterSet(state.filters);
    const subscription = state.drafts.employees.currentUser
        ? state.drafts.employees.currentUser.subscription
        : null;

    return {
        chartData: state.statistics.chartData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        projectFilter: filters.project,
        uitvoerderFilter: filters.uitvoerder,
        afgerondFilter: filters.afgerond,
        resultaatFilter: filters.resultaat,
        situatieFilter: filters.situatie,
        minDuration: filters.minduration,
        maxDuration: filters.maxduration,
        inspectionAssigns: getFilteredAndGroupedInspectionAssigns(state),
        roles: getRoles(state),
        subscription: subscription,
        projects: state.entities.projects,
        employees: state.entities.employees,

    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadChartData: bindActionCreators(loadInspectionData, dispatch),
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        filterProject: bindActionCreators(filterProject, dispatch),
        filterResultaat: bindActionCreators(filterResultaat, dispatch),
        filterSituatie: bindActionCreators(filterSituatie, dispatch),
        filterUitvoerder: bindActionCreators(filterUitvoerder, dispatch),
        filterAfgerond: bindActionCreators(filterAfgerond, dispatch),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (breadCrumbs) => {
            dispatch(setCrumbPath(breadCrumbs));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        fetchInspections: () => {
            dispatch(fetchInspections());
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
    };
};

let projects = [];
let projectFilterList = [];

class StatisticsInspectionOverview extends React.Component<Props, State> {
    props: Props;
    state: {
        openProject: false,
        anchorEl: 0,
        exportOpen: false,
    };

    componentDidMount() {
        //set view key
        this.props.setViewkey();
        this.props.setCrumbPath({title: 'Rapportages', crumbs: [{subTitle: i18n.t('Workplace inspections'),}]});

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        this.onFilterChanged();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.periodType !== prevProps.periodType ||
            this.props.periodYear !== prevProps.periodYear ||
            this.props.periodMonth !== prevProps.periodMonth ||
            this.props.periodWeek !== prevProps.periodWeek ||
            this.props.periodFrom !== prevProps.periodFrom ||
            this.props.periodTill !== prevProps.periodTill ||
            this.props.minDuration !== prevProps.minDuration ||
            this.props.maxDuration !== prevProps.maxDuration ||
            this.props.projectFilter !== prevProps.projectFilter ||
            this.props.uitvoerderFilter !== prevProps.uitvoerderFilter ||
            this.props.afgerondFilter !== prevProps.afgerondFilter ||
            this.props.resultaatFilter !== prevProps.resultaatFilter ||
            this.props.situatieFilter !== prevProps.situatieFilter ||
            !_.isEqual(
                this.props.inspectionAssigns.length,
                prevProps.inspectionAssigns.length
            )
        ) {
            this.onFilterChanged(prevProps);
        }
    }

    onFilterChanged = (props?: Props) => {
        if (!props) {
            props = this.props;
        }

        let path: string =
            this.props.location.pathname + '?year=' + props.periodYear;

        let period = moment().year(props.periodYear);
        let periodTill = '';
        switch (props.periodType) {
            case 'month':
                period = period.month(props.periodMonth);
                path += '&month=' + (props.periodMonth + 1);
                break;
            case 'week':
                let locale: string =
                    window.navigator.userLanguage || window.navigator.language;
                period = period
                    .week(props.periodWeek)
                    .locale(locale)
                    .weekday(0);
                path += '&week=' + props.periodWeek;
                break;
            case 'fromtill':
                //TODO: Format this
                //path = this.props.location.pathname + '?from=' + props.periodFrom + '&till=' + props.periodTill;
                if (props.periodFrom && props.periodTill) {
                    period = moment(props.periodFrom);
                    periodTill = moment(props.periodTill);
                }
                break;
            default:
                break;
        }

        if (props.periodType !== 'fromtill') {
            let periodString = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            this.props.goTo(path + this.props.location.hash);

            // execute chart api call
            props.loadChartData(
                props.periodType,
                periodString,
                null,
                null,
                this.props.projectFilter,
                this.props.uitvoerderFilter,
                this.props.afgerondFilter,
                this.props.resultaatFilter,
                this.props.situatieFilter
            );
        } else if (period && periodTill) {
            let periodString = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            let periodEnd = periodTill.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            this.props.goTo(path + this.props.location.hash);

            // execute chart api call
            props.loadChartData(
                props.periodType,
                periodString,
                periodEnd,
                null,
                this.props.projectFilter,
                this.props.uitvoerderFilter,
                this.props.afgerondFilter,
                this.props.resultaatFilter,
                this.props.situatieFilter
            );
        }
    };

    onItemClicked = (clickedInspectionAssign) => {
        const {location} = this.props;
        if (location) {
            let loc = location.pathname + location.search + location.hash;
            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('Werkplekinspecties'),
                    link: `${loc}`,
                    subTitle: clickedInspectionAssign.inspection.title
                }
            });
        }

        let target =
            'rapportages/werkplekinspectie/' +
            clickedInspectionAssign.inspection.id;

        let url = window.location.href;
        let parts = url.split('?');
        if (parts.length > 1) {
            target += '?' + parts[1];
        }
        this.props.goTo(target);
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadCsv = () => {
        let filteredinspectionAssigns = [];

        this.props.inspectionAssigns.forEach((inspection) => {
            let add = true;
            if (
                this.props.projectFilter &&
                this.props.projectFilter !== 'all'
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        assign.project &&
                        assign.projectId === this.props.projectFilter
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (
                this.props.uitvoerderFilter &&
                this.props.uitvoerderFilter !== 0
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (assign.user.id === this.props.uitvoerderFilter) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (this.props.afgerondFilter && this.props.afgerondFilter !== 0) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.afgerondFilter === 1 &&
                        assign.result &&
                        assign.result.complete
                    ) {
                        found = true;
                    } else if (
                        this.props.afgerondFilter === 2 &&
                        (!assign.result || assign.result.complete === false)
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (
                this.props.resultaatFilter &&
                this.props.resultaatFilter !== 0
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.resultaatFilter === 1 &&
                        assign.result &&
                        assign.result.allCorrect
                    ) {
                        found = true;
                    } else if (
                        this.props.resultaatFilter === 2 &&
                        assign.result &&
                        assign.result.allCorrect === false
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (this.props.situatieFilter && this.props.situatieFilter !== 0) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.situatieFilter === 1 &&
                        assign.result &&
                        !assign.result.remarks.unsafe
                    ) {
                        found = true;
                        assign.result.results.forEach((result) => {
                            if (result.remark.unsafe) {
                                found = false;
                            }
                        });
                    } else if (
                        this.props.situatieFilter === 2 &&
                        assign.result &&
                        assign.result.remarks.unsafe
                    ) {
                        found = true;
                    } else if (
                        this.props.situatieFilter === 2 &&
                        assign.result
                    ) {
                        assign.result.results.forEach((result) => {
                            if (result.remark.unsafe) {
                                found = true;
                            }
                        });
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (add) {
                filteredinspectionAssigns.push(inspection);
            }
        });

        filteredinspectionAssigns = filteredinspectionAssigns
            .filter((inspection) =>
                this.props.roles.includes('CUMELA') ||
                (this.props.subscription &&
                    this.props.subscription.cumela === true) ||
                (this.props.subscription &&
                    this.props.subscription.caoLeo === true)
                    ? inspection
                    : inspection.inspection.source !== 'cumela'
            )
            .filter((inspection) =>
                this.state && this.state.searchtext
                    ? inspection.inspection.title
                    .toLowerCase()
                    .indexOf(this.state.searchtext.toLowerCase()) > -1
                    : true
            );

        let data = [];

        filteredinspectionAssigns.forEach((item) => {
            data.push({
                inspection: {
                    title: item.inspection.title,
                },
                latestVersion: item.latestVersion,
                employeeCount: item.employeeCount,
                finished: item.finished,
            });
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTION_RAPPORTAGE',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute(
                    'download',
                    i18n.t('werkplekinspectie_rapportage.csv')
                );
                link.click();
            })
            .catch((err) => console.log(err));
    };

    getList = () => {
        return (
            <StatisticsListInspections
                onItemClick={this.onItemClicked}
                searchtext={this.state ? this.state.searchtext : ''}
                year={this.props.periodYear}
                project={this.props.projectFilter}
                uitvoerder={this.props.uitvoerderFilter}
                afgerond={this.props.afgerondFilter}
                resultaat={this.props.resultaatFilter}
                situatie={this.props.situatieFilter}
            />
        );
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openProject: false,
            openUitvoerder: false,
            openAfgerond: false,
            openResultaat: false,
            openSituatie: false,
        });
        this.setState(newState);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openProject: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openUitvoerder: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openAfgerond: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap4 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openResultaat: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap5 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSituatie: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleInspectionAfgerondFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({toolboxFilter: event.target.value});

        this.setState(
            {afgerondFilter: event.target.value},
            this.onFilterChanged
        );
        this.props.filterAfgerond(event.target.value);
    };

    handleInspectionProjectFilterChange = (event) => {
        this.handleRequestClose();

        let project = 0;
        if (event.target.value === -1) {
            project = 'all';
        } else {
            if (projectFilterList[event.target.value]) {
                project = projectFilterList[event.target.value].id;
            }
        }

        this.setState({toolboxFilter: project});

        this.setState({projectFilter: project}, this.onFilterChanged);

        this.props.filterProject(project);
    };

    handleInspectionUitvoerderFilterChange = (event) => {
        this.handleRequestClose();

        let employee = 0;
        if (event.target.value === -1) {
            employee = 0;
        } else {
            employee = event.target.value;
        }

        this.setState({toolboxFilter: employee});

        this.setState({uitvoerderFilter: employee}, this.onFilterChanged);
        this.props.filterUitvoerder(employee);
    };

    handleInspectionResultaatFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({toolboxFilter: event.target.value});

        this.setState(
            {resultaatFilter: event.target.value},
            this.onFilterChanged
        );
        this.props.filterResultaat(event.target.value);
    };

    handleInspectionSituatieFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({toolboxFilter: event.target.value});

        this.setState(
            {situatieFilter: event.target.value},
            this.onFilterChanged
        );
        this.props.filterSituatie(event.target.value);
    };

    getFilterBar = () => {
        let projectIndex = 0;
        const {t} = this.props;

        let userIds = [];
        let users = [];

        let projectIds = [];
        projects = [];
        projectFilterList = [];

        this.props.inspectionAssigns.forEach((inspection) => {
            if (inspection.assignList.length > 0) {
                inspection.assignList.forEach((assign) => {
                    if (assign.user && !userIds.includes(assign.user.id)) {
                        userIds.push(assign.user.id);
                    }
                    // Fetch project from the store.
                    let thisProject = this.props.projects.byId[assign.projectId];

                    if (
                        assign &&
                        assign.projectId &&
                        !projectIds.includes(assign.projectId)
                    ) {
                        projectIds.push(assign.projectId);
                    }
                    // Create projectFilterList based on the unique projects from the InspectionsAssign list
                    let found = false;
                    if (
                        projectFilterList.some(
                            (p) =>
                                p !== null && p !== undefined &&
                                assign.projectId !== null &&
                                p.id === assign.projectId
                        )
                    ) {
                        found = true;
                    }
                    if (!found) {
                        if (thisProject !== null) {
                            projectFilterList.push(thisProject);
                        }
                    }
                });
            }
        });

        userIds.forEach((user) => {
            if (this.props.employees.byId[user]) {
                users.push(this.props.employees.byId[user]);
            }
        });

        projectIds.forEach((projectId) => {
            if (
                this.props.projects.byId[projectId] &&
                projectId !== '999InvullenTijdenInspectie'
            ) {
                projects.push(this.props.projects.byId[projectId]);
            }
        });

        if (this.props.projects.byId['999InvullenTijdenInspectie']) {
            projects.push(
                this.props.projects.byId['999InvullenTijdenInspectie']
            );
        }

        // Set current filter
        let projectFilterLocale = t('No project');
        if (this.props.projectFilter === 'all') {
            projectFilterLocale = t('Everything');
        } else {
            let searchResult = projectFilterList.find(
                (p) => p != null && p.id === this.props.projectFilter
            );
            if (searchResult && searchResult.omschrijving.length > 0) {
                projectFilterLocale = searchResult.omschrijving;
            } else {
                projectFilterLocale = t('No project');
            }
        }

        return (
            <div className="filterbar" style={{paddingRight: '480px'}}>
                <PeriodFilterBlock/>

                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={t('Project/work area')}
                            filterValue={projectFilterLocale}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openProject : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.props.projectFilter ? this.props.projectFilter : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleInspectionProjectFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {projectFilterList &&
                        projectFilterList.map((project, index) => (
                            <MenuItem
                                key={index}
                                onClick={
                                    this.handleInspectionProjectFilterChange
                                }
                                value={projectIndex++}
                            >
                                {project != null &&
                                project.omschrijving != null &&
                                project.omschrijving.length > 0
                                    ? project.omschrijving
                                    : t('No project')}
                            </MenuItem>
                        ))}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={t('Performer')}
                            filterValue={
                                this.props.uitvoerderFilter === 0
                                    ? t('Everything')
                                    : this.props.employees.byId[
                                        this.props.uitvoerderFilter
                                        ].fullname
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openUitvoerder : false}
                    onClose={this.handleRequestClose}
                    value={this.props.uitvoerderFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleInspectionUitvoerderFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {users &&
                        users.map((employee, index) => (
                            <MenuItem
                                key={index}
                                onClick={
                                    this.handleInspectionUitvoerderFilterChange
                                }
                                value={employee.id}
                            >
                                {employee.fullname}
                            </MenuItem>
                        ))}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={t('Completed')}
                            filterValue={
                                this.props.afgerondFilter === 0
                                    ? t('Everything')
                                    : this.props.afgerondFilter === 1
                                        ? t('Yes')
                                        : t('No')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openAfgerond : false}
                    onClose={this.handleRequestClose}
                    value={this.props.afgerondFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        style={{width: '130px'}}
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={1}
                    >
                        {t('Yes')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionAfgerondFilterChange}
                        value={2}
                    >
                        {t('No')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap4}>
                        <FilterHeader
                            filterTitle={t('Result')}
                            filterValue={
                                this.props.resultaatFilter === 0
                                    ? t('Everything')
                                    : this.props.resultaatFilter === 1
                                        ? t('Correct')
                                        : t('Not correct')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openResultaat : false}
                    onClose={this.handleRequestClose}
                    value={this.props.resultaatFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        style={{width: '130px'}}
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={1}
                    >
                        {t('Correct')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionResultaatFilterChange}
                        value={2}
                    >
                        {t('Not correct')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap5}>
                        <FilterHeader
                            filterTitle={t('Situation')}
                            filterValue={
                                this.props.situatieFilter === 0
                                    ? t('Everything')
                                    : this.props.situatieFilter === 1
                                        ? t('Is save')
                                        : t('Unsafe')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSituatie : false}
                    onClose={this.handleRequestClose}
                    value={this.props.resultaatFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                    getContentAnchorEl={null}
                >
                    <MenuItem
                        style={{width: '130px'}}
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={1}
                    >
                        {t('Is save')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleInspectionSituatieFilterChange}
                        value={2}
                    >
                        {t('Unsafe')}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    getPDF = () => {
        let filteredinspectionAssigns = [];

        this.props.inspectionAssigns.forEach((inspection) => {
            let add = true;
            if (
                this.props.projectFilter &&
                this.props.projectFilter !== 'all'
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        assign.projectId &&
                        assign.projectId === this.props.projectFilter
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (
                this.props.uitvoerderFilter &&
                this.props.uitvoerderFilter !== 0
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (assign.user.id === this.props.uitvoerderFilter) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (this.props.afgerondFilter && this.props.afgerondFilter !== 0) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.afgerondFilter === 1 &&
                        assign.result &&
                        assign.result.complete
                    ) {
                        found = true;
                    } else if (
                        this.props.afgerondFilter === 2 &&
                        (!assign.result || assign.result.complete === false)
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (
                this.props.resultaatFilter &&
                this.props.resultaatFilter !== 0
            ) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.resultaatFilter === 1 &&
                        assign.result &&
                        assign.result.allCorrect
                    ) {
                        found = true;
                    } else if (
                        this.props.resultaatFilter === 2 &&
                        assign.result &&
                        assign.result.allCorrect === false
                    ) {
                        found = true;
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (this.props.situatieFilter && this.props.situatieFilter !== 0) {
                let found = false;
                inspection.assignList.forEach((assign) => {
                    if (
                        this.props.situatieFilter === 1 &&
                        assign.result &&
                        !assign.result.remarks.unsafe
                    ) {
                        found = true;
                        assign.result.results.forEach((result) => {
                            if (result.remark.unsafe) {
                                found = false;
                            }
                        });
                    } else if (
                        this.props.situatieFilter === 2 &&
                        assign.result &&
                        assign.result.remarks.unsafe
                    ) {
                        found = true;
                    } else if (
                        this.props.situatieFilter === 2 &&
                        assign.result
                    ) {
                        assign.result.results.forEach((result) => {
                            if (result.remark.unsafe) {
                                found = true;
                            }
                        });
                    }
                });

                if (!found) {
                    add = false;
                }
            }

            if (add) {
                filteredinspectionAssigns.push(inspection);
            }
        });

        filteredinspectionAssigns = filteredinspectionAssigns
            .filter((inspection) =>
                this.props.roles.includes('CUMELA') ||
                (this.props.subscription &&
                    this.props.subscription.cumela === true) ||
                (this.props.subscription &&
                    this.props.subscription.caoLeo === true)
                    ? inspection
                    : inspection.inspection.source !== 'cumela'
            )
            .filter((inspection) =>
                this.state && this.state.searchtext
                    ? inspection.inspection.title
                    .toLowerCase()
                    .indexOf(this.state.searchtext.toLowerCase()) > -1
                    : true
            );

        let data = [];

        filteredinspectionAssigns.forEach((item) => {
            data.push({
                inspection: {
                    title: item.inspection.title,
                },
                latestVersion: item.latestVersion,
                employeeCount: item.employeeCount,
                finished: item.finished,
            });
        });

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTION_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                chartData: this.props.chartData,
                roles: this.props.roles,
                isCumela: this.props.subscription.cumela,
                isCaoLeo: this.props.subscription.caoLeo,
                periodType: this.props.periodType,
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    i18n.t('werkplekinspectierapportage.pdf')
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    render() {
        const {chartData, t} = this.props;
        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                widemode={true}
                searchPlaceholder={t('Search workplace inspections')}
                searchAlwaysOpen={true}
                onActionButton={this.toggleExport}
                onActionText={this.state && this.state.exportOpen ? 'Close' : 'Download'}
                onDropdown1Button={this.state && this.state.exportOpen ? this.getPDF : null}
                onDropdown2Button={this.state && this.state.exportOpen ? this.downloadCsv : null}
                onDropdown1Text="PDF"
                onDropdown2Text="CSV"
            />
        );
        return (
            <div className="Statistics">
                <AppModule
                    loading={chartData.toolboxBarData ? false : true}
                    prepend={tableActionHeader}
                    hasTabs
                >
                    <StatisticsInspectionCard
                        inspectionBarData={
                            this.props.chartData.inspectionBarData
                                ? this.props.chartData.inspectionBarData
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList()}
                        totalInspectionsSend={
                            this.props.chartData.totalInspectionsSend
                        }
                        totalInspectionsCompleted={
                            this.props.chartData.totalInspectionsCompleted
                        }
                        totalInspectionsNotCompleted={
                            this.props.chartData.totalInspectionsNotCompleted
                        }
                    />
                </AppModule>
                {/*<div*/}
                {/*    style={{*/}
                {/*        position: 'fixed',*/}
                {/*        top: '84px',*/}
                {/*        right: '36px',*/}
                {/*        zIndex: 1500,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div>*/}
                {/*        <Fab   onClick={this.toggleExport}>*/}
                {/*            {this.state && this.state.exportOpen ? (*/}
                {/*                <CloseIcon />*/}
                {/*            ) : (*/}
                {/*                <DownloadIcon />*/}
                {/*            )}*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        style={*/}
                {/*            this.state && this.state.exportOpen*/}
                {/*                ? {*/}
                {/*                      marginTop: '20px',*/}
                {/*                      marginLeft: '8px',*/}
                {/*                      visibility: 'visible',*/}
                {/*                      transition: 'margin-top 300ms',*/}
                {/*                  }*/}
                {/*                : {*/}
                {/*                      marginTop: '0px',*/}
                {/*                      marginLeft: '8px',*/}
                {/*                      visibility: 'hidden',*/}
                {/*                      transition: 'margin-top 300ms',*/}
                {/*                  }*/}
                {/*        }*/}
                {/*        className={'tooltip'}*/}
                {/*    >*/}
                {/*        <span*/}
                {/*            className={*/}
                {/*                this.state && this.state.exportOpen*/}
                {/*                    ? 'tooltiptextvisible'*/}
                {/*                    : 'tooltiptext'*/}
                {/*            }*/}
                {/*            style={{ top: '87px', right: '60px' }}*/}
                {/*        >*/}
                {/*            {t('Download PDF file')}*/}
                {/*        </span>*/}
                {/*        <Fab color="primary"  onClick={this.getPDF}>*/}
                {/*            <InsertIcon />*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        style={*/}
                {/*            this.state && this.state.exportOpen*/}
                {/*                ? {*/}
                {/*                      marginTop: '15px',*/}
                {/*                      marginLeft: '8px',*/}
                {/*                      visibility: 'visible',*/}
                {/*                      transition: 'margin-top 300ms',*/}
                {/*                  }*/}
                {/*                : {*/}
                {/*                      marginTop: '0px',*/}
                {/*                      marginLeft: '8px',*/}
                {/*                      visibility: 'hidden',*/}
                {/*                      transition: 'margin-top 300ms',*/}
                {/*                  }*/}
                {/*        }*/}
                {/*        className={'tooltip'}*/}
                {/*    >*/}
                {/*        <span*/}
                {/*            className={*/}
                {/*                this.state && this.state.exportOpen*/}
                {/*                    ? 'tooltiptextvisible'*/}
                {/*                    : 'tooltiptext'*/}
                {/*            }*/}
                {/*            style={{ top: '145px', right: '60px' }}*/}
                {/*        >*/}
                {/*            {t('Download CSV file')}*/}
                {/*        </span>*/}
                {/*        <Fab*/}
                {/*            color="primary"*/}
                {/*            */}
                {/*            onClick={this.downloadCsv}*/}
                {/*        >*/}
                {/*            <DescIcon />*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(),)(StatisticsInspectionOverview))
);

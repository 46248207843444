// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import classnames from 'classnames';
import {
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Icon,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {editQuestionBlockDraft, showSnackbar} from '../../actions';
import PhotosBlockForm from '../ContentBlock/PhotosBlockForm';
import AnswerListBlockForm from './AnswerListBlockForm';
import './QuestionBlock.css';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './QuestionBlock.module.scss';

type Props = {
    questionBlock: any,
    onChange: Function,
    contentBlocks: any,
    classes: any,
    connectDragPreview: Function,
    questionBlockTypes: any,
};

type State = {
    isExpanded: boolean,
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onChange: (key, value) =>
            dispatch(
                editQuestionBlockDraft(props.questionBlock.id, key, value)
            ),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};

class QuestionBlock extends React.Component {
    props: Props;
    state: State = {
        isExpanded: false,
        title: this.props.questionBlock.title,
        explanationAnswers: this.props.questionBlock.explanationAnswers
    };
    timer = null;

    handleHeaderClick = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    };

    handleDeleteClick = () => {
        this.props.onDelete(this.props.questionBlock.id);
    };

    handleFormChange = (key) => (event) => {
        const {t} = this.props;
        const value = event.target.value;
        if (key === 'title' && value.length > 200) {
            this.props.textfull(t('Text cannot be longer than 200 characters'));
            return;
        }

        this.setState({[key]: value});

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.props.onChange(key, value);
        }, 400);
    };

    getInputId(path) {
        return this.props.questionBlock.id + '-' + [].concat(path).join('-');
    }

    renderIcon() {
        for (let i = 0; i < this.props.questionBlockTypes.length; i++) {
            if (
                this.props.questionBlock.type ===
                this.props.questionBlockTypes[i].id
            ) {
                return (
                    <Icon className="material-icons">
                        {this.props.questionBlockTypes[i].icon}
                    </Icon>
                );
            }
        }
        return <div>?</div>;
    }

    render() {
        const {
            contentBlocks,
            questionBlock,
            connectDragPreview,
            connectDragSource,
            t,
        } = this.props;
        let title = questionBlock.title;

        return (
            <div className={classes.root}>
                {connectDragPreview(
                    connectDragSource(
                        <div>
                            <CardHeader
                                onClick={this.handleHeaderClick}
                                className={classnames({
                                    [classes.cardHeader]: true,
                                    'question-card-header': true,
                                })}
                                avatar={this.renderIcon()}
                                title={title}
                            />
                        </div>
                    )
                )}

                <CardActions className={classes.cardActions}>
                    <IconButton onClick={this.handleDeleteClick}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton
                        onClick={this.handleHeaderClick}
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.isExpanded,
                        })}
                    >
                        <ExpandMore/>
                    </IconButton>
                </CardActions>

                <Collapse in={this.state.isExpanded} transitionduration="auto">
                    <CardContent className={classes.cardContent}>
                        <FormControl>
                            <InputLabel htmlFor={this.getInputId('text')}>
                                {t('Title')}
                            </InputLabel>
                            <Input
                                fullWidth={true}
                                id={this.getInputId('text')}

                                onChange={this.handleFormChange('title')}
                                label={t('Title')}
                                value={this.state.title}
                                endAdornment={
                                    <InputAdornment
                                        position="end"
                                        style={{
                                            fontSize: '14px',
                                            opacity: '0.2',
                                        }}
                                    >
                                        {200 - questionBlock.title.length}
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {questionBlock.blocks.map((contentBlockId) => (
                            <div key={contentBlockId}>
                                {/*contentBlocks[contentBlockId].textBlock && <TextBlockForm contentBlock={contentBlocks[contentBlockId]} />*/}
                                {contentBlocks[contentBlockId].photosBlock && (
                                    <PhotosBlockForm
                                        contentBlock={
                                            contentBlocks[contentBlockId]
                                        }
                                    />
                                )}
                            </div>
                        ))}
                        <AnswerListBlockForm questionBlock={questionBlock}/>

                        <FormControl>
                            <InputLabel
                                shrink={true}>{t('toolbox.questions.answers.item.explanation.title')}</InputLabel>
                            <Input
                                fullWidth={true}
                                id={this.getInputId('explanationAnswers')}

                                onChange={this.handleFormChange('explanationAnswers')}
                                placeholder={t('toolbox.questions.answers.item.explanation.input-label')}
                                value={this.state.explanationAnswers}
                            />
                        </FormControl>
                    </CardContent>
                </Collapse>
            </div>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation()
)(QuestionBlock);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import TextBlockPreview from './TextBlockPreview';
import ListBlockPreview from './ListBlockPreview';
import PhotosBlockPreview from './PhotosBlockPreview';
import VideoBlockPreview from './VideoBlockPreview';
import classes from './BlockPreview.module.scss';
import VideoToolboxBlockPreview from "./VideoToolboxBlockPreview";

type Props = {};

const BlockPreview = (props: Props) => {
    const {block, format} = props;
    const classnames = [classes.root];
    if (block && (block.highlight || block.highlighted)) {
        classnames.push(classes.highlight);
    }

    return (
        <div className={classnames.join(' ')}>
            {block && block.title && block.highlight ?
                (<h2 className={classes.titleHighlight}>{block.title}</h2>) :
                (<h2 className={classes.title}>{block.title}</h2>)
            }
            {block && block.textBlock && (<TextBlockPreview block={block.textBlock}/>)}
            {block && block.listBlock && (<ListBlockPreview block={block.listBlock}/>)}
            {block && block.photosBlock && (<PhotosBlockPreview block={block.photosBlock} format={format}/>)}
            {block && block.videoBlock && (<VideoBlockPreview block={block.videoBlock} format={format}/>)}
            {block && block.videoToolboxBlock && (
                <VideoToolboxBlockPreview block={block.videoToolboxBlock} format={format}/>)}
            {block && block.subtitle && (<h3 className={classes.subtitle}>{block.subtitle}</h3>)}
        </div>
    );
}

export default (BlockPreview);

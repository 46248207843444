// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import classes from './TextBlockPreview.module.scss';

type Props = {};

const TextBlockPreview = (props: Props) => {
    const { block  } = props;

    return (
        <div className={ classes.root }>
            { block.text && (
                <p>
                    { block.text }
                </p>
            )}
        </div>
    );
}

export default (TextBlockPreview);

const getBooleanFromString = (string) => {
    return string === "true"
}

export const getModules = () => {
    return {
        documents: process.env.REACT_APP_MODULE_DOCUMENTS
            ? getBooleanFromString(process.env.REACT_APP_MODULE_DOCUMENTS)
            : true,
        speakUp: process.env.REACT_APP_MODULE_SPEAK_UP
            ? getBooleanFromString(process.env.REACT_APP_MODULE_SPEAK_UP)
            : true,
        incidents: process.env.REACT_APP_MODULE_INCIDENTS
            ? getBooleanFromString(process.env.REACT_APP_MODULE_INCIDENTS)
            : true,
        inspections: process.env.REACT_APP_MODULE_INSPECTIONS
            ? getBooleanFromString(process.env.REACT_APP_MODULE_INSPECTIONS)
            : true,
        videoTB: process.env.REACT_APP_MODULE_VTB
            ? getBooleanFromString(process.env.REACT_APP_MODULE_VTB)
            : true,
    };
}

export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
  
// @flow
import React from 'react';
import { IconButton, Menu, MenuItem, MenuList } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterHeader from '../FilterHeader';
import $ from 'jquery';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './SimplePagination.module.scss';

type Props = {
    count: number,
    rowsPerPage: number,
    page: number,
    onChangePage: Function,
    onChangeRowsPerPage: Function,
};

type State = {
    openRows: boolean,
};

class SimplePagination extends React.Component {
    props: Props;
    state: State = {
        openRows: false,
    };

    handleBackClick = (event) => {
        if (this.props.page === 0) {
            const totalPages = Math.ceil(
                this.props.count / this.props.rowsPerPage
            );
            this.props.onChangePage(event, totalPages - 1);
        } else {
            this.props.onChangePage(event, Math.max(this.props.page - 1, 0));
        }
    };

    handleForwardClick = (event) => {
        const totalPages = Math.ceil(this.props.count / this.props.rowsPerPage);
        if (this.props.page === totalPages - 1) {
            this.props.onChangePage(event, 0);
        } else {
            this.props.onChangePage(
                event,
                Math.min(this.props.page + 1, totalPages - 1)
            );
        }
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { openRows: false });
        this.setState(newState);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openRows: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handlePageRowsClick = (event) => {
        //const totalPages = Math.ceil(this.props.count / this.props.rowsPerPage);
        this.handleRequestClose();
        this.props.onChangeRowsPerPage(event.target.value);
    };

    render() {
        const { count, rowsPerPage, page, t } = this.props;
        const fromItem = page * rowsPerPage + 1;
        let toItem = (page + 1) * rowsPerPage;

        if (toItem > count) {
            toItem = count;
        }

        return (
            <div className={classes.root}>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={t('Rows per page')}
                            filterValue={rowsPerPage}
                        />
                    </span>
                </div>
                <Menu
                    open={this.state ? this.state.openRows : false}
                    onClose={this.handleRequestClose}
                    value={rowsPerPage}
                    classes={{paper: classes.menupopup,}}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                        <MenuList>
                            <MenuItem
                                onClick={this.handlePageRowsClick}
                                value={10}
                            >
                                10
                            </MenuItem>
                            <MenuItem onClick={this.handlePageRowsClick} value={25}>
                                25
                            </MenuItem>
                            <MenuItem onClick={this.handlePageRowsClick} value={50}>
                                50
                            </MenuItem>
                            <MenuItem onClick={this.handlePageRowsClick} value={100}>
                                100
                            </MenuItem>
                        </MenuList>
                </Menu>

                <div className={classes.label}>
                    {fromItem} - {toItem} {t('from')} {count}{' '}
                </div>
                <IconButton
                    disabled={this.props.count === 0}
                    onClick={this.handleBackClick}
                    className={classes.button}
                    size="large">
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    disabled={this.props.count === 0}
                    onClick={this.handleForwardClick}
                    className={classes.button}
                    size="large">
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
        );
    }
}

export default compose(withTranslation())(SimplePagination);

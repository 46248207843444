import React from 'react'; // eslint-disable-line no-unused-vars
import Viewer from 'react-viewer';

type Props = {
    photos: Array<{ id: number, url: string }>,
    width: string,
    height: string,
    marginRight: string,
    marginBottom: string,
    borderRadius: string,
    float: string,
    padding: string,
};

type State = {
    imageVisible: boolean,
    activePhotoId: number | null,
};

class PhotoViewer extends React.Component<Props, State> {
    static defaultProps = {
        width: '64px',
        height: '64px',
        marginRight: '8px',
        marginBottom: '8px',
        marginTop: '0px',
        borderRadius: '8px',
        float: 'left',
        padding: '8px',
    };

    state: State = {
        imageVisible: false,
        activePhotoId: null,
    };

    render() {
        const {photos, width, height, marginRight, marginBottom, marginTop, borderRadius, float, padding} = this.props;
        return (
            <div>
                {photos.length > 0 &&
                    photos.map((photo) => (
                        <img
                            key={photo.id}
                            src={photo.url}
                            alt=""
                            style={{
                                width,
                                height,
                                marginRight,
                                marginBottom,
                                marginTop,
                                borderRadius,
                                cursor: 'pointer',
                                float,
                                padding,
                            }}
                            onClick={() =>
                                this.setState({
                                    imageVisible: true,
                                    activePhotoId: photo.id,
                                })
                            }
                        />
                    ))}

                {this.state.imageVisible && (
                    <Viewer
                        visible={this.state.imageVisible}
                        activeIndex={photos.findIndex((p) => p.id === this.state.activePhotoId)}
                        drag={false}
                        scalable={false}
                        zIndex={1305}
                        downloadable={true}
                        onClose={() => this.setState({ imageVisible: false })}
                        onMaskClick={() => this.setState({ imageVisible: false })}
                        images={photos.map((photo) => ({
                            src: photo.url,
                            downloadUrl: photo.url,
                        }))}
                    />
                )}
            </div>
        );
    }
}

export default PhotoViewer;

// @flow
import TypedRecord from '../components/Util/IRecord';
import type {TSubscriptionAction} from '../actions';
import type {TEmployee} from './employeesReducer';

export type TSubscription = {
    id?: number,
    state: 'REQUESTED' | 'CONFIRMED' | 'CHECKED' | 'REFUSED' | 'ACTIVATED' | 'DISABLED' | 'DELETED',
    subscriptionHolder: TEmployee,
    type: 'CUMELA' | 'VEILIG_VAKWERK',
    expiryDate: Date,
    sector: string,
    maxEmployees: number,
    companyName: string,
    address: string,
    zipcode: string,
    city: string,
    btwNummer: string,
    kvkNummer: string,
    iban: string,
    bic: string,
    cumela: boolean,
    debtorNumber: string,
    caoLeo: boolean,
    vv_functions: boolean,
    credits_setup: boolean,
};

export type TChangeSubscriptionHolder = {
    subscription: TSubscription,
    user: TEmployee
};

export type THistory = {
    id?: number,
    subscriptionId: number,
    maxEmployees: number,
    activatedOn: Date
};

export type TPrice = {};

export type TSubscriptionStub = {};

export type TSubscriptionFunctionality = {
    azureEnabled: boolean,
    href: string,
    id: number,
    multiLanguageEnabled: boolean,
    whitelabelEnabled: boolean,
    videoToolboxEnabled: boolean,
    videoToolboxShowTrial: boolean
};

export type TSubscriptionFunctionalityWhitelabel = {
    id: number,
    url: string,
    packageNameIos: string,
    packageNameAndroid: string,
    storeLinkIos: string,
    mailMainColor: string,
    mailFontcolor: string,
    logo: any,
    href: string
}

export type TSubscriptionFunctionalityWhitelabelAppStoreCodes = {
    availableCodes: number,
    claimedCodes: number
}

export class TStateSubscription extends TypedRecord({
    subscriptionStubs: null,
    editabonnee: null,
    abonneesCUMELA: null,
    abonnees: null,
    history: null,
    categories: null,
    functionality: undefined,
    functionalityWhitelabel: undefined,
    functionalityWhitelabelAppStoreCodes: undefined
    //diff:null
}) {
    subscriptionStubs: TSubscriptionStub;
    editabonnee: TSubscription;
    functionality: TSubscriptionFunctionality;
    functionalityWhitelabel: TSubscriptionFunctionalityWhitelabel;
    functionalityWhitelabelAppStoreCodes: TSubscriptionFunctionalityWhitelabelAppStoreCodes;
    //diff: { removed: List<TSubscription>, added: List<TSubscription> };
}

const initialState = new TStateSubscription();

export default function subscriptionReducer(state: TStateSubscription = initialState, action: TSubscriptionAction) {
    switch (action.type) {
        case 'FETCH_SUBSCRIPTIONS': {
            return state.set('subscriptionStubs', action.subscriptionStubs);
        }
        case 'FETCH_ABONNEES': {
            return state.set('abonnees', action.abonnees);
        }
        case 'FETCH_ABONNEES_CUMELA': {
            return state.set('abonneesCUMELA', action.abonneesCUMELA);
        }
        case 'FETCH_HISTORY': {
            return state.set('history', action.history);
        }
        case 'FETCH_SUBSCRIPTION_CATEGORIES': {
            return state.set('categories', action.categories);
        }
        case 'IMPERSONATE': {
            console.log(TChangeSubscriptionHolder.user.id)
            return state.set('impersonate', action.impersonate('', TChangeSubscriptionHolder.user.id));
        }
        case 'ABONNEE_EDIT': {
            return state.set('editabonnee', action.abonnee);
        }
        case "FETCH_SUBSCRIPTION_FUNCTIONALITY": {
            return state.set("functionality", action.functionality)
        }
        case "FETCH_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL": {
            return state.set("functionalityWhitelabel", action.functionalityWhitelabel)
        }
        case "FETCH_SUBSCRIPTION_FUNCTIONALITY_WHITELABEL_APP_STORE_CODES": {
            return state.set("functionalityWhitelabelAppStoreCodes", action.functionalityWhitelabelAppStoreCodes)
        }
        case "FETCH_USED_VIDEO_TOOLBOXES": {
            return state.set("usedVideoToolboxes", action.usedVideoToolboxes)
        }
        default:
            return state;
    }
}


// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {setCrumbPath, showSnackbar} from '../../../actions';
import classes from './TaskOpdracht.module.scss';
import {apiAddUpdateTask, apiDeleteTaskById, getTaskById } from "../../../lib/api";
import i18n from "i18next";
import {
    AppBar,
    Button,
    Divider,
    FormControl,
    IconButton,
    TextField,
    Toolbar,
    Typography,
    Card,
    CardContent
} from "@mui/material";
import moment from "moment";
import {push} from "connected-react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SimpleImageInput from "../../SimpleImageInput";
import DeleteIcon from "@mui/icons-material/Delete";

type Props = {
};

type State = {
};

const mapStateToProps = (store, props) => {

    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Tasks/' }));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class TaskOpdracht extends React.Component<Props, State> {
    props: Props;
    state: state = {
        confirmDialogOpen: false,
        editDialogOpen: false,
        editDialogViewOnly: true,
        selectedTask: null,
        photos: [],
        feedbackText: null,
        showFeedback: false,
        done: false,
        showThumb: true,
    };

    componentDidMount() {
        this.props.setCrumbPath();

        if (! this.props.location.task) {
            // maybe reloaded the page use url param taskId to load this task
            if (this.props.match.params && this.props.match.params.taskId) {
                getTaskById(this.props.match.params.taskId)
                    .then((entities) => {
                        let task = entities.body._embedded.task;
                        let photos = [];
                        let feedbackText = null;
                        if (task && task.taskFeedback) {
                            photos = task.taskFeedback.photos;
                            feedbackText = task.taskFeedback.feedbackText;
                        }
                        this.setState({
                            task: task,
                            photos: photos,
                            feedbackText: feedbackText,
                            showFeedback: task.taskFeedback.done,
                            done: task.taskFeedback.done,

                        });

                    })
                    .catch((error) => {
                        showSnackbar(
                            `${i18n.t(
                                'Unknown error while fetching task'
                            )}: ` + error.message
                        )
                        throw error;
                    });

            }
        } else {
            this.setState({
                task: this.props.location.task,
                photos: this.props.location.task.taskFeedback.photos,
                feedbackText: this.props.location.task.taskFeedback.feedbackText,
                showFeedback: this.props.location.task.taskFeedback.done,
                done: this.props.location.task.taskFeedback.done,

            })
        }
    }

    handlePageBack = () => {
        if (this.props.history) {
            this.props.history.goBack();
        } else {
            this.props.goTo('/web/opdrachten');
        }
    }
    handleShowFeedback = () => {
        this.setState({
            showFeedback: true
        });
    }

    deleteTask = (task) => {
        apiDeleteTaskById(task)
            .then((entities) => {
                const newList = this.state.tasks.filter((myTask) => (myTask.id !== task));
                this.setState({
                    tasks: newList,
                    taskToHandle: null,
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    toggleThumb = () => {
        this.setState({
            showThumb: !this.state.showThumb
        });
    }

    handleImageUpload = (event) => {
        if (event.id !== undefined) {
            this.setState({
                photos: [
                    ...this.state.photos,
                    { id: event.id, url: event.url, photo: event.id },
                ],
            });
        }
    };

    handleRemoveImage = (id) => {
        if (this.state.photos) {
            let photos = this.state.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
                return null
            });

            this.setState({
                photos: newPhotos,
            });
        }
    };

    handleEditInput = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
            modified: true
        })
    }

    emptyFeedback = () => {
        const { feedbackText } = this.state;
        if (feedbackText === null || feedbackText === undefined || feedbackText === "") {
            return true;
        }
        return false;
    }

    handleSubmit = () => {
        if (this.emptyFeedback()) {
            return;
        }

        let postData = this.createPostData();
        apiAddUpdateTask(postData)
            .then((entities) => {
                // Go back to Opdrachten
                this.handlePageBack();
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    createPostData = () => {
        let user = this.state.task.user;
        let taskFeedback = {
            photos: this.state.photos,
            feedbackText: this.state.feedbackText,
            done: true
        };

        let postData = {
            id: this.state.task.id,
            title: this.state.task.title,
            description: this.state.task.description,
            user: {
                id: user.id,
                firstname: user.firstname,
                middlename: user.middlename,
                lastname: user.lastname
            },
            taskFeedback: taskFeedback,
            deleted: false,
        };
        return postData;
    }

    render() {
        const { t } = i18n;
        const { task, showFeedback, done  } = this.state;

        return (
            <div>
            { task && (
                <div className={classes.root}>
                    <AppBar   className={classes.appBar}>
                        <Toolbar disableGutters className={classes.toolbar}>
                            <IconButton onClick={this.handlePageBack}>
                                <ArrowBackIcon className={classes.appBarButton}/>
                            </IconButton>

                            <Typography
                                type="title"
                                color="inherit"
                                className={classes.flex}
                            >
                                {t('Task Title')}
                            </Typography>
                            {(showFeedback && !done) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={this.emptyFeedback()}
                                >
                                    {t('Mark as Completed')}
                                </Button>
                            )}
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <div className={classes.formWrapper}>
                            <Card>
                                <CardContent className={classes.responseCardContent}>
                                    <div className={classes.wrapContainer}>
                                        <p className={classes.createdBy}>{t('From: ')}{task.createdBy}</p>
                                        <p className={classes.createdDate}>{t('Date: ')}{moment(task.date).format('LLL')}</p>
                                    </div>
                                    <TextField className={classes.message}
                                        error={this.state.titleError}
                                        helperText={this.state.titleError ? this.state.helperText : ''}
                                        style={{ backgroundColor: 'white', }}
                                        fullWidth={true}
                                        disabled={true}
                                        id='title'
                                        label={t('Title')}
                                        onChange={this.handleEditInput}
                                        value={task.title}
                                        autoFocus={true}
                                        onFocus={function (e) {
                                            let val = e.target.value;
                                            e.target.value = '';
                                            e.target.value = val;
                                        }}
                                    />
                                    <TextField className={classes.message}
                                        error={this.state.descriptionError}
                                        helperText={this.state.descriptionError ? this.state.helperText : ''}
                                        style={{ backgroundColor: 'white', }}
                                        fullWidth={true}
                                        disabled={true}
                                        id='description'
                                        onChange={this.handleEditInput}
                                        value={task.description}
                                        autoFocus={true}
                                        multiline={true}
                                        minRows={5}
                                        label={t('Description')}
                                        onFocus={function (e) {
                                            let val = e.target.value;
                                            e.target.value = '';
                                            e.target.value = val;
                                        }}
                                    />

                                    {this.state.showFeedback && (
                                        <div>
                                            <Divider className={classes.divider}></Divider>

                                            <FormControl>
                                                <TextField className={classes.message}
                                                           error={this.state.descriptionError}
                                                           helperText={this.state.descriptionError ? this.state.helperText : ''}
                                                           style={{ backgroundColor: 'white', }}
                                                           fullWidth={true}
                                                           disabled={done}
                                                           id='feedbackText'
                                                           onChange={this.handleEditInput}
                                                           value={this.state.feedbackText}
                                                           placeholder={t('taskFeedbackTextHelper')}
                                                           autoFocus={true}
                                                           multiline={true}
                                                           minRows={5}
                                                           label={t('Explanation')}
                                                           onFocus={function (e) {
                                                               let val = e.target.value;
                                                               e.target.value = '';
                                                               e.target.value = val;
                                                           }}
                                                />
                                            </FormControl>

                                            { !done && (
                                                <div>
                                                <div className={classes.photoBlock}>
                                                    <div className={classes.photoItem}>
                                                        <SimpleImageInput
                                                            id={'feedbackphoto0'}
                                                            onChange={this.handleImageUpload}
                                                            url={
                                                                this.state.photos &&
                                                                this.state.photos[0]
                                                                    ? this.state.photos[0].url
                                                                    : ''
                                                            }
                                                        />
                                                        {this.state.photos && this.state.photos[0] && (
                                                            <IconButton

                                                                className="drawer-button"
                                                                onClick={() =>
                                                                    this.handleRemoveImage(0)
                                                                }
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '-5px',
                                                                    right: '-20px',
                                                                }}
                                                            >
                                                                <DeleteIcon className="drawer-icon" />
                                                            </IconButton>
                                                        )}

                                                    </div>
                                                </div>

                                                <FormControl>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleSubmit}
                                                        disabled={this.emptyFeedback()}
                                                    >
                                                        {t('Mark as Completed')}
                                                    </Button>
                                                </FormControl>
                                                </div>
                                            )}

                                            { done && (
                                                <div>
                                                    {this.state.photos && this.state.photos[0] &&
                                                        this.state.photos.length > 0 && (
                                                            <div
                                                                style={{
                                                                    maxWidth: '550px',
                                                                    marginTop: '16px',
                                                                }}
                                                            >
                                                                {this.state.photos.map(
                                                                    (photo) => {
                                                                        return (
                                                                            this.state.showThumb ?
                                                                                <img className={classes.photoThumb}
                                                                                     src={ photo.url}
                                                                                     alt=""
                                                                                     onClick={this.toggleThumb}
                                                                                />

                                                                                :
                                                                                <img className={classes.photoLarge}
                                                                                     src={ photo.url}
                                                                                     alt=""
                                                                                     onClick={this.toggleThumb}
                                                                                />

                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        )}
                                                </div>

                                            )}

                                        </div>

                                    )}

                                </CardContent>
                            </Card>

                            {this.state.showFeedback === false && (
                                <FormControl className={classes.inputElement}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.handleShowFeedback}
                                    >
                                        {t('Continue')}
                                    </Button>
                                </FormControl>
                            )}

                        </div>
                    </div>
                </div>
            )}
            </div>

        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskOpdracht);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import AppModule from '../AppModule/AppModule';
import {setCrumbPath, showSnackbar} from '../../actions';
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch} from '@mui/material'
import {getNotificationSettings, postNotificationSettings} from '../../lib/api';
import {getModules} from "../lib";
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";

type Props = {
    employee?: any,
    setCrumbPath: (employeeName: string) => void,
};

type State = {
    tab: number,
};

const mapStateToProps = (store, props) => {
    const employee = store.drafts.employees.currentUser;

    return {
        employee: employee
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: 'Settings'}));
        },
        showMessage: (message) => {
            dispatch(showSnackbar(message));
        },

    };
};

class Settings extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
        incidentNotifications: true,
        inspectionNotifications: false,
        inspectionNotificationsIncorrect: true,
        toolboxNotifications: false,
        speakUpNotifications: true,
        loading: true,
        tableWidth: "medium"
    };

    componentDidMount() {
        var tableWidth = localStorage.getItem("tableWidth");
        if (tableWidth === null) {
            tableWidth = 'medium';
        }
        this.setState({tableWidth});
        this.props.setCrumbPath();
        this.notificationSettingsFetch();
    }

    handleIncidentNotificationsSwitch() {
        this.setState({
            incidentNotifications: !this.state.incidentNotifications,
        });
    }

    handleInspectionNotificationsSwitch() {
        this.setState({
            inspectionNotifications: !this.state.inspectionNotifications,
        });
    }

    handleInspectionIncorrectNotificationsSwitch() {
        this.setState({
            inspectionNotificationsIncorrect: !this.state.inspectionNotificationsIncorrect,
        });
    }

    handleToolboxNotificationsSwitch() {
        this.setState({
            toolboxNotifications: !this.state.toolboxNotifications,
        });
    }

    handleSpeakUpNotificationsSwitch() {
        this.setState({
            speakUpNotifications: !this.state.speakUpNotifications,
        });
    }

    notificationSettingsFetch() {
        getNotificationSettings()
            .then((res) => {
                let settings = res.body._embedded.kamsettings
                this.handleSettingsFetch(settings)
            }).catch((error) => {
            console.log(error)
            throw error;
        });
    }

    handleSettingsFetch(settings) {
        if (settings === null) {
            this.setState({
                incidentNotifications: true,
                inspectionNotifications: false,
                inspectionNotificationsIncorrect: true,
                toolboxNotifications: false,
                speakUpNotifications: true,
                loading: false,
            })
        } else {
            this.setState({
                incidentNotifications: settings.incidentNotifications,
                inspectionNotifications: settings.inspectionNotifications,
                toolboxNotifications: settings.toolboxNotifications,
                inspectionNotificationsIncorrect: settings.inspectionNotificationsIncorrect,
                speakUpNotifications: settings.speakUpNotifications,
                loading: false,
            })
        }
    }

    handleTableWidthChange = (event) => {
        console.log(event.target.value)
        localStorage.setItem('tableWidth', event.target.value);
        this.setState({tableWidth: event.target.value});
        switch (event.target.value) {
            case "small":
                localStorage.setItem("tableWidthSize", "1260px");
                break;
            case "medium":
                localStorage.setItem("tableWidthSize", "1520px");
                break;
            case "large":
                localStorage.setItem("tableWidthSize", "1760px");
                break;
            case "maximum":
                localStorage.setItem("tableWidthSize", "none");
                break;
        }
    }

    saveSettings = () => {
        let inspectionNotificationsIncorrect = true

        if (!this.state.inspectionNotifications) {
            inspectionNotificationsIncorrect = this.state.inspectionNotificationsIncorrect
        }

        let data = {
            user: this.props.employee,
            incidentNotifications: this.state.incidentNotifications,
            inspectionNotifications: this.state.inspectionNotifications,
            inspectionNotificationsIncorrect: inspectionNotificationsIncorrect,
            toolboxNotifications: this.state.toolboxNotifications,
            speakUpNotifications: this.state.speakUpNotifications
        }

        postNotificationSettings(data)
            .then((res) => {
                this.props.showMessage(this.props.t('Saved settings'));
            }).catch((error) => {
            throw error;
        });
    }

    render() {
        const {t} = this.props;
        const {
            incidentNotifications,
            inspectionNotifications,
            inspectionNotificationsIncorrect,
            toolboxNotifications,
            speakUpNotifications,
            loading,
            tableWidth
        } = this.state;

        const modules = getModules();
        console.log("tableWidth: " + tableWidth)

        return (

            <div>
                <AppModule overrideWidth={1260} loading={loading}>
                    <h1>{t('Mail Settings')}</h1>
                    <FormControlLabel
                        style={{display: 'flex', alignItems: 'center'}}
                        control={
                            <Switch
                                value={incidentNotifications}
                                checked={incidentNotifications}
                                onClick={(event) => this.handleIncidentNotificationsSwitch()}
                                id="incidentNotifications"
                            />
                        }
                        label={t('Notificaties Incidenten')}
                    />
                    {!modules.speakUp && (
                        <FormControlLabel
                            style={{display: 'flex', alignItems: 'center'}}
                            control={
                                <Switch
                                    value={inspectionNotifications}
                                    checked={inspectionNotifications}
                                    onClick={(event) => this.handleInspectionNotificationsSwitch()}
                                    id="inspectionNotifications"
                                />
                            }
                            label={t('Notificaties Werkplekinspecties')}
                        />
                    )}
                    {!modules.speakUp && (
                        <FormControlLabel
                            style={{display: 'flex', alignItems: 'center'}}
                            control={
                                <Switch
                                    value={inspectionNotificationsIncorrect}
                                    checked={inspectionNotificationsIncorrect}
                                    disabled={inspectionNotifications}
                                    onClick={(event) => this.handleInspectionIncorrectNotificationsSwitch()}
                                    id="inspectionNotificationsIncorrect"
                                />
                            }
                            label={t('Notificaties Incorrecte Werkplekinspecties')}
                        />
                    )}
                    <FormControlLabel
                        style={{display: 'flex', alignItems: 'center'}}
                        control={
                            <Switch
                                value={toolboxNotifications}
                                checked={toolboxNotifications}
                                onClick={(event) => this.handleToolboxNotificationsSwitch()}
                                id="toolboxNotifications"
                            />
                        }
                        label={t('Notificaties Incorrecte Toolboxen')}
                    />

                    {modules.speakUp && (
                        <FormControlLabel
                            style={{display: 'flex', alignItems: 'center'}}
                            control={
                                <Switch
                                    value={speakUpNotifications}
                                    checked={speakUpNotifications}
                                    onClick={(event) => this.handleSpeakUpNotificationsSwitch()}
                                    id="speakUpNotifications"
                                />
                            }
                            label={t('Notificaties Incorrecte SpeakUp')}
                        />
                    )}

                    <Button
                        onClick={(event) => this.saveSettings()}
                        className="secondaryButton"
                    >
                            <span>
                                {t('Save')}
                            </span>
                    </Button>
                    <h1>{t('Website Settings')}</h1>
                    <LanguageDropdown
                        className="dropdown-settings"
                        style={{width: "250px"}}
                    />
                    <br/>
                    <FormControl component="fieldset">
                        {/* Label for the radio group */}
                        <FormLabel component="legend">{t('Page width')}</FormLabel>
                        <RadioGroup
                            aria-label="size"
                            name="size"
                            value={tableWidth}
                            onChange={this.handleTableWidthChange}
                        >
                            {/* Each FormControlLabel renders a radio button option */}
                            <FormControlLabel value="small" control={<Radio/>} label={t("Small")}/>
                            <FormControlLabel value="medium" control={<Radio/>} label={t("Medium")}/>
                            <FormControlLabel value="large" control={<Radio/>} label={t("Large")}/>
                            <FormControlLabel value="maximum" control={<Radio/>} label={t("Maximum")}/>
                        </RadioGroup>
                    </FormControl>
                    {/*<h1>{t('Other settings')}</h1>*/}
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(Settings))
);
// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Button, createMuiTheme, Paper} from '@mui/material';
import {adaptV4Theme, StyledEngineProvider, ThemeProvider as MuiThemeProvider} from '@mui/material/styles';
import classnames from 'classnames';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    classes: any,
    loading: boolean,
    openDialog: (string) => void,
    navigate: (string) => void,
    vv_functions: boolean,
    showNewFeatures: boolean,
};

type State = {
    open: boolean,
};

class PaymentReminder extends React.Component {
    props: Props;
    state: State = {
        open: true,
    };

    render() {
        const { classes, t } = this.props;
        let trialEndsIn = this.props.trialEndsIn;

        return (
            <div
                className={classnames({
                    [classes.rootRoot]: true,
                    [classes.collapsedRootRoot]: !this.state.open,
                })}
            >
                <Paper elevation={4}
                    className={classnames({
                        [classes.root]: true,
                        [classes.collapsedRoot]: !this.state.open,
                    })}
                >
                    <div className={classnames({ [classes.topSection]: true })}>
                        <StyledEngineProvider injectFirst>
                            <MuiThemeProvider
                                theme={createMuiTheme(adaptV4Theme({
                                    palette: {
                                        mode: 'dark',
                                    },
                                }))}
                            >
                                {trialEndsIn > 0 && (
                                    <h2
                                        className={classnames({
                                            [classes.topSectionText]: true,
                                            [classes.collapsedTopSectionText]: !this
                                                .state.open,
                                        })}
                                        style={{ display: 'inline-block' }}
                                    >
                                        {t('Your subscriptions expires in')}{' '}
                                        {trialEndsIn}{' '}
                                        {trialEndsIn === 1 ? t('day') : t('days')}.
                                    </h2>
                                )}
                                {trialEndsIn === 0 && (
                                    <h2
                                        className={classnames({
                                            [classes.topSectionText]: true,
                                            [classes.collapsedTopSectionText]: !this
                                                .state.open,
                                        })}
                                        style={{ display: 'inline-block' }}
                                    >
                                        {t('Your trial subscription is expired')}
                                    </h2>
                                )}
                                {!this.props.stripeIsSet && (
                                    <Button
                                        
                                        variant="contained"
                                        style={{ display: 'none', float: 'right' }}
                                        color="primary"
                                        className={classes.upgradeButton}
                                        onClick={() => {
                                            window.location.href =
                                                '/abonnement/payment';
                                        }}
                                    >
                                        {t('Enter payment details')}
                                    </Button>
                                )}
                                {trialEndsIn === 0 && (
                                    <p
                                        className={classnames({
                                            [classes.topSectionText]: true,
                                            [classes.collapsedTopSectionText]: !this
                                                .state.open,
                                        })}
                                    >
                                        {t('Contact to keep using Veilig Vakwerk')}
                                    </p>
                                )}
                                {!this.props.stripeIsSet && trialEndsIn > 0 && (
                                    <p
                                        className={classnames({
                                            [classes.topSectionText]: true,
                                            [classes.collapsedTopSectionText]: !this
                                                .state.open,
                                        })}
                                    >
                                        {t('change subscription info')}
                                    </p>
                                )}
                                {this.props.stripeIsSet && (
                                    <p
                                        className={classnames({
                                            [classes.topSectionText]: true,
                                            [classes.collapsedTopSectionText]: !this
                                                .state.open,
                                        })}
                                    >
                                        {t('subscription will auto update')}
                                    </p>
                                )}
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default compose(withTranslation())(PaymentReminder);

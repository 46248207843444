// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    InputLabel,
    Menu,
    MenuItem,
    Switch
} from '@mui/material';
import {
    deleteIncident,
    fetchIncidentCause,
    fetchIncidents,
    fetchIncidentTypes,
    filterPeriodFrom,
    filterPeriodMonth,
    filterPeriodTill,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    pushCrumbPath,
    saveIncident,
    seenIncident,
    setCrumbPath,
    setViewkey,
} from '../../actions';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import 'moment/min/locales';
import {inProgress} from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import WarningIcon from '@mui/icons-material/Warning';
import Viewer from 'react-viewer';
import ConfirmDialog from '../ConfirmDialog';
import PeriodFilterBlock from '../FilterBlocks/PeriodFilterBlock';
import FilterHeader from '../FilterHeader';
import $ from 'jquery';
import {bindActionCreators} from 'redux';
import {getCurrentFilterSet} from '../../reducers/filterReducer';
import {fetchCSV, fetchPDF} from '../../lib/api';
import {percentColors1} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';
import {push} from "connected-react-router";

moment.locale(i18n.t('LOCALE'));

type Props = {
    loading: boolean,
    incidents: Array<any>,
    permissions: any,
    setCrumbPath: () => void,
    roles: Array<any>,
    fetchIncidents: () => mixed,
    fetchIncidentTypes: () => mixed,
    fetchIncidentCause: () => mixed,
    saveIncident: () => mixed,
    deleteIncident: () => mixed,
    seenIncident: () => mixed,
    incidentcause: Array<any>,
    incidenttype: Array<any>,
};

type State = {
    columns: Array<any>,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    searchtext: string,
    incidentOpen: boolean,
    incident: Array<any>,
    imageVisible: boolean,
    editMode: boolean,
    showDeleteDialog: boolean,
    incidentToDelete: Array<any>,
    anchorEl?: any,
    openType: boolean,
    typeFilter: any,
    openCause: boolean,
    causeFilter: any,
    openEmployees: boolean,
    employeesFilter: any,
    openMaatregel: boolean,
    maatregelFilter: any,
    exportOpen: boolean,
    situatieFilter: any,
    openSituatie: boolean,
    openWarning: boolean,
    showResolveDialog: boolean,
    incidentToResolve: any,
    incidentToResolveComment: string,
    incidentToResolveSwitch: boolean,
};

const viewkey: string = 'INCIDENT_VIEW';

const mapStateToProps = (store) => {
    let filters = getCurrentFilterSet(store.filters);

    return {
        loading: !inProgress(store),
        incidents: store.entities.incidents,
        incidenttype: store.entities.incidenttype,
        incidentcause: store.entities.incidentcause,
        employees: store.entities.employees,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        fetchIncidents: () => {
            dispatch(fetchIncidents());
        },
        fetchIncidentTypes: () => {
            dispatch(fetchIncidentTypes());
        },
        fetchIncidentCause: () => {
            dispatch(fetchIncidentCause());
        },
        saveIncident: (data) => {
            dispatch(saveIncident(data));
        },
        seenIncident: (data) => {
            dispatch(seenIncident(data));
        },
        deleteIncident: (data) => {
            dispatch(deleteIncident(data));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({
                title: 'Registratiemeldingen'
            }));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path, state) => {
            dispatch(push(path, state));
        },
    };
};

let users = [];

class Incidents extends React.Component<Props, State> {
    props: Props;
    state: State = {
        columns: [
            {id: 'date', label: 'Date', size: '10%'},
            {id: 'type', label: 'Type of report', size: '15%'},
            {id: 'title', label: 'Description', size: '20%'},
            {id: 'from', label: 'Reported by', size: '15%'},
            {id: 'cause', label: 'Type of cause', size: '8%'},
            {id: 'maatregel', label: 'Measures', size: '8%'},
            {id: 'attachments', label: 'Attachments', size: '8%'},
            {id: 'situation', label: 'Situation', size: '8%'},
            {id: 'kamResolved', label: 'Resolved', size: '8%'},
        ],
        actions: [],
        searchtext: '',
        incidentOpen: false,
        imageVisible: false,
        activePhotoId: undefined,
        editMode: false,
        showDeleteDialog: false,
        anchorEl: 0,
        openType: false,
        typeFilter: 0,
        openCause: false,
        causeFilter: 0,
        openEmployees: false,
        employeesFilter: 0,
        openMaatregel: false,
        maatregelFilter: 0,
        exportOpen: false,
        situatieFilter: 0,
        openSituatie: false,
        openWarning: false,
        showResolveDialog: false,
        incidentToResolveComment: null,
        incidentToResolveSwitch: null,
        page: 0,
        rowsPerPage: 10,

    };

    gotoDetailPage = (rowId) => {
        const incident = this.props.incidents.byId[rowId];
        const state = {
            incident: incident
        };
        if (incident.isnew) {
            this.props.seenIncident(incident);
        }

        this.props.goTo('registratiemelding/details/' + incident.id, state);

    };

    handleAction = (event, action) => {
        if (action.id === 'view') {
            this.gotoDetailPage(action.rowId);

        } else if (action.id === 'delete') {
            // window.$crisp.push(['do', 'chat:hide']);

            this.setState({
                incidentToDelete: this.props.incidents.byId[action.rowId],
                incident: this.props.incidents.byId[action.rowId],
                showDeleteDialog: true,
            });
        } else if (action.id === 'markResolved') {
            // window.$crisp.push(['do', 'chat:hide']);
            let incident = this.props.incidents.byId[action.rowId];
            this.setState({
                incidentToResolve: incident,
                incidentToResolveComment: incident.kamComment,
                incidentToResolveSwitch: incident.kamResolved,
                showResolveDialog: true,
            });
        } else if (action.id === 'getPDF') {
            console.log('getPDF');
            this.downloadPdf(this.props.incidents.byId[action.rowId])

        }
    };

    editKamStatus = (key) => (event) => {
        if (key === 'kamComment') {
            this.setState({
                incidentToResolveComment: event.target.value
            });
        } else if (key === 'kamSwitch') {
            this.setState({
                incidentToResolveSwitch: !this.state.incidentToResolveSwitch
            });

        } else if (key === 'kamSave') {
            // Update incident
            let incident = this.props.incidents.byId[this.state.incidentToResolve.id];
            incident.kamComment = this.state.incidentToResolveComment;
            incident.kamResolved = this.state.incidentToResolveSwitch;

            this.props.saveIncident(incident);

            this.setState({
                showResolveDialog: false
            });
        }
    }

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    mapIncidentToRow = (incident) => {
        const {t} = this.props;
        moment.locale(i18n.t('LOCALE'));

        return {
            ...incident,
            date: !incident.date ? (
                '-'
            ) : incident.isnew ? (
                <div>

                    <div
                        style={{
                            width: '8px',
                            height: '8px',
                            backgroundColor: '#0076FF',
                            borderRadius: '24px',
                            float: 'left',
                            marginTop: '4px',
                        }}
                    ></div>
                    <div style={{float: 'left', marginLeft: '8px'}}>
                        {moment(incident.date).format('L HH:mm')}
                    </div>
                </div>
            ) : (
                <div>
                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        width: '8px',*/}
                    {/*        height: '8px',*/}
                    {/*        float: 'left',*/}
                    {/*        marginTop: '4px',*/}
                    {/*    }}*/}
                    {/*></div>*/}
                    <div style={{float: 'left'}}>
                        {moment(incident.date).format('L HH:mm')}
                    </div>
                </div>
            ),
            type:
                incident.type && this.props.incidenttype
                    ? t(
                        this.props.incidenttype.byId[incident.type].locKey ||
                        this.props.incidenttype.byId[incident.type].name
                    )
                    : '-',
            from: incident.anonymous ? t("anonymous") : incident.user.fullname,
            cause:
                incident.cause && this.props.incidentcause
                    ? t(
                        this.props.incidentcause.byId[incident.cause]
                            .locKey ||
                        this.props.incidentcause.byId[incident.cause].name
                    )
                    : '-',
            title: incident.remark.text ? incident.remark.text : '-',
            attachments: incident.remark.photos
                ? incident.remark.photos.length
                : '0',
            maatregel:
                incident.maatregelen && incident.maatregelen.length > 0
                    ? t('Yes')
                    : t('No'),
            situation: incident.remark.unsafe ? (
                <div
                    className={'border-text'}
                    style={{
                        backgroundColor: getColorForPercentage(0),
                        borderColor: getColorForPercentage(0),
                        width: '70px',
                        fontWeight: '300',
                    }}
                >
                    <span>
                        <WarningIcon
                            style={{
                                width: '14px',
                                height: '14px',
                                position: 'relative',
                                top: '2px',
                                marginRight: '5px',
                            }}
                        />
                        {t('Unsafe')}
                    </span>
                </div>
            ) : (
                ''
            ),
            kamResolved:
                (<div title={incident.kamComment}>
                    {incident.kamResolved ? t('Yes') : t('No')}
                </div>),
        };
    };

    onRowClick = (row, event) => {
        // window.$crisp.push(['do', 'chat:hide']);
        this.props.pushCrumbPath({
            crumb: {
                name: i18n.t('Registratiemeldingen'),
                link: `/registratiemeldingen?page=${this.state.page}&rowsPerPage=${this.state.rowsPerPage}`
            }
        });

        this.gotoDetailPage(row.id);
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openType: false,
            openCause: false,
            openEmployees: false,
            openMaatregel: false,
            openSituatie: false,
        });
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap1 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openEmployees: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openCause: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openMaatregel: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap4 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSituatie: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({typeFilter: event.target.value});
    };

    handleCauseFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({causeFilter: event.target.value});
    };

    handleEmployeesFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({employeesFilter: event.target.value});
    };

    handleMaatregelFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({maatregelFilter: event.target.value});
    };

    handleSituatieFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({situatieFilter: event.target.value});
    };

    getFilterBar = () => {
        const {t} = this.props;

        return (
            <div className="filterbar">
                <PeriodFilterBlock/>
                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={t('Type of report')}
                            filterValue={
                                !this.props.incidenttype ||
                                this.state.typeFilter === 0
                                    ? t('Everything')
                                    : this.props.incidenttype.byId[
                                        this.state.typeFilter
                                        ].name
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0} key={0}
                              selected={0 === this.state.typeFilter}>
                        Alles
                    </MenuItem>
                    {this.props.incidenttype &&
                        this.props.incidenttype.allIds.map((id) => {
                            return (
                                <MenuItem
                                    onClick={this.handleTypeFilterChange}
                                    value={id}
                                    key={id}
                                    selected={id === this.state.typeFilter.toString()}
                                >
                                    {t(`${this.props.incidenttype.byId[id].locKey || this.props.incidenttype.byId[id].name}`)}
                                </MenuItem>
                            );
                        })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap1}>
                        <FilterHeader
                            filterTitle={t('Detector')}
                            filterValue={
                                !this.props.employees ||
                                this.state.employeesFilter === 0
                                    ? t('Everything')
                                    : this.props.employees.byId[
                                        this.state.employeesFilter
                                        ].fullname
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openEmployees : false}
                    onClose={this.handleRequestClose}
                    value={this.state.employeesFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleEmployeesFilterChange}
                        value={0}
                        key={0}
                        selected={0 === this.state.employeesFilter}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {this.props.employees &&
                        this.props.employees.allIds.length > 0 &&
                        users &&
                        users.map((id) => {
                            return this.props.employees.byId[id] ? (
                                <MenuItem
                                    onClick={this.handleEmployeesFilterChange}
                                    value={id}
                                    key={id}
                                    selected={id === this.state.employeesFilter}
                                >
                                    {this.props.employees.byId[id].fullname}
                                </MenuItem>
                            ) : null;
                        })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={t('Type of cause')}
                            filterValue={
                                !this.props.incidentcause ||
                                this.state.causeFilter === 0
                                    ? t('Everything')
                                    : t(
                                        this.props.incidentcause.byId[
                                            this.state.causeFilter
                                            ].locKey ||
                                        this.props.incidentcause.byId[
                                            this.state.causeFilter
                                            ].name
                                    )
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openCause : false}
                    onClose={this.handleRequestClose}
                    value={this.state.causeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleCauseFilterChange} value={0} key={0}
                              selected={0 === this.state.causeFilter}>
                        {t('Everything')}
                    </MenuItem>
                    {this.props.incidentcause &&
                        this.props.incidentcause.allIds.map((id) => {
                            return (
                                <MenuItem
                                    onClick={this.handleCauseFilterChange}
                                    value={id}
                                    key={id}
                                    selected={id === this.state.causeFilter.toString()}
                                >
                                    {t(
                                        `${
                                            this.props.incidentcause.byId[id]
                                                .locKey ||
                                            this.props.incidentcause.byId[id]
                                                .name
                                        }`
                                    )}
                                </MenuItem>
                            );
                        })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={t('Measures')}
                            filterValue={
                                this.state.maatregelFilter === 0
                                    ? t('Everything')
                                    : this.state.maatregelFilter === 1
                                        ? t('Yes')
                                        : t('No')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openMaatregel : false}
                    onClose={this.handleRequestClose}
                    value={this.state.maatregelFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleMaatregelFilterChange}
                        value={0}
                        selected={0 === this.state.maatregelFilter}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMaatregelFilterChange}
                        value={1}
                        selected={1 === this.state.maatregelFilter}

                        style={{width: '120px'}}
                    >
                        {t('Yes')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleMaatregelFilterChange}
                        value={2}
                        selected={2 === this.state.maatregelFilter}

                    >
                        {t('No')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap4}>
                        <FilterHeader
                            filterTitle={t('Situation')}
                            filterValue={
                                this.state.situatieFilter === 0
                                    ? t('Everything')
                                    : this.state.situatieFilter === 1
                                        ? t('Is save')
                                        : t('Unsafe')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSituatie : false}
                    onClose={this.handleRequestClose}
                    value={this.state.situatieFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleSituatieFilterChange}
                        value={0}
                        selected={0 === this.state.situatieFilter}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleSituatieFilterChange}
                        value={1}
                        selected={1 === this.state.situatieFilter}
                        style={{width: '120px'}}
                    >
                        {t('Is save')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleSituatieFilterChange}
                        value={2}
                        selected={2 === this.state.situatieFilter}
                    >
                        {t('Unsafe')}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    incidentSorter = () => {
        return {
            date: (a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.remark.text.toLowerCase();
                b = b.remark.text.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            from: (a, b) => {
                a = a.user.fullname.toLowerCase();
                b = b.user.fullname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            attachments: (a, b) => {
                a = a.remark.photos.length;
                b = b.remark.photos.length;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            situation: (a, b) => {
                a = a.remark.unsafe;
                b = b.remark.unsafe;

                if (a === true) {
                    return 1;
                } else if (b === true) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    toggleDrawer = () => {
        // window.$crisp.push(['do', 'chat:show']);
        this.setState({
            incidentOpen: false,
            editMode: false,
        });
    };

    editMode = () => {
        this.setState({
            editMode: true,
        });
    };

    deleteIncident = () => {
        this.props.deleteIncident(this.state.incident);

        // window.$crisp.push(['do', 'chat:show']);
        this.setState({
            incidentOpen: false,
            editMode: false,
        });
    };

    deleteIncidentDialog = () => {
        this.setState({
            incidentToDelete: this.state.incident,
            showDeleteDialog: true,
        });
    };

    hideDeleteDialog = () => {
        this.setState({
            showDeleteDialog: false,
        });
    };

    save = () => {
        if (
            this.state.incident.remark.text.length > 0 &&
            this.state.incident.type !== null &&
            this.state.incident.cause !== null &&
            this.state.incident.cause !== undefined &&
            this.state.incident.cause_comment.length > 0
        ) {
            this.props.saveIncident(this.state.incident);
            this.setState({
                editMode: false,
            });
        } else {
            this.setState({
                openWarning: true,
            });
        }
    };

    cancelEditMode = () => {
        this.setState({
            editMode: false,
            incident: this.props.incidents.byId[this.state.incident.id],
        });
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadPdf = (incident) => {
        console.log('downloadPdf: for: ', incident);

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'INCIDENT',
            options: {},
            data: [],
            info: {
                incident: incident,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);

                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    incident.date + '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));

    };

    // Download a CSV with all rows that match the selected period
    downloadCsv = () => {
        const {t} = this.props;
        let incidents = [];

        this.props.incidents &&
        this.props.incidents.allIds &&
        this.props.incidents.allIds.forEach((id) => {
            incidents.push(this.props.incidents.byId[id]);

            if (!users.includes(this.props.incidents.byId[id].user.id)) {
                users.push(this.props.incidents.byId[id].user.id);
            }
        });

        incidents = incidents
            .filter((i) =>
                this.state.typeFilter && this.state.typeFilter !== 0
                    ? i.type === this.state.typeFilter.toString()
                    : true
            )
            .filter((i) =>
                this.state.employeesFilter && this.state.employeesFilter !== 0
                    ? i.user.id === this.state.employeesFilter
                    : true
            )
            .filter((i) =>
                this.state.causeFilter && this.state.causeFilter !== 0
                    ? i.cause === this.state.causeFilter.toString()
                    : true
            )
            .filter((i) =>
                this.state.maatregelFilter && this.state.maatregelFilter !== 0
                    ? this.state.maatregelFilter === 1
                        ? i.maatregelen && i.maatregelen.length > 0
                        : i.maatregelen == null || i.maatregelen.length === 0
                    : true
            )
            .filter((i) =>
                this.state.situatieFilter && this.state.situatieFilter !== 0
                    ? this.state.situatieFilter === 1
                        ? !i.remark.unsafe
                        : i.remark.unsafe
                    : true
            ).sort((a, b) => {
                if (a.date < b.date) return -1;
                if (a.date > b.date) return 1;
                return 0;
            });

        if (this.props.periodType === 'year') {
            incidents = incidents.filter(
                (i) => moment(i.date).year() === this.props.periodYear
            );
        } else if (this.props.periodType === 'month') {
            incidents = incidents
                .filter((i) => moment(i.date).year() === this.props.periodYear)
                .filter(
                    (i) => moment(i.date).month() === this.props.periodMonth
                );
        } else if (this.props.periodType === 'week') {
            incidents = incidents
                .filter((i) => moment(i.date).year() === this.props.periodYear)
                .filter((i) => moment(i.date).week() === this.props.periodWeek);
        } else if (this.props.periodType === 'fromtill') {
            incidents = incidents.filter(
                (i) =>
                    moment(i.date) > moment(this.props.periodFrom) &&
                    moment(i.date) < this.props.periodTill
            );
        }

        let data = [];

        incidents.forEach((item) => {
            data.push({
                date: moment(item.date).format('L HH:mm'),
                type:
                    item.type && this.props.incidenttype
                        ? t(
                            this.props.incidenttype.byId[item.type].locKey ||
                            this.props.incidenttype.byId[item.type].name
                        )
                        : '',
                user: {
                    fullname: item.anonymous ? t("anonymous") : item.user.fullname,
                },
                cause:
                    item.cause && this.props.incidentcause
                        ? t(
                            this.props.incidentcause.byId[item.cause]
                                .locKey ||
                            this.props.incidentcause.byId[item.cause].name
                        )
                        : '',
                text: item.remark.text,
                bijlagen: item.remark.photos.length,
                maatregelen: item.maatregelen,
                situatie: item.remark.unsafe ? t('Unsafe situation') : '',
                causecomment: item.cause_comment,
                slachtoffer: item.slachtoffer,
                address: item.address,
                resolved: item.kamResolved ? t('Yes') : t('No'),
                comment: item.kamComment,
            });
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'INCIDENT',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'registratiemeldingen.csv');
                link.click();
            })
            .catch((err) => console.log(err));
    };

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    combineDateAndTime = (date, time) => {
        let momentDate = moment(date);
        let momentTime = moment(time);

        const hours = momentTime.hours();
        const minutes = momentTime.minutes();

        var year = momentDate.year();
        var month = momentDate.month();
        var day = momentDate.date();
        var combined = moment()
            .year(year)
            .month(month)
            .date(day)
            .hours(hours)
            .minutes(minutes);

        return new Date(combined.valueOf());
    };

    componentDidMount() {
        const {t} = this.props;
        this.props.setViewkey();
        this.props.setCrumbPath();
        this.props.fetchIncidents();
        this.props.fetchIncidentTypes();
        this.props.fetchIncidentCause();

        let actions = [];

        actions.push({id: 'view', label: 'View'});
        actions.push({id: 'markResolved', label: 'Mark as done'});
        // TODO: Enable when ready actions.push({ id: 'getPDF', label: t('Download PDF file') });
        actions.push({id: 'delete', label: 'Delete'});

        moment.locale(i18n.t('LOCALE'));

        this.setState({
            actions: actions,
        });

    }

    handleImageUpload = (event) => {
        if (event.id !== undefined) {
            this.setState({
                incident: {
                    ...this.state.incident,
                    remark: {
                        ...this.state.incident.remark,
                        photos: [
                            ...this.state.incident.remark.photos,
                            {id: event.id, url: event.url, photo: event.id},
                        ],
                    },
                },
            });
        }
    };

    handleRemoveImage = (id) => {
        if (this.state.incident.remark.photos) {
            let photos = this.state.incident.remark.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
            });

            this.setState({
                incident: {
                    ...this.state.incident,
                    remark: {
                        ...this.state.incident.remark,
                        photos: newPhotos,
                    },
                },
            });
        }
    };

    render() {
        const {loading, t} = this.props;
        const {actions, columns, showResolveDialog} = this.state;

        let incidents = [];
        this.props.incidents &&
        this.props.incidents.allIds &&
        this.props.incidents.allIds.forEach((id) => {
            incidents.push(this.props.incidents.byId[id]);

            if (!users.includes(this.props.incidents.byId[id].user.id)) {
                users.push(this.props.incidents.byId[id].user.id);
            }
        });

        incidents = incidents
            .filter((i) =>
                this.state.typeFilter && this.state.typeFilter !== 0
                    ? i.type === this.state.typeFilter.toString()
                    : true
            )
            .filter((i) =>
                this.state.employeesFilter && this.state.employeesFilter !== 0
                    ? i.user.id === this.state.employeesFilter
                    : true
            )
            .filter((i) =>
                this.state.causeFilter && this.state.causeFilter !== 0
                    ? i.cause === this.state.causeFilter.toString()
                    : true
            )
            .filter((i) =>
                this.state.maatregelFilter && this.state.maatregelFilter !== 0
                    ? this.state.maatregelFilter === 1
                        ? i.maatregelen && i.maatregelen.length > 0
                        : i.maatregelen == null || i.maatregelen.length === 0
                    : true
            )
            .filter((i) =>
                this.state.situatieFilter && this.state.situatieFilter !== 0
                    ? this.state.situatieFilter === 1
                        ? !i.remark.unsafe
                        : i.remark.unsafe
                    : true
            );

        if (this.props.periodType === 'year') {
            incidents = incidents.filter(
                (i) => moment(i.date).year() === this.props.periodYear
            );
        } else if (this.props.periodType === 'month') {
            incidents = incidents
                .filter((i) => moment(i.date).year() === this.props.periodYear)
                .filter(
                    (i) => moment(i.date).month() === this.props.periodMonth
                );
        } else if (this.props.periodType === 'week') {
            incidents = incidents
                .filter((i) => moment(i.date).year() === this.props.periodYear)
                .filter((i) => moment(i.date).week() === this.props.periodWeek);
        } else if (this.props.periodType === 'fromtill') {
            incidents = incidents.filter(
                (i) =>
                    moment(i.date) > moment(this.props.periodFrom) &&
                    moment(i.date) < this.props.periodTill
            );
        }

        const searchresults = incidents;

        const tableActionHeader = (
            <TableActionHeader title={this.getFilterBar()}
                               onActionButton={this.downloadCsv}
                               onActionText="Download"/>
        );

        return (
            <div>
                <Dialog
                    onClose={() => {
                        this.setState({showResolveDialog: false});
                    }}
                    open={showResolveDialog}
                >
                    <DialogTitle>{t('Mark as done')}</DialogTitle>
                    <DialogContent>
                        <InputLabel htmlFor="wpi-kam-comment">
                            {t('Enter KAM Comment')}
                        </InputLabel>
                        <Input
                            fullWidth={true}

                            id="kamComment"
                            value={this.state.incidentToResolveComment}
                            onChange={this.editKamStatus('kamComment')}
                            multiline

                        />
                        <p style={{marginBottom: '-12px'}}>
                            {t('Resolved by KAM?')}{' '}
                            <div
                                style={{
                                    float: 'right',
                                    marginTop: '-12px',
                                    marginRight: '50px',
                                }}
                            >
                                <Switch
                                    checked={this.state.incidentToResolveSwitch}
                                    onChange={this.editKamStatus('kamSwitch')}
                                    id="kamSwitch"
                                />
                            </div>
                        </p>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({showResolveDialog: false});
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.editKamStatus('kamSave')}
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openWarning}
                    onClose={() => this.setState({openWarning: false})}
                >
                    <DialogTitle className="title">
                        {t('Not all mandatory fields completed')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Fill all fields and send again')}
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button

                            className="confirmButton"
                            onClick={() =>
                                this.setState({openWarning: false})
                            }
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>
                <ConfirmDialog
                    open={this.state.showDeleteDialog}
                    handleClose={this.hideDeleteDialog}
                    deleteincident={this.state.incidentToDelete}
                    deleteIncidentFunction={this.deleteIncident}
                />

                <AppModule loading={loading} prepend={tableActionHeader}>
                    <EnhancedTable
                        hover
                        defaultOrderBy={'date'}
                        defaultOrder="desc"
                        columns={columns}
                        onClick={this.onRowClick}
                        rows={searchresults}
                        formatter={this.mapIncidentToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.incidentSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t('No reports yet')
                        }
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}

                    />
                </AppModule>
                {/*<div*/}
                {/*    style={{*/}
                {/*        position: 'fixed',*/}
                {/*        top: '32px',*/}
                {/*        right: '36px',*/}
                {/*        zIndex: 1101,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div>*/}
                {/*        <Fab onClick={this.toggleExport}>*/}
                {/*            {this.state && this.state.exportOpen ? (*/}
                {/*                <CloseIcon/>*/}
                {/*            ) : (*/}
                {/*                <DownloadIcon/>*/}
                {/*            )}*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        style={*/}
                {/*            this.state && this.state.exportOpen*/}
                {/*                ? {*/}
                {/*                    marginTop: '15px',*/}
                {/*                    marginLeft: '8px',*/}
                {/*                    visibility: 'visible',*/}
                {/*                    transition: 'margin-top 300ms',*/}
                {/*                }*/}
                {/*                : {*/}
                {/*                    marginTop: '0px',*/}
                {/*                    marginLeft: '8px',*/}
                {/*                    visibility: 'hidden',*/}
                {/*                    transition: 'margin-top 300ms',*/}
                {/*                }*/}
                {/*        }*/}
                {/*        className={'tooltip'}*/}
                {/*    >*/}
                {/*        <span*/}
                {/*            className={*/}
                {/*                this.state && this.state.exportOpen*/}
                {/*                    ? 'tooltiptextvisible'*/}
                {/*                    : 'tooltiptext'*/}
                {/*            }*/}
                {/*            style={{top: '87px', right: '60px'}}*/}
                {/*        >*/}
                {/*            {t('Download CSV file')}*/}
                {/*        </span>*/}
                {/*        <Fab*/}
                {/*            color="primary"*/}

                {/*            onClick={this.downloadCsv}*/}
                {/*        >*/}
                {/*            <DescIcon/>*/}
                {/*        </Fab>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {this.state.incident &&
                    this.state.incident.remark.photos.length > 0 && (
                        <Viewer
                            visible={this.state.imageVisible}
                            activeIndex={this.state.incident.remark.photos.findIndex(
                                (p) => p.id === this.state.activePhotoId
                            )}
                            drag={false}
                            scalable={false}
                            zIndex={2000}
                            downloadable={true}
                            onClose={() => {
                                this.setState({imageVisible: false});
                            }}
                            onMaskClick={() => {
                                this.setState({imageVisible: false});
                            }}
                            images={this.state.incident.remark.photos.map(
                                (photo) => {
                                    return {
                                        src: photo.url,
                                        downloadUrl: photo.url + '.jpg',
                                    };
                                }
                            )}
                        />
                    )}
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Incidents);

import {fetchAll} from './apiActions';
import {setEntities, setEntity} from './entityActions';
import {showSnackbar} from './uiActions';
import i18n from 'i18next';
import * as api from '../lib/api';

export function fetchVideoToolboxes() {
    return (dispatch) => {
        return fetchAll('videoToolboxes')(dispatch).then((entities) => {
            dispatch(setEntities('videoToolboxes', entities));
        });
    };
}

export function createVideoToolbox(data, callback) {
    return (dispatch) => {
        api.createVideoToolbox(data)
            .then((entity) => {
                const videoToolboxes = entity.body;
                dispatch(setEntity('videoToolboxes', videoToolboxes));

                if (callback) {
                    callback(videoToolboxes);
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not add Video Toolbox')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function toggleShowVideoToolboxSubscriptionTrial(subscriptionId, data, callback) {
    return (dispatch) => {
        api.changeVideoToolboxSubscription(subscriptionId, data)
            .then((entity) => {
                const videoToolboxSubscription = entity.body;
                callback(videoToolboxSubscription);
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Something is wrong')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import {TableHead, TableSortLabel} from '@mui/material';
import EnhancedTableCell from './EnhancedTableCell';
import EnhancedTableRow from './EnhancedTableRow';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './EnhancedTableHead.module.scss';

type Props = {
    onRequestSort: Function,
    order: string,
    orderBy: string,
};

type State = {};

class EnhancedTableHead extends React.Component {
    props: Props;
    state: State = {};

    handleSort = (property) => (event) => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {actions, order, orderBy, columns, t} = this.props;

        return (
            <TableHead
                className={classnames({
                    [classes.root]: true,
                    'mui-fixed': true,
                })}
            >
                <EnhancedTableRow className={classes.row}>
                    {columns.map((column) => {
                        if (
                            (this.props.sorter != null &&
                                this.props.sorter[column.id] === null) ||
                            column.sorter === false
                        ) {
                            return (
                                <EnhancedTableCell
                                    key={column.id}
                                    size={column.size}
                                    className={classes.cell}
                                    noborder
                                    nopadding
                                >
                                    {t(column.label)}
                                </EnhancedTableCell>
                            );
                        } else {
                            return (
                                <EnhancedTableCell
                                    key={column.id}
                                    numeric={column.numeric}
                                    size={column.size}
                                    noborder
                                    nopadding
                                    className={classes.cell}
                                >
                                    <div className={'tooltip'} style={{padding: 0}}>
                                        <span
                                            className={'tooltiptext'}
                                            style={{left: '50px'}}
                                        >
                                            {t('Sort')}
                                        </span>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            onClick={this.handleSort(column.id)}
                                            className={classes.noHorizontalPadding}
                                        >
                                            {t(column.label)}
                                        </TableSortLabel>
                                    </div>
                                </EnhancedTableCell>
                            );
                        }
                    })}
                    {actions > 0 && (
                        <EnhancedTableCell
                            className={classes.actions}
                            noborder
                            nopadding
                            size={'64px'}
                        >
                            {t('Actions')}
                        </EnhancedTableCell>
                    )}
                </EnhancedTableRow>
            </TableHead>
        );
    }
}

export default compose(withTranslation())(EnhancedTableHead);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Button, Paper} from '@mui/material';
import classes from './DashboardTableCard.module.scss';

type Props = {};

type State = {};

class DashboardTableCard extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const {children, title, onButtonClick, buttonText} = this.props;
        return (
            <div className={classes.root}>
                <Paper elevation={4} className={classes.paper}>
                    <div className={classes.title}>
                        <div className={classes.titleText}>{title}</div>
                        <Button onClick={onButtonClick} className={classes.titleButton}>{buttonText}</Button>
                    </div>
                    <div className={classes.content}>
                        {children}
                    </div>
                </Paper>
            </div>
        );
    }
}

export default (DashboardTableCard);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, FormControl, InputLabel} from '@mui/material';
import ListBlockItem from './ListBlockItem';
import {DragDropContainer, DragDropItem} from '../DragDrop';
import {
    addListItemBlockDraft,
    deleteListItemBlockDraft,
    editListBlockDraft,
    editListItemBlockDraft,
    moveListItemBlockDraft,
    showSnackbar,
} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './CheckListBlockForm.module.scss';

type Props = {
    classes: any,
    contentBlock: any,
    onChange: Function,
    update: Function,
    onItemChange: Function,
    onItemMove: Function,
    onItemDelete: Function,
    onItemAdd: Function,
};

type State = {};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editListBlockDraft(props.contentBlock.id, key, value)),
        onItemChange: (index, key, value) =>
            dispatch(
                editListItemBlockDraft(props.contentBlock.id, index, key, value)
            ),
        onItemMove: (fromIndex, toIndex) =>
            dispatch(
                moveListItemBlockDraft(
                    props.contentBlock.id,
                    fromIndex,
                    toIndex
                )
            ),
        onItemDelete: (index) =>
            dispatch(deleteListItemBlockDraft(props.contentBlock.id, index)),
        onItemAdd: () => dispatch(addListItemBlockDraft(props.contentBlock.id)),
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class CheckListBlockForm extends React.Component {
    props: Props;
    state: State = {};

    handleFormChange = (key) => (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.update(key, event.target.value);
    };

    // key is the name of the field to set
    // text or openQuestion
    handleItemFormChange = (index, key) => (event) => {
        const { t } = this.props;
        if (key === 'text' && event.target.value.length > 5000) {
            this.props.showSnackbar(
                t('Text cannot be longer than 5000 characters')
            );
            return;
        }

        let id = 0;
        this.props.contentBlock.listBlock.items.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
            if (item.openQuestion === undefined || item.openQuestion === null) {
                this.props.onItemChange(i, 'openQuestion', false);
            }
            if (item.photoQuestion === undefined || item.openQuestion === null) {
                this.props.onItemChange(i, 'photoQuestion', false);
            }
            if (item.yesnoQuestion === undefined || item.yesnoQuestion === null) {
                this.props.onItemChange(i, 'yesnoQuestion', false);
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }

        if (key === 'questionType') {
            if (event.target.value === 'check') {
                this.props.onItemChange(id, 'openQuestion', false);
                this.props.onItemChange(id, 'photoQuestion', false);
                this.props.onItemChange(id, 'yesnoQuestion', false);
            }
            if (event.target.value === 'open') {
                this.props.onItemChange(id, 'openQuestion', true);
                this.props.onItemChange(id, 'photoQuestion', false);
                this.props.onItemChange(id, 'yesnoQuestion', false);
            }
            if (event.target.value === 'photo') {
                this.props.onItemChange(id, 'openQuestion', false);
                this.props.onItemChange(id, 'photoQuestion', true);
                this.props.onItemChange(id, 'yesnoQuestion', false);
            }
            if (event.target.value === 'yesno') {
                this.props.onItemChange(id, 'openQuestion', false);
                this.props.onItemChange(id, 'photoQuestion', false);
                this.props.onItemChange(id, 'yesnoQuestion', true);
            }
        } else {
            this.props.onItemChange(id, key, event.target.value);
        }
    };

    handleItemMove = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemMove(fromIndex, toIndex);
    };

    handleItemDelete = (index) => (event) => {
        let id = 0;
        this.props.contentBlock.listBlock.items.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemDelete(id);
    };

    handleItemAdd = (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemAdd();
    };

    handlePhotoItemAdd = (event) => {
        if (this.props.onPhoto) {
            this.props.onPhoto();
        }
        this.props.onItemAdd();
    };

    getInputId(path) {
        return (
            this.props.contentBlock.id +
            '-checkListBlock-' +
            [].concat(path).join('-')
        );
    }

    render() {
        const { contentBlock, t } = this.props;

        return (
            <form className={classes.root}>
                <FormControl>
                    <InputLabel shrink={true}>
                        {t('Checklist items')}
                    </InputLabel>
                    <div className={classes.items}>
                        <DragDropContainer onMove={this.handleItemMove}>
                            {contentBlock.listBlock.items.map((item, i) => {
                                return (
                                    <DragDropItem
                                        className={classes.item}
                                        id={item.id}
                                        key={i}
                                        data={item}
                                    >
                                        <ListBlockItem
                                            item={item}
                                            onDelete={this.handleItemDelete(
                                                item.id
                                            )}
                                            onChange={this.handleItemFormChange(
                                                item.id,
                                                'text'
                                            )}
                                            onQuestionType={this.handleItemFormChange(
                                                item.id,
                                                'questionType'
                                            )}
                                        />
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>
                        <Button
                            className={classes.addButton}
                            onClick={this.handleItemAdd}
                        >
                            {t('Add checklist item')}
                        </Button>
                    </div>
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    
    withTranslation()
)(CheckListBlockForm);

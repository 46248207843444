// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem
} from '@mui/material';
import {
    cancelInspection,
    cancelInspectionGroup,
    fetchInspectionGroupAssigns,
    pushCrumbPath,
    setCrumbPath,
} from '../../actions';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import {getPermissions, inProgress} from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {openDialog} from '../../actions/uiActions';
import $ from 'jquery';
import FilterHeader from '../FilterHeader';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";
import {fetchAssignedInspectionsOverviewWithParams} from "../../lib/api";

type Props = {
    loading: boolean,
    toolboxAssigns: any,
    setCrumbPath: () => void,
    goToDetailView: (toolboxId: string, deployedDate: Date) => mixed,
    goToPreview: (toolboxId: string, deployedDate: Date) => mixed,
};

type State = {
    page: number,
    rowsPerPage: number,
    inspections: List,
    searchtext: string,
    typeFilter: any,
};

const mapStateToProps = (store) => {
    const projectsStore = store.entities.projects;
    const employeeStore = store.entities.employees;

    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        groupAssigns: store.entities.inspectionGroupAssigns,
        projects: projectsStore.byId,
        employees: employeeStore.byId,

    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: (breadCrumb) => {
            dispatch(setCrumbPath(breadCrumb));
        },
        goToDetailView: (werkplekinspectieId, page, rowsPerPage) => {
            dispatch(
                push(`/werkplekinspectie/detail/${werkplekinspectieId}#resultaten?page=${page}&rowsPerPage=${rowsPerPage}`)
            );
        },
        goToPreview: (werkplekinspectieId, deployedDate, page, rowsPerPage) => {
            dispatch(
                push(`/werkplekinspectie/preview/${werkplekinspectieId}#ingepland?page=${page}&rowsPerPage=${rowsPerPage}`)
            );
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        fetchInspectionGroupAssigns: () => {
            dispatch(fetchInspectionGroupAssigns());
        },
        cancelInspection: (inspectionId) => {
            dispatch(cancelInspection(inspectionId));
        },
        cancelGroup: (inspectionId) => {
            dispatch(cancelInspectionGroup(inspectionId));
        },
    };
};

class InspectionScheduledOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        searchtext: "",
        page: 0,
        rowsPerPage: 10,
        orderBy: "",
        orderDirection: "",
        typeFilterText: 'scheduled',
        totalSize: 0,
        index: 0,

        actions: [
            {id: 'cancel', label: 'Cancel'}
        ],

        columns: [
            {id: 'logo', label: 'Type', size: '60px', noPadding: true},
            {id: 'title', label: 'Title', size: '20%'},
            {id: 'scheduled', label: 'Scheduled', size: '15%'},
            {id: 'project', label: 'Project/work area', size: '25%'},
            {id: 'deploydBy', label: 'To be executed by', size: '25%'},
            {id: 'dateDone', label: 'Deadline', size: '30%'},
        ],
        typeFilter: 0,
    };

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    handleAction = (event, action) => {
        if (action.id === 'cancel') {
            this.setState({
                dialogOpen: true,
                inspectionToCancel: action.rowId,
            });
        }
    };

    hideConfirmDialog = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    handleCancel = () => {
        if (this.props.groupAssigns.byId[this.state.inspectionToCancel]) {
            this.props.cancelGroup(this.state.inspectionToCancel);
        } else {
            this.props.cancelInspection(this.state.inspectionToCancel);
        }
        this.hideConfirmDialog();
    };

    mapInspectionAssignToRow = (inspectionAssign) => {
        const {t} = this.props;
        moment.locale(i18n.t('LOCALE'));
        const thisUser = this.props.employees[inspectionAssign.user.id];

        // first try fullname this could be #1 the groep name (see render(), #2 the user or else the supplied shortname
        let deployedBy = inspectionAssign.user.fullname ? inspectionAssign.user.fullname : thisUser ? thisUser.fullname : inspectionAssign.user.shortname;

        let thisProject = null;
        if (inspectionAssign.projectId) {
            thisProject = this.props.projects[inspectionAssign.projectId];
        }

        return {
            ...inspectionAssign,
            scheduled: !inspectionAssign.scheduled
                ? '-'
                : moment(inspectionAssign.scheduled).format('l HH:mm'),
            title: inspectionAssign.inspection.title,
            project:
                thisProject &&
                thisProject.omschrijving &&
                thisProject.externalId != null &&
                thisProject.externalId +
                ': ' +
                thisProject.omschrijving,
            deploydBy: deployedBy,
            dateDone:
                inspectionAssign.inspection && inspectionAssign.deadline
                    ? moment(inspectionAssign.deadline).format(t('dateTime'))
                    : '-',
            logo:
                inspectionAssign.inspection.source === 'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '30px'}}
                        >
                            {t('Cumela inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt={t('Cumela logo')}
                        />
                    </div>
                ) : inspectionAssign.inspection.source === 'global' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Veilig Vakwerk inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt={t('Admin logo')}
                        />
                    </div>
                ) : inspectionAssign.inspection.rootInspectionSource ===
                'global' ||
                inspectionAssign.inspection.rootInspectionSource ===
                'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Custom inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt="Aangepast logo"
                        />
                    </div>
                ) : (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '25px'}}
                        >
                            {t('Own inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt={t('Own logo')}
                        />
                    </div>
                ),
        };
    };

    onRowClick = (row) => {
        if (row.inspection.id) {
            // this.props.goToDetailView(row.inspection.id);
            // Set Crumb for current location
            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('Scheduled'),
                    link: `/werkplekinspecties#ingepland?page=${this.state.page}&rowsPerPage=${this.state.rowsPerPage}`,
                    subTitle: row.inspection.title,
                }
            });

            this.props.goToPreview(row.inspection.id, null, this.state.page, this.state.rowsPerPage);
        }
    };

    componentDidMount() {
        this.props.fetchInspectionGroupAssigns();

        let actions = [{id: 'cancel', label: 'Cancel'}];

        this.setState({
            actions: actions
        });

        this.inspectionAssignFetch();

        console.log("INFO: InspectionScheduledOverview: componentDidMount()");
        // If url params rows and page go to right page
        if (this.props.location.hash.includes("ingepland")) {
            const searchString = '?' + this.props.location.hash.split("?")[1]
            console.log("INFO: Custom searchString: " + searchString);
            let jumpToPage = 0;
            if (searchString.includes("page")) {
                jumpToPage = new URLSearchParams(searchString).get("page");
                console.log("INFO: page from SearchParams: " + jumpToPage);
                this.setState({startPage: Number(jumpToPage)});
                this.setState({page: Number(jumpToPage)});
            }
            let startRowsPerPage = 10;
            if (searchString.includes("rowsPerPage")) {
                startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
                console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
                this.setState({startRowsPerPage: Number(startRowsPerPage)});
                this.setState({rowsPerPage: Number(startRowsPerPage)});
            }
        }

    }

    inspectionAssignFetch = () => {
        let inspections = this.props.inspections

        fetchAssignedInspectionsOverviewWithParams(this.state.searchtext, 0, 100, this.state.orderBy, this.state.orderDirection, this.state.typeFilterText)
            .then((res) => {
                let inspectionAssign = res.body._embedded.inspectionAssigns.inspectionAssignProjections
                let listInspectionAssigns = []
                const totalSize = res.body._embedded.inspectionAssigns.totalSize
                inspectionAssign.forEach(singleInspectionAssign => {
                    listInspectionAssigns.push({
                        ...singleInspectionAssign,
                        inspection: {...inspections.byId[singleInspectionAssign.inspectionId]}
                    })
                });
                this.setState({
                    scheduledInspections: listInspectionAssigns,
                    totalSize: totalSize
                })
            }).catch((error) => {
            throw error;
        });
    }

    handleScheduleButtonClick = () => {
        this.props.openDialog('inspectionDeploy', {initialScheduled: true});
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    inspectionAssignedSorter = () => {
        return {
            dateDone: (a, b) => {
                a = a.deadline;
                b = b.deadline;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.inspection.title.toLowerCase();
                b = b.inspection.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            logo: (a, b) => {
                if (a.inspection.source > b.inspection.source) {
                    return 1;
                } else if (a.inspection.source < b.inspection.source) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({typeFilter: event.target.value});
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {openType: false});
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterBar = (list, searchresults) => {
        const {t} = this.props;
        let vvw = false;
        let cumela = false;
        let aangepast = false;
        let eigen = false;

        list.forEach((inspection) => {
            if (
                inspection.inspection.source !== 'cumela' &&
                inspection.inspection.source !== 'global' &&
                inspection.inspection.rootInspectionSource !== 'cumela' &&
                inspection.inspection.rootInspectionSource !== 'global'
            ) {
                eigen = true;
            } else if (
                inspection.inspection.source !== 'cumela' &&
                inspection.inspection.source !== 'global' &&
                (inspection.inspection.rootInspectionSource === 'cumela' ||
                    inspection.inspection.rootInspectionSource === 'global')
            ) {
                aangepast = true;
            } else if (inspection.inspection.source === 'global') {
                vvw = true;
            } else if (inspection.inspection.source === 'cumela') {
                cumela = true;
            }
        });

        return (
            <div className="filterbar">
                <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {(list ? list.length : 0) +
                        ' ' +
                        t(list && list.length === 1 ? 'workplace inspection' : 'workplace inspections') +
                        ' ' +
                        t('scheduled') +
                        (this.state.searchtext.length > 0
                            ? ' - ' + (searchresults ? searchresults.length : 0) +
                            ' ' + t(searchresults && searchresults.length === 1 ? 'searchresult' : 'searchresults')
                            : '')}
                </div>
                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Alles')
                                    : this.state.typeFilter === 1
                                        ? t('Veilig Vakwerk')
                                        : this.state.typeFilter === 2
                                            ? 'CUMELA'
                                            : this.state.typeFilter === 3
                                                ? t('Edited')
                                                : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0} key={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                            key={1}
                        >
                            {t('Veilig Vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                            key={2}
                        >
                            {t('CUMELA')}
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                            key={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                            key={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    render() {
        const {loading, t} = this.props;
        const {scheduledInspections, columns, actions, dialogOpen} = this.state;

        const list = scheduledInspections
            ? scheduledInspections.filter(
                (row) =>
                    row.scheduled && moment(row.scheduled).isAfter(moment())
            )
            : [];

        if (this.props.groupAssigns) {
            this.props.groupAssigns.allIds.forEach((id) => {
                let group = this.props.groupAssigns.byId[id];

                list.push({
                    scheduled: group.scheduled,
                    inspection: group.inspection,
                    deadline: group.deadline,
                    project: group.project,
                    id: group.id,
                    user: {
                        fullname: 'Groep: ' + group.usergroup.name,
                    },
                });
            });
        }

        const searchresults = list
            .filter((row) => {
                let textFound = false;
                row.inspection.inspection &&
                row.inspection.inspection.categories.forEach((cat) => {
                    if (
                        cat.title
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1
                    ) {
                        textFound = true;
                    } else {
                        cat.items.forEach((i) => {
                            if (
                                i.title
                                    .toLowerCase()
                                    .indexOf(
                                        this.state.searchtext.toLowerCase()
                                    ) > -1
                            ) {
                                textFound = true;
                            }
                        });
                    }
                });

                return (
                    (row.inspection.title &&
                        row.inspection.title
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.inspection.description &&
                        row.inspection.description
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.inspection.source &&
                        row.inspection.source
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.project &&
                        row.project.omschrijving &&
                        row.project.omschrijving
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.project &&
                        row.project.externalId &&
                        row.project.externalId
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.inspection.author &&
                        row.inspection.author
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    (row.user.fullname &&
                        row.user.fullname
                            .replace('  ', ' ')
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1) ||
                    textFound
                );
            })
            .filter((inspection) => {
                if (this.state.typeFilter === 0) {
                    return true;
                } else if (this.state.typeFilter === 1) {
                    if (inspection.inspection.source === 'global') {
                        return true;
                    }
                } else if (this.state.typeFilter === 2) {
                    if (inspection.inspection.source === 'cumela') {
                        return true;
                    }
                } else if (this.state.typeFilter === 3) {
                    if (
                        inspection.inspection.source !== 'cumela' &&
                        inspection.inspection.source !== 'global' &&
                        (inspection.inspection.rootInspectionSource ===
                            'cumela' ||
                            inspection.inspection.rootInspectionSource ===
                            'global')
                    ) {
                        return true;
                    }
                } else if (this.state.typeFilter === 4) {
                    if (
                        inspection.inspection.source !== 'cumela' &&
                        inspection.inspection.source !== 'global' &&
                        inspection.inspection.rootInspectionSource !==
                        'cumela' &&
                        inspection.inspection.rootInspectionSource !== 'global'
                    ) {
                        return true;
                    }
                } else {
                    return false;
                }
                return null
            });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search workplace inspections')}
                searchAlwaysOpen={true}
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(list, searchresults)}
                onActionButton={this.handleScheduleButtonClick}
                onActionText="Schedule"
            />
        );

        return (
            <div>
                <Dialog
                    open={dialogOpen ?? false}
                    onClose={this.hideConfirmDialog}
                >
                    <DialogTitle>
                        {t('Cancel workplace inspection')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t(
                                'Are u sure you want to cancel this workplace inspection?'
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={this.hideConfirmDialog}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleCancel}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    {/*<div*/}
                    {/*    className={classnames({*/}
                    {/*        [classes.button]: true,*/}
                    {/*        'mui-fixed': true,*/}
                    {/*    })}*/}
                    {/*>*/}
                    {/*    <div className={'tooltip'} style={{padding: 0}}>*/}
                    {/*        <span*/}
                    {/*            className={'tooltiptext'}*/}
                    {/*            style={{top: '60px', left: '35px'}}*/}
                    {/*        >*/}
                    {/*            Inspectie inplannen*/}
                    {/*        </span>*/}
                    {/*        <Fab*/}

                    {/*            onClick={this.handleScheduleButtonClick}*/}
                    {/*        >*/}
                    {/*            <ScheduleIcon/>*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <EnhancedTable
                        defaultOrderBy="scheduled"
                        defaultOrder="desc"
                        hover
                        className="click"
                        onClick={this.onRowClick}
                        columns={columns}
                        rows={searchresults}
                        formatter={this.mapInspectionAssignToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.inspectionAssignedSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                    'No workplace inspections have been scheduled yet.'
                                )
                        }
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}
                    />
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(),)(InspectionScheduledOverview))
);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Typography} from '@mui/material';
import Loader from '../Loader';
import * as api from '../../lib/api';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './RetrievePinPage.module.scss';

type Props = {};

type State = {
    loading: boolean,
    pin: string | undefined,
    error?: string | undefined,
};

class RetrievePinPage extends React.Component {
    props: Props;
    state: State = {
        loading: true,
        pin: undefined,
        error: undefined,
    };

    componentDidMount() {
        const { t, i18n } = this.props;
        const aid = new URLSearchParams(window.location.search).get('aid');
        const lang = new URLSearchParams(window.location.search).get(
            'language'
        );

        if (lang) {
            i18n.changeLanguage(lang);
        }

        api.exchangeAidForPin(aid)
            .then((pin) => {
                this.setState({
                    loading: false,
                    pin: pin,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    error: t('Failed to get pin'),
                });
            });
    }

    render() {
        const { t } = this.props;
        const { loading, pin, error } = this.state;

        return (
            <div className={classes.root}>
                <img
                    className={classes.logo}
                    src="/assets/images/logo.svg"
                    alt="Logo"
                />
                <div className={classes.header}>
                    <div className={classes.headerContent}>
                        <Typography
                            className={classes.title}
                            component="h2"
                             
                            type="display1"
                            gutterBottom={true}
                            align="center"
                        >
                            {t('Your login pin')}
                        </Typography>
                        <div className={classes.message}>
                            {t('Download app to your mobile phone.')}{' '}
                            {t('Then login with this PIN-code')}
                        </div>
                    </div>
                </div>
                <div className={classes.content}>
                    <Loader size={100} loading={loading}>
                        {pin && (
                            <div className={classes.pinCode}>
                                {pin.split('').map((pinNumber, index) => (
                                    <div
                                        key={index}
                                        className={classes.pinNumber}
                                    >
                                        {pinNumber}
                                    </div>
                                ))}
                            </div>
                        )}

                        {error && <div className={classes.error}>{error}</div>}
                    </Loader>
                </div>
            </div>
        );
    }
}

export default compose(withTranslation(), )(RetrievePinPage);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Button, FormControl, Input, InputLabel, Paper, Typography,} from '@mui/material';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import {LinkList, LinkListItem} from '../LinkList';
import {forgotPassword, processForm, resetForm} from '../../actions';
import {getForm} from '../../selectors';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './ForgotPasswordForm.module.scss'

type Props = {
    processing: boolean,
    result: any,
    error: any,
    onSubmit: Function,
    onReset: Function,
    onNavigate: Function,
};

type State = {
    form: any,
};

const mapStateToProps = (store) => {
    return getForm('forgotPassword')(store);
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (email) => {
            dispatch(processForm('forgotPassword', forgotPassword(email)));
        },
        onReset: () => {
            dispatch(resetForm('forgotPassword'));
        },
        onNavigate: (url) => {
            dispatch(push(url));
        },
    };
};

class ForgotPasswordForm extends React.Component {
    props: Props;
    state: State = {
        form: {
            email: '',
        },
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    handleChange = (event) => {
        this.setState({
            form: { ...this.state.form, [event.target.id]: event.target.value },
        });
    };

    handleLinkItemClick = (event) => {
        event.preventDefault();
        this.reset();
        this.props.onNavigate(event.target.href.replace(/^.*\/\/[^\/]+/, '')); // eslint-disable-line no-useless-escape
    };

    submit() {
        this.props.onSubmit(this.state.form.email);
    }

    reset() {
        this.setState({ form: { email: '' } });
        this.props.onReset();
    }

    render() {
        const { processing, result, error, t } = this.props;
        const success = result && !error;
        const message = success
            ? `${t('We send you an email')}. ${t('To reset your password')}.`
            : `${t('Enter email to reset your password')}.`;

        return (
            <div>
                <Paper elevation={4} className={classes.paper}>
                    <Typography
                        variant="h1"
                        type="display1"
                        gutterBottom={true}
                        align="center"
                    >
                        {t('Forgot password')}
                    </Typography>
                    <Loader size={100} loading={processing}>
                        <ErrorMessage error={error} />
                        <div className={classes.message}>{message}</div>
                        {!success && (
                            <form onSubmit={this.handleSubmit}>
                                <FormControl>
                                    <InputLabel htmlFor="email">
                                        {t('Email address')}
                                    </InputLabel>
                                    <Input
                                        fullWidth={true}
                                        id="email"
                                        inputProps={{ autoComplete: 'off' }}
                                        onChange={this.handleChange}
                                        placeholder={t('Email address')}
                                        required
                                        value={this.state.form.email}
                                    />
                                </FormControl>
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.button}
                                >
                                    {t('Send')}
                                </Button>
                            </form>
                        )}
                        <LinkList>
                            {success && (
                                <LinkListItem
                                    onClick={this.handleLinkItemClick}
                                    href="#"
                                >
                                    {t('Resend')}
                                </LinkListItem>
                            )}
                            <LinkListItem
                                onClick={this.handleLinkItemClick}
                                href="/auth/login"
                            >
                                {t('Login')}
                            </LinkListItem>
                        </LinkList>
                    </Loader>
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ForgotPasswordForm);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import {TableCell, TableFooter} from '@mui/material';
import EnhancedTableRow from './EnhancedTableRow';
import classes from './EnhancedTableFooter.module.scss';

type Props = {
    onRequestSort: Function,
    order: string,
    orderBy: string,
    rowCount: number,
};

type State = {};

class EnhancedTableFooter extends React.Component {
    props: Props;
    state: State = {};

    handleSort = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { children } = this.props;

        return (
            <TableFooter className={ classnames({[classes.root]: true, 'mui-fixed': true}) }>
                <EnhancedTableRow className={ classes.row }>
                    <TableCell className={classes.cell}>
                        <div className={ classes.children }>{ children }</div>
                    </TableCell>
                </EnhancedTableRow>
            </TableFooter>
        );
    }
}

export default (EnhancedTableFooter);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import moment from 'moment';
import TitleDescription from '../TitleDescription';
import './ToolboxAssignFeedback.css';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {formatV2User} from "../../lib/utils";
import classes from './ToolboxAssignFeedback.module.scss';

type Props = {
    information: any
};

type State = {};

class ToolboxAssignFeedback extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { information, t } = this.props;

        if (!information || information.feedback.length <= 0) {
            return <div></div>;
        }

        function sortNumber(a, b) {
            return b.version - a.version;
        }

        let allFeedback = information.feedback.sort(sortNumber);

        let version;

        return (
            <div className="container">
                <div className={classes.container}>
                    <h2>{t('Feedback on this toolbox')}</h2>
                    {allFeedback.map((feedback) => {
                        if (version === feedback.version) {
                            return (
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={
                                        moment(feedback.date, '').format(
                                            t('DD-MM-YYYY')
                                        ) +
                                        ', ' +
                                        formatV2User(feedback.user)
                                    }
                                    itemDescription={feedback.feedback}
                                />
                            );
                        } else {
                            version = feedback.version;
                            return (
                                <div style={{ marginTop: '40px' }}>
                                    {t('Version')} {version}
                                    <TitleDescription
                                        className={classes.item}
                                        itemTitle={
                                            moment(
                                                feedback.date,
                                                ''
                                            ).format(t('DD-MM-YYYY')) +
                                            ', ' +
                                            formatV2User(feedback.user)
                                        }
                                        itemDescription={feedback.feedback}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}

export default compose(
    
    withTranslation()
)(ToolboxAssignFeedback);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Typography,} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {processForm, resetForm, resetPassword} from '../../actions';
import Loader from '../Loader';
import ErrorMessage from '../ErrorMessage';
import {LinkList, LinkListItem} from '../LinkList';
import {getForm} from '../../selectors';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import classes from './ConfirmRegistrationForm.module.scss';

type Props = {
    processing: boolean,
    result: any,
    error: any,
    onSubmit: Function,
    onReset: Function,
    onNavigate: Function,
    showPassword: boolean,
    showPasswordRepeat: boolean,
};

type State = {
    form: any,
    notSamePassword: string,
};

const mapStateToProps = (store) => {
    return getForm('confirmRegistration')(store);
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (newPassword, token, userId) => {
            dispatch(
                processForm(
                    'confirmRegistration',
                    resetPassword(newPassword, token, userId)
                )
            );
        },
        onNavigate: (url) => {
            dispatch(push(url));
        },
        onReset: () => {
            dispatch(resetForm('confirmRegistration'));
        },
    };
};

class ConfirmRegistrationForm extends React.Component {
    props: Props;
    state: State = {
        form: {
            newPassword: '',
            newPasswordRepeat: '',
        },
        showPassword: false,
        showPasswordRepeat: false,
    };

    handleSubmit = (event) => {
        const { t } = this.props;
        if (this.state.form.newPassword !== this.state.form.newPasswordRepeat) {
            this.setState({ notSamePassword: true });
            this.setState({
                notSamePasswordText: t('Passwords are not the same'),
            });
        } else {
            event.preventDefault();
            this.submit();
        }
    };

    handleChange = (event) => {
        this.setState({
            form: { ...this.state.form, [event.target.id]: event.target.value },
        });
        this.setState({ notSamePasswordText: null });
        this.setState({ notSamePassword: false });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleClickShowPasssword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPasswordRepeat = (event) => {
        event.preventDefault();
    };

    handleClickShowPassswordRepeat = () => {
        this.setState({ showPasswordRepeat: !this.state.showPasswordRepeat });
    };

    submit() {
        const params = new URLSearchParams(this.props.location.search);
        this.props.onSubmit(
            this.state.form.newPassword,
            params.get('registrationToken'),
            params.get('userId')
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.result !== this.props.result && !this.props.error) {
            this.props.history.push('/auth/login?message=registered');
        }
    }

    componentDidMount() {
        const { i18n } = this.props;
        const lang = new URLSearchParams(window.location.search).get(
            'language'
        );

        console.log('lang ', lang);

        if (lang) {
            console.log('i18n', i18n);
            i18n.changeLanguage(lang);
            Cookies.set('lang', lang, { expires: 365 });
        }
    }

    render() {
        const { processing, error, t } = this.props;

        return (
            <div>
                <Paper elevation={4} className={classes.paper}>
                    <Typography
                        component="h1"
                         
                        type="display1"
                        gutterBottom={true}
                        align="center"
                    >
                        {t('Confirm registration')}
                    </Typography>
                    <Loader size={100} loading={processing}>
                        <ErrorMessage error={error} />
                        <div className={classes.message}>
                            {t('Enter password to finish registration')}{' '}
                            {t('Password requirments info')} <br />
                            <br />
                            {t('Already account? then you can')}{' '}
                            <a className={classes.link} href="/auth/login">
                                {t('Login right away')}
                            </a>
                            .
                        </div>
                        {this.state.notSamePasswordText && (
                            <div className="warningText">
                                {this.state.notSamePasswordText}
                            </div>
                        )}
                        <FormControl>
                            <InputLabel htmlFor="newPassword">
                                {t('Password')}
                            </InputLabel>
                            <Input
                                fullWidth={true}
                                id="newPassword"
                                inputProps={{ autoComplete: 'off' }}
                                onChange={this.handleChange}
                                placeholder={t('Password')}
                                required
                                type={
                                    this.state.showPassword
                                        ? 'text'
                                        : 'password'
                                }
                                value={this.state.form.newPassword}
                                error={this.state.notSamePassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                this.handleClickShowPasssword
                                            }
                                            onMouseDown={
                                                this.handleMouseDownPassword
                                            }
                                            size="large">
                                            {this.state.showPassword ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <VisibilityIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="newPasswordRepeat">
                                {t('Password again')}
                            </InputLabel>
                            <Input
                                fullWidth={true}
                                id="newPasswordRepeat"
                                inputProps={{ autoComplete: 'off' }}
                                onChange={this.handleChange}
                                placeholder={t('Password again')}
                                required
                                type={
                                    this.state.showPasswordRepeat
                                        ? 'text'
                                        : 'password'
                                }
                                value={this.state.form.newPasswordRepeat}
                                error={this.state.notSamePassword}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                this
                                                    .handleClickShowPassswordRepeat
                                            }
                                            onMouseDown={
                                                this
                                                    .handleMouseDownPasswordRepeat
                                            }
                                            size="large">
                                            {this.state.showPasswordRepeat ? (
                                                <VisibilityOffIcon />
                                            ) : (
                                                <VisibilityIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            
                            color="primary"
                            type="submit"
                            className={classes.button}
                        >
                            {t('Send')}
                        </Button>
                        <LinkList>
                            <LinkListItem href="/auth/login">
                                {t('Login')}
                            </LinkListItem>
                            <LinkListItem
                                target="_blank"
                                href="https://veiligvakwerk.nl/aanvragen"
                            >
                                {t('Request account')}
                            </LinkListItem>
                        </LinkList>
                    </Loader>
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ConfirmRegistrationForm);

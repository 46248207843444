// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, FormControl, InputLabel} from '@mui/material';
import AnswerListItem from './AnswerListItem';
import {DragDropContainer, DragDropItem} from '../DragDrop';
import {
    addAnswerListItemBlockDraft,
    deleteAnswerListItemBlockDraft,
    editAnswerListBlockDraft,
    editAnswerListItemBlockDraft,
    moveAnswerListItemBlockDraft,
    showSnackbar,
} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './AnswerListBlockForm.module.scss';

type Props = {
    classes: any,
    questionBlock: any,
    onChange: Function,
    onItemChange: Function,
    onItemMove: Function,
    onItemDelete: Function,
    onItemAdd: Function,
    showSnackbar: Function,
};

type State = {};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onChange: (key, value) =>
            dispatch(
                editAnswerListBlockDraft(props.questionBlock.id, key, value)
            ),
        onItemChange: (index, key, value) =>
            dispatch(
                editAnswerListItemBlockDraft(
                    props.questionBlock.id,
                    index,
                    key,
                    value
                )
            ),
        onItemMove: (fromIndex, toIndex) =>
            dispatch(
                moveAnswerListItemBlockDraft(
                    props.questionBlock.id,
                    fromIndex,
                    toIndex
                )
            ),
        onItemDelete: (index) =>
            dispatch(
                deleteAnswerListItemBlockDraft(props.questionBlock.id, index)
            ),
        onItemAdd: () =>
            dispatch(addAnswerListItemBlockDraft(props.questionBlock.id)),
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class AnswerListBlockForm extends React.Component {
    props: Props;
    state: State = {};

    handleFormChange = (key) => (event) => {
        this.props.onChange(key, event.target.value);
    };

    handleItemFormChange = (index) => (key) => (event) => {
        const {t} = this.props;
        if (key === 'text' && event.target.value.length > 255) {
            this.props.showSnackbar(
                t('Text cannot be longer than 255 characters')
            );
            return;
        }

        let id = 0;
        this.props.questionBlock.possibleAnswers.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        if (key === 'correct') {
            this.props.onItemChange(
                id,
                key,
                !this.props.questionBlock.possibleAnswers[id].correct
            );
        } else {
            this.props.onItemChange(id, key, event.target.value);
        }
    };

    handleItemMove = (fromIndex, toIndex) => {
        this.props.onItemMove(fromIndex, toIndex);
    };

    handleItemDelete = (index) => (event) => {
        let id = 0;
        this.props.questionBlock.possibleAnswers.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        this.props.onItemDelete(id);
    };

    handleItemAdd = (event) => {
        this.props.onItemAdd();
    };

    getInputId(path) {
        return (
            this.props.questionBlock.id +
            '-answerListBlock-' +
            [].concat(path).join('-')
        );
    }

    render() {
        const {questionBlock, t} = this.props;

        return (
            <form className={classes.root}>
                <FormControl>
                    <InputLabel shrink={true}>{t('Answers')}</InputLabel>
                    <div className={classes.items}>
                        <DragDropContainer onMove={this.handleItemMove}>
                            {questionBlock.possibleAnswers.map((item, i) => {
                                return (
                                    <DragDropItem
                                        className={classes.item}
                                        id={item.id}
                                        key={item.id}
                                        data={item}
                                    >
                                        <AnswerListItem
                                            item={item}
                                            onDelete={this.handleItemDelete(
                                                item.id
                                            )}
                                            onChange={this.handleItemFormChange(
                                                item.id
                                            )}
                                        />
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.addButton}
                            onClick={this.handleItemAdd}
                        >
                            {t('Add answer')}
                        </Button>
                    </div>
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),

    withTranslation()
)(AnswerListBlockForm);

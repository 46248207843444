// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classes from './LinkList.module.scss';

type Props = {};

const LinkList = (props: Props) => {
    return (
        <ul className={ classes.root }>
            { React.Children.toArray(props.children).filter(child => child).map((child, i) => React.cloneElement(child, { linkListIndex: i })) }
        </ul>
    );
};

export default (LinkList);

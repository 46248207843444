// @flow
import React from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, TextField,} from '@mui/material';
import {showSnackbar} from '../../actions';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './AddPromoCodeEditCard.module.scss';

type Props = {
    onSave: () => mixed,
    goBack: () => mixed,
    clearDiff: () => mixed,
};

type State = {
    code: string,
    startDate: string,
    endDate: string,
    discount: number,
    saving: boolean,
};

const mapStateToProps = (store) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class AddPromoCodeEditCard extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    handleCodeChange = (event: any) => {
        let upperCaseCode = "";
        if (event.target.value) {
            upperCaseCode = event.target.value.toUpperCase();
        }
        this.setState({
            code: upperCaseCode
        });
    };

    handleDiscountChange = (event: any) => {
        this.setState({
            discount: event.target.value
        });
    };

    handleStartDateChange = (event: any) => {
        this.setState({
            startDate: event.target.value
        });
    };

    handleEndDateChange = (event: any) => {
        this.setState({
            endDate: event.target.value
        });
    };

    handleExpiryDateChange = (event: any) => {
        try {
            var now = new Date();
            var submittedDate = new Date(
                event.target.value.replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    '$2/$1/$3'
                )
            );
            if (submittedDate <= now) {
                this.setState({
                    saving: true,
                });
                this.setState({dateIsInPast: true});
            } else {
                this.setState({
                    saving: false,
                });
                this.setState({dateIsInPast: false});
            }
        } catch (e) {
        }
        this.props.onEdit(
            Object.assign({}, this.props.promocode, {
                expiryDate: event.target.value,
            })
        );
    };

    cancelEdit = () => {
        // this.props.onEdit(undefined);
        this.props.goBack();
    };

    savePromoCode = () => {
        let startDate = this.state.startDate + 'T01:00:00Z';
        let endDate = this.state.endDate + 'T01:00:00Z';
        this.props.onSave(this.state.code, startDate, endDate, this.state.discount);
    };

    render() {
        const {t} = this.props;

        return (
            <div>
                <DialogContent>
                    <DialogContentText
                        className={classes.text}
                        style={{marginTop: '-20px', color: 'black'}}
                    >
                        {t('Fill out this form and hit the Save Button')}
                    </DialogContentText>

                    <DialogContentText
                        className={classes.text}
                        style={{
                            marginTop: '10px',
                            marginBottom: '0px',
                        }}
                    >
                        {t('Code')}
                    </DialogContentText>

                    <TextField
                        error={this.state.dateIsInPast}

                        className={classes.text}
                        style={{
                            marginTop: '5px',
                            marginBottom: '0px',
                        }}
                        type="text"
                        id="code"
                        value={this.state.code}
                        onChange={this.handleCodeChange}
                    />

                    <DialogContentText
                        className={classes.text}
                        style={{
                            marginTop: '10px',
                            marginBottom: '0px',
                        }}
                    >
                        {t('Discount (%)')}
                    </DialogContentText>

                    <TextField
                        error={this.state.dateIsInPast}

                        className={classes.text}
                        style={{
                            marginTop: '5px',
                            marginBottom: '0px',
                        }}
                        type="number"
                        id="discount"
                        value={this.state.discount}
                        onChange={this.handleDiscountChange}
                    />

                    <DialogContentText
                        className={classes.text}
                        style={{
                            marginTop: '10px',
                            marginBottom: '0px',
                        }}
                    >
                        {t('Start Date (yyyy-mm-dd)')}
                    </DialogContentText>

                    <TextField
                        error={this.state.dateIsInPast}

                        className={classes.text}
                        style={{
                            marginTop: '5px',
                            marginBottom: '0px',
                        }}
                        type="string"
                        id="startDate"
                        defaultValue={"YYYY-MM-DD"}
                        value={this.state.startDate}
                        onChange={this.handleStartDateChange}
                    />

                    <DialogContentText
                        className={classes.text}
                        style={{
                            marginTop: '10px',
                            marginBottom: '0px',
                        }}
                    >
                        {t('End Date (yyyy-mm-dd)')}
                    </DialogContentText>

                    <TextField
                        error={this.state.dateIsInPast}

                        className={classes.text}
                        style={{
                            marginTop: '5px',
                            marginBottom: '0px',
                        }}
                        type="string"
                        id="endDate"
                        defaultValue={"YYYY-MM-DD"}
                        value={this.state.endDate}
                        onChange={this.handleEndDateChange}
                    />

                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        className="button cancel"
                        onClick={this.cancelEdit}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className="button add"
                        onClick={this.savePromoCode}
                        disabled={this.state.saving}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(AddPromoCodeEditCard);

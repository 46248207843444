// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Autocomplete,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
} from '@mui/material';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import {closeDialog, createVideoToolbox, fetchVideoToolboxes, processFormFailure,} from '../../actions';
import {getForm} from '../../selectors';
import TextField from '@mui/material/TextField';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './DialogVideoToolboxCreate.module.scss';

type Props = {
    open: boolean,
    isLoading: boolean,
    error: any,
};

type State = {
    form: any,
};

const filter = createFilterOptions<any>();

const mapStateToProps = (store) => {
    const abonnees = store.subscription.abonnees;

    let abonneesByIdMap = new Map();
    if (abonnees) {
        abonnees.forEach((abonnee) => {
            abonneesByIdMap.set(abonnee.id, abonnee);
        });
    }

    let categorySet = new Set();
    if (store.entities.videoToolboxes.allIds) {
        store.entities.videoToolboxes.allIds.map((id) => store.entities.videoToolboxes.byId[id]).forEach((videoToolbox) => {
            categorySet.add(videoToolbox.category);
        });
    }

    return {
        ...getForm('videoToolboxCreate')(store),
        categories: Array.from(categorySet, (label, id) => ({
            id: label,
            label
        })),
        abonnees: Array.from(abonneesByIdMap, ([id, obj]) => ({
            id,
            label: obj.companyName + " (" + obj.id + ")"
        })).sort((a, b) => {
            return a.label.toLowerCase() === b.label.toLowerCase() ? (a.id < b.id ? -1 : 1) : (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1)
        }),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchVideoToolboxes: () => {
            dispatch(fetchVideoToolboxes());
        },
        onSubmit: (data, onClose) => {
            dispatch(
                createVideoToolbox(data, onClose)
            );
        },
        onClose: () => {
            dispatch(closeDialog('videoToolboxCreate'));
        },
        onError: (message) => {
            dispatch(processFormFailure('videoToolboxCreate', {message}));
        },
    };
};

class DialogVideoToolboxCreate extends React.Component {
    props: Props;
    state: State = {
        form: {
            title: '',
            url: '',
            category: '',
            subscription: [],
        },
        createClicked: false,
    };

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    handleCreateClick = (event) => {
        const {t} = this.props;
        this.setState({createClicked: true})
        var hasError = false
        if (!this.state.form.name) {
            this.props.onError(t('Name is obligated'));
            hasError = true
        }
        if (!this.state.form.url) {
            this.props.onError(t('URL is obligated'));
            hasError = true
        }
        if (!this.state.form.category) {
            this.props.onError(t('Category is obligated'));
            hasError = true
        }
        if (!this.state.form.language) {
            this.props.onError(t('Language is obligated'));
            hasError = true
        }
        if (!hasError) {
            this.props.onSubmit(this.state.form, this.props.onClose);
        }
    };

    handleChange = (inputId) => (event, newValue) => {
        const formState = {...this.state.form};
        if (inputId === 'category' && newValue && newValue.newValue) {
            this.props.categories.push({id: newValue.id, label: newValue.id});
        }
        if ((inputId === 'category' || inputId === 'language' || inputId === 'category') && newValue && newValue.id) {
            this.setState({
                form: {...formState, [inputId]: newValue.id},
            });
        } else {
            this.setState({
                form: {...formState, [inputId]: event.target.value},
            });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState({
                form: {name: '', url: '', category: '', subscription: null},
            });
        }
    }

    render() {
        const {open, error, processing, t} = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('Create new Video Toolbox')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <ErrorMessage
                        className={classes.errorMessage}
                        error={error}
                    />
                    <Loader size={100} loading={processing}>
                        <form onSubmit={this.handleSubmit}>
                            <FormControl>
                                <TextField
                                    fullWidth={true}
                                    {...(this.state.createClicked && this.state.form.name === '' && {
                                        error,
                                        helperText: t("Name cannot be empty.")
                                    })}
                                    id="DialogVideoToolboxCreate-name"
                                    onChange={this.handleChange('name')}
                                    label={t('Name')}
                                    value={this.state.form.name}
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    fullWidth={true}
                                    {...(this.state.createClicked && this.state.form.url === '' && {
                                        error,
                                        helperText: t("URL cannot be empty.")
                                    })}
                                    id="DialogVideoToolboxCreate-url"
                                    onChange={this.handleChange('url')}
                                    label={t('URL')}
                                    value={this.state.form.url}
                                />
                            </FormControl>
                            <FormControl>
                                <Autocomplete
                                    id="DialogVideoToolboxCreate-category"
                                    onChange={this.handleChange('category')}
                                    // label={t('Category')}
                                    options={this.props.categories}
                                    // value={this.state.form.category}
                                    renderInput={(params) => <TextField {...params} label={t("Category")}/>}
                                    freeSolo
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const {inputValue} = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option.title);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                id: inputValue,
                                                // label: inputValue,
                                                label: `Add "${inputValue}"`,
                                                newValue: true,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return `Add "${option.inputValue}"`;
                                        }
                                        // Regular option
                                        return option.label;
                                    }}
                                    value={this.state.form.category}
                                />
                            </FormControl>
                            <FormControl>
                                <Autocomplete
                                    id="DialogVideoToolboxCreate-language"
                                    options={[{"label": t("nl"), "id": "nl"}, {"label": t("en"), "id": "en"}]}
                                    onChange={this.handleChange('language')}
                                    // value={this.state.form.language}
                                    renderInput={(params) => <TextField {...params} label={t("Language")}/>}
                                />
                            </FormControl>
                            <FormControl>
                                <Autocomplete
                                    id="DialogVideoToolboxCreate-subscription"
                                    options={this.props.abonnees}
                                    onChange={this.handleChange('subscription')}
                                    // value={this.state.form.subscription}
                                    renderInput={(params) => <TextField {...params} label={t("Subscription")}/>}
                                />
                            </FormControl>
                        </form>
                    </Loader>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={processing}
                        onClick={this.handleCancelClick}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={processing}
                        onClick={this.handleCreateClick}
                    >
                        {t('Add')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogVideoToolboxCreate);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import moment from '../../lib/moment';
import TitleDescription from '../TitleDescription';
import ToolboxAssignFeedback from '../ToolboxAssignFeedback';
import getRoles from '../../selectors/getRoles';
import {connect} from 'react-redux';
import {Divider, Paper} from '@mui/material';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {V2_TOOLBOX_INFORMATION_REDUX} from "../../lib/apiTypes";
import {isUndefinedOrNull} from "../../lib/utils";
import {getToolboxInformation} from "../../actions";
import classes from './ToolboxAssignInformation.module.scss';

type Props = {
    allSectors: Array<mixed>,
    allCategories: Array<mixed>,
    toolboxResultsOverview: Array<mixed>,
    deploymentId?: number,
    toolbox: any,
    getToolboxInformation: (toolboxId: string) => mixed,
    information: any
};

type State = {};

const mapStateToProps = (store, props) => {
    let informationEntities = store.entities[V2_TOOLBOX_INFORMATION_REDUX];
    let information
    if (!isUndefinedOrNull(props.toolbox) && informationEntities.byId[props.toolbox.id]) {
        information = informationEntities.byId[props.toolbox.id].information
    }

    return {
        information: information,
        roles: getRoles(store)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getToolboxInformation: (toolboxId) => {
            dispatch(getToolboxInformation(toolboxId));
        }
    }
}

class ToolboxAssignInformation extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentDidMount() {
        const { toolbox, getToolboxInformation } = this.props

        // Retrieve the latest information
        getToolboxInformation(toolbox.id)
    }

    render() {
        const {
            toolboxResultsOverview,
            allSectors,
            allCategories,
            information,
            t,
        } = this.props;
        const toolbox = this.props.toolbox;
        let secties;
        let categories;

        if (!toolbox.sectors || toolbox.sectors.length === 0) {
            secties = '-';
        } else {
            secties = toolbox.sectors.map(
                (sectorId) =>
                    allSectors.byId[sectorId] && (
                        <div key={sectorId}>
                            {t(
                                allSectors.byId[sectorId].locKey ||
                                    allSectors.byId[sectorId].name
                            )}
                        </div>
                    )
            ); // makes uppercase the first letter of the secotr's name
        }

        if (toolbox.category && allCategories) {
            categories =
                allCategories.byId[toolbox.category] &&
                t(
                    allCategories.byId[toolbox.category].locKey ||
                        allCategories.byId[toolbox.category].name
                );
        } else {
            categories = '-';
        }

        // Toolbox Meeting
        let subjects = [];
        if (information && information.meeting) {
            if (information.meeting.subjects.length === 0) {
                subjects.push(
                    <div key={-1}>
                        <div className={classes.itemLeft}>
                            <TitleDescription
                                key={-1}
                                itemDescription={t('No topics')}
                            />
                        </div>
                    </div>
                );
            }
            information.meeting.subjects
                .forEach((subject, i) => {
                    subjects.push(
                        <div key={i}>
                            <div className={classes.itemLeft}>
                                <TitleDescription
                                    key={i}
                                    itemDescription={'• ' + subject}
                                />
                            </div>
                        </div>
                    );
                });
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>

                <div className={classes.container}>
                    <Paper elevation={4} className={classes.paper}>
                    <div>
                        <h3>{t('Information about the toolbox')}</h3>
                        <div className={classes.itemLeft}>
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Title')}
                                itemDescription={toolbox.title}
                            />
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Description')}
                                itemDescription={
                                    toolbox.description === ''
                                        ? '-'
                                        : toolbox.description
                                }
                            />
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Sectors')}
                                itemDescription={secties}
                            />
                        </div>
                        <div className={classes.itemRight}>
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Author')}
                                itemDescription={toolbox.author}
                            />
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Category')}
                                itemDescription={categories}
                            />
                            {toolboxResultsOverview && toolboxResultsOverview.length > 0 && (
                                <TitleDescription
                                    className={classes.item4v}
                                    itemTitle={t('Sent to')}
                                    itemDescription={
                                        toolboxResultsOverview.length + ' ' + t('employees')
                                    }
                                />
                            )}
                            <TitleDescription
                                className={classes.item4v}
                                itemTitle={t('Version')}
                                itemDescription={toolbox.version}
                            />
                        </div>

                        {/* Show meeting details */}

                        {information && information.meeting && (
                            <div>
                                <Divider className={classes.divider} />
                                <h3>{t('Information about the meeting')}</h3>
                                <TitleDescription
                                    className={classes.item3o}
                                    itemTitle={'Datum'}
                                    itemDescription={moment(
                                        information.meeting.date
                                    ).format(t('DD-MM-YYYY'))}
                                />
                                <TitleDescription
                                    className={classes.item3o}
                                    itemTitle={t('Time')}
                                    itemDescription={
                                        moment(information.meeting.date).format(
                                            'HH:mm'
                                        ) + t('hour')
                                    }
                                />
                                <TitleDescription
                                    className={classes.item3o}
                                    itemTitle={t('Location')}
                                    itemDescription={
                                        information.meeting.location
                                    }
                                />
                                <TitleDescription
                                    className={classes.item3o}
                                    itemTitle={t('Chairman')}
                                    itemDescription={
                                        information.meeting.chairman
                                            ? information.meeting.chairman
                                            : '-'
                                    }
                                />

                                <Divider className={classes.divider} />
                                <TitleDescription
                                    className={classes.item3o}
                                    itemTitle={t('Topics covered')}
                                />
                                {subjects}

                                <Divider className={classes.divider} />
                            </div>
                        )}
                        <div className={classes.itemLeft}>
                            {information &&
                            information.feedback.length > 0 &&
                            this.props.roles &&
                            !this.props.roles.includes('CUMELA') ? (
                                <ToolboxAssignFeedback
                                    toolbox={toolbox}
                                    information={information}
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxAssignInformation);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import InspectionTrendList from "./InspectionTrendList";
import {fetchInspectionTrend} from "../../../lib/api";
import {showSnackbar} from "../../../actions";
import { Paper} from '@mui/material';
import i18n from "i18next";
import InspectionTrendProjectList from "./InspectionTrendProjectList";
import {connect} from "react-redux";
import classes from './InspectionTrendView.module.scss';

type Props = {
    inspection?: any,
    inspectionAssign?: any,
};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        projects: store.entities.projects,
    };
};

class InspectionTrendView extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentDidMount() {
        // Fetch inspection trend

        fetchInspectionTrend(this.props.inspectionId)
            .then((entities) => {
                this.setState({
                    trendinspection: entities.body._embedded.trendinspection
                });
                // this.props.setCrumbPath(entities.body._embedded.trendinspection);
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching fetchInspectionDetail'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    render() {
        const { projects  } = this.props;

        return (
            <div>
                <Paper elevation={4} className={classes.paper}>

                <div>
                    <br/>
                    <h4>{i18n.t('WPI Project Overview')}</h4>
                </div>
                <InspectionTrendProjectList
                    projects={projects}
                    trendprojects={this.state.trendinspection ? this.state.trendinspection.projects : null}
                />

                <div>
                    <br/>
                    <h4>{i18n.t('WPI Item Overview')}</h4>
                </div>
                <InspectionTrendList
                    onItemClick={this.onItemClicked}
                    getLastSentDate={this.getLastSentDate}
                    countFinishedInspectionsPerProject={this.countFinishedInspectionsPerProject}
                    inspectionsAssignsByProject={ this.myList}
                    trendinspection={this.state.trendinspection ? this.state.trendinspection.items : null}
                    searchtext={this.state ? this.state.searchtext : ''}

                    setPage={0}
                    setRowsPerPage={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={100}
                />
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    
    withTranslation()
)(InspectionTrendView);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import _ from 'lodash';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputAdornment,
    Menu,
    MenuItem,
    Paper,
    Tab,
    Tabs,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import Select from 'react-select';
import Slide from '@mui/material/Slide';
import ToolboxContentEditor from '../ToolboxContentEditor';
import ToolboxQuestionsEditor from '../ToolboxQuestionsEditor';
import ToolboxPreview from '../ToolboxPreview';
import Loader from '../Loader';
import {
    createSector,
    editToolboxDraft,
    getCurrentEmployee,
    getToolboxById,
    initToolboxDraft,
    saveToolbox,
    setCrumbPath,
} from '../../actions';
import {getCategories, getContentBlockTypesForToolbox, getQuestionBlockTypes, getSectors,} from '../../selectors';
import DropdownButton from '../DropdownButton/DropdownButton';
import getRoles from '../../selectors/getRoles';
import {getLanguages, isUndefinedOrNull, isUndefinedOrNullOrNaN,} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import getSectorsById from "../../selectors/getSectorsById";
import classes from './ToolboxEdit.module.scss';
import colors from "../../lib/themes/theme.scss";
import classnames from "classnames";
import i18n from "i18next";

type Props = {
    toolbox: any,
    sectors: Array<any>,
    sectorsById: Array<any>,
    categories: Array<any>,
    toolboxes: any,
    contentBlockTypes: Array<any>,
    loading: boolean,
    roles: Array<any>,
};

type State = {
    activeTab: number,
    changed: boolean,
    warningDialogOpen: boolean,
    dropdownAnchorElement: any,
    saveAsNewDialogOpen: boolean,
    newToolboxName: string,
    newToolboxNameError: ?string,
    submitting: boolean,
    askNewToolbox: boolean,
};

const mapStateToProps = (store, props) => {
    let toolboxDraft = props.toolbox ? store.drafts.toolboxes[props.toolbox.id] : null;

    if (!isUndefinedOrNull(toolboxDraft) && isUndefinedOrNull(toolboxDraft.toolbox)) {
        toolboxDraft = {
            ...store.entities.toolbox.byId[toolboxDraft.id],
        };
    }

    const toolboxes = store.entities.toolboxes;
    const employee = store.drafts.employees.currentUser;

    return {
        contentBlockTypes: getContentBlockTypesForToolbox(store),
        questionBlockTypes: getQuestionBlockTypes(store),
        sectors: getSectors(store),
        sectorsById: getSectorsById(store),
        toolboxes: toolboxes.allIds.map((id) => toolboxes.byId[id]),
        categories: getCategories(store),
        toolboxDraft: toolboxDraft,
        loading: false,
        roles: getRoles(store),
        employee: employee,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSave: (toolboxDraft, openNewToolbox) => {
            dispatch(saveToolbox(toolboxDraft, openNewToolbox));
        }, onChange: (key, value) => {
            dispatch(editToolboxDraft(props.toolbox.id, key, value));
        }, onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);
            dispatch(push('/toolbox/details/' + props.toolbox.id));
        }, initDraft: (toolbox) => {
            dispatch(initToolboxDraft(toolbox));
        }, fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        }, createSector: (data, callback) => {
            dispatch(createSector(data, callback));
        }, getToolboxById: (toolboxId) => {
            dispatch(getToolboxById(toolboxId));
        }, setCrumbPath: (breadCrumb) => {
            dispatch(setCrumbPath(breadCrumb));
        },
    };
};

// For react-select
const customStyles = {
    menu: provided => ({...provided, zIndex: 9999}), option: (provided, state) => {
        return {
            ...provided, color: "black", backgroundColor: state.isSelected ? colors.primaryColor : "white", "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};

const flex = {display: 'flex'};
const none = {display: 'none'};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ToolboxEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        activeTab: 0,
        changed: false,
        warningDialogOpen: false,
        saveAsNewDialogOpen: false,
        dropdownAnchorElement: null,
        newToolboxName: '',
        newToolboxNameError: null,
        submitting: false,
        askNewToolbox: false,
        errorVideo: false,
    };
    timer = null;

    handleChangeLanguage = (select) => {
        this.props.onChange('language', select.value);
        this.setState({
            changed: true,
        });
    };

    handleChange = (key) => (event) => {
        if (event && event.target.value.length <= 105) {
            this.props.onChange(key, event.target.value);
        }

        this.setState({
            changed: true, submitting: false,
        });

        // let error=false;
        // this.props.toolboxDraft.toolbox.blocks && this.props.toolboxDraft.toolbox.blocks.map(b=>{
        //     if (b.type==='videoBlock'){
        //         if ( !b.videoBlock.url.toLowerCase().includes("youtube")){
        //             error=true;
        //         }
        //     }
        // });
        //
        // this.setState({
        //     errorVideo: error
        // });
    };

    handleTabsChange = (event, value) => {
        this.setState({activeTab: value});
    };

    handleClose = (event) => {
        this.props.setCrumbPath({
            title: this.props.t('Toolboxes'),
            crumbs: [{
                name: i18n.t('Overview'),
                link: `/toolboxen#overzicht?page=0&rows=10`,
                subTitle: this.props.toolboxDraft.title,
            }],
        });

        if (this.state.changed) {
            this.setState({
                warningDialogOpen: true,
            });
        } else {
            this.props.onClose();
        }
    };

    checkSaveType = (event) => {
        if (this.props.toolbox.title !== this.props.toolboxDraft.title) {
            this.setState({
                askNewToolbox: true,
            });
        } else {
            this.handleSave();
        }
    };

    isTitleUnique = () => {
        let unique = true;
        this.props.toolboxes.forEach((toolbox) => {
            if (toolbox.title.toLowerCase() === this.state.newToolboxName.toLowerCase()) {
                unique = false;
            }
        });

        return unique;
    };

    handleSave = (event) => {
        const {t} = this.props;
        if (!this.props.loading) {
            let unique = true;
            if (this.props.toolbox.title !== this.props.toolboxDraft.title) {
                unique = this.isTitleUnique();
            }

            if (unique) {
                this.props.onSave(this.props.toolboxDraft, event != null);

                this.setState({
                    changed: false, submitting: true,
                });
            } else {
                this.setState({
                    submitting: false,
                });

                alert(t('The name cannot be the same as the existing toolbox'));
            }
        }
        this.setState({
            changed: false, submitting: true,
        });

        this.requestAskNewToolboxClose();
    };

    handleSaveAsNewStarted = (event) => {
        this.requestCloseDropdown();

        this.setState({
            saveAsNewDialogOpen: true, newToolboxName: this.props.toolboxDraft.title,
        });
    };

    handleSaveAsNewClick = (event) => {
        const {t} = this.props;
        let newTitle;
        if (!this.state.saveAsNewDialogOpen) {
            newTitle = this.props.toolboxDraft.title;
            this.setState({
                newToolboxName: this.props.toolboxDraft.title,
            });
        } else {
            newTitle = this.state.newToolboxName;
        }
        this.setState({
            submitting: true,
        });

        const isCumela = this.props.roles.includes('CUMELA');
        const isAdmin = this.props.roles.includes('ADMIN');
        const extendedCumelaToolbox = !isCumela && this.props.toolboxDraft.source === 'cumela';
        const extendedAdminToolbox = !isAdmin && this.props.toolboxDraft.source === 'global';
        const unique = this.isTitleUnique();

        if (!unique) {
            this.setState({
                submitting: false, askNewToolbox: false,
            });

            alert(t('The name cannot be the same as the existing toolbox'));
        } else {
            const newDraft = {};

            //The API automatically increases version by 1, so we send 0 to get version 1
            const newVersion = isCumela || isAdmin ? '0.-1' : '1.-1';

            let sectors = this.props.toolboxDraft.sectors.filter((sector) => !isUndefinedOrNullOrNaN(sector));

            if (!isCumela) {
                sectors = sectors
                    .map((s) => this.props.sectors.filter((sec) => sec.id === s)[0])
                    .filter((s) => s && s.name !== 'Cumela sector')
                    .map((s) => s.id);
            }

            Object.assign(newDraft, this.props.toolboxDraft, {
                id: extendedCumelaToolbox || extendedAdminToolbox ? this.props.toolboxDraft.id : null,
                title: newTitle || this.props.toolboxDraft.title,
                version: extendedCumelaToolbox || extendedAdminToolbox ? this.props.toolboxDraft.version : newVersion,
                published: undefined,
                sectors: sectors,
                toolboxId: null,
            });
            this.setState({
                changed: false, submiting: false,
            });
            this.props.onSave(newDraft, false);
            this.requestSaveAsNewDialogClose();

            //Use onClose instead of handleClose to skip warning popup
            //this.props.onClose();
        }
    };

    handleDropdownClick = (event) => {
        this.setState({
            dropdownAnchorElement: event.currentTarget,
        });
    };

    requestCloseDropdown = () => {
        this.setState({
            dropdownAnchorElement: null,
        });
    };

    requestWarningDialogClose = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    requestAskNewToolboxClose = () => {
        this.setState({
            askNewToolbox: false,
        });
    };

    requestSaveAsNewDialogClose = () => {
        this.setState({
            saveAsNewDialogOpen: false,
        });
    };

    handleWarningDontSave = () => {
        this.setState({
            warningDialogOpen: false, changed: false,
        });
        // Needed to prevent update loop on ToolboxPreview page where blocks will be removed
        this.props.initDraft(this.props.toolbox);
        this.props.onClose();
    };

    handleWarningSave = () => {
        this.setState({
            warningDialogOpen: false, changed: false, submitting: true,
        });
        this.handleSave();
        // this.props.onClose();
    };

    handleNewToolboxNameChanged = (event) => {
        this.setState({
            newToolboxNameError: null, newToolboxName: event.target.value,
        });
    };

    componentDidMount() {
        this.props.fetchCurrentUser();
        if (this.props.toolbox) {
            if (!isUndefinedOrNull(this.props.toolbox.toolbox)) {
                this.props.initDraft(this.props.toolbox);
            } else {
                this.props.getToolboxById(this.props.toolboxDraft.id);
            }
        }

        this.requestAskNewToolboxClose();
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        // The toolbox has been retrieved, let's set the draft and crumb
        if (this.props.toolbox && _.get(prevProps, 'toolbox.id', null) !== _.get(this.props, 'toolbox.id', null) && !isUndefinedOrNull(this.props.toolbox.toolbox)) {
            this.props.initDraft(this.props.toolbox);
            this.setState({
                activeTab: 0, submitting: false,
            });
        }
    }

    handleChangeMulti = (multi) => {
        this.props.onChange('sectors', multi.map((select) => parseInt(select.value, 10)));
        this.setState({
            changed: true,
        });
    };

    handleChangeSingle = (single) => {
        this.props.onChange('category', single.value);
        this.setState({
            changed: true,
        });
    };

    handleCreateSector = (sector) => {
        this.props.createSector(sector, (savedSector) => {
            let sectors = this.props.toolboxDraft.sectors;
            sectors.push(savedSector.id);
            this.props.onChange('sectors', sectors);
        });
    };

    render() {
        const {
            categories, contentBlockTypes, questionBlockTypes, toolboxDraft, open, loading, t,
        } = this.props;
        const {warningDialogOpen, saveAsNewDialogOpen, submitting} = this.state;
        let {sectors} = this.props;
        const languages = getLanguages(true);

        let selectedLanguage = null
        if (toolboxDraft && toolboxDraft.language) {
            selectedLanguage = t(`translated-${toolboxDraft.language}`);
        }

        let categoryLabel = null;
        if (this.props.toolboxDraft && this.props.toolboxDraft.category && !this.props.toolboxDraft.category.label) {
            this.props.categories.forEach((cat) => {
                if (cat.id === this.props.toolboxDraft.category) {
                    categoryLabel = cat.name;
                }
            })
        }

        let tabInfoText = '';
        switch (this.state.activeTab) {
            case 0:
                tabInfoText = t('toolbox edit info text toolbox content');
                break;
            case 1:
                tabInfoText = t('toolbox edit info text question list');
                break;
            case 2:
                tabInfoText = t('toolbox edit info text example');
                break;
            default:
                tabInfoText = t('toolbox edit info text toolbox content');
        }

        let toolboxIconSrc = "/assets/images/vvw-logo.svg";
        let toolboxIconAlt = "Veilig Vakwerk logo";
        if (toolboxDraft && toolboxDraft.source === 'cumela') {
            toolboxIconSrc = "/assets/images/cumela-logo.png";
            toolboxIconAlt = "Cumela logo";
        } else if (toolboxDraft && toolboxDraft.source === 'global') {
            toolboxIconSrc = "/assets/images/vvw-admin-logo.svg";
            toolboxIconAlt = "";
        } else if (toolboxDraft && toolboxDraft.rootboxSource === 'global' || toolboxDraft && toolboxDraft.rootboxSource === 'cumela') {
            toolboxIconSrc = "/assets/images/aangepast.svg";
            toolboxIconAlt = "Custom logo";
        }

        return (<Dialog
            fullScreen
            maxWidth={false}
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
            className={classes.toolboxEdit}
            PaperProps={{
                sx: {
                    p: 0, // sets padding to 0
                },
            }}
        >
            <Dialog
                open={warningDialogOpen}
                onClose={this.requestWarningDialogClose}
            >
                <DialogTitle>{t('Save Changes')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('You have made changes to the toolbox. Do you want to save it?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.requestWarningDialogClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.handleWarningDontSave}
                    >
                        {t('Dont save')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleWarningSave}
                        disabled={this.state.submitting || (toolboxDraft && toolboxDraft.title.length === 0) || (toolboxDraft && toolboxDraft.category === null)}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={this.state.askNewToolbox}
                onClose={this.requestAskNewToolboxClose}
            >
                <DialogTitle>{t('Save or overwrite?')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Do you want to overwrite or save the toolbox as a new toolbox?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.requestAskNewToolboxClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSave}
                        disabled={this.state.submitting || (toolboxDraft && toolboxDraft.title.length === 0) || (toolboxDraft && toolboxDraft.category === null)}
                    >
                        {t('Overwrite')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSaveAsNewClick}
                        disabled={this.state.submitting || (toolboxDraft && toolboxDraft.title.length === 0) || (toolboxDraft && toolboxDraft.category === null)}
                    >
                        {t('Save as new')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={saveAsNewDialogOpen}
                onClose={this.requestSaveAsNewDialogClose}
            >
                <DialogTitle>{t('Save as a new toolbox')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Enter a new name for the new toolbox.')}
                    </DialogContentText>
                    <TextField
                        fullWidth
                        error={!!this.state.newToolboxNameError}
                        helperText={this.state.newToolboxNameError}
                        onChange={this.handleNewToolboxNameChanged}
                        defaultValue={toolboxDraft ? toolboxDraft.title : ''}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        onClick={this.requestSaveAsNewDialogClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.handleSaveAsNewClick}
                        disabled={this.state.submitting || this.state.newToolboxName.length === 0}
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>

            <AppBar
                className={classes.appBar}
                style={{boxShadow: 'none'}}
            >
                {toolboxDraft && (<Toolbar disableGutters>
                    <img className={classes.toolbarLogo}
                         src={toolboxIconSrc}
                         alt={toolboxIconAlt}
                    />
                    <Typography
                        variant="h2"
                        className={classnames({
                            [classes.flex]: true, [classes.toolboxTitle]: true,
                        })}
                    >
                        {toolboxDraft.title} - {toolboxDraft.version}
                    </Typography>
                    <Button
                        className={classes.cancelButton}
                        variant="contained"
                        color="secondary"
                        onClick={this.handleClose}
                        disabled={this.state.errorVideo}
                    >
                        {t('Cancel')}
                    </Button>
                    {(toolboxDraft.source === 'cumela' && !this.props.roles.includes('CUMELA')) || (toolboxDraft.source === 'global' && !this.props.roles.includes('ADMIN')) ? (
                        <Button

                            variant="contained"
                            color="primary"
                            onClick={this.handleSaveAsNewStarted}
                            disabled={this.state.errorVideo}
                        >
                            {t('Save as a new toolbox')}
                        </Button>) : (<DropdownButton

                        variant="contained"
                        color="primary"
                        onClick={this.checkSaveType}
                        onDropdownClick={this.handleDropdownClick}
                        disabled={this.state.submitting || toolboxDraft.title.length === 0 || this.state.errorVideo || (toolboxDraft && toolboxDraft.category === null)}
                    >
                        {t('Save')}
                    </DropdownButton>)}
                </Toolbar>)}
            </AppBar>

            <Menu
                anchorEl={this.state.dropdownAnchorElement}
                open={Boolean(this.state.dropdownAnchorElement)}
                onClose={this.requestCloseDropdown}
            >
                <MenuItem
                    key="saveas"
                    onClick={this.handleSaveAsNewStarted}
                >
                    {t('Save as a new toolbox')}
                </MenuItem>
            </Menu>

            {toolboxDraft && (<DialogContent className={classes.content}>
                <Loader loading={loading || submitting}>
                    <div className={classes.toolboxInfo}>
                        <div className={classes.toolboxInfoContent}>
                            <form className={classes.toolboxInfoForm}>
                                <FormControl>
                                    <TextField
                                        label={t('Title')}
                                        id="toolbox-title"
                                        error={toolboxDraft.title.length === 0}
                                        placeholder={toolboxDraft.title.length === 0 ? 'Titel mag niet leeg zijn.' : ''}
                                        required
                                        variant="standard"
                                        value={toolboxDraft.title}
                                        onChange={this.handleChange('title')}
                                        InputProps={{
                                            endAdornment: <InputAdornment
                                                position="end"
                                                style={{
                                                    fontSize: '14px', opacity: '0.2',
                                                }}
                                            >
                                                {105 - toolboxDraft.title.length}
                                            </InputAdornment>
                                        }}
                                    />
                                </FormControl>
                                {languages.length > 1 && (<FormControl>
                                    <Select
                                        styles={customStyles}
                                        fullWidth
                                        value={{value: toolboxDraft.language, label: selectedLanguage}}
                                        onChange={this.handleChangeLanguage}
                                        placeholder={t('Language')}
                                        id="language-single-select"
                                        options={languages.map((lang) => ({
                                            value: lang, label: t(`translated-${lang}`),
                                        }))}
                                    />
                                </FormControl>)}
                                <FormControl>
                                    <TextField
                                        label={t('Description')}
                                        helperText={t('Shown in the toolbox preview.')}
                                        fullWidth={true}
                                        id="toolbox-description"
                                        variant="standard"
                                        multiline
                                        maxrows="4"
                                        value={toolboxDraft.description}
                                        onChange={this.handleChange('description')}
                                        InputProps={{
                                            endAdornment: <InputAdornment
                                                position="end"
                                                style={{
                                                    fontSize: '14px', opacity: '0.2',
                                                }}
                                            >
                                                {105 - toolboxDraft.description.length}
                                            </InputAdornment>
                                        }}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Select
                                        styles={customStyles}
                                        isMulti={true}
                                        value={toolboxDraft.sectors.map((sector) => ({
                                            value: sector,
                                            label: this.props.sectorsById[sector] ? (this.props.sectorsById[sector]).name : 'Onbekend',
                                        }))}
                                        className={classes.sectorInput}
                                        onChange={this.handleChangeMulti}
                                        placeholder={t('Sectors')}
                                        fullWidth
                                        options={sectors.filter((sector) => (sector.subscriptionHolder === null && sector.name !== 'Cumela sector') || (this.props.employee && sector.subscriptionHolder && sector.subscriptionHolder.companyname === this.props.employee.companyname) || (this.props.roles.includes('CUMELA') && sector.name === 'Cumela sector'))
                                            .map((sector) => ({
                                                value: sector.id,
                                                label: sector.subscriptionHolder === null ? t(sector.name) : sector.name,
                                            }))}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Select
                                        styles={customStyles}
                                        fullWidth
                                        value={{value: toolboxDraft.category, label: categoryLabel}}
                                        onChange={this.handleChangeSingle}
                                        placeholder={t('Category')}
                                        id="react-select-single"
                                        options={categories.map((category) => ({
                                            value: category.id, label: t(category.locKey || category.name),
                                        }))}
                                    />
                                </FormControl>
                            </form>
                            {/*<div className={classes.toolboxInfoIcon}>*/}
                            {/*    <img*/}
                            {/*        height="50"*/}
                            {/*        width="50"*/}
                            {/*        src={toolboxIconSrc}*/}
                            {/*        alt={toolboxIconAlt}*/}
                            {/*    />*/}
                            {/*    <div>*/}
                            {/*        {t('Version')}{' '}*/}
                            {/*        {toolboxDraft.version}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <Paper elevation={4} className={classes.editors}>
                        <AppBar
                            position="static"
                            style={{boxShadow: 'none'}}
                            className={classes.tabsAppBar}
                        >
                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.handleTabsChange}
                                indicatorColor={colors.primaryColor}
                                textColor="secondary"
                                sx={{'& .Mui-selected': {color: colors.mainTabsSelectedColor}}}
                            >
                                <Tab label={t('Toolbox content')} sx={{color: colors.mainTabsColor}}/>
                                <Tab label={t('Questionnaire')} sx={{color: colors.mainTabsColor}}/>
                                <Tab label={t('Example')} sx={{color: colors.mainTabsColor}}/>
                            </Tabs>
                        </AppBar>
                        <div>
                            <div className={classes.tooltiptext}>
                                {tabInfoText}
                            </div>
                            {this.state.activeTab === 0 && (<ToolboxContentEditor
                                style={this.state.activeTab === 0 ? flex : none}
                                toolbox={toolboxDraft}
                                contentBlockTypes={contentBlockTypes}
                                onChange={this.handleChange('changes')}
                            />)}
                            {this.state.activeTab === 1 && (<ToolboxQuestionsEditor
                                style={this.state.activeTab === 1 ? flex : none}
                                toolbox={toolboxDraft}
                                questionBlockTypes={questionBlockTypes}
                                onChange={this.handleChange('changes2')}
                            />)}
                            {this.state.activeTab === 2 && (<ToolboxPreview
                                style={{
                                    display: this.state.activeTab === 2 ? 'flex' : 'none',
                                }}
                                toolbox={toolboxDraft}
                            />)}
                        </div>
                    </Paper>
                </Loader>
            </DialogContent>)}
        </Dialog>);
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ToolboxEdit);

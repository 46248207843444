import Color from 'color';

const colors = {
    primary: '#13294b',
    secondary: '#00ad6f',
    highlight: '#13294b',
    highlightText: '#FFFFFF',
    textOnDarkBackground: '#00ad6f',


    text: '#5E646D',
    textDark: '#272D35',

    error: 'red',
    border: '#ECF1F4',
};

var primary = {
    50: Color(colors.primary).lighten(0.45).rgb().string(),
    100: Color(colors.primary).lighten(0.4).rgb().string(),
    200: Color(colors.primary).lighten(0.3).rgb().string(),
    300: Color(colors.primary).lighten(0.2).rgb().string(),
    400: Color(colors.primary).lighten(0.1).rgb().string(),
    500: Color(colors.primary).lighten(0).rgb().string(),
    600: Color(colors.primary).darken(0.1).rgb().string(),
    700: Color(colors.primary).darken(0.2).rgb().string(),
    800: Color(colors.primary).darken(0.3).rgb().string(),
    900: Color(colors.primary).darken(0.4).rgb().string(),
    A50: Color(colors.secondary).lighten(0.45).rgb().string(),
    A100: Color(colors.secondary).lighten(0.4).rgb().string(),
    A200: Color(colors.secondary).lighten(0.3).rgb().string(),
    A300: Color(colors.secondary).lighten(0.2).rgb().string(),
    A400: Color(colors.secondary).lighten(0.1).rgb().string(),
    A500: Color(colors.secondary).lighten(0).rgb().string(),
    A600: Color(colors.secondary).darken(0.1).rgb().string(),
    A700: Color(colors.secondary).darken(0.2).rgb().string(),
    A800: Color(colors.secondary).darken(0.3).rgb().string(),
    A900: Color(colors.secondary).darken(0.4).rgb().string(),
    contrastDefaultColor: 'light',
};

const spacingUnit = 8;

export const theme = {
    palette: {
        primary: primary,
        secondary: primary,
        colors: colors,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    zIndex: 1101,
                    padding: '0px',
                },
            positionFixed: {},
            },
        },
        MuiTableCell: {
            root: {
                whiteSpace: 'initial',
                '&:first-child': {
                    paddingLeft: '48px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: colors.secondary,
                        '&.Mui-focusVisible': {
                            backgroundColor: colors.secondary,
                        },
                        '&.Mui-hover': {
                            backgroundColor: colors.secondary,
                        },
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    width: '100%',
                    maxWidth: '100%',
                },
            },
        },
        MuiInputLabel: {
          color: '#fff',
        },
        MuiFab: {
            styleOverrides: {
                label: {
                    justifyContent: 'center',
                },
                root: {
                    backgroundColor: colors.secondary,
                    color: '#ffff',
                    borderRadius: '50%',
                    zIndex: 1102,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                icon: {
                    width: '24px',
                    height: '24px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                title: {
                    fontSize: '20px',
                    fontFamily: 'filson-soft, sans-serif',
                },
                subheading: {
                    fontSize: '16px',
                },
                h1: {
                    fontSize: '2.125rem',
                    fontWeight: '400',
                    color: colors.secondary,
                },
                h2: {
                    color: colors.highlightText,
                    fontSize: '20px',
                    fontWeight: '500',
                },
            },
        },
        MuiListSubheader: {
            root: {
                fontSize: '14px',
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    padding: '12px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                    fontWeight: '500',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
                shadow2: {
                    boxShadow: 'none',
                },
                shadow4: {
                    boxShadow: 'none',
                },
                rounded: {
                    borderRadius: '4px',
                },
            },

        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    checked: {
                        color: colors.secondary,
                    },
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                pointerEvents: 'none',
            },
            popper: {
                pointerEvents: 'none',
            },
        },
        MuiSelect: {
            styleOverrides: {
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthSm: {
                    maxWidth: 700,
                    overflowY: 'visible',
                },
            },
        },
        ClosedButton:{
            backgroundColor: colors.secondary,
        },
        breadCrumbsStyle: {
            root: {
                marginLeft: '8px',
            },
            crumb: {
                color: 'black',
            },
        },
        MuiFormControl:{
            styleOverrides: {
                root:{
                    display: 'block',
                    margin: '10px 0px',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: colors.secondary,
                },
            },
        },
        // MuiTab:{
        //     styleOverrides: {
        //         root:{
        //             '&.Mui-selected': {
        //                 color: 'black',
        //                 borderBottom: '1px solid black',
        //             },
        //         },
        //
        //     },
        // },
    },
    spacingUnit: spacingUnit,
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 360,
            sm: 600,
            md: 960,
            lg: 1350,
            xl: 1920,
        },
    },
};
export const legacyTheme = {
    ...theme,
    palette: {
        primary1Color: primary['A500'],
        primary2Color: primary['A300'],
        pickerHeaderColor: primary['A500'],
    },
    stepper: {
        iconColor: primary[500],
    },
    datePicker: {
        color: primary[500],
        selectColor: primary[500],
        textColor: primary[500],
        selectTextColor: 'black',
        calendarYearBackgroundColor: 'white',
    },
    timePicker: {
        textColor: primary[500],
        accentColor: primary['A500'],
        clockCircleColor: '#eee',
        headerColor: primary['A500'],
        selectTextColor: primary[500],
    },
};

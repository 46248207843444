import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationNL from './translations/nl.json';
import translationEN from './translations/en.json';

// the translations
const resources = {
    nl: {
        translation: translationNL,
    },
    en: {
        translation: translationEN,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'nl',
    fallbackLng: 'nl',
    keySeparator: false,
    // saveMissing: true,
    // missingKeyHandler: (lng, ns, key) => {
    //     console.warn(`Missing translation: ${key} in ${lng}`);
    // },
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

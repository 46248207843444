// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
//import TinyMCE from 'react-tinymce';
import {FormControl, InputAdornment, TextField} from '@mui/material';
import {editTextBlockDraft, showSnackbar} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './TextBlockForm.module.scss';

type Props = {
    classes: any,
    contentBlock: any,
    update: Function,
    onChange: Function,
    textfull: Function,
};

type State = {};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editTextBlockDraft(props.contentBlock.id, key, value)),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};

class TextBlockForm extends React.Component {
    props: Props;
    state: State = {
        value: this.props.contentBlock.textBlock.text,
    };
    timer = null;

    handleFormChange = (key) => (event) => {
        const { t } = this.props;
        const value = event.target.value;

        if (key === 'text' && value.length > 5000) {
            this.props.textfull(
                t('Text cannot be longer than 5000 characters')
            );
            return;
        }

        this.setState({ value });

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange();
            }
            this.props.update(key, value);
        }, 400);
    };

    getInputId(path) {
        return this.props.contentBlock.id + '-textBlock-' + path;
    }

    render() {
        const { contentBlock, t } = this.props;

        return (
            <form className={classes.root}>
                <FormControl>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id={this.getInputId('text')}

                        multiline
                        maxRows="8"
                        onChange={this.handleFormChange('text')}
                        label={t('Text')}
                        value={this.state.value}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                style={{ fontSize: '14px', opacity: '0.2' }}
                            >
                                {5000 - contentBlock.textBlock.text.length}
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    
    withTranslation()
)(TextBlockForm);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl,} from '@mui/material';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import {closeDialog, createUsergroup, fetchUsergroups, processForm, processFormFailure,} from '../../actions';
import {getForm} from '../../selectors';
import TextField from '@mui/material/TextField';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './DialogUsergroupCreate.module.scss';

type Props = {
    open: boolean,
    isLoading: boolean,
    error: any,
};

type State = {
    form: any,
};

const mapStateToProps = (store) => {
    const usergroups = store.entities.usergroups;

    return {
        ...getForm('usergroupCreate')(store),
        usergroups: usergroups.allIds.map((id) => usergroups.byId[id]),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
        onSubmit: (data) => {
            dispatch(
                processForm('usergroupCreate', createUsergroup(data, true))
            );
        },
        onClose: () => {
            dispatch(closeDialog('usergroupCreate'));
        },
        onError: (message) => {
            dispatch(processFormFailure('usergroupCreate', {message}));
        },
    };
};

class DialogUsergroupCreate extends React.Component {
    props: Props;
    state: State = {
        form: {
            title: '',
        },
    };

    handleRequestClose = (event) => {
        this.props.onClose();
    };

    handleCancelClick = (event) => {
        this.props.onClose();
    };

    handleCreateClick = (event) => {
        const {t} = this.props;
        if (this.state.form.name) {
            this.props.onSubmit(this.state.form);
        } else {
            this.props.onError(t('Group name is obligated'));
        }
    };

    handleChange = (inputId) => (event) => {
        const formState = {...this.state.form};
        this.setState({
            form: {...formState, [inputId]: event.target.value},
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState({
                form: {name: '',},
            });
        }
    }

    render() {
        const {open, error, processing, t} = this.props;

        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleRequestClose}
            >
                <DialogTitle>{t('Create new group')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <ErrorMessage
                        className={classes.errorMessage}
                        error={error}
                    />
                    <Loader size={100} loading={processing}>
                        <form onSubmit={this.handleSubmit}>
                            <FormControl>
                                <TextField
                                    fullWidth={true}
                                    id="DialogToolboxCreate-title"

                                    onChange={this.handleChange('name')}
                                    label={t('Name')}
                                    value={this.state.form.name}
                                />
                            </FormControl>
                        </form>
                    </Loader>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={processing}
                        onClick={this.handleCancelClick}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={processing}
                        onClick={this.handleCreateClick}
                    >
                        {t('Add')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogUsergroupCreate);

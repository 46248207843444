// @flow
import React from 'react';
import {List, Map} from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowToolbox from '../../StatisticsView/StatisticsRow/StatisticsRowToolbox';
import {connect} from 'react-redux';
import {getFilteredAndGroupedToolboxAssigns, getFilteredAndGroupedToolboxMeetingsAssigns,} from '../../../selectors';
import {fetchToolboxAssigns} from '../../../actions/toolboxActions';
import getRoles from '../../../selectors/getRoles';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {getCurrentEmployee, pushCrumbPath} from '../../../actions';
import {withRouter} from "react-router";
import i18n from "i18next";

const mapStateToProps = (state) => {
    return {
        roles: getRoles(state),
        toolboxAssigns: getFilteredAndGroupedToolboxAssigns(
            state
        ).filter((toolbox) =>
            getRoles(state).includes('CUMELA') ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.cumela ===
                    true) ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.caoLeo === true)
                ? toolbox
                : toolbox.toolbox.source !== 'cumela'
        ),
        toolboxMeetingsAssigns: getFilteredAndGroupedToolboxMeetingsAssigns(
            state
        ).filter((toolbox) =>
            getRoles(state).includes('CUMELA') ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.cumela ===
                    true) ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.caoLeo === true)
                ? toolbox
                : toolbox.toolbox.source !== 'cumela'
        ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
    };
};

class StatisticsListDistributors extends React.PureComponent {
    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        this.props.fetchCurrentUser();
        this.props.fetchToolboxAssigns();
    }

    rowProvider = (toolboxAssign) => {
        const { t } = this.props;
        if (!toolboxAssign || !toolboxAssign.toolbox) {
            return undefined;
        }

        return (
            <StatisticsRowToolbox
                key={toolboxAssign.id}
                handleRowClick={() => this.onRowClick(toolboxAssign)}
                title={toolboxAssign.toolbox.title}
                latestVersion={toolboxAssign.latestVersion}
                nrToolboxMeetings={
                    this.props.isNoToolboxMeetingChecked
                        ? '0'
                        : this.countToolboxMeetings(toolboxAssign)
                }
                individualToolbox={
                    this.props.isToolboxMeetingChecked
                        ? '-'
                        : this.returnIndividualToolbox(toolboxAssign) > 0
                        ? t('Yes')
                        : t('No')
                }
                totalSend={
                    this.props.isNoToolboxMeetingChecked
                        ? this.returnIndividualToolbox(toolboxAssign)
                        : toolboxAssign.assignList
                        ? toolboxAssign.assignList.length
                        : 0
                }
                totalCompleted={toolboxAssign.finished}
                answersCorrect={toolboxAssign.answersCorrect}
                answersIncorrect={toolboxAssign.answersIncorrect}
                logo={
                    toolboxAssign.toolbox.source === 'cumela'
                        ? '/assets/images/cumela-logo.png'
                        : toolboxAssign.toolbox.source === 'global'
                        ? '/assets/images/vvw-admin-logo.svg'
                        : toolboxAssign.toolbox.rootboxSource === 'global' ||
                          toolboxAssign.toolbox.rootboxSource === 'cumela'
                        ? '/assets/images/aangepast.svg'
                        : '/assets/images/vvw-logo.svg'
                }
            />
        );
    };

    countToolboxMeetings(toolboxAssign) {
        let countedMeetings = [];
        toolboxAssign.assignList.forEach((ta) => {
            if (ta.toolboxMeeting === true) {
                let isNew = true;
                if (countedMeetings.length > 0) {
                    countedMeetings.forEach((meeting) => {
                        if (
                            ta.toolboxMeetingChairman ===
                                meeting.toolboxMeetingChairman &&
                            this.checkSameArray(
                                ta.toolboxMeetingSubjects,
                                meeting.toolboxMeetingSubjects
                            ) &&
                            ta.toolboxMeetingDate ===
                                meeting.toolboxMeetingDate &&
                            ta.toolboxMeetingLocation ===
                                meeting.toolboxMeetingLocation
                        ) {
                            isNew = false;
                        }
                    });
                }
                if (isNew) {
                    countedMeetings.push({
                        toolboxMeetingChairman: ta.toolboxMeetingChairman,
                        toolboxMeetingSubjects: ta.toolboxMeetingSubjects,
                        toolboxMeetingDate: ta.toolboxMeetingDate,
                        toolboxMeetingLocation: ta.toolboxMeetingLocation,
                    });
                }
            }
        });

        return countedMeetings.length;
    }

    checkSameArray(a, b) {
        let i = a.length;
        if (i !== b.length) return false;
        while (i--) {
            if (a[i].subject !== b[i].subject) return false;
        }
        return true;
    }

    returnIndividualToolbox(toolboxAssign) {
        var individuals = 0;
        toolboxAssign.assignList.forEach((ta) => {
            if (ta.toolboxMeeting === false) {
                individuals++;
            }
        });
        return individuals;
    }

    headerProvider = () => {
        const { t } = this.props;
        return {
            logo: 'Type',
            title: 'Title',
            latestVersion: 'Version',
            individualToolbox: 'Sent individually',
            nrToolboxMeetings: 'Number of toolbox meetings',
            totalCompleted: 'Score',
        };
    };

    onRowClick = (toolboxAssign) => {
        const { location } = this.props;
        // Set current location
        let currentLocation = location.pathname + location.search + location.hash;
        console.log('XXX StatisticsListToolboxes');
        this.props.pushCrumbPath({crumb: { name: i18n.t('Toolboxen'), link: `${currentLocation}`, subTitle: toolboxAssign.toolbox.title}});

        this.props.onItemClick(toolboxAssign,this.props.isToolboxMeetingChecked);

    };

    render() {
        const { t } = this.props;
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set('title', (toolboxAssignA, toolboxAssignB) => {
            if (toolboxAssignA.toolbox.title && toolboxAssignB.toolbox.title) {
                if (
                    toolboxAssignA.toolbox.title.toLowerCase() >
                    toolboxAssignB.toolbox.title.toLowerCase()
                ) {
                    return 1;
                }
                if (
                    toolboxAssignA.toolbox.title.toLowerCase() <
                    toolboxAssignB.toolbox.title.toLowerCase()
                ) {
                    return -1;
                }
                return 0;
            }
            return 0;
        });
        sorters = sorters.set(
            'totalCompleted',
            (inspectionAssignA, inspectionAssignB) => {
                if (inspectionAssignA.finished > inspectionAssignB.finished) {
                    return 1;
                }
                if (inspectionAssignA.finished < inspectionAssignB.finished) {
                    return -1;
                }
                if (inspectionAssignA.finished === inspectionAssignB.finished) {
                    if (
                        inspectionAssignA.totalEmployees >
                        inspectionAssignB.totalEmployees
                    ) {
                        return 1;
                    }
                    if (
                        inspectionAssignA.totalEmployees <
                        inspectionAssignB.totalEmployees
                    ) {
                        return -1;
                    }
                }
                return 0;
            }
        );

        sorters = sorters.set('nrToolboxMeetings', (taA, taB) => {
            let nrA = this.countToolboxMeetings(taA);
            let nrB = this.countToolboxMeetings(taB);
            if (nrA > nrB) {
                return 1;
            }
            if (nrA < nrB) {
                return -1;
            }
            return 0;
        });
        sorters = sorters.set(' individualToolbox', (taA, taB) => {
            let nrA =
                this.returnIndividualToolbox(taA) > 0 ? t('Yes') : t('No');
            let nrB =
                this.returnIndividualToolbox(taB) > 0 ? t('Yes') : t('No');
            if (nrA > nrB) {
                return 1;
            }
            if (nrA < nrB) {
                return -1;
            }
            return 0;
        });
        sorters = sorters.set(
            'logo',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.toolbox.source >
                    inspectionAssignB.toolbox.source
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.toolbox.source <
                    inspectionAssignB.toolbox.source
                ) {
                    return -1;
                }
                return 0;
            }
        );

        let ta = null;
        if (
            this.props.isToolboxMeetingChecked ||
            this.props.isNoToolboxMeetingChecked
        ) {
            ta = this.props.toolboxMeetingsAssigns;
        } else {
            ta = this.props.toolboxAssigns;
        }

        const data = List(
            ta !== undefined &&
                ta
                    .filter((toolbox) =>
                        this.props.searchtext
                            ? toolbox.toolbox.title
                                  .toLowerCase()
                                  .indexOf(
                                      this.props.searchtext.toLowerCase()
                                  ) > -1
                            : true
                    )
                    .filter((toolbox) =>
                        this.props.sector && this.props.sector !== 0
                            ? toolbox.toolbox.sectors.includes(
                                  this.props.sector
                              )
                            : true
                    )
                    .filter((toolbox) =>
                        this.props.category && this.props.category !== 'all'
                            ? toolbox.toolbox.category === this.props.category
                            : true
                    )
                    .filter((toolbox) =>
                        this.props.afgerond && this.props.afgerond !== 0
                            ? this.props.afgerond === 1
                                ? toolbox.finished !== 0
                                : toolbox.finished !== toolbox.employeeCount
                            : true
                    )
                    .map((item) =>
                        Object.assign(item, {
                            totalEmployees: item.employeeCount,
                            totalCompleted: item.finished,
                            individualSent: this.props.isToolboxMeetingChecked
                                ? '-'
                                : this.returnIndividualToolbox(item) > 0
                                ? t('Yes')
                                : t('No'),
                            nrToolboxMeetings: this.props.isNoToolboxMeetingChecked
                                ? '0'
                                : this.countToolboxMeetings(item),
                        })
                    )
        );

        return (
            <PaginatedSortFilterView
                data={data}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="title"
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(StatisticsListDistributors))
);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Switch,
    Toolbar,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Loader from '../../Loader';
import {inProgress} from '../../../selectors';
import getRoles from '../../../selectors/getRoles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {fetchSubscriptionCategories, upgradeAbonnee} from '../../../actions';
import {TSubscription} from '../../../reducers/subscriptionReducer';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './AbonnementEdit.module.scss';
import TextField from "@mui/material/TextField";

type Props = {
    loading: boolean, onUpgraded: () => mixed, upgradeAbonnee: (abonnee: TSubscription, password: String) => void,
    prices: []
};

type State = {
    warningDialogOpen: boolean,
    subscription: any,
    menuOpen: boolean,
    menuCreditsOpen: boolean,
    menuCumelaOpen: boolean,
    menuCaoleoOpen: boolean,
    prijsZonderKorting: any,
    dialogUpgraded: boolean,
    rowMenuAnchorElement: null,
    creditsPrice: any,
};

const mapStateToProps = (store, props) => {
    let categories = store.subscription.categories;

    return {
        loading: !inProgress(store), roles: getRoles(store), categories: categories,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        // upgradeAbonnee: bindActionCreators(upgradeAbonnee, dispatch)
        onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);

            dispatch(push('/abonnement'));
        },
        fetchCategories: (id: Number) => {
            dispatch(fetchSubscriptionCategories(id));
        },
        upgradeAbonnee: (abonnee, callback) => {
            dispatch(upgradeAbonnee(abonnee, (success, error) => {
                callback(success, error);
            }))
        }
    };
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AbonnementEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        warningDialogOpen: false,
        subscription: this.props.subscription,
        menuOpen: false,
        menuCreditsOpen: false,
        menuCumelaOpen: false,
        menuCaoleoOpen: false,
        rowMenuAnchorElement: null,
        prijsZonderKorting: 0,
        creditsPrice: 99,
        confirmationPassword: '',
        maxEmployees: 0,
        newCredits: 0,
        prices: [],
        relevantPrices: [],
        credits: []
    };

    handleClose = (event) => {
        if (this.props.subscription.vv_functions) {
            const price = this.state.relevantPrices.find((price) => price.option === this.props.subscription.maxEmployees);
            this.setState({
                subscription: {
                    ...this.props.subscription, price: price.price,
                },
            });
        } else {
            this.setState({
                subscription: {
                    ...this.props.subscription, maxEmployees: 0, price: 0,
                },
            });
        }
        this.props.onClose();
    };

    requestWarningDialogClose = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    requestDialogUpgradedClose = () => {
        this.setState({
            dialogUpgraded: false,
        });
        this.props.onClose();
        this.props.onUpgraded();

        window.location.reload();
    };

    handleSneldelenChange = (event) => {
        if (this.props.subscription.credits_setup !== true) {
            this.setState({
                subscription: {
                    ...this.state.subscription,
                    credits: this.state.subscription.credits_setup ? this.props.subscription.credits : 100,
                    credits_setup: !this.state.subscription.credits_setup,
                },
                newCredits: this.state.credits.length >= 1 ? this.state.credits[0].amount : 0,
                creditsPrice: this.state.credits.length >= 1 ? this.state.credits[0].price : 0,

            });
        } else {
            this.setState({
                newCredits: 0,
            })
        }
    };

    handleEmployeesChange = (action) => (event) => {
        this.setState({
            menuOpen: false, priceWithoutSale: action.priceWithoutSale, subscription: {
                ...this.state.subscription,
                maxEmployees: action.option,
                price: action.price,
                priceInteger: action.price,
                subscriptionPrice: action,
            },
        });
    };

    handleCreditsChange = (action) => (event) => {
        this.setState({
            menuCreditsOpen: false, creditsPrice: action.price, subscription: {
                ...this.state.subscription, credits: action.amount,
            },
            newCredits: action.amount,
        });
    };

    handleCumelaChange = (action) => (event) => {
        if (action === true) {
            this.props.fetchCategories(-1);
        } else {
            this.props.fetchCategories(-3);
        }
        this.setState({
            menuCumelaOpen: false, subscription: {
                ...this.state.subscription, cumela: action, caoLeo: false,
            },
        });
    };

    handleCaoleoChange = (action) => (event) => {
        if (action === true) {
            this.props.fetchCategories(-2);
        } else {
            this.props.fetchCategories(-3);
        }
        this.setState({
            menuCaoleoOpen: false, subscription: {
                ...this.state.subscription, caoLeo: action,
            },
        });
    };

    openEmployeeMenu = (event) => {
        if (this.props.subscription && this.props.subscription.maxEmployees >= 0 && this.props.subscription && this.props.subscription.maxEmployees < 250) {
            this.setState({
                menuOpen: true, rowMenuAnchorElement: event.currentTarget,
            });
        }
    };

    openCumelaMenu = (event) => {
        this.setState({
            menuCumelaOpen: true, rowMenuAnchorElement: event.currentTarget,
        });
    };

    openCaoleoMenu = (event) => {
        this.setState({
            menuCaoleoOpen: true, rowMenuAnchorElement: event.currentTarget,
        });
    };

    openCreditsMenu = (event) => {
        this.setState({
            menuCreditsOpen: true, rowMenuAnchorElement: event.currentTarget,
        });
    };

    handleRowMenuRequestClose = (event) => {
        this.setState({menuOpen: false});
    };

    handleRowMenuCreditsRequestClose = (event) => {
        this.setState({menuCreditsOpen: false});
    };

    handleRowMenuCumelaRequestClose = (event) => {
        this.setState({menuCumelaOpen: false});
    };

    handleRowMenuCaoleoRequestClose = (event) => {
        this.setState({menuCaoleoOpen: false});
    };

    handleUpgrade = (event) => {
        this.props.upgradeAbonnee({
            ...this.state.subscription,
            password: this.state.confirmationPassword,
            newCredits: this.state.newCredits
        }, (success, error) => {
            if (success) {
                this.setState({
                    warningDialogOpen: false,
                    dialogUpgraded: true
                })
            } else {

            }
        })
    }

    onPasswordChange = (event) => {
        this.setState({confirmationPassword: event.target.value});
    }

    handleUpgradeDialog = (event) => {
        this.setState({
            subscription: {
                ...this.state.subscription, vv_functions: true,
            },
        });
        //
        // alert(this.state.subscription.vv_functions);
        this.setState({warningDialogOpen: true});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prices.length === 0 && this.props.prices.length > 0) {
            this.setState({
                prices: this.props.prices,
                credits: this.props.prices.filter((price) => (price.subscriptionType === "SNELDELEN")).map((price) =>
                    ({amount: price.option, price: price.price})
                ).sort((a, b) => (a.amount > b.amount ? 1 : -1))
            })
            var maxEmployees = this.props.subscription.maxEmployees;
            var relevantPrices = this.props.prices.filter((price) => (
                this.props.subscription.cumela ?
                    price.subscriptionType === "CUMELA" :
                    this.props.subscription.caoLeo ?
                        price.subscriptionType === "CAO_LEO" :
                        price.subscriptionType === "VV"
            ))
            var price = relevantPrices.find((price) => price.option === maxEmployees)
            this.setState({
                relevantPrices: relevantPrices.sort((a, b) => (a.option > b.option ? 1 : -1)),
                priceWithoutSale: price.priceWithoutSale,
                price: price.price,
                subscription: {
                    ...this.props.subscription, price: price.price,
                },
            })
        }

        if (prevProps.categories !== this.props.categories || (this.props.categories && prevProps.categories === null)) {
            for (let i = 0; i !== this.props.categories.length; i++) {
                if (this.props.categories[i].maxEmployees === this.state.subscription.maxEmployees.toString()) {
                    this.setState({
                        prijsZonderKorting: this.props.categories[i].prijsZonderKorting,
                        subscription: {
                            ...this.state.subscription, price: this.props.categories[i].prijs,
                        },
                    });
                }
            }
        }
    }

    render() {
        const {open, loading, categories, t} = this.props;
        const {
            warningDialogOpen, dialogUpgraded, subscription, priceWithoutSale, credits
        } = this.state;

        const accountType = subscription.cumela ? 'cumela' : subscription.caoLeo ? 'caoleo' : 'veiligvakwerk';
        const OPSTARTPRIJS = 99;

        const currencyFormatter = new Intl.NumberFormat(t('LOCALE'), {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })

        let subscriptionName = '';
        if (subscription.credits_setup && !subscription.vv_functions) {
            subscriptionName = t('Veilig Vakwerk Sneldelen only');
        } else {
            if (subscription.cumela) {
                if (subscription.maxEmployees > 250 && priceWithoutSale === 0) {
                    this.setState({
                        priceWithoutSale: 3 * subscription.price,
                    });
                }
                if (subscription.credits_setup) {
                    subscriptionName = t('Veilig Vakwerk Cumela met Sneldelen');
                } else {
                    subscriptionName = t('Veilig Vakwerk Cumela');
                }
            } else {
                if (subscription.caoLeo) {
                    if (subscription.credits_setup) {
                        // TODO make sure this text fits the screen
                        subscriptionName = t('Veilig Vakwerk cao Groen, Grond en Infrastructuur met Sneldelen');
                    } else {
                        subscriptionName = t('Veilig Vakwerk cao Groen, Grond en Infrastructuur');
                    }
                } else {
                    if (subscription.credits_setup) {
                        subscriptionName = t('Veilig Vakwerk met Sneldelen');
                    } else {
                        subscriptionName = t('Veilig Vakwerk Standaard');
                    }
                }
            }
        }

        return (<Dialog
            fullScreen
            maxWidth={false}
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    p: 0, // sets padding to 0
                },
            }}
        >
            <Dialog
                open={warningDialogOpen}
                onClose={this.requestWarningDialogClose}
            >
                <DialogTitle>{t('Upgrade subscription')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.subscription.maxEmployees !== subscription.maxEmployees && t('Request subscription for maximal') + ' ' + subscription.maxEmployees + ' ' + t('employees') + '? ' + t('Yearly costs are') + ' ' + currencyFormatter.format(subscription.price) + '. '}
                        {this.props.subscription.credits_setup === false && subscription.credits_setup === true && t('One-off costs are') + ' ' + t('EENMALIGE KOSTEN') + ' + ' + currencyFormatter.format(this.state.creditsPrice) + ' (' + subscription.credits + ' ' + t('Credits') + ').'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.customPadding}>
                    <TextField
                        type="password"
                        required
                        label={t('Password')}
                        value={this.state.confirmationPassword}
                        onChange={this.onPasswordChange}
                    />
                    <div style={{flex: '1 0 0'}}/>
                    <Button
                        color="secondary"
                        onClick={this.requestWarningDialogClose}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleUpgrade}>
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogUpgraded}
                onClose={this.requestDialogUpgradedClose}
            >
                <DialogTitle>
                    {t('Your subscription had been upgraded')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('We received your subscription adjustment')}{' '}
                        <br/>
                        {t('subscription adjustments info')} <br/>
                        {t('You can get started right away.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.requestDialogUpgradedClose}
                    >
                        {t('Ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <AppBar className={classes.appBar}>
                <Toolbar disableGutters>
                    <IconButton
                        className={classes.appBarButton}
                        color="inherit"
                        onClick={this.handleClose}
                        size="large">
                        <CloseIcon/>
                    </IconButton>
                    <Typography
                        type="title"
                        color="inherit"
                        className={classes.flex}
                    >
                        {t('Update subscription')}
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent className={classes.content}>
                <Loader loading={loading}>
                    <div className={classes.abonnementContent}>
                        <div className={classes.abonnementInfoCard}>
                            <div
                                style={{
                                    background: '#FFDD4A', height: '308px',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '24px',
                                        fontFamily: 'filson-soft,sans-serif',
                                        lineHeight: '24px',
                                        paddingTop: '36px',
                                    }}
                                >
                                    {subscription.maxEmployees < 0 ? t('Unlimited number of employees') : t('Till') + ' ' + subscription.maxEmployees + ' ' + t('employees')}
                                </div>
                                {accountType === 'cumela' && subscription.maxEmployees > 0 ? (<div>
                                    <div
                                        style={{
                                            opacity: '0.5',
                                            fontSize: '14px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {t('Cumela discount')}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '64px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '47px',
                                            fontFamily: 'filson-soft,sans-serif',
                                        }}
                                    >
                                        {currencyFormatter.format(subscription.maxEmployees > 250 || subscription.maxEmployees < 0 ? subscription.priceInteger : subscription.price)}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '28.8px',
                                            color: '#282D35',
                                            lineHeight: '21.6px',
                                            textAlign: 'center',
                                            paddingTop: '31px',
                                            fontFamily: 'filson-soft,sans-serif',
                                            backgroundImage: 'url(/assets/images/stripe.svg)',
                                            backgroundSize: '50% 50%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'bottom',
                                        }}
                                    >
                                        {currencyFormatter.format(priceWithoutSale)}
                                    </div>
                                    <div
                                        style={{
                                            opacity: '0.5',
                                            fontSize: '17px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '11px',
                                        }}
                                    >
                                        {t('Subscription price per year')}
                                    </div>
                                </div>) : accountType === 'caoleo' && subscription.maxEmployees > 0 ? (<div>
                                    <div
                                        style={{
                                            opacity: '0.5',
                                            fontSize: '14px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {t('Groen, grond discount')}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '64px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '47px',
                                            fontFamily: 'filson-soft,sans-serif',
                                        }}
                                    >
                                        {currencyFormatter.format(subscription.maxEmployees > 250 || subscription.maxEmployees < 0 ? subscription.priceInteger : subscription.price)}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '28.8px',
                                            color: '#282D35',
                                            lineHeight: '21.6px',
                                            textAlign: 'center',
                                            paddingTop: '31px',
                                            fontFamily: 'filson-soft,sans-serif',
                                            backgroundImage: 'url(/assets/images/stripe.svg)',
                                            backgroundSize: '50% 50%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'bottom',
                                        }}
                                    >
                                        {currencyFormatter.format(priceWithoutSale)}
                                    </div>
                                    <div
                                        style={{
                                            opacity: '0.5',
                                            fontSize: '17px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '11px',
                                        }}
                                    >
                                        {t('Subscription price per year')}
                                    </div>
                                </div>) : (<div>
                                    <div
                                        style={{
                                            fontSize: '64px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '81px',
                                            fontFamily: 'filson-soft,sans-serif',
                                        }}
                                    >
                                        {currencyFormatter.format(subscription.maxEmployees > 250 || subscription.maxEmployees < 0 ? subscription.priceInteger : subscription.price)}
                                    </div>
                                    <div
                                        style={{
                                            opacity: '0.5',
                                            fontSize: '17px',
                                            color: '#282D35',
                                            lineHeight: '24px',
                                            textAlign: 'center',
                                            paddingTop: '50px',
                                        }}
                                    >
                                        {t('Subscription price per year')}
                                    </div>
                                </div>)}
                            </div>
                            <div
                                style={this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() === subscription.maxEmployees.toString() && this.props.subscription.credits_setup === subscription.credits_setup ? {
                                    background: '#FFDD4A', height: '185px', opacity: '0.8',
                                } : this.props.subscription.credits_setup === false && subscription.credits_setup === true ? {
                                    background: '#FFDD4A',
                                    height: subscriptionName.length > 39 ? '280px' : '260px',
                                    opacity: '0.8',
                                } : {
                                    background: '#FFDD4A', height: '240px', opacity: '0.8',
                                }}
                            >
                                <div
                                    style={{
                                        marginLeft: '16px', marginRight: '16px', paddingTop: '22px',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: '14px', marginBottom: '4px', lineHeight: '24px',
                                        }}
                                    >
                                        {t('Subscription')}
                                    </div>
                                    <b style={{lineHeight: '24px'}}>
                                        {subscriptionName}
                                    </b>
                                    <Divider
                                        style={{
                                            marginTop: '10px', marginBottom: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            fontSize: '14px', marginBottom: '4px', lineHeight: '24px',
                                        }}
                                    >
                                        {t('Number of employees')}
                                    </div>
                                    <b style={{lineHeight: '24px'}}>
                                        {subscription.maxEmployees < 0 ? t('Unlimited number of employees') : t('Till') + ' ' + subscription.maxEmployees + ' ' + t('active employees')}
                                    </b>
                                    {((this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() !== subscription.maxEmployees.toString()) || this.props.subscription.credits_setup !== subscription.credits_setup) && (
                                        <Divider
                                            style={{
                                                marginTop: '10px', marginBottom: '10px',
                                            }}
                                        />)}
                                    {((this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() !== subscription.maxEmployees.toString()) || this.props.subscription.credits_setup !== subscription.credits_setup) && (
                                        <div
                                            style={{
                                                fontSize: '14px', marginBottom: '4px', lineHeight: '24px',
                                            }}
                                        >
                                            {t('New costs')}
                                        </div>)}
                                    {this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() !== subscription.maxEmployees.toString() && (
                                        <b
                                            style={{
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {currencyFormatter.format(subscription.price)}{' '}
                                            {t('yearly')}
                                        </b>)}
                                    {(this.props.subscription.vv_functions === false || (this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() === subscription.maxEmployees.toString())) && this.props.subscription.credits_setup === false && subscription.credits_setup === true && (
                                        <b
                                            style={{
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {' '}
                                            {subscription.credits}{' '}
                                            {t('credits')} ({currencyFormatter.format(this.state.creditsPrice)}){' + '}
                                            {t('EENMALIGE KOSTEN')}{' '}
                                            {t('start-up costs')} = {currencyFormatter.format(parseInt(this.state.creditsPrice, 10) + OPSTARTPRIJS)}
                                        </b>)}
                                    {this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() !== subscription.maxEmployees.toString() && this.props.subscription.credits_setup === false && subscription.credits_setup === true && (
                                        <b
                                            style={{
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {' '}
                                            + {subscription.credits}{' '}
                                            {t('credits')} ({currencyFormatter.format(this.state.creditsPrice)}){' + '}
                                            {t('EENMALIGE KOSTEN')}{' '}
                                            {t('start-up costs')} = {currencyFormatter.format(parseInt(this.state.creditsPrice, 10) + parseInt(subscription.price, 10) + OPSTARTPRIJS)}
                                        </b>)}
                                </div>
                            </div>
                        </div>
                        <div className={classes.abonnementInfoContent}>
                            <h2 style={{fontSize: '24px'}}>
                                {t('Subscription details')}
                            </h2>
                            <p
                                style={{
                                    opacity: '0.54', marginBottom: '50px', lineHeight: '24px',
                                }}
                            >
                                {this.props.subscription.maxEmployees > 0 ? this.props.subscription.maxEmployees === 250 ? this.props.subscription.credits_setup === true ? t('more employees credits info') : t('Adjust current subscription wishes.') : t('Adjust current subscription wishes.') : this.props.subscription.credits_setup === true ? t('max enjoyment over subscription') : t('Adjust current subscription wishes.')}
                            </p>
                            <Divider/>
                            <div style={{display: 'block'}}>
                                <div
                                    className={classes.pElement}
                                    style={{
                                        marginLeft: '10px', marginRight: '50px',
                                    }}
                                >
                                    <b>{t('Subscription type')}</b>
                                    <div
                                        style={{
                                            color: '#0091EA', float: 'right',
                                        }}
                                    >
                                        {subscriptionName}
                                    </div>
                                </div>
                            </div>
                            {!this.props.subscription.vv_functions && (<Divider/>)}
                            {!this.props.subscription.vv_functions && (<div style={{display: 'block'}}>
                                <div
                                    className={classes.pElement}
                                    style={{
                                        marginLeft: '10px', marginRight: '10px',
                                    }}
                                >
                                    <b>
                                        {t('Are you a member of CUMELA Netherlands?')}
                                    </b>
                                    <IconButton
                                        onClick={this.openCumelaMenu}
                                        style={{
                                            width: '40px', float: 'right', marginTop: '-15px',
                                        }}
                                        size="large">
                                        <ExpandMoreIcon/>
                                    </IconButton>
                                    <div
                                        style={{
                                            color: '#0091EA', float: 'right',
                                        }}
                                        onClick={this.openCumelaMenu}
                                    >
                                        {subscription.cumela ? t('Yes') : t('No')}
                                    </div>
                                </div>
                            </div>)}
                            {!this.props.subscription.vv_functions && !subscription.cumela && <Divider/>}
                            {!this.props.subscription.vv_functions && !subscription.cumela && (
                                <div style={{display: 'block'}}>
                                    <div
                                        className={classes.pElement}
                                        style={{
                                            marginLeft: '10px', marginRight: '10px',
                                        }}
                                    >
                                        <b>
                                            {t('Collective labour agreement?')}
                                        </b>
                                        <IconButton
                                            onClick={this.openCaoleoMenu}
                                            style={{
                                                width: '40px', float: 'right', marginTop: '-15px',
                                            }}
                                            size="large">
                                            <ExpandMoreIcon/>
                                        </IconButton>
                                        <div
                                            style={{
                                                color: '#0091EA', float: 'right',
                                            }}
                                            onClick={this.openCaoleoMenu}
                                        >
                                            {subscription.caoLeo ? t('Yes') : t('No')}
                                        </div>
                                    </div>
                                </div>)}
                            <Divider/>
                            <div style={{display: 'block'}}>
                                <div
                                    className={classes.pElement}
                                    style={{
                                        marginLeft: '10px', marginRight: '10px',
                                    }}
                                >
                                    <b>{t('Number of employees')}</b>
                                    <IconButton
                                        onClick={this.openEmployeeMenu}
                                        style={this.props.subscription.maxEmployees >= 0 && this.props.subscription.maxEmployees < 250 ? {
                                            visibility: 'visible',
                                            width: '40px',
                                            float: 'right',
                                            marginTop: '-15px',
                                        } : {
                                            visibility: 'hidden', width: '40px', float: 'right', marginTop: '-15px',
                                        }}
                                        size="large">
                                        <ExpandMoreIcon/>
                                    </IconButton>
                                    <div
                                        style={{
                                            color: '#0091EA', float: 'right',
                                        }}
                                        onClick={this.openEmployeeMenu}
                                    >
                                        {subscription.maxEmployees < 0 ? t('Unlimited number of employees') : t('Till') + ' ' + subscription.maxEmployees + ' ' + t('active employees')}
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div style={{display: 'block'}}>
                                <div
                                    className={classes.pElement}
                                    style={{
                                        marginLeft: '10px', marginRight: '10px',
                                    }}
                                >
                                    <b>{t('Module sneldelen')}</b>
                                    <div
                                        style={this.props.subscription.credits_setup != null && this.props.subscription.credits_setup === true ? {
                                            opacity: '0.5', float: 'right', marginTop: '-15px', marginRight: '10px',
                                        } : {
                                            opacity: '1.0', float: 'right', marginTop: '-15px', marginRight: '10px',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={<Switch
                                                checked={subscription.credits_setup}
                                                onChange={this.handleSneldelenChange}
                                            />}
                                            id="unsafeIncident"
                                            style={{marginTop: "5px"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.props.subscription.credits_setup === false && subscription.credits_setup === true && (
                                <Divider/>)}
                            {this.props.subscription.credits_setup === false && subscription.credits_setup === true && (
                                <div style={{display: 'block'}}>
                                    <div
                                        className={classes.pElement}
                                        style={{
                                            marginLeft: '10px', marginRight: '10px',
                                        }}
                                    >
                                        <b>{t('Credit amount')}</b>
                                        <IconButton
                                            onClick={this.openCreditsMenu}
                                            style={{
                                                width: '40px', float: 'right', marginTop: '-15px',
                                            }}
                                            size="large">
                                            <ExpandMoreIcon/>
                                        </IconButton>
                                        <div
                                            style={{
                                                color: '#0091EA', float: 'right',
                                            }}
                                            onClick={this.openCreditsMenu}
                                        >
                                            {subscription.credits}{' '}
                                            {t('credits')}
                                        </div>
                                    </div>
                                </div>)}
                            <Divider/>
                            <Button

                                variant="contained"
                                color="primary"
                                className={classes.upgradeButton}
                                onClick={this.handleUpgradeDialog}
                                disabled={this.props.subscription.vv_functions && this.props.subscription.maxEmployees.toString() === subscription.maxEmployees.toString() && this.props.subscription.credits_setup === subscription.credits_setup}
                            >
                                {/*{subscription.vv_functions*/}
                                {/*    ? t('Upgrade')*/}
                                {/*    : t('Request')}*/}
                                {t('Edit')}
                            </Button>
                        </div>
                    </div>
                    <Paper elevation={0}>
                        <Popover
                            anchorEl={this.state.rowMenuAnchorElement}
                            id={this.uuid + '-rowMenu'}
                            open={this.state.menuOpen}
                            onClose={this.handleRowMenuRequestClose}
                        >
                            <List>
                                {this.state.relevantPrices !== null && this.state.relevantPrices
                                    .filter((price) => price.visible === true && price.option >= this.props.subscription.maxEmployees)
                                    .map((action) => (<ListItem
                                        button
                                        key={action.id}
                                        onClick={this.handleEmployeesChange(action)}
                                    >
                                        <ListItemText
                                            primary={action.description}
                                        />
                                    </ListItem>))}
                            </List>
                        </Popover>
                    </Paper>
                    <Paper elevation={0}>
                        <Popover
                            anchorEl={this.state.rowMenuAnchorElement}
                            id={this.uuid + '-rowMenu'}
                            open={this.state.menuCreditsOpen}
                            onClose={this.handleRowMenuCreditsRequestClose}
                        >
                            <List>
                                {credits !== null && credits.map((action, index) => (<ListItem
                                    button
                                    key={index}
                                    onClick={this.handleCreditsChange(action)}
                                >
                                    <ListItemText
                                        primary={action.amount + ' ' + t('credits')}
                                    />
                                </ListItem>))}
                            </List>
                        </Popover>
                    </Paper>
                    <Paper elevation={0}>
                        <Popover
                            anchorEl={this.state.rowMenuAnchorElement}
                            id={this.uuid + '-rowMenu'}
                            open={this.state.menuCumelaOpen}
                            onClose={this.handleRowMenuCumelaRequestClose}
                        >
                            <List>
                                <ListItem
                                    button
                                    key={'2'}
                                    onClick={this.handleCumelaChange(false)}
                                >
                                    <ListItemText primary={t('No')}/>
                                </ListItem>
                                <ListItem
                                    button
                                    key={'1'}
                                    onClick={this.handleCumelaChange(true)}
                                >
                                    <ListItemText primary={t('Yes')}/>
                                </ListItem>
                            </List>
                        </Popover>
                    </Paper>
                    <Paper elevation={0}>
                        <Popover
                            anchorEl={this.state.rowMenuAnchorElement}
                            id={this.uuid + '-rowMenu'}
                            open={this.state.menuCaoleoOpen}
                            onClose={this.handleRowMenuCaoleoRequestClose}
                        >
                            <List>
                                <ListItem
                                    button
                                    key={null}
                                    onClick={this.handleCaoleoChange(false)}
                                >
                                    <ListItemText primary={t('No')}/>
                                </ListItem>
                                <ListItem
                                    button
                                    key={null}
                                    onClick={this.handleCaoleoChange(true)}
                                >
                                    <ListItemText primary={t('Yes')}/>
                                </ListItem>
                            </List>
                        </Popover>
                    </Paper>
                </Loader>
            </DialogContent>
        </Dialog>);
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),

    withTranslation())(AbonnementEdit);

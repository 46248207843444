// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ImageList,
    ImageListItem
} from '@mui/material';
import BlockTypeCard from '../BlockTypeCard';
import ContentBlock from '../ContentBlock';
import {DragDropContainer, DragDropItem, DragDropSource} from '../DragDrop';
import {
    deleteToolboxDraftContentBlock,
    hasVideoToolboxAddOn,
    insertToolboxDraftContentBlockType,
    moveToolboxDraftContentBlock,
    startVideoToolboxTrial,
} from '../../actions';
import {compose} from 'recompose';
import {Trans, withTranslation} from 'react-i18next';
import classes from './ToolboxContentEditor.module.scss';
import getRoles from "../../selectors/getRoles";
import {getSubscriptionPrices} from "../../lib/api";

type Props = {
    toolbox: any,
    onMoveContentBlock: Function,
    contentBlockTypes: Array<any>,
    onChange: () => any,
};

type State = {
    activeTab: number,
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        contentBlockDrafts: store.drafts.contentBlocks,
        subscription: subscription,
        roles: getRoles(store)
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddContentBlock: (index, data) =>
            dispatch(
                insertToolboxDraftContentBlockType(
                    props.toolbox.id,
                    data,
                    index
                )
            ),
        onMoveContentBlock: (fromIndex, toIndex) =>
            dispatch(
                moveToolboxDraftContentBlock(
                    props.toolbox.id,
                    fromIndex,
                    toIndex
                )
            ),
        onDeleteContentBlock: (contentBlockId) =>
            dispatch(
                deleteToolboxDraftContentBlock(props.toolbox.id, contentBlockId)
            ),
        startTrialPeriod: (subscriptionId) => {
            dispatch(
                startVideoToolboxTrial(subscriptionId)
            )
        },
        goToAbonnement: () => {
            //dispatch(push("/abonnement"))
            window.open('/abonnement/videotoolbox', '_blank');
        }
    };
};

class ToolboxContentEditor extends React.PureComponent<void, Props, State> {
    props: Props;
    state: State = {
        openTrialDialog: false,
        prices: [],
    };

    handleAddContentBlock = (index, data) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onAddContentBlock(index, data);
    };

    handleMoveContentBlock = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onMoveContentBlock(fromIndex, toIndex);
    };

    handleDeleteContentBlock = (contentBlockId) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onDeleteContentBlock(contentBlockId);
    };

    canEnableVideoToolboxTrial = () => {
        return this.props.subscription && this.props.subscription.videoToolboxSubscriptions.length >= 1 && this.props.subscription.videoToolboxSubscriptions[0].showTrial;
    }

    openStartVideoToolboxTrial = () => {
        this.setState({openTrialDialog: true});
    }

    startTrial = () => {
        // if (this.props.roles.includes('SUBSCRIPTIONHOLDER')) {
        this.props.startTrialPeriod(this.props.subscription.id)
        // }
        this.setState({
            openTrialDialog: false
        })
    }

    isSubscriptionHolder = () => {
        return true //this.props.roles.includes('SUBSCRIPTIONHOLDER')
    }

    hasEndedTrial = () => {
        if (this.props.subscription && this.props.subscription.videoToolboxSubscriptions.length >= 1) {
            return Date.parse(this.props.subscription.videoToolboxSubscriptions[0].trialEnds) < Date.now()
        }
        return true;
    }

    componentDidMount() {
        if (this.props.subscription.videoToolboxSubscriptions.length >= 1 && !this.props.subscription.videoToolboxSubscriptions[0].enabled && this.props.subscription.videoToolboxSubscriptions[0].showTrial && this.props.subscription.videoToolboxSubscriptions[0].trialEnds === null) {
            getSubscriptionPrices().then((entities) => {
                const prices = entities.body
                this.setState({prices})
            })
        }
    }

    render() {
        const {
            style,
            toolbox,
            contentBlockTypes,
            contentBlockDrafts,
            t,
        } = this.props;

        var hasPrice = this.props.subscription.videoToolboxSubscriptions.length >= 1 && !this.props.subscription.videoToolboxSubscriptions[0].enabled && this.props.subscription.videoToolboxSubscriptions[0].showTrial && this.props.subscription.videoToolboxSubscriptions[0].trialEnds === null

        const subscription = this.props.subscription;
        const {prices} = this.state || [];

        if (prices.length === 0) {
            hasPrice = false
        }

        const currencyFormatter = new Intl.NumberFormat(t('LOCALE'), {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })

        return (
            <div style={style} className={classes.root}>
                <Dialog
                    open={this.state.openTrialDialog}
                    onClose={() => {
                        this.setState({
                            openTrialDialog: false
                        });
                    }}
                >
                    <DialogTitle
                        className="title">{t(this.hasEndedTrial() ? 'Video Toolbox trial ended' : 'Activate Video Toolbox trial')}</DialogTitle>
                    <DialogContent>
                        <div className="display-linebreak">
                            <Trans
                                i18nKey={t(`Video Toolbox trial ${this.hasEndedTrial() ? "ended " : ""}${this.isSubscriptionHolder() ? "" : "non-"}subscriptionholder`,
                                    {
                                        subscriptionholder: subscription.fullNameSubscriptionHolder,
                                        price: hasPrice ? currencyFormatter.format(this.state.prices.find((price) => price.subscriptionType === 'VTB' && Number(price.handle.split('_')[0]) === subscription.maxEmployees && price.option === 10).price) : 0
                                    })}
                                // The following will only substitute the Component part in the translation text, not the inner text part
                                components={{
                                    linkToSite: <a href="https://veiligvakwerk.nl/product/videotoolbox" target="_black"
                                                   rel="noopener noreferrer"/>
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    openTrialDialog: false
                                });
                            }}
                        >
                            {t('Close')}
                        </Button>
                        {this.isSubscriptionHolder() && (<Button
                            variant="contained"
                            color="primary"
                            onClick={this.hasEndedTrial() ? this.props.goToAbonnement : this.startTrial}
                        >
                            {t(this.hasEndedTrial() ? 'Update subscription' : 'Start trial period')}
                        </Button>)}
                    </DialogActions>
                </Dialog>
                <Card className={classes.cardLeft}>
                    <CardContent>
                        <DragDropContainer
                            onMove={this.handleMoveContentBlock}
                            onAdd={this.handleAddContentBlock}
                            accepts="ContentBlockType"
                            emptyText={t('Drag your content blocks here to format the toolbox content')}
                        >
                            {toolbox.toolbox.blocks.map((contentBlockId, i) => {
                                return (
                                    <DragDropItem
                                        key={contentBlockId}
                                        data={
                                            contentBlockDrafts[contentBlockId]
                                        }
                                    >
                                        <ContentBlock
                                            onChange={this.props.onChange}
                                            onDelete={
                                                this.handleDeleteContentBlock
                                            }
                                            contentBlock={
                                                contentBlockDrafts[
                                                    contentBlockId
                                                    ]
                                            }
                                            contentBlockTypes={
                                                contentBlockTypes
                                            }
                                        />
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>
                    </CardContent>
                </Card>

                <Card className={classes.cardRight}>
                    <CardContent>
                        <ImageList
                            rowHeight={'auto'}
                            gap={16}
                            className={classes.imageList}
                            cols={3}
                        >
                            {contentBlockTypes.map((contentBlockType, i) => (
                                (contentBlockType.id !== 'videoToolboxBlock' || (contentBlockType.id === 'videoToolboxBlock' && (hasVideoToolboxAddOn(this.props.subscription) || this.canEnableVideoToolboxTrial()))) &&
                                <ImageListItem
                                    key={contentBlockType.id}
                                    cols={1}
                                >
                                    {(contentBlockType.id !== 'videoToolboxBlock' || hasVideoToolboxAddOn(this.props.subscription)) &&
                                        <DragDropSource
                                            data={contentBlockType}
                                            transfers="ContentBlockType"
                                        >
                                            <BlockTypeCard
                                                blockType={contentBlockType}
                                                hasSubscription={true}
                                                key={i}
                                                onClick={() => {
                                                    this.handleAddContentBlock(
                                                        this.props.toolbox.toolbox
                                                            .blocks.length,
                                                        contentBlockType
                                                    );
                                                }}
                                            />
                                        </DragDropSource>
                                    }
                                    {(contentBlockType.id === 'videoToolboxBlock' && !hasVideoToolboxAddOn(this.props.subscription) && this.canEnableVideoToolboxTrial()) &&
                                        <BlockTypeCard
                                            blockType={contentBlockType}
                                            hasSubscription={false}
                                            key={i}
                                            style={{cursor: "help"}}
                                            onClick={this.openStartVideoToolboxTrial}
                                        />
                                    }
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ToolboxContentEditor);

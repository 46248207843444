// @flow
import React from 'react';
import {AppBar, IconButton, Snackbar, Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {push} from 'connected-react-router';
import {DragDropContext} from 'react-dnd';
import Slide from '@mui/material/Slide';
import {compose} from 'recompose';
import BreadCrumbs from '../BreadCrumbs';
import SideMenu from '../SideMenu';
import Dashboard from '../Dashboard';
import Abonnement from '../Abonnement';
import Toolbox from '../Toolbox';
import Inspection from '../Inspection';
import EmployeesOverview from '../Employee/EmployeesOverview';
import Project from '../Project';
import DialogToolboxCreate from '../DialogToolboxCreate';
import DialogInspectionCreate from '../DialogInspectionCreate';
import DialogInspectionDeploy from '../DialogInspectionDeploy';
import DialogError from '../DialogError';
import DialogIE from '../DialogIE/DialogIE';
import DialogToolboxDeploy from '../DialogToolboxDeploy';
import DialogPasteListItems from '../ContentBlock/DialogPasteListItems';
import {getCurrentEmployee, hideSnackbar, toggleSideMenu,} from '../../actions';
import DragDropBackend from '../../lib/DragDropBackend';
import EmployeeEditView from '../Employee/EmployeeEditView';
import EmployeeDetailView from '../Employee/EmployeeDetailView';
import ToolboxDetailView from '../Toolbox/ToolboxDetailView';
import InspectionDetailView from '../Inspection/InspectionDetailView';
import UsergroupDetailView from '../Usergroup/UsergroupDetailView';
import StatisticsInspectionView from '../StatisticsView/StatisticsInspection/StatisticsInspectionView';
import StatisticsInspectionDetailView from '../StatisticsView/StatisticsInspection/StatisticsInspectionDetailView';
import StatisticsToolboxView from '../StatisticsView/StatisticsToolbox/StatisticsToolboxView';
import StatisticsToolboxMeetingView from '../StatisticsView/StatisticsToolbox/StatisticsToolboxMeetingView';
import StatisticsToolboxDetailView from '../StatisticsView/StatisticsToolbox/StatisticsToolboxDetailView';
import StatisticsView from '../StatisticsView/StatisticsView';
import StatisticsEmployeeView from '../StatisticsView/StatisticsEmployee/StatisticsEmployeeView';
import StatisticsEmployeeDetailView from '../StatisticsView/StatisticsEmployee/StatisticsEmployeeDetailView';
import InspectionOverviewPreview from '../InspectionPreview/InspectionOverviewPreview';
import ToolboxResultsView from '../Toolbox/ToolboxResultsView/ToolboxResultsView';
import SubscriptionsOverview from '../Subscriptions';
import PromoCodesOverview from '../PromoCodes'
import Incidents from '../Incidents/Incident.js';
import IncidentDetailView from "../Incidents/IncidentDetailView/IncidentDetailView";
import SpeakUp from "../SpeakUp/SpeakUp.js";
import Documents from "../Documents/Documents.js";
import AbonneeDetailView from '../Subscriptions/AbonneeDetailView';
import SubscriptionsCUMELAOverview from '../SubscriptionsCUMELA';
import AbonneeDetailViewCUMELA from '../SubscriptionsCUMELA/AbonneeDetailViewCUMELA';
import ProjectDetailsView from '../Project/ProjectOverview/ProjectDetailsView/ProjectDetailsView';
import opdrachten from '../Webclient/opdrachten';
import Taken from '../Taken/Taken';
import TaskOpdracht from "../Webclient/Task/TaskOpdracht";
import ToolboxOpdracht from '../Webclient/ToolboxOpdracht';
import OpdrachtenCompleted from '../Webclient/OpdrachtenCompleted';
import WebSideMenu from '../SideMenu/WebSideMenu';
import ToolboxOpdrachtFinished from '../Webclient/ToolboxOpdrachtFinished';
import InspectionOpdracht from '../Webclient/InspectionOpdracht';
import InspectionOpdrachtFinished from '../Webclient/InspectionOpdrachtFinished';
import Incident from '../Webclient/Incident/Incident';
import IncidentCreate from '../Webclient/IncidentCreate';
import WebAppSpeakup from '../Webclient/WebAppSpeakup/WebAppSpeakup';
import WebAppCreateSpeakup from '../Webclient/WebAppCreateSpeakup/WebAppCreateSpeakup';
import Help from '../Webclient/Help';
import PublicInspections from '../Webclient/PublicInspections';
import getRoles from '../../selectors/getRoles';
import './App.scss';
import Settings from '../Settings/Settings';
import VideoToolbox from "../VideoToolbox/VideoToolbox";
import LanguageDropdown from '../LanguageDropdown';
import Cookies from "js-cookie";
import moment from "moment";
import i18n from "../../i18n";

type Props = {
    toggleSideMenu: () => void,
    goto: (url: string) => void,
};

type State = {
    searchtext: string,
};

const mapStateToProps = (store) => {
    return {
        isOpen: store.ui.sideMenu.isOpen,
        snackbarOpen: store.ui.snackbar.open,
        snackbarMessage: store.ui.snackbar.message,
        roles: getRoles(store),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSideMenu: () => {
            dispatch(toggleSideMenu());
        },
        closeSnackbar: () => {
            dispatch(hideSnackbar());
        },
        goto: (url: string) => {
            dispatch(push(url));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class App extends React.Component<Props, State> {
    props: Props;
    state: State;

    handleSnackbarClose = (event) => {
        this.props.closeSnackbar();
    };

    componentDidMount() {
        const lang = Cookies.get('lang');
        if (lang) {
            i18n.changeLanguage(lang);
            moment.locale(lang);

            this.setState({
                language: lang,
            });
        }
    }

    render() {
        const {
            goto,
            location,
            toggleSideMenu,
            roles,
            snackbarOpen,
            snackbarMessage,
        } = this.props;
        const {pathname} = location;
        const isSubscriptionHolder = roles.includes('SUBSCRIPTIONHOLDER');
        const isCoordinator = roles.includes('COORDINATOR');
        const isAdmin = roles.includes('ADMIN');

        let webUser = false;
        if (pathname.includes('/web')) {
            webUser = true;
        }

        return (
            <div className={this.props.isOpen ? "App appSideMenuOpen" : "App appSideMenuClosed"}>
                {!pathname.includes("/web/resultaten/") &&
                    !pathname.includes("/web/task/") &&
                    !pathname.includes("/web/toolbox/") &&
                    !pathname.includes("/web/inspection/") &&
                    !pathname.includes("/web/incident/create") &&
                    !pathname.includes("/web/speakup/create") && (
                        <AppBar className="app-bar" position="fixed">
                            <Toolbar className={this.props.isOpen ? "sideMenuOpen" : "sideMenuClosed"}
                                     disableGutters={true}>
                                <IconButton className={this.props.isOpen ? "hideIcon" : "showIcon"} onClick={toggleSideMenu}
                                            size="large">
                                    {!this.props.isOpen && <MenuIcon className="drawer-icon"/>}
                                </IconButton>
                                <BreadCrumbs goto={goto}/>
                                { webUser && (
                                    <LanguageDropdown
                                    	className="dropdown"
                                    />
                                )}
                            </Toolbar>
                        </AppBar>
                    )}
                {pathname.includes("/web") ? (
                    <WebSideMenu/>
                ) : isSubscriptionHolder || isCoordinator ? (
                    <SideMenu/>
                ) : (
                    <WebSideMenu/>
                )}
                <Switch>
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route exact path="/" component={Dashboard}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/dashboard" component={Dashboard}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/abonnement/:id?" component={Abonnement}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/toolboxen/:id?" component={Toolbox}/>
                    )}
                    {(isAdmin) && (
                        <Route path="/videotoolboxen" component={VideoToolbox}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/toolbox/details/:toolboxId/:deploymentId/results/:employerId/:toolboxAssignId"
                            component={ToolboxResultsView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/toolbox/details/:toolboxId/:deploymentId?"
                            component={ToolboxDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/werkplekinspecties/:id?"
                            component={Inspection}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/werkplekinspectie/details/:inspectionId"
                            component={InspectionDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/werkplekinspectie/preview/:inspectionId"
                            component={InspectionOverviewPreview}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/medewerkers/aanmaken"
                            component={EmployeeEditView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/medewerkers/:id?"
                            component={EmployeesOverview}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/medewerker/details/:employeeId/:page/:rowsPerPage"
                            component={EmployeeDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/medewerker/details/:employeeId"
                            component={EmployeeDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/projecten/details/:id"
                            component={ProjectDetailsView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/projecten/:id?" component={Project}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/groep/details/:id/:page/:rowsPerPage"
                            component={UsergroupDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/groep/details/:id"
                            component={UsergroupDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/taken/" component={Taken}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/medewerker/:employerId/toolbox/details/:toolboxId/:toolboxDate"
                            component={StatisticsEmployeeDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/medewerker/:employerId/werkplekinspectie/details/:inspectionId"
                            component={StatisticsEmployeeDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/werkplekinspectie/details/:inspectionId"
                            component={StatisticsInspectionDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/werkplekinspectie/:id"
                            component={StatisticsInspectionView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/toolbox/details/:toolboxId"
                            component={StatisticsToolboxDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/toolbox/:id"
                            component={StatisticsToolboxView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/toolboxMeeting/:id"
                            component={StatisticsToolboxMeetingView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/rapportages/medewerker/:id"
                            component={StatisticsEmployeeView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/rapportages" component={StatisticsView}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/abonnees/:id?"
                            component={SubscriptionsOverview}
                        />
                    )}
                    {(isAdmin) && (
                        <Route
                            path="/promocodes"
                            component={PromoCodesOverview}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/abonnee/details/:abonneeId"
                            component={AbonneeDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/abonneesCUMELA/:id?"
                            component={SubscriptionsCUMELAOverview}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/abonneeCUMELA/details/:abonneeId"
                            component={AbonneeDetailViewCUMELA}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/speakup" component={SpeakUp}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route path="/documents" component={Documents}/>
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/registratiemeldingen"
                            component={Incidents}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/registratiemelding/details/:incidentId"
                            component={IncidentDetailView}
                        />
                    )}
                    {(isSubscriptionHolder || isCoordinator) && (
                        <Route
                            path="/settings"
                            component={Settings}
                        />
                    )}
                    <Route
                        path="/web/task/:taskId"
                        component={TaskOpdracht}
                    />
                    <Route
                        path="/web/toolbox/:toolboxId"
                        component={ToolboxOpdracht}
                    />
                    <Route
                        path="/web/inspection/:inspectionAssignId"
                        component={InspectionOpdracht}
                    />
                    <Route
                        path="/web/nieuweinspectie/:inspectionAssignId"
                        component={InspectionOpdracht}
                    />
                    <Route
                        path="/web/nieuweinspectie"
                        component={PublicInspections}
                    />
                    <Route path="/web/opdrachten" component={opdrachten}/>
                    <Route
                        path="/web/afgerond"
                        component={OpdrachtenCompleted}
                    />
                    <Route
                        path="/web/resultaten/toolbox/:toolboxId"
                        component={ToolboxOpdrachtFinished}
                    />
                    <Route
                        path="/web/resultaten/inspection/:toolboxId"
                        component={InspectionOpdrachtFinished}
                    />
                    <Route
                        path="/web/incident/create/:incidentId"
                        component={IncidentCreate}
                    />
                    <Route
                        path="/web/incident/create"
                        component={IncidentCreate}
                    />
                    <Route path="/web/incident" component={Incident}/>
                    <Route
                        path="/web/speakup/create/:incidentId"
                        component={WebAppCreateSpeakup}
                    />
                    <Route
                        path="/web/speakup/create"
                        component={WebAppCreateSpeakup}
                    />
                    <Route path="/web/help" component={Help}/>
                    <Route path="/web/speakup" component={WebAppSpeakup}/>
                    <Route path="/web/documents" component={Documents}/>

                </Switch>
                <DialogError/>
                <DialogIE/>
                <DialogInspectionCreate/>
                <DialogInspectionDeploy/>
                <DialogToolboxCreate/>
                <DialogToolboxDeploy/>
                <DialogPasteListItems/>
                <Snackbar
                    open={snackbarOpen}
                    onClose={this.handleSnackbarClose}
                    transition={Transition}
                    autoHideDuration={3000}
                    message={<span id="message-id">{snackbarMessage}</span>}
                    action={[
                        <IconButton
                            key="close"
                            color="inherit"
                            className=""
                            onClick={this.handleSnackbarClose}
                            size="large">
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

/* <Route path="/medewerkers" component={Employee} /> */
export default compose(
    DragDropContext(DragDropBackend),
    connect(mapStateToProps, mapDispatchToProps)
)(App);

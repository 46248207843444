// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import uuidv4 from 'uuid/v4';
import keycode from 'keycode';
import './EnhancedTable.css';
import {IconButton, List, ListItem, ListItemText, Popover, Table, TableBody} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EnhancedTableRow from './EnhancedTableRow';
import EnhancedTableCell from './EnhancedTableCell';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableFooter from './EnhancedTableFooter';
import SimplePagination from '../SimplePagination';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './EnhancedTable.module.scss';

type Props = {
    hover: boolean,
    emptyState?: string,
};

type State = {};

class EnhancedTable extends React.Component {
    props: Props;
    state: State = {
        handledStartPage: false,
        selected: [],
        rows: [],
        page: 0,
        rowsPerPage: 10,
        isRowMenuOpen: false,
        rowMenuAnchorElement: null,
        rowMenuCurrentId: null,
        rowActions: [],
    };

    uuid;

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        const def = (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);

        let sorter;

        if (!this.props.sorter || this.props.sorter[orderBy] === undefined) {
            sorter = def;
        } else if (this.props.sorter[orderBy] == null) {
            sorter = null;
        } else {
            sorter = this.props.sorter[orderBy];
        }

        const rows =
            order === 'asc'
                ? [...this.props.rows].sort(sorter).map(this.props.formatter)
                : [...this.props.rows]
                    .sort((a, b) => -sorter(a, b))
                    .map(this.props.formatter);

        this.setState({rows, order, orderBy});
    };

    handleRowKeyDown = (id) => (event) => {
        if (keycode(event) === 'space') {
            this.handleClick(event, id);
        }
    };

    handleRowClick = (row) => (event) => {
        const {selected} = this.state;
        const id = row.id;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        this.setState({selected: newSelected});

        if (this.props.onClick) {
            this.props.onClick(row, event);
        }
    };

    handleChangePage = (event, page) => {
        this.setState({page});
        if (this.props.onPageChange) {
            this.props.onPageChange(page);
        }

    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event, page: 0});
        if (this.props.onChangeRowsPerPage) {
            this.props.onRowsPerPageChange(event);
            this.props.onPageChange(0);
        }
        this.props.onRowsPerPageChange(event);
        this.props.onPageChange(0);
    };

    handleActionsClick = (row) => (event) => {
        const rowActions = this.props.actions.filter((action) =>
            typeof action.isVisible === 'function'
                ? action.isVisible(row.id)
                : true
        );
        this.setState({
            isRowMenuOpen: true,
            rowMenuAnchorElement: event.currentTarget,
            rowMenuCurrentId: row.id,
            rowActions,
        });
    };

    handleActionClick = (action) => (event) => {
        this.props.onAction(event, {
            ...action,
            rowId: this.state.rowMenuCurrentId,
        });
        this.setState({
            isRowMenuOpen: false,
            rowMenuAnchorElement: null,
            rowMenuCurrentId: null,
        });
    };

    handleRowMenuRequestClose = (event) => {
        this.setState({
            isRowMenuOpen: false,
            rowMenuAnchorElement: null,
            rowMenuCurrentId: null,
        });
    };

    customSort = (nextProps) => {
        let order;
        if (this.state.orderBy || nextProps.defaultOrderBy) {
            const orderBy = this.state.orderBy || nextProps.defaultOrderBy;
            order = this.state.order || nextProps.defaultOrder;

            const def = (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);

            let sorter;

            if (!nextProps.sorter || nextProps.sorter[orderBy] === undefined) {
                sorter = def;
            } else if (nextProps.sorter[orderBy] == null) {
                sorter = null;
            } else {
                sorter = nextProps.sorter[orderBy];
            }

            const rows =
                order === 'asc'
                    ? [...nextProps.rows].sort(sorter).map(nextProps.formatter)
                    : [...nextProps.rows]
                        .sort((a, b) => -sorter(a, b))
                        .map(nextProps.formatter);

            this.setState({rows: rows, order: order, orderBy: orderBy});
        } else {
            this.setState({rows: nextProps.rows.map(nextProps.formatter)});
        }
    };

    handleSettingsClick = (event) => {
    };

    isSelected = (id) => this.state.selected.indexOf(id) !== -1;

    // componentWillMount() {
    //     this.uuid = uuidv4();
    // }

    componentDidMount() {
        this.uuid = uuidv4();
        if (this.props.rows && this.props.formatter) {
            this.setState({rows: this.props.rows.map(this.props.formatter)});
        }
        console.log("INFO: EnhancedTable: componentDidMount: page: " + this.state.page);

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps !== this.props && this.props.rows && this.props.formatter) {
            this.customSort(this.props);
        }
        let maxPage = 0;
        if (this.props.rows != null && this.props.rows.length !== 0) {
            maxPage = Math.ceil(this.props.rows.length / this.state.rowsPerPage) - 1;
            maxPage = maxPage > 0 ? maxPage : 0;
            if (this.state.page > maxPage) {
                this.setState({
                    page: maxPage,
                });
            }
        }

        if (prevProps.startPage !== this.props.startPage || prevState.handledStartPage === false) {
            if (this.props.startPage !== undefined) {
                this.setState({
                    page: this.props.startPage,
                    handledStartPage: true,
                });
                console.log("INFO: Enhanced Table startPage = " + this.props.startPage);
            }

        }
        if (prevProps.startRowsPerPage !== this.props.startRowsPerPage) {
            this.setState({
                rowsPerPage: this.props.startRowsPerPage
            });

            console.log("INFO: Enhanced Table startRowsPerPage = " + this.props.startRowsPerPage);
        }

    }

    render() {
        const {columns, hover, emptyState, t} = this.props;
        const {
            rows,
            order,
            orderBy,
            rowsPerPage,
            page,
            rowActions,
        } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <EnhancedTableHead
                            columns={columns}
                            onRequestSort={this.handleRequestSort}
                            order={order}
                            orderBy={orderBy}
                            actions={this.props.actions.length}
                            sorter={this.props.sorter}
                        />
                        <TableBody className={classes.body}>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    const isSelected = this.isSelected(row.id);
                                    return (
                                        <EnhancedTableRow
                                            hover={hover}
                                            onKeyDown={this.handleRowKeyDown(
                                                row.id
                                            )}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isSelected}
                                            notify={row.isnew && row.isnew}
                                            className={classes.row}
                                        >
                                            {columns.map((col) => (
                                                <EnhancedTableCell
                                                    key={col.id}
                                                    noHoverBackground={
                                                        col.noHoverBackground
                                                    }
                                                    hover={hover}
                                                    size={col.size}
                                                    nopadding={col.noPadding}
                                                    numeric={col.numeric}
                                                    onClick={this.handleRowClick(row)}
                                                >
                                                    {row[col.id]}
                                                </EnhancedTableCell>
                                            ))}
                                            {this.props.actions.length > 0 && (
                                                <EnhancedTableCell
                                                    noHoverBackground
                                                    size={'64px'}
                                                    nopadding
                                                    className={classes.actions}
                                                >
                                                    {row.showMenu !== false && (
                                                        <IconButton
                                                            className="row-action-button"
                                                            onClick={this.handleActionsClick(
                                                                row
                                                            )}
                                                        >
                                                            <MoreVertIcon/>
                                                        </IconButton>
                                                    )}
                                                </EnhancedTableCell>
                                            )}
                                        </EnhancedTableRow>
                                    );
                                })}
                        </TableBody>
                        <EnhancedTableFooter>
                            <SimplePagination
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={
                                    this.handleChangeRowsPerPage
                                }
                            />
                        </EnhancedTableFooter>
                    </Table>
                    {rows.length === 0 ? (
                        <div className="emptyState">
                            {emptyState || t('No items')}
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                {this.props.actions.length > 0 && (
                    <Popover
                        id={this.uuid + '-rowMenu'}
                        anchorEl={this.state.rowMenuAnchorElement}
                        open={this.state.isRowMenuOpen}
                        onClose={this.handleRowMenuRequestClose}
                        className={classes.actionMenu}
                    >
                        <List>
                            {rowActions.map((action) => (
                                <ListItem
                                    button
                                    key={action.id}
                                    onClick={this.handleActionClick(action)}
                                >
                                    <ListItemText
                                        primary={t(action.label)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Popover>
                )}
            </div>
        );
    }
}

export default compose(withTranslation())(EnhancedTable);

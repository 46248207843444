// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import VideoToolboxOverview from './VideoToolboxOverview';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {isUndefinedOrNull} from '../../lib/utils';
import {fetchAbonnees, fetchVideoToolboxes, setCrumbPath} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './VideoToolbox.module.scss';
import colors from "../../lib/themes/theme.scss";
import VideoToolboxSubscriptions from "./VideoToolboxSubscriptions/VideoToolboxSubscriptions";
import i18n from "i18next";

type Props = {
    match: any,
};

type State = {
    tab: number,
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: i18n.t('Video Toolboxes')}));
        },
        fetchAbonnees: () => {
            dispatch(fetchAbonnees());
        },
        fetchVideoToolboxes: () => {
            dispatch(fetchVideoToolboxes())
        }
    };
};

class VideoToolbox extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({tab: value});
    };

    componentDidMount() {
        this.props.setCrumbPath();
        this.props.fetchAbonnees();
        this.props.fetchVideoToolboxes();

        const tabs = ['#overzicht', '#subscriptions'];

        if (this.props.location.hash) {
            console.log(this.props.location.hash)
            let index = tabs.indexOf(this.props.location.hash.split("?")[0]);
            if (index === -1) index = 0;

            this.setState({
                tab: index,
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        const {match, videoToolbox, getVideoToolboxById} = this.props;
        if (
            prevProps.match.params.id !== match.params.id &&
            !isUndefinedOrNull(match.params.id) &&
            (isUndefinedOrNull(videoToolbox) ||
                isUndefinedOrNull(videoToolbox.byId) ||
                isUndefinedOrNull(videoToolbox.byId[match.params.id]))
        ) {
            getVideoToolboxById(match.params.id);
        }
    }

    render() {
        const {t} = this.props;
        const {tab} = this.state;

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{'& .Mui-selected': {color: colors.mainTabsSelectedColor}}}
                >
                    <Tab label={t('Video Toolboxes')} href="#overzicht" sx={{color: colors.mainTabsColor}}/>
                    <Tab label={t('Subscriptions')} href="#subscriptions" sx={{color: colors.mainTabsColor}}/>
                </Tabs>

                {tab === 0 && <VideoToolboxOverview/>}
                {tab === 1 && <VideoToolboxSubscriptions/>}
            </div>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation()
)(VideoToolbox);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    changeAddOnVideoToolbox,
    fetchAbonnees,
    fetchVideoToolboxes,
    pushCrumbPath,
    setCrumbPath,
    toggleShowVideoToolboxSubscriptionTrial,
} from '../../../actions';
import AppModule from '../../AppModule';
import EnhancedTable from '../../EnhancedTable';
import moment from '../../../lib/moment';
import {getPermissions} from '../../../selectors';
import TableActionHeader from '../../TableActionHeader';
import getRoles from '../../../selectors/getRoles';
import {percentColors1} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";

type Props = {
    loading: boolean,
    fetchSubscriptions: () => void,
};

type State = {
    searchtext: string,
};

const mapStateToProps = (store) => {
    return {
        loading: false,
        permissions: getPermissions(store),
        roles: getRoles(store),
        abonnees: store.subscription.abonnees,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchVideoToolboxes: () => {
            dispatch(fetchVideoToolboxes())
        },
        fetchAbonnees: () => {
            dispatch(fetchAbonnees())
        },
        changeAddOnVideoToolbox: (subscriptionId, enabled) => {
            dispatch(changeAddOnVideoToolbox(subscriptionId, enabled))
        },
        editVideoToolboxSubscription: (subscriptionId, videoToolboxSubscription, callback) => {
            dispatch(toggleShowVideoToolboxSubscriptionTrial(subscriptionId, videoToolboxSubscription, callback))
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: i18n.t('Video Toolbox Subscriptions')}));
        },
    };
};

class VideoToolboxSubscriptions extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        columns: [
            {id: 'externalId', label: 'Nr', size: '10%'},
            {id: 'companyName', label: 'Company', size: '30%'},
            {id: 'emailaddress', label: 'Email address', size: '25%'},
            {id: 'subscriptionStartDate', label: 'Start Date', size: '15%'},
            {id: 'addOn', label: 'Status', size: '20%'},
        ],
        actions: [
            // {
            //     id: 'activate',
            //     label: 'Activate',
            //     isVisible: (id) => this.canActivate(id, true),
            // },
            // {
            //     id: 'deactivate',
            //     label: 'Deactivate',
            //     isVisible: (id) => this.canActivate(id, false),
            // },
            {
                id: 'showTrial',
                label: 'Show trial',
                isVisible: (id) => this.canShowTrial(id, true),
            },
            {
                id: 'hideTrial',
                label: 'Hide trial',
                isVisible: (id) => this.canShowTrial(id, false),
            },
        ],
        searchtext: '',
    };

    componentDidMount() {
        const {t} = this.props;

        this.props.fetchAbonnees();

        this.props.setCrumbPath()
    }

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    activeToText = (abonnee) => {
        let {t} = this.props;
        let text = '';
        let colorValue = 0;

        if (abonnee && abonnee.videoToolboxSubscriptions.length >= 1) {
            const vts = abonnee.videoToolboxSubscriptions[0];
            if (vts.enabled) {
                text = t("Active")
                colorValue = 1
            } else if (vts.trialEnds != null && Date.parse(vts.trialEnds) >= Date.now()) {
                text = t("Trial") + " " + moment(vts.trialEnds).format('L')
                colorValue = 1
            } else if (vts.trialEnds != null && Date.parse(vts.trialEnds) < Date.now()) {
                text = t("Trial over")
                colorValue = 0.5
            } else if (vts.showTrial) {
                text = t("Can start trial")
                colorValue = 0.5
            } else {
                text = t("Inactive")
            }
        } else {
            text = t("Inactive")
        }

        return (
            <div
                className={'border-text'}
                style={{
                    backgroundColor: getColorForPercentage(colorValue),
                    borderColor: getColorForPercentage(colorValue),
                    width: '200px',
                }}
            >
                {text}
            </div>
        );
    };

    mapAbonneeToRow = (abonnee) => {
        const {t} = this.props;

        moment.locale(i18n.t('LOCALE'));

        return {
            ...abonnee,
            companyName: abonnee.companyName,
            emailaddress: abonnee.emailAdressSubscriptionHolder,
            externalId: abonnee.id,
            addOn: this.activeToText(abonnee),
            subscriptionStartDate: abonnee.videoToolboxSubscriptions && abonnee.videoToolboxSubscriptions.length >= 1 && abonnee.videoToolboxSubscriptions[0].subscriptionStartDate ? moment(abonnee.videoToolboxSubscriptions[0].subscriptionStartDate).format("L") : '',
        };
    };

    onRowClick = (row, event) => {
        // TODO: Detail page
    };

    abonneeSorter = () => {
        return {
            externalId: (a, b) => {
                return a.id - b.id;
            },
            companyName: (a, b) => {
                a = a.companyName.toLowerCase();
                b = b.companyName.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            emailaddress: (a, b) => {
                a = a.emailAdressSubscriptionHolder.toLowerCase();
                b = b.emailAdressSubscriptionHolder.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            addOn: (a, b) => {
                let prioA = 0
                let prioB = 0
                if (a.videoToolboxSubscriptions.length >= 1) {
                    const subA = a.videoToolboxSubscriptions[0];
                    if (subA.enabled) {
                        prioA = 4
                    } else if (subA.trialEnds != null && Date.parse(subA.trialEnds) >= Date.now()) {
                        prioA = 3
                    } else if (subA.trialEnds != null && Date.parse(subA.trialEnds) < Date.now()) {
                        prioA = 2
                    } else if (subA.showTrial) {
                        prioA = 1
                    }
                }
                if (b.videoToolboxSubscriptions.length >= 1) {
                    const subB = b.videoToolboxSubscriptions[0];
                    if (subB.enabled) {
                        prioB = 4
                    } else if (subB.trialEnds != null && Date.parse(subB.trialEnds) >= Date.now()) {
                        prioB = 3
                    } else if (subB.trialEnds != null && Date.parse(subB.trialEnds) < Date.now()) {
                        prioB = 2
                    } else if (subB.showTrial) {
                        prioB = 1
                    }
                }

                return (prioB - prioA) !== 0 ? prioB - prioA : a.id - b.id
            },
            subscriptionStartDate: (a, b) => {
                let subA;
                let subB;
                if (a.videoToolboxSubscriptions.length >= 1) {
                    subA = a.videoToolboxSubscriptions[0].subscriptionStartDate;
                }
                if (b.videoToolboxSubscriptions.length >= 1) {
                    subB = b.videoToolboxSubscriptions[0].subscriptionStartDate;
                }
                // Handle empty strings: Treat as the smallest value
                if (subA === '' && subB === '') return 0;
                if (subA === '') return -1;
                if (subB === '') return 1;

                // Convert to Date objects and compare timestamps
                return new Date(subA).getTime() - new Date(subB).getTime();
            }
        };
    };

    getAbonnee = (abonneeId: number) => {
        return this.props.abonnees.find((abo) => abo.id === abonneeId);
    };

    canActivate = (rowId, activate) => {
        if (this.getAbonnee(rowId).videoToolboxSubscriptions.length >= 1) {
            let bool = !this.getAbonnee(rowId).videoToolboxSubscriptions[0].enabled;
            return activate ? bool : !bool;
        } else {
            return activate;
        }
    };

    canShowTrial = (rowId, showTrial) => {
        if (this.getAbonnee(rowId).videoToolboxSubscriptions.length >= 1) {
            let vts = this.getAbonnee(rowId).videoToolboxSubscriptions[0];
            if (vts.enabled) {
                return false;
            } else {
                if (vts.trialEnds != null && Date.parse(vts.trialEnds) >= Date.now()) {
                    return false;
                } else if (vts.trialEnds == null) {
                    return showTrial !== vts.showTrial;
                }
            }
        } else {
            return showTrial;
        }
    }

    handleAction = (event, action) => {
        const {t} = this.props;
        // if (action.id === 'activate') {
        //     this.props.editFunctionality(action.rowId, {videoToolboxEnabled: true})
        // } else if (action.id === 'deactivate') {
        //     this.props.editFunctionality(action.rowId, {videoToolboxEnabled: false})
        // } else
        if (action.id === 'showTrial') {
            this.props.editVideoToolboxSubscription(action.rowId, {showTrial: true}, (data) => {
                    this.props.abonnees.find((abo) => abo.id === action.rowId).videoToolboxSubscriptions = [{...data}];
                    this.forceUpdate();
                }
            )
        } else if (action.id === 'hideTrial') {
            this.props.editVideoToolboxSubscription(action.rowId, {showTrial: false}, (data) => {
                    this.props.abonnees.find((abo) => abo.id === action.rowId).videoToolboxSubscriptions = [{...data}];
                    this.forceUpdate();
                }
            )
        }
    };

    render() {
        const {loading, t} = this.props;
        const {
            actions,
            columns,
        } = this.state;

        let abonnees = this.props.abonnees || [];

        const searchresults = abonnees
            .filter((abonnee) => {
                let textFound = false;

                return (
                    abonnee.companyName
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                );
            })

        const tableActionHeader = (
            <TableActionHeader
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search abonnees')}
                searchAlwaysOpen={true}
                title={"Subscriptions"}
                widemode={true}
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <EnhancedTable
                        hover
                        defaultOrderBy={'addOn'}
                        defaultOrder="asc"
                        columns={columns}
                        onClick={this.onRowClick}
                        rows={searchresults}
                        formatter={this.mapAbonneeToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.abonneeSorter()}
                        emptyState={t('No searchresults')}
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 0.5) {
        color = percentColors[1]
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(VideoToolboxSubscriptions))
);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input,
    InputLabel,
    Menu,
    MenuItem,
    Switch
} from '@mui/material';
import {
    deleteInspectionAssign,
    fetchEmployees,
    fetchInspections,
    fetchProjects,
    openDialog,
    pushCrumbPath,
    sendInspectionReminder,
    setCrumbPath,
    updateInspectionAssign,
} from '../../actions';
import AppModule from '../AppModule';
import moment from '../../lib/moment';
import {getPermissions, inProgress} from '../../selectors';
import './InspectionAssignedOverview.css';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import PaginationTable from '../PaginationTable';
import classnames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';
import ConfirmDialog from '../ConfirmDialog';
import $ from 'jquery';
import FilterHeader from '../FilterHeader';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import 'moment/min/locales';
import i18n from 'i18next';
import {withRouter} from "react-router";
import {fetchAssignedInspectionsOverviewWithParams, fetchCSV} from '../../lib/api';
import classes from './InspectionAssignedOverview.module.scss';

moment.locale(i18n.t('LOCALE'));

type Props = {
    inspections: Array<any>,
    employees: Array<any>,
    projects: Array<any>,
    loading: boolean,
    inspectionAssigns: any,
    setCrumbPath: () => void,
    goToDetailView: (toolboxId: string, deployedDate: Date) => mixed,
    sendReminder: any,
    totalSize: Number
};

type State = {
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    showResolveDialog: boolean,
    showConfirmDialog: boolean,
    showDeleteDialog: boolean,
    inspectionToDelete: any,
    inspectionToResolve: any,
    inspectionToResolveComment: string,
    inspectionToResolveSwitch: boolean,
    rows: Array,
    searchtext: String,
    page: Number,
    rowsPerPage: Number,
    orderBy: String,
    orderDirection: String,
    typeFilterText: String,
    totalSize: Number,
    index: Number,
};

const mapStateToProps = (store, props) => {
    const projectsStore = store.entities.projects;
    const employeeStore = store.entities.employees;
    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        projects: projectsStore.byId,
        employees: employeeStore.byId
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: (breadCrumb) => {
            dispatch(setCrumbPath(breadCrumb));
        },
        goToDetailView: (werkplekinspectieId, page, rowsPerPage, searchString, typeFilterText) => {
            console.log('INFO: InspectionAssignedOverview toto detail where werkplekinspectieId = ' + werkplekinspectieId + ' page = ' + page + ' rowsPerPage = ' + rowsPerPage);
            dispatch(
                push(`/werkplekinspectie/details/${werkplekinspectieId}#resultaten?page=${page}&rowsPerPage=${rowsPerPage}&searchString=${searchString}&typeFilter=${typeFilterText}`)
            );
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        sendReminder: (toolboxId, users) => {
            dispatch(sendInspectionReminder(toolboxId, users));
        },
        goto: (url: string) => {
            dispatch(push(url));
        },
        deleteInspection: (inspectionId: string) => {
            dispatch(deleteInspectionAssign(inspectionId));
        },
        updateInspectionAssign: (id, kamData: any) => {
            dispatch(updateInspectionAssign(id, kamData));
        },
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees(props.display));
        },
        fetchInspections: () => {
            dispatch(fetchInspections());
        },
    };
};

class InspectionAssignedOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        columns: [
            {id: 'logo', label: 'Type', size: '60px', noPadding: true, sorter: false},
            {id: 'title', label: 'Title', size: '20%'},
            {id: 'deployed', label: 'Created', size: '12%'},
            {
                id: 'project',
                label: `Project/workfield`,
                size: '20%',
            },
            {
                id: 'deployedBy',
                label: 'To be executed by',
                size: '15%',
            },
            {id: 'dateDone', label: 'Deadline', size: '11%'},
            {id: 'finished', label: 'Completed', size: '11%'},
            {id: 'remarks', label: 'Note', size: '8%', sorter: false},
            {id: 'completed', label: 'Result', size: '14%', noPadding: true, sorter: false},
            {id: 'kamResolved', label: 'Resolved', size: '10%', sorter: false},
        ],
        actions: [
            {
                id: 'reminder',
                label: 'Herinnering sturen',
                isVisible: (id) => this.isVisible(id),
            },
            {id: 'delete', label: 'Resultaat verwijderen', isVisible: true},
            {id: 'kamStatus', label: 'Mark as done', isVisible: true},
        ],
        showResolveDialog: false,
        showConfirmDialog: false,
        showDeleteDialog: false,
        typeFilter: 0,
        orderBy: "deployed",
        orderDirection: "desc",
        typeFilterText: "",
        searchtext: "",
        page: 0,
        rowsPerPage: 10,
        totalSize: 0,
        index: 0,
    };

    isVisible = (rowId) => {
        const inspectionAssign = this.state.rows.find(
            (inspectionAssigns) => inspectionAssigns.id === rowId
        );

        if (inspectionAssign) {
            return inspectionAssign.result === null;
        }
        return true;
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    showResolveDialog = () => {
        this.setState({
            showResolveDialog: true,
        });
    };

    showDeleteDialog = () => {
        this.setState({
            showDeleteDialog: true,
        });
    };

    editKamStatus = (key) => (event) => {
        if (key === 'kamComment') {
            this.setState({
                inspectionToResolveComment: event.target.value
            });
        } else if (key === 'kamSwitch') {
            this.setState({
                inspectionToResolveSwitch: !this.state.inspectionToResolveSwitch
            });

        } else if (key === 'kamSave') {
            const id = this.state.inspectionToResolve.id;

            let kamData = {
                kamResolved: this.state.inspectionToResolveSwitch,
                kamComment: this.state.inspectionToResolveComment
            };

            this.props.updateInspectionAssign(id, kamData);

            this.setState({
                showResolveDialog: false
            });
        }
    }
    confirmDelete = () => {
        this.props.deleteInspection(this.state.inspectionToDelete);
        this.setState({
            showDeleteDialog: false,
        });
    };

    setPage = (page) => {
        this.setState({
            page: page
        })
    }
    setRowsPerPage = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
    }

    inspectionAssignFetch = () => {
        let inspections = this.props.inspections

        fetchAssignedInspectionsOverviewWithParams(this.state.searchtext, this.state.page, this.state.rowsPerPage, this.state.orderBy, this.state.orderDirection, this.state.typeFilterText)
            .then((res) => {
                let inspectionAssign = res.body._embedded.inspectionAssigns.inspectionAssignProjections
                let listInspectionAssigns = []
                let totalSize = res.body._embedded.inspectionAssigns.totalSize
                inspectionAssign.forEach(singleInspectionAssign => {
                    listInspectionAssigns.push({
                        ...singleInspectionAssign,
                        inspection: {...inspections.byId[singleInspectionAssign.inspectionId]}
                    })
                })
                this.setState({
                    rows: listInspectionAssigns,
                    totalSize: totalSize
                })
            }).catch((error) => {
            throw error;
        });
    }

    handelOrderDirection = (orderDirection) => {
        this.setState({
            orderDirection: orderDirection
        })
    }
    handleOrderBy = (orderBy) => {
        this.setState({
            orderBy: orderBy
        })
    }
    onPageChange = (page) => {
        this.setState({
            page: page
        })
    }
    onRowsPerPageChange = (rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage,
            page: 0
        })
    }

    handleSorting = (orderBy) => {
        let orderByParam;
        switch (orderBy) {
            case "title":
                orderByParam = "inspection.title";
                break;
            case "dateDone":
                orderByParam = "deadline";
                break;
            case "finished":
                orderByParam = "result.date";
                break;
            case "kamResolved":
                orderByParam = "kamResolved";
                break;
            case "project":
                orderByParam = "project.omschrijving";
                break;
            case 'deployedBy':
                orderByParam = "user"
                break;
            default:
                orderByParam = "deployed";
        }
        if (this.state.orderBy === orderByParam) {
            if (this.state.orderDirection === "desc") {
                this.setState({orderDirection: "asc"});
            } else if (this.state.orderDirection === "asc") {
                this.setState({orderDirection: "desc"});
            } else {
                this.setState({orderDirection: "asc"});
            }
        }
        this.setState({
            orderBy: orderByParam,
        });
    };

    handleAction = (event, action) => {
        if (action.id === 'reminder') {
            const inspectionAssign = this.state.rows.find(
                (inspectionAssign) => inspectionAssign.id === action.rowId
            );
            let users = [inspectionAssign.user.id];

            this.setState({
                reminderToSend: inspectionAssign.inspection.id,
                reminderUsers: users,
            });
            this.showConfirmDialog();
        }
        if (action.id === 'delete') {
            const inspectionAssign = this.state.rows.find(
                (inspectionAssign) => inspectionAssign.id === action.rowId
            );

            this.setState({inspectionToDelete: inspectionAssign.id});

            this.showDeleteDialog();
        }
        if (action.id === 'kamStatus') {
            const inspectionAssign = this.state.rows.find(
                (inspectionAssign) => inspectionAssign.id === action.rowId
            );

            this.setState({
                inspectionToResolve: inspectionAssign,
                inspectionToResolveComment: inspectionAssign.kamComment,
                inspectionToResolveSwitch: inspectionAssign.kamResolved,

            });

            this.showResolveDialog();
        }
    };

    handleCSVClick = () => {
        const {rows} = this.state;

        let items = [];
        rows.forEach(item => {
            if (!item.activity) {
                item.activity = "-"
            }
            if (!item.result) {
                item.resultaatDatum = "-"
                item.resultaatCorrect = "-"
            } else {
                item.resultaatDatum = this.formatDateString(item.result.date)
                item.resultaatCorrect = item.result.allCorrect
            }
            if (!item.deadline) {
                item.deadline = "-"
            } else {
                item.deadline = this.formatDateString(item.deadline)
            }
            if (!item.kamResolved) {
                item.kamComment = "-"
            }
            if (item.deployed) {
                item.deployed = this.formatDateString(item.deployed)
            }
            // Pass full project details to item.
            if (this.props.projects && this.props.projects[item.projectId]) {
                item.project = this.props.projects[item.projectId];
            }

            items.push(item)
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'INSPECTION_ASSIGN_OVERVIEW',
            options: {},
            data: items,
            info: {},
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', 'InspectionAssignOverview.csv');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    }

    getFirstletterLastname = (user) => {
        let eersteletter = user.firstname;
        let res = eersteletter.slice(0, 1);
        let tussenvoegsel = user.middlename ? user.middlename + ' ' : '';
        let achternaam = user.lastname;

        return res + '. ' + tussenvoegsel + achternaam;
    };

    mapInspectionAssignToRow = (inspectionAssign) => {
        const {t} = this.props;
        let everythingOk = inspectionAssign.result != null && inspectionAssign.result.allCorrect
        const complete = inspectionAssign.result != null && inspectionAssign.result.complete;
        let remarkCount = inspectionAssign.result != null && inspectionAssign.result.totalComments;
        let unsafe = inspectionAssign.result != null && inspectionAssign.result.unsafe;

        let tooltip;
        if (inspectionAssign.result == null) {
            tooltip = t('Workplace inspection is not yet completed');
        } else if (everythingOk && remarkCount === 0) {
            tooltip = t('Everything is fine no comments');
        } else if (everythingOk && remarkCount === 1) {
            tooltip = t('Everything is fine, with comment');
        } else if (everythingOk && remarkCount > 1) {
            tooltip =
                t('Everything is fine, but there are') +
                ' ' +
                remarkCount +
                ' ' +
                t('comments');
        } else if (!everythingOk && remarkCount === 1) {
            tooltip = t('Something is wrong, with comment');
        } else if (!everythingOk) {
            tooltip =
                t('Something is wrong, there are') +
                ' ' +
                remarkCount +
                ' ' +
                t('comments') +
                '.';
        }

        if (inspectionAssign.result != null && inspectionAssign.result.date) {
            tooltip +=
                ` ${t('Completed')} ` +
                moment().to(moment(inspectionAssign.result.date));
        }

        let text = '';
        if (inspectionAssign.result == null) {
            text = t('Not completed');
        } else if (unsafe) {
            text = t('Unsafe situation');
        } else if (everythingOk) {
            tooltip = t('Correct');
            text = t('Correct');
        } else if (!everythingOk) {
            tooltip = t('Not correct');
            text = t('Not correct');
        }

        const completed = (
            <div className={'tooltip'} style={{padding: 0}}>
                <span className={'tooltiptext'}>{tooltip}</span>
                <span
                    className={classnames({
                        [classes.finishedCount]: true,
                        [classes.finishedNone]: !everythingOk,
                        [classes.finishedAll]: everythingOk,
                        [classes.notComplete]: !complete,
                    })}
                >
                    {unsafe && (
                        <WarningIcon
                            style={{
                                width: '14px',
                                height: '14px',
                                position: 'relative',
                                top: '2px',
                                marginRight: '5px',
                            }}
                        />
                    )}
                    {text}
                </span>
            </div>
        );
        moment.locale(i18n.t('LOCALE'));

        let projectEmpty =
            inspectionAssign.result &&
            (!inspectionAssign.projectId ||
                typeof inspectionAssign.projectId === undefined ||
                inspectionAssign.projectId === '999InvullenTijdenInspectie');

        let thisProject = undefined;
        if (inspectionAssign.projectId !== null && inspectionAssign.projectId !== undefined && inspectionAssign.projectId !== '999InvullenTijdenInspectie') {
            thisProject = this.props.projects[inspectionAssign.projectId];
        }

        let thisUser = inspectionAssign.user;   // Only id and shortname
        // Try to get the full user details from the employee list
        if (this.props.employees[thisUser.id]) {
            thisUser = this.props.employees[thisUser.id];
        }

        // kamResoled
        // Only show the kamResolved status when explicitly marked done or
        // Show status when wpi is unsafe or not everything
        let kamResolved = '';
        if (inspectionAssign.kamResolved) {
            kamResolved = t('Yes');
        } else if (inspectionAssign.result != null && (unsafe || !everythingOk)) {
            kamResolved = t('No');
        }

        return {
            ...inspectionAssign,
            deployed: !(inspectionAssign.deployed || inspectionAssign.scheduled)
                ? '-'
                : moment(
                    inspectionAssign.deployed || inspectionAssign.scheduled
                ).format('L HH:mm'),
            title: inspectionAssign.inspection.title,
            project: projectEmpty ? (
                <div className={'tooltip'} style={{padding: 0}}>
                    {t('No project')}
                    <Button
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'Transparent',
                            marginLeft: '-30px',
                        }}
                        onClick={(event) =>
                            this.onButtonInsideRowClick(
                                event,
                                inspectionAssign.id
                            )
                        }
                    >
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Choose project')}
                        </span>
                        <img
                            src="/assets/images/edit_icon.png"
                            alt={t('Edit')}
                        />
                    </Button>
                </div>
            ) : thisProject ? (
                thisProject.externalId +
                ': ' +
                thisProject.omschrijving
            ) : (
                '-'
            ),
            deployedBy: thisUser.extern
                ? thisUser.shortname + `(${t('external')})`
                : thisUser.fullname ? thisUser.fullname : thisUser.shortname,
            completed: completed,

            dateDone:
                inspectionAssign.inspection && inspectionAssign.deadline
                    ? moment(inspectionAssign.deadline).format('L')
                    : '-',
            finished:
                inspectionAssign.inspection && inspectionAssign.result
                    ? moment(inspectionAssign.result.date).format('L')
                    : '-',
            showMenu: true,
            remarks: inspectionAssign.result === null ? '-' : remarkCount,
            logo:
                inspectionAssign.inspection.source === 'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '30px'}}
                        >
                            {t('Cumela inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt={t('Cumela logo')}
                        />
                    </div>
                ) : inspectionAssign.inspection.source === 'global' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Veilig Vakwerk inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt={t('Admin logo')}
                        />
                    </div>
                ) : inspectionAssign.inspection.rootInspectionSource ===
                'global' ||
                inspectionAssign.inspection.rootInspectionSource ===
                'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Custom inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt={t('Custom logo')}
                        />
                    </div>
                ) : (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '25px'}}
                        >
                            {t('Own inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt={t('Own logo')}
                        />
                    </div>
                ),
            kamResolved: (
                <div className={'tooltip'}
                     style={{
                         whiteSpace: 'pre-wrap',
                         width: '100px',
                         height: 'min-content',
                         wordWrap: 'break-word',
                         padding: 0
                     }}>
                    <div
                        className={'tooltiptext'} style={{
                        whiteSpace: 'pre-wrap',
                        left: '-20px',
                        top: '30px',
                        width: '100px',
                        height: 'min-content'
                    }}
                    >
                        {inspectionAssign.kamComment === null ? '-' : inspectionAssign.kamComment}
                    </div>
                    <div>
                        {kamResolved}
                    </div>
                </div>

            ),
        };
    };

    onRowClick = (row) => {
        if (row.inspection.id) {
            // Set Crumb for current location
            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('Results'),
                    link: `/werkplekinspecties#resultaten?page=${this.state.page}&rowsPerPage=${this.state.rowsPerPage}`,
                    subTitle: row.inspection.title,
                }
            });

            this.props.goToDetailView(row.id, this.state.page, this.state.rowsPerPage, this.state.searchtext, this.state.typeFilterText);
        }
    };

    onButtonInsideRowClick(event, id) {
        this.props.goto(
            'werkplekinspectie/details/' + id + '?from=uitgestuurd#informatie'
        );
        event.stopPropagation();
    }

    componentDidMount() {
        if (this.props.projects.length === 0) {
            this.props.fetchProjects();
        }
        if (this.props.employees.length === 0) {
            this.props.fetchEmployees();
        }
        this.inspectionAssignFetch();

        const searchString = '?' + this.props.location.hash.split("?")[1]
        const searchStringWithValues = new URLSearchParams(searchString)

        let startRowsPerPage = this.state.rowsPerPage;
        let startPage = this.state.page;
        let searchtext = this.state.searchtext
        let typeFilterText = this.state.typeFilterText
        let typeFilter = this.state.typeFilter

        if (searchStringWithValues.has("rowsPerPage")) {
            startRowsPerPage = searchStringWithValues.get("rowsPerPage");
        }
        if (searchStringWithValues.has("page") && this.state.index === 0) {
            startPage = parseInt(searchStringWithValues.get("page"), 10);
        }
        if (searchStringWithValues.has("searchString")) {
            searchtext = searchStringWithValues.get("searchString")
        }
        if (searchStringWithValues.has("typeFilter")) {
            typeFilterText = searchStringWithValues.get("typeFilter")
            typeFilter = this.typeFilterTextToTypeFilter(typeFilterText)
        }

        this.setState({
            rowsPerPage: startRowsPerPage,
            searchtext: searchtext,
            typeFilterText: typeFilterText,
            typeFilter: typeFilter,
            page: startPage
        })

        moment.locale(i18n.t('LOCALE'));
        this.setState({
            actions: [
                {
                    id: 'reminder',
                    label: 'Send reminder',
                    isVisible: (id) => this.isVisible(id),
                },
                {
                    id: 'delete',
                    label: 'Delete result',
                    isVisible: true,
                },
                {
                    id: 'kamStatus',
                    label: 'Mark as done',
                    isVisible: true,
                },
            ],
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.inspections.byId !== this.props.inspections.byId || this.props.inspections === [] || prevState.page !== this.state.page || prevState.rowsPerPage !== this.state.rowsPerPage || prevState.searchtext !== this.state.searchtext || prevState.orderDirection !== this.state.orderDirection || prevState.orderBy !== this.state.orderBy || prevState.typeFilterText !== this.state.typeFilterText) {
            this.inspectionAssignFetch();
        }
    }

    handleDeployButtonClick = () => {
        this.props.openDialog('inspectionDeploy', {initialScheduled: false});
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
            page: 0
        });
    };

    handleTypeFilterChange = (event) => {
        let typeFilter = event.target.value
        this.handleRequestClose();
        this.setState({typeFilter: typeFilter});
        let typefilterText;
        typeFilter === 0
            ? typefilterText = ""
            : typeFilter === 1
                ? typefilterText = "global"
                : typeFilter === 2
                    ? typefilterText = 'cumela'
                    : typeFilter === 3
                        ? typefilterText = "edited"
                        : typefilterText = "Own"
        this.setState({
            typeFilterText: typefilterText,
            page: 0
        })
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {openType: false});
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    typeFilterTextToTypeFilter(typeFilterText: String) {
        if (typeFilterText !== "") {
            if (typeFilterText === "global") {
                return 1;
            }
            if (typeFilterText === "cumela") {
                return 2;
            }
            if (typeFilterText === "Own") {
                return 4;
            }
        } else {
            return 0;
        }
    }

    formatDateString = (date) => {
        let parsedDate = Date.parse(date)
        parsedDate = moment(parsedDate).format('YYYY-MM-DD HH:mm')
        return parsedDate
    }

    getFilterBar = (list, searchresults) => {
        const {t} = this.props;
        let vvw = true;
        let cumela = true;
        let aangepast = true;
        let eigen = true;

        return (
            <div className="filterbar">
                <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {this.state.totalSize +
                        ' ' +
                        t(this.state.totalSize === 1 ? 'workplace inspection' : 'workplace inspections')
                    }
                </div>
                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Everything')
                                    : this.state.typeFilter === 1
                                        ? t('Veilig Vakwerk')
                                        : this.state.typeFilter === 2
                                            ? 'CUMELA'
                                            : this.state.typeFilter === 3
                                                ? t('Edited')
                                                : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={(this.state && this.state.openType) ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('Veilig Vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            CUMELA
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    render() {
        const {loading, inspectionAssigns = [], t} = this.props;
        const {columns, actions, showResolveDialog, showDeleteDialog} = this.state;

        if (!inspectionAssigns) {
            return <div/>;
        }

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search workplace inspections')}
                searchAlwaysOpen={true}
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(inspectionAssigns, inspectionAssigns)}
                onActionButton={this.handleCSVClick}
                onActionText="Download"
            />
        );

        return (
            <div>
                <Dialog
                    onClose={() => {
                        this.setState({showResolveDialog: false});
                    }}
                    open={showResolveDialog}
                >
                    <DialogTitle>{t('Mark as done')}</DialogTitle>
                    <DialogContent>
                        <InputLabel htmlFor="wpi-kam-comment">
                            {t('Enter KAM Comment')}
                        </InputLabel>
                        <Input
                            fullWidth={true}

                            id="kamComment"
                            value={this.state.inspectionToResolveComment}
                            onChange={this.editKamStatus('kamComment')}
                            multiline
                        />
                        <p style={{marginBottom: '-12px'}}>
                            {t('Resolved by KAM?')}{' '}
                            <div
                                style={{
                                    float: 'right',
                                    marginTop: '-12px',
                                    marginRight: '50px',
                                }}
                            >
                                <Switch
                                    checked={this.state.inspectionToResolveSwitch}
                                    onChange={this.editKamStatus('kamSwitch')}
                                    id="kamSwitch"
                                />
                            </div>
                        </p>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({showResolveDialog: false});
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.editKamStatus('kamSave')}
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={() => {
                        this.setState({showDeleteDialog: false});
                    }}
                    open={showDeleteDialog}
                >
                    <DialogTitle>{t('Delete')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Sure to Delete result?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({showDeleteDialog: false});
                            }}
                        >
                            {t('No')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.confirmDelete}>{t('Yes')}</Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    handleClose={this.hideConfirmDialog}
                    sendreminder={this.state.reminderToSend}
                    reminderusers={this.state.reminderUsers}
                    Send={this.props.sendReminder}
                />
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    {/*<div*/}
                    {/*    className={classnames({*/}
                    {/*        [classes.button]: true,*/}
                    {/*        'mui-fixed': true,*/}
                    {/*    })}*/}
                    {/*>*/}
                    {/*    <div className={'tooltip'} style={{padding: 0}}>*/}
                    {/*        <span className={'tooltiptext'}*/}
                    {/*              style={{top: '60px', left: '130px'}}>*/}
                    {/*                {t('Download CSV')}*/}
                    {/*        </span>*/}
                    {/*        <Fab*/}
                    {/*            color="primary"*/}
                    {/*            onClick={this.handleCSVClick}>*/}
                    {/*            <DescIcon/>*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*TODO change to EnhancedTable*/}
                    <PaginationTable
                        defaultOrderBy="deployed"
                        defaultOrder="desc"
                        hover
                        className="click"
                        onClick={this.onRowClick}
                        columns={columns}
                        rows={this.state.rows}
                        length={this.state.totalSize}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        formatter={this.mapInspectionAssignToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t('No workplace inspection sent out')
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                        handleSorting={this.handleSorting}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(),)(InspectionAssignedOverview))
);

import React from 'react'; // eslint-disable-line no-unused-vars
import {Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {withTranslation} from 'react-i18next';
import classes from './ConfirmDialog.module.scss';

type Props = {
    open: boolean,
    handleClose: () => mixed,
    onSave: (employee?: TEmployee) => mixed,
    deleteemployee: TEmployee,
    deleteEmployeeFunction: (employee?: TEmployee, action) => mixed,
    deletetoolbox: String,
    deleteToolboxFunction: String,
    deleteInspection: String,
    deleteInspectionFunction: String,
    deletegroup: String,
    deleteGroupFunction: Group,
    sendreminder: String,
    display: string,
    sendReminder: String,
    reminderusers: any,
    cookies: any,
    deleteproject: String,
    deleteProjectFunction: String,
    deletetask: String,
    deleteTaskFunction: String,
    deleteIncidentFunction: any,
    deleteincident: any,
    deleteDocumentFunction: any,
    deletedocument: any,
};

type State = {
    tab: number,
};

class ConfirmDialog extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    cancelEdit = () => {
        this.props.handleClose();
    };

    delete = () => {
        const {t} = this.props;
        if (this.props.deleteemployee) {
            this.props.deleteEmployeeFunction(
                this.props.deleteemployee,
                this.props.display
            );
        } else if (this.props.deletetoolbox) {
            this.props.deleteToolboxFunction(this.props.deletetoolbox);
        } else if (this.props.deleteinspection) {
            this.props.deleteInspectionFunction(this.props.deleteinspection);
        } else if (this.props.deletegroup) {
            this.props.deleteGroupFunction(this.props.deletegroup, t);
        } else if (this.props.sendreminder) {
            this.props.Send(this.props.sendreminder, this.props.reminderusers);
        } else if (this.props.deleteproject) {
            this.props.deleteProjectFunction(this.props.deleteproject);
        } else if (this.props.deletetask) {
            this.props.deleteTaskFunction(this.props.deletetask);
        } else if (this.props.deleteincident) {
            this.props.deleteIncidentFunction(this.props.deleteincident);
        } else if (this.props.deletedocument) {
            this.props.deleteDocumentFunction(this.props.deletedocument);
        }
        this.props.handleClose();
    };

    acceptCookies = () => {
        localStorage.setItem('cookies', true);
        this.props.handleClose();
    };

    render() {
        const {t} = this.props;
        const delEmployee = this.props.deleteemployee;
        const delToolbox = this.props.deletetoolbox;
        const delInspection = this.props.deleteinspection;
        const delGroup = this.props.deletegroup;
        const sendreminder = this.props.sendreminder;
        const cookies = this.props.cookies;
        const delProject = this.props.deleteproject;
        const delTask = this.props.deletetask;
        const delIncident = this.props.deleteincident;
        const delDocument = this.props.deletedocument;

        if (
            !delEmployee &&
            !delToolbox &&
            !delInspection &&
            !delGroup &&
            !sendreminder &&
            !cookies &&
            !delProject &&
            !delTask &&
            !delIncident &&
            !delDocument
        ) {
            return <div/>;
        }
        if (delEmployee) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {delEmployee.extern ? t('Delete external user?') : t('Delete user?')}
                    </DialogTitle>
                    <DialogContent>
                        {delEmployee.extern ? t('Do you want to delete the following external user? ') : t('Do you want to delete the following user? ')}
                        {delEmployee.firstname +
                            (delEmployee.middlename
                                ? ' ' + delEmployee.middlename
                                : '') +
                            ' ' +
                            delEmployee.lastname}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton} onClick={this.cancelEdit}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton} onClick={this.delete}>
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delToolbox) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete Toolbox?')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Are u sure you want to delete this toolbox?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delInspection) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete workplace inspection?')}
                    </DialogTitle>
                    <DialogContent>
                        {t(
                            'Are u sure you want to delete this workplace inspection?'
                        )}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delGroup) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete group?')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Are u sure you want to delete this group?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (sendreminder) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t("Send reminder?")}
                    </DialogTitle>
                    {this.props.reminderusers.length > 1 ? (
                        <DialogContent>
                            {t('A number of')} {this.props.reminderusers.length}{' '}
                            {t('staff users will get a reminder, are u sure?')}
                        </DialogContent>
                    ) : (
                        <DialogContent>
                            {t('A number of')} {this.props.reminderusers.length}{' '}
                            {t('staff user will get a reminder, are u sure?')}
                        </DialogContent>
                    )}
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Confirm')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (cookies) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('We use cookies')}
                    </DialogTitle>
                    <DialogContent>
                        {t('This website uses cookies ')}
                        <a
                            href="https://veiligvakwerk.nl/privacybeleid"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('privacy policy')}
                        </a>
                        .
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            className={classes.confirmButton}
                            onClick={this.acceptCookies}
                        >
                            {t('i agree')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delProject) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete project?')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Sure to delete Project?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delTask) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete task')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Sure to delete task?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delIncident) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete incident?')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Sure to delete incident?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
        if (delDocument) {
            return (
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                >
                    <DialogTitle>
                        {t('Delete document?')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Sure to delete document?')}
                    </DialogContent>
                    <div className={classes.confirmButtonContainer}>
                        <Button
                            color="secondary"
                            className={classes.confirmButton}
                            onClick={this.cancelEdit}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                            onClick={this.delete}
                        >
                            {t('Delete')}
                        </Button>
                    </div>
                </Dialog>
            );
        }
    }
}

export default withTranslation()(ConfirmDialog);

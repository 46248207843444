// @flow

import TypedRecord from "../components/Util/IRecord";

export class TStateExternal extends TypedRecord({
    externalToolboxAssign: undefined
}) {
}

const initialState = new TStateExternal();

export default function subscriptionReducer(state =  initialState, action) {
    switch (action.type) {
        case 'EXTERNAL_TOOLBOX_ASSIGN': {
            return state.set('externalToolboxAssign', action.ta);
        }
        default:
            return state;
    }
}


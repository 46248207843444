// @flow
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem,
    ListItemText,
    InputLabel,
} from '@mui/material';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import {
    fetchSpeakupContacts,
    fetchSpeakupCompanies,
    getAppSpeakups,
    sendSpeakupResult,
} from '../../../actions';
import classes from './WebAppCreateSpeakup.module.scss';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from 'connected-react-router';
import TableActionHeader from '../../TableActionHeader';
import { inProgress } from '../../../selectors';
import SimpleImageInput from '../../SimpleImageInput';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField'

type Props = {
    speakupcontact: Array<any>,
    speakupcompany: Array<any>,
};

type State = {
    feedbackText: string,
    unsafe: boolean,
    photos: any,
    incident: any,
    id: any,
    open: boolean,
    speakupContact: any,
    speakupCompany: any,
    address: string,
    location: any,
    causeText: any,
    maatregelen: any,
    sending: boolean,
};

const mapStateToProps = (store, props) => {
    const incidentId = props.match.params.incidentId;

    const speakups = store.entities.speakups;

    let speakup = null;

    if (props.match.params.incidentId !== undefined && speakups) {
        speakups.allIds.forEach((id) => {
            if (
                speakups.byId[id] !== null &&
                id === parseInt(incidentId, 10)
            ) {
                speakup = speakups.byId[id];
            }
        });
    }

    return {
        speakup: speakup,
        speakupcontact: store.entities.speakupcontact,
        speakupcompany: store.entities.speakupcompany,
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendResults: (result) => {
            dispatch(sendSpeakupResult(result));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppSpeakups: () => {
            dispatch(getAppSpeakups());
        },
        fetchSpeakupContacts: () => {
            dispatch(fetchSpeakupContacts());
        },
        fetchSpeakupCompanies: () => {
            dispatch(fetchSpeakupCompanies());
        },
    };
};

class WebAppCreateSpeakup extends React.Component<Props, State> {
    props: Props;
    state: State = {
        feedbackText: this.props.t('SpeakUp Template'),
        unsafe: false,
        photos: [],
        id: '',
        open: false,
        speakupContact: 0,
        speakupCompany: 0,
        causeText: '',
        location: { lat: 0.0, lon: 0.0 },
        maatregelen: '',
        sending: false,
    };

    componentDidMount() {
        this.props.getAppSpeakups();
        this.props.fetchSpeakupContacts();
        this.props.fetchSpeakupCompanies();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.speakup !== prevProps.speakup) && this.props.speakup) {
            this.setState({
                feedbackText: this.props.speakup.remark.text,
                photos: this.props.speakup.remark.photos,
                id: this.props.speakup.id,
                speakupContact: this.props.speakup.contact,
                speakupCompany: this.props.speakup.company,
            });
        }
    }

    handleFormChange = (key) => (event) => {
        if (key === 'text') {
            if (event.target.value.length < 2048) {
                this.setState({
                    feedbackText: event.target.value,
                });
            }
        } else if (key === 'form_contact') {
            this.setState({
                speakupContact: event.target.value,
            });
        } else if (key === 'form_company') {
            this.setState({
                speakupCompany: event.target.value,
            });
        }
    };

    handleImageUpload = (event) => {
        if (event.id !== undefined) {
            this.setState({
                photos: [
                    ...this.state.photos,
                    { id: event.id, url: event.url, photo: event.id },
                ],
            });
        }
    };

    sendResults = () => {
        if (
            this.state.feedbackText.length > 0 &&
            this.state.speakupContact !== 0 &&
            this.state.speakupCompany !== 0
        ) {
            this.setState({
                sending: true,
            });
            let results = {
                contact: this.state.speakupContact,
                company: this.state.speakupCompany,
                date: Date.now(),
                remark: {
                    photos: this.state.photos,
                    text: this.state.feedbackText,
                    unsafe: false,
                },
                complete: true,
                isnew: true,
                deleted: false,
            };
            this.props.sendResults(results);
        } else {
            this.setState({
                open: true,
            });
        }
    };

    handleRemoveImage = (id) => {
        if (this.state.photos) {
            let photos = this.state.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
            });

            this.setState({
                photos: newPhotos,
            });
        }
    };

    backToOverview = () => {
        this.props.goTo('/web/speakup');
    };

    render() {
        const { loading, t } = this.props;
        const { open } = this.state;

        const tableActionHeader = (
            <TableActionHeader
                title={
                    this.props.match.params.incidentId != null
                        ? t('View your reported SpeakUp')
                        : t('Enter the details below')
                }
            />
        );

        // window.$crisp.push(['do', 'chat:hide']);

        return (
            <div className="App">
                <Dialog
                    open={open}
                    onClose={() => this.setState({ open: false })}
                >
                    <DialogTitle className="title">
                        {t('Not all mandatory fields completed')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Fill all fields and send again')}
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button
                            className="confirmButton"
                            onClick={() => this.setState({ open: false })}
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>
                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        <IconButton onClick={this.backToOverview}>
                            <ArrowBackIcon className={classes.backButton}/>
                        </IconButton>
                        <Typography
                            variant="h2"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('SpeakUp Report')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <AppModule
                    className="appModule"
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    <div
                        className={classes.paper}
                        id="tbExternPaperFeedback"
                        style={{
                            width: '92%',
                            maxWidth: '568px',
                            margin: '0 auto',
                        }}
                    >
                        <h1>
                            {!this.props.incident
                                ? t('SpeakUp Report')
                                : t('Your registration report from') +
                                  ' ' +
                                  moment(this.props.incident.date).format(
                                      t('dateTime')
                                  )}
                        </h1>
                        {this.props.match.params.incidentId == null && (
                            <p style={{ opacity: '0.6' }}>
                                {t('Describe the report as accurately as possible')}
                            </p>
                        )}
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('speakup_contact_title')}</b>
                        </p>
                        <FormControl
                            className={classes.item}
                        >
                            <InputLabel id="idLabelContact" >{t('speakup_contact_title_short')}*</InputLabel>
                            <Select
                                labelId="idLabelContact"
                                label={t('speakup_contact_title_short')+'*'}
                                className={classes.select}
                                value={
                                    this.state.speakupContact
                                        ? this.state.speakupContact
                                        : ''
                                }
                                disabled={
                                    this.props.match.params.incidentId != null
                                }
                                onChange={this.handleFormChange('form_contact')}
                            >
                                {this.props.speakupcontact &&
                                    this.props.speakupcontact.allIds.map(
                                        (type) => {
                                            return (
                                                <MenuItem
                                                    key={ this.props.speakupcontact.byId[type].id }
                                                    value={ this.props.speakupcontact.byId[type].id }
                                                >
                                                    <ListItemText
                                                        primary={t(
                                                            this.props.speakupcontact.byId[type].locKey ||
                                                            this.props.speakupcontact.byId[type].name
                                                        )}
                                                    />
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </Select>
                        </FormControl>

                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('speakup_company_title')}</b>
                        </p>
                        <FormControl
                            className={classes.item}
                        >
                            <InputLabel id="idLabelCompany" >{t('speakup_company_select')}*</InputLabel>
                            <Select
                                labelId="idLabelCompany"
                                label={t('speakup_company_select')+'*'}
                                value={
                                    this.state.speakupCompany
                                        ? this.state.speakupCompany
                                        : ''
                                }
                                className={classes.select}
                                onChange={this.handleFormChange('form_company')}
                                disabled={ this.props.match.params.incidentId != null }
                            >
                                {this.props.speakupcompany &&
                                this.props.speakupcompany.allIds.map(
                                    (type) => {
                                        return (
                                            <MenuItem key={ this.props.speakupcompany.byId[type].id }
                                                value={ this.props.speakupcompany.byId[type].id }  >
                                                <ListItemText primary={this.props.speakupcompany.byId[type].name} />
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>

                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Description')}</b>
                        </p>
                        <FormControl
                            className={classes.item2}
                            style={{ backgroundColor: '#F0F0F0' }}
                        >
                            <TextField
                                fullWidth={true}
                                id="incident-description"
                                onChange={this.handleFormChange('text')}
                                multiline
                                rows={10}
                                value={this.state.feedbackText}
                                disabled={ this.props.match.params.incidentId != null }
                            />
                        </FormControl>

                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>
                                {this.props.match.params.incidentId == null
                                    ? t('Add image(s)')
                                    : t('Images')}
                            </b>
                        </p>
                        {!loading &&
                            this.props.match.params.incidentId == null && (
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'incidentphoto0'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[0]
                                                    ? this.state.photos[0].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[0] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() => this.handleRemoveImage(0  ) }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                    {this.state.photos[0] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput id={'incidentphoto1'}
                                                onChange={ this.handleImageUpload }
                                                disabled={this.state.id !== ''}
                                                url={ this.state.photos && this.state.photos[1]
                                                        ? this.state.photos[1].url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos && this.state.photos[1] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() => this.handleRemoveImage(1 )}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[1] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto2'}
                                                onChange={ this.handleImageUpload }
                                                url={ this.state.photos && this.state.photos[2]
                                                        ? this.state.photos[2].url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[2] && (
                                                    <IconButton    className="drawer-button" onClick={() => this.handleRemoveImage(2 )}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[2] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto3'}
                                                onChange={ this.handleImageUpload }
                                                url={
                                                    this.state.photos &&
                                                    this.state.photos[3]
                                                        ? this.state.photos[3].url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[3] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() =>
                                                            this.handleRemoveImage(3 )}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[3] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto4'}
                                                onChange={ this.handleImageUpload }
                                                url={
                                                    this.state.photos && this.state.photos[4]
                                                        ? this.state.photos[4].url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[4] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() => this.handleRemoveImage(4 )}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                </div>
                            )}
                        {this.props.speakup &&
                            this.props.speakup.remark.photos.map((photo) => (
                                <img
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                    }}
                                    src={photo.url}
                                    alt={'foto'}
                                    width="128px"
                                    height="128px"
                                />
                            ))}
                        <br />
                        {this.props.match.params.incidentId == null && (
                            <Button
                                
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.sendResults}
                                id="sendresultsButton"
                                style={{
                                    width: '100%',
                                    height: '64px',
                                    marginTop: '24px',
                                }}
                                disabled={this.state.sending}
                            >
                                {t('Send notification')}
                            </Button>
                        )}
                        <br />
                        <br />
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(WebAppCreateSpeakup);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import AppModule from '../AppModule';
import {pushCrumbPath, setCrumbPath, showSnackbar} from '../../actions';
import classes from './Taken.module.scss';
import {apiDeleteTaskById, fetchAllTasks} from "../../lib/api";
import i18n from "i18next";
import EnhancedTable from "../EnhancedTable";
import moment from "../../lib/moment";
import ConfirmDialog from "../ConfirmDialog";
import {Menu, MenuItem} from "@mui/material";
import TableActionHeader from "../TableActionHeader";
import FilterHeader from "../FilterHeader";
import $ from "jquery";
import {push} from "connected-react-router";
import {withRouter} from "react-router";
import {percentColors3} from "../../lib/utils";
import classnames from "classnames";

type Props = {};

type State = {
    page: number,
    rowsPerPage: number,
    startPage: number,
    startRowsPerPage: number,
};

const mapStateToProps = (store, props) => {

    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: 'Task Title'}));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class Taken extends React.Component<Props, State> {
    props: Props;
    state: state = {
        page: 0,
        rowsPerPage: 10,
        loading: false,
        confirmDialogOpen: false,
        editDialogOpen: false,
        editDialogViewOnly: true,
        selectedTask: null,
        tasks: [],
        typeFilter: 0,
        openType: false,
        searchtext: "",
        actions: [
            {id: 'delete', label: 'Delete'},
        ],
        columns: [
            {
                id: 'for',
                numeric: false,
                size: '20%',
                label: 'For employee',
            },
            {
                id: 'date',
                numeric: false,
                size: '15%',
                label: 'Date',
            },
            {
                id: 'title',
                numeric: false,
                size: '46%',
                label: 'Title',
            },
            {
                id: 'dateResolved',
                numeric: false,
                size: '12%',
                label: 'Date Resolved',
            },
            {
                id: 'status',
                numeric: false,
                size: '10%',
                label: "Status",
                noPadding: true,
            },
        ],
    };

    componentDidMount() {
        this.loadData();
        this.props.setCrumbPath();

        const searchString = '?' + this.props.location.search.split("?")[1]

        if (searchString.includes("page")) {
            const jumpToPage = new URLSearchParams(searchString).get("page");
            this.setState({startPage: Number(jumpToPage)});
            this.setState({page: Number(jumpToPage)});
        }
        if (searchString.includes("rowsPerPage")) {
            const startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
            this.setState({startRowsPerPage: Number(startRowsPerPage)});
            this.setState({rowsPerPage: Number(startRowsPerPage)});
        }
    }

    loadData() {
        this.setState({loading: true});
        fetchAllTasks()
            .then((entities) => {
                // Sort latest till oldest
                const sortedTasks = entities.body._embedded.tasks.sort((a, b) => {
                    if (a.date < b.date) return -1;
                    if (a.date > b.date) return 1;
                    return 0;
                })

                this.setState({
                    loading: false,
                    tasks: sortedTasks
                });

            })
            .catch((error) => {
                this.setState({loading: false});
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching all tasks'
                    )}: ` + error.message
                )
                throw error;
            });
    }

    deleteTask = (task) => {
        apiDeleteTaskById(task)
            .then((entities) => {
                const newList = this.state.tasks.filter((myTask) => (myTask.id !== task));
                this.setState({
                    tasks: newList,
                    taskToHandle: null,
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    updateList = (updatedTask) => {
        let updatedList = []
        let updated = false;
        this.state.tasks.forEach((task) => {
            if (task.id === updatedTask.id) {
                updatedList.push(updatedTask);
                updated = true;
            } else {
                updatedList.push(task);
            }
        })
        if (updated === false) {
            // This is a new task
            updatedList.push(updatedTask);
        }
        this.setState({
            tasks: updatedList
        });
        // this.loadData();
    }
    onRowClick = (task, event) => {
        if (task.inspectionAssign) {
            // Goto inspection assign werkplekinspectie/details/12599#trend
            let path = 'werkplekinspectie/details/' + task.inspectionAssign.id + '#tasks';
            this.props.goTo(path);

            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('tasks'),
                    link: `/taken?page=${this.state.page}&rowsPerPage=${this.state.rowsPerPage}`,
                    subTitle: i18n.t('Workplace inspection task'),
                }
            });
        }
        if (task.incident) {
            // Goto inspection assign werkplekinspectie/details/12599#trend
            let path = 'registratiemelding/details/' + task.incident.id + '#tasks';
            this.props.goTo(path);

            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('tasks'),
                    link: `/taken?page=${this.state.page}&rowsPerPage=${this.state.rowsPerPage}`,
                    subTitle: i18n.t('Incident task'),
                }
            });

        }
    }

    handleAction = (event, action) => {
        if (action.id === 'delete') {
            this.setState({
                taskToHandle: action.rowId,
            });

            this.showConfirmDialog();
        }
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    hideEditDialog = () => {
        this.setState({
            editDialogOpen: false,
            editDialogViewOnly: true
        });
    };

    showEditDialog = () => {
        this.setState({
            editDialogOpen: true,
        });
    };
    showAddDialog = () => {
        this.setState({
            selectedTask: null,
            editDialogViewOnly: false,
            editDialogOpen: true,
        });
    };

    mapTaskToRow = (task) => {
        const taskDone = task ? task.taskFeedback.done : false;

        const completed = (
            <div>
                <div
                    className={classnames({
                        [classes.taskFinished]: taskDone,
                        [classes.taskNotFinished]: !taskDone
                    })}
                >
                    {task ? task.taskFeedback.done ? i18n.t('StatusDone') : i18n.t('StatusNotDone') : '-'}
                </div>
            </div>
        );

        return {
            ...task,
            for: task
                ? task.user
                    ? task.user.fullname
                    : '-'
                : '0',
            date: task
                ? task.date
                    ? moment(task.date).format('L HH:mm')
                    : '-'
                : '-',
            title: task
                ? task.title
                : '-',
            dateResolved: task
                ? task.dateResolved
                    ? moment(task.dateResolved).format('L HH:mm')
                    : '-'
                : '-',
            status: completed,
        };
    };

    taskSorter = () => {
        return {
            date: (a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            for: (a, b) => {
                a = a.user.fullname.toLowerCase();
                b = b.user.fullname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.title.toLowerCase();
                b = b.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            dateResolved: (a, b) => {
                a = a.dateResolved;
                b = b.dateResolved;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },

            status: (a, b) => {
                a = a.taskFeedback.done;
                b = b.taskFeedback.done;

                if (a === true && b === false) {
                    return 1;
                } else if (a === false && b === true) {
                    return -1;
                } else {
                    return 0;
                }
            },

        };
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext.toLowerCase(),
        });
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({typeFilter: event.target.value});
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {openType: false});
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    getFilterBar = (searchresults) => {
        const {t} = i18n;
        const {tasks} = this.state;
        let filterDone = true;
        let filterOpen = true;

        return (
            <div className="filterbar">
                <div className={classes.filterBar}>
                    {(tasks ? tasks.length : 0) + ' ' + (tasks && tasks.length === 1 ? t('task') : t('tasks')) +
                        (this.state.searchtext.length > 0
                            ? ' - ' + (searchresults ? searchresults.length : 0) +
                            ' ' + t(searchresults && searchresults.length === 1 ? 'searchresult' : 'searchresults')
                            : '')
                    }
                </div>
                <div className={classes.statusDropdown}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={t('Status')}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Alles')
                                    : this.state.typeFilter === 1
                                        ? t('StatusNotDone')
                                        : t('StatusDone')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {filterOpen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('StatusNotDone')}
                        </MenuItem>
                    )}
                    {filterDone && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('StatusDone')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    render() {
        const {t} = i18n;
        const {tasks, actions, columns, loading, searchtext} = this.state;

        const searchresults = tasks.filter((task) => {
            // Filter on Search
            let textFound = false;
            if (task.title.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.description.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.user.fullname.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.project) {
                if (task.project.omschrijving.toLowerCase().indexOf(searchtext) > -1) {
                    textFound = true;
                }
            }
            return textFound;
        }).filter((task) => {
            // Filter on filter selection
            if (this.state.typeFilter === 0) {
                return true; // All
            }
            if (this.state.typeFilter === 1 && task.taskFeedback.done === false) {
                return true; // Open
            }
            if (this.state.typeFilter === 2 && task.taskFeedback.done === true) {
                return true; // Closed
            }

            return false;
        });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search tasks')}
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(searchresults)}
                searchAlwaysOpen={true}

            />
        );

        return (
            <div className="Task">
                <AppModule className={classes.appModule}
                           loading={loading}
                           hasTabs={false}
                           prepend={tableActionHeader}
                >

                    <ConfirmDialog
                        open={this.state.confirmDialogOpen}
                        handleClose={this.hideConfirmDialog}
                        deletetask={this.state.taskToHandle}
                        deleteTaskFunction={this.deleteTask}
                    />
                    <EnhancedTable
                        hover
                        columns={columns}
                        onClick={this.onRowClick}
                        defaultOrder="desc"
                        defaultOrderBy='date'
                        rows={searchresults}
                        formatter={this.mapTaskToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.taskSorter()}
                        emptyState={
                            tasks
                                ? t('No tasks')
                                : t('No tasks')
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}
                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors3;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Taken)
);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, InputLabel,} from '@mui/material';
import PhotosBlockItem from './PhotosBlockItem';
import {DragDropContainer, DragDropItem} from '../DragDrop';
import {
    addPhotosItemBlockDraft,
    addPhotosPDFBlockDraft,
    deletePhotosItemBlockDraft,
    editPhotosItemBlockDraft,
    movePhotosItemBlockDraft,
    processForm,
    uploadPdf,
} from '../../actions';
import styled from 'styled-components';
import {getForm} from '../../selectors';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './PhotosBlockForm.module.scss';

const FileInput = styled.input`
  position: absolute;
  left: -99999px;
  margin: 0;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  line-height: normal;
  color: inherit;
`;

type Props = {
    classes: any,
    contentBlock: any,
    onChange: Function,
    update: Function,
    onItemMove: Function,
    onItemDelete: Function,
    onItemAdd: Function,
    onPDFAdd: Function,
};

type State = {
    loadingImage: boolean,
    openFileSizeDialog: boolean,
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(
                editPhotosItemBlockDraft(props.contentBlock.id, key, value)
            ),
        onItemMove: (fromIndex, toIndex) =>
            dispatch(
                movePhotosItemBlockDraft(
                    props.contentBlock.id,
                    fromIndex,
                    toIndex
                )
            ),
        onItemDelete: (index) =>
            dispatch(deletePhotosItemBlockDraft(props.contentBlock.id, index)),
        onItemAdd: () =>
            dispatch(addPhotosItemBlockDraft(props.contentBlock.id)),
        onPDFAdd: (result) =>
            dispatch(addPhotosPDFBlockDraft(props.contentBlock.id, result)),
        onSelectFile: (file) =>
            dispatch(
                processForm(props.id, uploadPdf(file, props.contentBlock.id))
            ),
    };
};

const mapStateToProps = (store, props) => {
    return getForm(props.id)(store);
};

class PhotosBlockForm extends React.Component {
    props: Props;
    state: State = {
        loadingImage: false,
        openFileSizeDialog: false,
    };

    handleItemFormChange = (key) => (event) => {
        let id = 0;
        this.props.contentBlock.photosBlock.photos.forEach((item, i) => {
            if (item.id === key) {
                id = i;
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.update(id, {url: event.url});
    };

    handleItemMove = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemMove(fromIndex, toIndex);
    };

    closeDialog = () => {
        this.setState({
            openFileSizeDialog: false,
        });
    };

    handleItemDelete = (index) => (event) => {
        let id = 0;
        this.props.contentBlock.photosBlock.photos.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemDelete(id);
    };

    handleItemAdd = (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemAdd();
    };

    handleInputChange = (event) => {
        if (event.target.files[0]) {
            if (event.target.files[0].size < 3000000) {
                this.setState({loadingImage: true});
                this.props.onSelectFile(event.target.files[0]);
            } else {
                this.setState({
                    openFileSizeDialog: true,
                });
            }
        }
    };

    getInputId(path) {
        return (
            this.props.contentBlock.id +
            '-photosBlock-' +
            [].concat(path).join('-')
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.result !== null && this.props.result !== prevProps.result) {
            if (this.props.result[0] && this.props.result[0].blockId === prevProps.contentBlock.id) {
                if (this.props.onChange) {
                    this.props.onChange();
                }

                this.props.onPDFAdd(this.props.result);

                this.setState({
                    loadingImage: false,
                });
            }
        }
    }

    render() {
        const {contentBlock, t} = this.props;

        return (
            <form className={classes.root}>
                <Dialog
                    open={this.state.openFileSizeDialog}
                    onClose={this.closeDialog}
                >
                    <DialogTitle className="title">
                        {t('File too large')}
                    </DialogTitle>
                    <DialogContent>
                        {t('The PDF file can be up to 3MB in size.')}
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button

                            className="confirmButton"
                            onClick={this.closeDialog}
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>
                <FormControl variant="standard">
                    <InputLabel shrink={true}>{t('Images')}</InputLabel>
                    <div className={classes.items}>
                        <DragDropContainer onMove={this.handleItemMove}>
                            {contentBlock.photosBlock.photos.map((item, i) => {
                                return (
                                    <DragDropItem
                                        className={classes.item}
                                        id={item.id}
                                        key={item.id}
                                        data={item}
                                    >
                                        <PhotosBlockItem
                                            item={item}
                                            onChange={this.handleItemFormChange(
                                                item.id
                                            )}
                                            onDelete={this.handleItemDelete(
                                                item.id
                                            )}
                                        />
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>
                        <div className={classes.paddingTwelve}>
                            <Button
                                className={classes.addButton}
                                onClick={this.handleItemAdd}
                                disabled={this.state.loadingImage}
                            >
                                {this.state.loadingImage
                                    ? t('Load PDF...')
                                    : t('Add picture')}
                            </Button>
                            <FileInput
                                id={'pdf-' + this.props.contentBlock.id}
                                ref="file"
                                type="file"
                                onChange={this.handleInputChange}
                                accept=".pdf"
                                style={{cursor: 'pointer'}}
                            />
                            {this.state.loadingImage && (
                                <CircularProgress
                                    className={classes.progress}
                                    color="secondary"
                                    size={22}
                                />
                            )}
                            <Button
                                className={classes.addButton}
                                disabled={this.state.loadingImage}
                                style={{marginLeft: '10px'}}
                                onClick={() => {
                                    document
                                        .getElementById(
                                            'pdf-' + this.props.contentBlock.id
                                        )
                                        .click();
                                }}
                            >
                                {this.state.loadingImage ? '' : t('Upload PDF')}
                            </Button>
                        </div>
                    </div>
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(PhotosBlockForm);

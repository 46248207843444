// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {Button, FormControl, IconButton, Input, InputLabel, MenuItem, Select,} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {DragDropContainer, DragDropItem} from '../DragDrop';
import {
    addListItemBlockDraft,
    deleteListItemBlockDraft,
    editListBlockDraft,
    editListItemBlockDraft,
    moveListItemBlockDraft,
    openDialog,
    showSnackbar,
} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './ListBlockForm.module.scss';
import BulletListItem from "./BulletListItem";

type Props = {
    classes: any,
    contentBlock: any,
    update: Function,
    onChange: Function,
    onItemChange: Function,
    onItemMove: Function,
    onItemDelete: Function,
    onItemAdd: Function,
    showSnackbar: Function,
};

type State = {};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editListBlockDraft(props.contentBlock.id, key, value)),
        onItemChange: (index, key, value) =>
            dispatch(
                editListItemBlockDraft(props.contentBlock.id, index, key, value)
            ),
        onItemMove: (fromIndex, toIndex) =>
            dispatch(
                moveListItemBlockDraft(
                    props.contentBlock.id,
                    fromIndex,
                    toIndex
                )
            ),
        onItemDelete: (index) =>
            dispatch(deleteListItemBlockDraft(props.contentBlock.id, index)),
        onItemAdd: () => dispatch(addListItemBlockDraft(props.contentBlock.id)),
        onItemMultiAdd: (contentBlockId) =>
            dispatch(
                openDialog('pasteListItems', { contentBlockId: contentBlockId })
            ),
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class ListBlockForm extends React.Component {
    props: Props;
    state: State = {};

    handleFormChange = (key) => (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.update(key, event.target.value);
    };

    handleItemFormChange = (index, key) => (event) => {
        const { t } = this.props;
        if (key === 'text' && event.target.value.length > 5000) {
            this.props.showSnackbar(
                t('Text cannot be longer than 5000 characters')
            );
            return;
        }

        let id = 0;
        this.props.contentBlock.listBlock.items.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemChange(id, key, event.target.value);
    };

    handleItemMove = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemMove(fromIndex, toIndex);
    };

    handleItemDelete = (index) => (event) => {
        let id = 0;
        this.props.contentBlock.listBlock.items.forEach((item, i) => {
            if (item.id === index) {
                id = i;
            }
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemDelete(id);
        this.forceUpdate();
    };

    handleItemAdd = (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemAdd();
    };

    handlePasteMultiClick = (event) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        this.props.onItemMultiAdd(this.props.contentBlock.id);
    };

    getInputId(path) {
        return (
            this.props.contentBlock.id +
            '-listBlock-' +
            [].concat(path).join('-')
        );
    }

    render() {
        const { contentBlock, t } = this.props;

        return (
            <form className={classes.root}>
                <FormControl variant="standard">
                    <InputLabel htmlFor={this.getInputId('type')}>
                        {t('Type')}
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        value={contentBlock.listBlock.type}
                        onChange={this.handleFormChange('type')}
                        input={<Input id={this.getInputId('type')} />}
                    >
                        <MenuItem value={'bullet'}>{t('Bullets')}</MenuItem>
                        <MenuItem value={'ordered'}>{t('Numbered')}</MenuItem>
                        <MenuItem value={'check'}>{t('Check marks')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard">
                    <InputLabel shrink={true} style={{paddingTop: "12px"}}>{t('Parts list')}</InputLabel>
                    <div className={classes.items}>
                        <DragDropContainer onMove={this.handleItemMove}>
                            {contentBlock.listBlock.items.map((item, i) => {
                                return (
                                    <DragDropItem
                                        className={classes.item}
                                        id={item.id}
                                        key={item.id}
                                        data={item}
                                    >
                                        {/*{ contentBlock.listBlock.type === 'bullet' ?*/}
                                        <BulletListItem
                                        item={item}
                                        onDelete={this.handleItemDelete(
                                            item.id
                                        )}
                                        onChange={this.handleItemFormChange(
                                            item.id,
                                            'text'
                                        )}
                                        />
                                        {/*:*/}
                                        {/*    <ListBlockItem*/}
                                        {/*        item={item}*/}
                                        {/*        onDelete={this.handleItemDelete(*/}
                                        {/*            item.id*/}
                                        {/*        )}*/}
                                        {/*        onChange={this.handleItemFormChange(*/}
                                        {/*            item.id,*/}
                                        {/*            'text'*/}
                                        {/*        )}*/}
                                        {/*        onQuestionType={this.handleItemFormChange(*/}
                                        {/*            item.id,*/}
                                        {/*            'questionType'*/}
                                        {/*        )}*/}
                                        {/*    />*/}
                                        {/*}*/}
                                    </DragDropItem>
                                );
                            })}
                        </DragDropContainer>

                        <div className={classes.itemActions}>
                            <Button
                                className={classes.addButton}
                                onClick={this.handleItemAdd}
                            >
                                {t('Add part')}
                            </Button>
                            <IconButton
                                className={classes.pasteMultiButton}
                                onClick={this.handlePasteMultiClick}
                                size="large">
                                <PlaylistAddIcon />
                            </IconButton>
                        </div>
                    </div>
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    
    withTranslation()
)(ListBlockForm);

// @flow
import React from 'react';
import {Map} from 'immutable';
import {Button, DialogActions, DialogContent, DialogContentText, TextField,} from '@mui/material';
import {fetchProjects} from '../../../actions';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './ProjectEditCard.module.scss';

type Props = {
    project?: TProject,
    onEdit: (project?: TProject) => mixed,
    onSave: (project: TProject) => mixed,
    goBack: () => mixed,
    projects: any,
};

type State = {
    errors: Map<string, string>,
    project: {
        type: string,
        omschrijving: string,
        opdrachtgever: string,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        projects: store.entities.projects,
    };
};

class ProjectEditCard extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        errors: new Map(),
        project: {
            importType: 'MANUAL',
            omschrijving: '',
            opdrachtgever: ''
        }
    };

    componentDidMount() {
        this.props.fetchProjects();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.props.project && this.state.project && this.props.project.id && !this.state.project.id) {
            if (this.props.project && this.props.project.id) {
                this.setState({
                    project: this.props.project
                })
            }
        }
    }

    handleOmschrijvingChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.setState({
            errors: this.state.errors.remove('omschrijving'),
            project: {
                ...this.state.project,
                omschrijving: event.target.value
            }
        });
    };

    handleOpdrachtgeverChange = (event: any) => {
        if (event.target.value.length > 100) {
            return;
        }

        this.setState({
            errors: this.state.errors.remove('opdrachtgever'),
            project: {
                ...this.state.project,
                opdrachtgever: event.target.value
            }
        });
    };

    cancelEdit = () => {
        this.setState({
            project: {
                importType: 'MANUAL',
                omschrijving: '',
                opdrachtgever: ''
            }
        });
        this.props.goBack();
    };

    saveProject = () => {
        console.log('XXX saveProject', this.state.project);
        let errors: Map<string, string> = new Map();
        const {t} = this.props;

        var errorTexts = {
            'omschrijving.required': t('This field is required'),
            'opdrachtgever.required': t('This field is required'),
        };

        if (!this.state.project) {
            //TODO error?
            return;
        }

        let project = this.state.project;

        if (!project.omschrijving || project.omschrijving === '') {
            errors = errors.set(
                'omschrijving',
                errorTexts['omschrijving.required']
            );
        }

        if (!project.opdrachtgever || project.opdrachtgever === '') {
            errors = errors.set(
                'opdrachtgever',
                errorTexts['opdrachtgever.required']
            );
        }

        //No errors
        if (errors.isEmpty()) {
            this.props.onSave(project, t);
        } else {
            this.setState({
                errors,
            });
        }
    };

    render() {
        const theProject = this.state.project;

        if (!theProject) {
            return <div/>;
        }

        const {t} = this.props;

        const errors = this.state.errors;

        return (
            <div style={{height: '100%'}}>
                <DialogContent style={{height: 'calc(100% - 100px)'}}>
                    <DialogContentText className={classes.text}>
                        {t('Enter the details of the project / work area.')}
                    </DialogContentText>
                    <TextField
                        variant="standard"
                        id="omschrijving"
                        label={t('Name')}
                        value={
                            theProject.omschrijving
                                ? theProject.omschrijving
                                : undefined
                        }
                        error={errors.get('omschrijving') != null}
                        helperText={errors.get('omschrijving')}
                        onChange={this.handleOmschrijvingChange}
                    />

                    <TextField
                        id="opdrachtgever"
                        variant="standard"
                        label={t('Customer')}
                        error={errors.get('opdrachtgever') != null}
                        helperText={errors.get('opdrachtgever')}
                        value={
                            theProject.opdrachtgever
                                ? theProject.opdrachtgever
                                : undefined
                        }
                        onChange={this.handleOpdrachtgeverChange}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        className="secondaryButton"
                        onClick={this.cancelEdit}>
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        color="primary"
                        className="secondaryButton"
                        onClick={this.saveProject}>
                        {theProject.id ? t('Save') : t('Add')}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(ProjectEditCard);

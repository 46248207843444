// @flow
import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterHeader from '../FilterHeader';
import $ from 'jquery';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './PaginationTablePagination.module.scss';

type Props = {
    count: number,
    currentPage: Number,
    rowsPerPage: number,
    onChangePage: Function,
    onChangeRowsPerPage: Function,
};

type State = {
    openRows: boolean,
};

class PaginationTablePagination extends React.Component {
    props: Props;
    state: State = {
        openRows: false,
        totalRows: 0,
        currentPage: 0,
        nextPage: 0,
        maxPage: 0,
    };

    handleBackClick = (event) => {
        let maxPage = Math.ceil(this.props.count / this.props.rowsPerPage)
        this.setState({maxPage : maxPage})
        if(this.props.currentPage === 0 ){
            this.setState({nextPage : this.state.maxPage})
            this.setState({currentPage : 0})
            this.props.onChangePage(event, maxPage -1);
        }else {
            let currentPage  = this.props.currentPage - 1;
            this.setState({currentPage : currentPage})
            this.setState({nextPage : currentPage})
            this.props.onChangePage(event, currentPage);
        }
    }

    handleForwardClick = (event) => {
    let maxPage = Math.ceil(this.props.count / this.props.rowsPerPage)
    this.setState({maxPage : maxPage})
        if(this.props.currentPage +1 === maxPage ){
            this.setState({nextPage : 0})
            this.setState({currentPage : maxPage})
            this.props.onChangePage(event, 0);
        }else {
            let nextpage  = this.props.currentPage + 1;
            this.setState({nextPage : nextpage})
            this.props.onChangePage(event, nextpage);
        }
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { openRows: false });
        this.setState(newState);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openRows: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handlePageRowsClick = (event) => {
        //const totalPages = Math.ceil(this.props.count / this.props.rowsPerPage);
        this.handleRequestClose();
        this.props.onChangeRowsPerPage(event.target.value);
    };

    render() {
        const { count, rowsPerPage, currentPage, t } = this.props;
        let fromItem
        if(currentPage === 0){
            fromItem = 1;
        }else{
            fromItem = (currentPage * rowsPerPage)
        }
        let toItem = (currentPage + 1) * rowsPerPage;

        if (toItem > count) {
            toItem = count;
        }

        return (
            <div className={classes.root}>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={t('Rows per page')}
                            filterValue={rowsPerPage}
                        />
                    </span>
                </div>
                <Menu
                    open={this.state ? this.state.openRows : false}
                    onClose={this.handleRequestClose}
                    value={rowsPerPage}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handlePageRowsClick}
                        value={10}
                    >
                        10
                    </MenuItem>
                    <MenuItem onClick={this.handlePageRowsClick} value={25}>
                        25
                    </MenuItem>
                    <MenuItem onClick={this.handlePageRowsClick} value={50}>
                        50
                    </MenuItem>
                    <MenuItem onClick={this.handlePageRowsClick} value={100}>
                        100
                    </MenuItem>
                </Menu>

                <div className={classes.label}>
                    {fromItem} - {toItem} {t('from')} {count}{' '}
                </div>
                <IconButton
                    disabled={this.props.count === 0}
                    onClick={this.handleBackClick}
                    className={classes.button}
                >
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton
                    disabled={this.props.count === 0}
                    onClick={this.handleForwardClick}
                    className={classes.button}
                >
                    <KeyboardArrowRightIcon />
                </IconButton>
            </div>
        );
    }
}

export default compose(withTranslation())(PaginationTablePagination);

//@flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {findDOMNode} from 'react-dom';
import _ from 'lodash';
import classnames from 'classnames';
import {DragSource, DropTarget} from 'react-dnd';
import {Card} from '@mui/material';
import classes from './DragDropItem.module.scss';

type Props = {
    dragId: any,
    index: number,
    type: string,
    children: any,
    classes: any,
    dragging: boolean,
    connectDragSource: Function,
    connectDropTarget: Function,
    connectAcceptTarget: Function,
    connectDragPreview: Function,
};

type State = {};

class DragDropItem extends React.Component {
    props: Props;
    state: State = {};

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.data !== this.props.data) || (nextProps.children.props.contentBlocks !== this.props.children.props.contentBlocks);
    }

    render() {
        const {
            children,
            dragging,
            connectDragSource,
            connectDropTarget,
            connectAcceptTarget,
            connectDragPreview
        } = this.props;
        const classProps = ('' + this.props.className).split(' ').reduce((result, classname) => {
            return {[classname]: true, ...result};
        }, {});

        return (
            connectDropTarget(<div className={classnames({
                ...classProps,
                [classes.root]: true,
                [classes.dragging]: dragging
            })}>{connectAcceptTarget(<div>
                <Card elevation={0}>
                    {
                        React.Children.toArray(children).filter(child => child).map((child, i) => {
                                let element;
                                let childWithProps = React.cloneElement(child, {
                                    connectDragPreview: connectDragPreview,
                                    connectDragSource: connectDragSource
                                });
                                if (child.type instanceof Function) {
                                    return childWithProps;
                                } else {
                                    element = connectDragPreview(<div key={i}>{childWithProps}</div>);
                                }
                                return element;
                            }
                        )
                    }
                </Card></div>)}</div>)
        );
    }
}

export default _.flowRight([
    DragSource(
        (props) => props.type,
        {
            beginDrag: function (props) {
                return {
                    id: props.dragId,
                    index: props.index
                };
            }
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            dragging: monitor.isDragging(),
        })
    ),
    DropTarget(
        (props) => props.type,
        {
            hover: (props, monitor, component) => {
                const dragIndex = monitor.getItem().index;
                const hoverIndex = props.index;
                if (dragIndex !== hoverIndex) {
                    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
                    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                    const clientOffset = monitor.getClientOffset();
                    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                    const draggingDown = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
                    const draggingUp = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
                    if (!draggingDown && !draggingUp) {
                        props.onMove(dragIndex, hoverIndex);
                        monitor.getItem().index = hoverIndex;
                    }
                }
            }
        },
        (connect, monitor) => ({
            connectDropTarget: connect.dropTarget()
        })
    ),
    DropTarget(
        (props) => {
            return props.accepts || 'UNKNOWN'
        },
        {
            hover: (props, monitor, component) => {
                const hoverIndex = props.index;
                const insertIndex = props.insertIndex;
                if (hoverIndex >= insertIndex) {
                    props.onAcceptMove(hoverIndex + 1, monitor.getItem());
                } else {
                    props.onAcceptMove(hoverIndex, monitor.getItem());
                }
            }
        },
        (connect, monitor) => ({
            connectAcceptTarget: connect.dropTarget()
        })
    ),

])(DragDropItem);

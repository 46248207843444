// @flow
import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {fetchPDF} from '../../../lib/api';
import moment from 'moment';
import {percentColors4} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    toolboxAssign: any,
    key?: number,
    fetchSectors: () => mixed,
    goToDetailView: (toolboxId: string, deployedDate: Date) => mixed,
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        goToDetailView: (toolboxAssign) => {
            dispatch(
                push(
                    '/toolbox/details/' +
                        toolboxAssign.toolbox.id +
                        '/' +
                        toolboxAssign.deploymentId +
                        '/results/' +
                        toolboxAssign.user.id +
                        '?from=rapportages'
                )
            );
        },
        goToDetailViewToolbox: (id) => {
            dispatch(push('/rapportages/toolboxMeeting/' + id));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        allSectors: store.entities.sectors,
    };
};

class StatisticsRowToolbox extends React.Component<Props, State> {
    state: State = {
        expanded: false,
    };

    handleExpandClick = (item) => {
        this.setState({ expanded: !this.state.expanded });
    };

    onItemClicked = (clickedToolboxAssign) => {
        this.props.goToDetailView(clickedToolboxAssign);
    };

    getTMPDF = (toolboxAssign) => {
        let data = [];
        toolboxAssign.listParticipants.map((p) => data.push(p));

        data.map((p) => (p.listParticipants = []));

        let pdfToolbox = Object.assign({}, data[0] ? data[0].toolbox : null);
        pdfToolbox.sectors = pdfToolbox.sectors.map((id, index) => {
            return this.props.allSectors.byId[id]
                ? this.props.allSectors.byId[id].name
                : id;
        });

        data[0].toolbox = pdfToolbox;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOXMEETING_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    'toolboxMeetingRapportage_' +
                        toolboxAssign.toolbox.title +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));

        toolboxAssign.listParticipants = data;
    };

    checkNrFilledOutToolboxes(toolboxAssign) {
        let filledOut = 0;
        if (toolboxAssign.listParticipants !== 0) {
            toolboxAssign.listParticipants.forEach((p) => {
                if (p.result && p.result.answers) {
                    filledOut++;
                }
            });
        }

        return filledOut;
    }

    getStateToolboxMeeting(toolboxAssign) {
        let completed = 0;
        if (toolboxAssign.listParticipants !== 0) {
            toolboxAssign.listParticipants.forEach((p) => {
                let nrC = this.getNrCompletedInspections(p);
                if (nrC === p.toolbox.toolbox.questions.length) {
                    completed++;
                }
            });
        }

        return completed;
    }

    getNrCompletedInspections(toolboxAssign) {
        let numberCompleted = 0;
        if (toolboxAssign.result && toolboxAssign.result.answers) {
            toolboxAssign.result.answers.forEach((answer) => {
                if (answer && answer.correct) numberCompleted++;
            });
        }

        return numberCompleted;
    }

    onRowClick = (row) => {
        if (row.id) {
            this.props.goToDetailViewToolbox(row.id);
        }

        // let target = 'rapportages/toolbox/toolboxMeeting/' + clickedToolboxAssign.toolbox.id;
        //
        // let url = window.location.href;
        // let parts = url.split('?');
        // if (parts.length > 1) {
        //     target += '?' + parts[1];
        // }
        // // alert(target);
        // this.props.goTo(target);
    };

    render() {
        let {
            toolboxAssign,
            key,
            handleRowClick,
            toolboxData = {},
            t,
            toolBoxes,
            ...childProps
        } = this.props;
        // let groupedToolboxMeeting=groupedToolboxAssign.filter(ta=> ta.id=toolboxAssign.id);
        let nrCompleted = this.getNrCompletedInspections(toolboxAssign);

        return (
            <React.Fragment>
                {/*<TableRow {...childProps} key={this.props.key} onClick={toolboxAssign.toolboxMeeting === false ? handleRowClick : () => this.handleExpandClick(toolboxAssign)}>*/}
                {/*<TableRow {...childProps} key={this.props.key} onClick={toolboxAssign.toolboxMeeting === false ? handleRowClick : () => this.onRowClick(toolboxAssign)}>*/}
                <TableRow
                    {...childProps}
                    key={this.props.key}
                    onClick={
                        this.props.isToolboxMeetingChecked === true &&
                        toolboxAssign.toolboxMeeting === true
                            ? () => this.onRowClick(toolboxAssign)
                            : handleRowClick
                    }
                >
                    <TableCell
                        style={{
                            width: '20%',
                            borderTop: '1px solid rgba(235, 235, 235, 1)',
                        }}
                    >
                        {toolboxAssign.deployed
                            ? moment(toolboxAssign.deployed).format(
                                  t('dateTime')
                              )
                            : '-'}
                    </TableCell>
                    <TableCell
                        style={{
                            width: '10%',
                            borderTop: '1px solid rgba(235, 235, 235, 1)',
                        }}
                    >
                        {toolboxAssign.toolbox.version || '-'}
                    </TableCell>
                    {/*<TableCell*/}
                    {/*style={{width: '35%'}}>{toolboxAssign.toolboxMeeting === true ? (toolboxAssign.listParticipants.length > 1 ? toolboxAssign.listParticipants.length + " medewerkers" : toolboxAssign.listParticipants.length + " medewerker") : ((toolboxAssign.user.roles.includes("EXTERN")) ? toolboxAssign.user.fullname + " (Extern)" : toolboxAssign.user.fullname)}</TableCell>*/}
                    <TableCell style={{ width: '35%' }}>
                        {this.props.isToolboxMeetingChecked
                            ? toolboxAssign.listParticipants.length > 1
                                ? toolboxAssign.listParticipants.length +
                                  t('employees')
                                : toolboxAssign.listParticipants.length +
                                  t('employee')
                            : toolboxAssign.user.roles.includes('EXTERN')
                            ? toolboxAssign.user.fullname +
                              '(' +
                                t('external') +
                              ')'
                            : toolboxAssign.user.fullname}
                    </TableCell>
                    <TableCell style={{ width: '15%' }}>
                        {toolboxAssign.toolboxMeeting === true
                            ? t('Yes')
                            : t('No')}
                    </TableCell>
                    <TableCell style={{ width: '15%' }}>
                        {toolboxAssign.toolboxMeeting === false
                            ? toolboxAssign.result &&
                              toolboxAssign.result.complete &&
                              toolboxAssign.result.date
                                ? moment(toolboxAssign.result.date).format(
                                      t('dateTime')
                                  )
                                : '-'
                            : toolboxAssign.result &&
                              toolboxAssign.result.complete &&
                              toolboxAssign.result.date
                            ? moment(toolboxAssign.result.date).format(
                                  t('dateTime')
                              )
                            : '-'}
                    </TableCell>
                    <TableCell style={{ width: '15%' }}>
                        {this.props.isToolboxMeetingChecked === false &&
                            toolboxAssign.result &&
                            toolboxAssign.result.complete && (
                                <div
                                    className={'border-text'}
                                    style={{
                                        backgroundColor: getColorForPercentage(
                                            nrCompleted /
                                                toolboxData.toolbox.questions
                                                    .length
                                        ),
                                        borderColor: getColorForPercentage(
                                            nrCompleted /
                                                toolboxData.toolbox.questions
                                                    .length
                                        ),
                                        width: '60px',
                                    }}
                                >
                                    {toolboxAssign.result
                                        ? nrCompleted +
                                          '/' +
                                          toolboxData.toolbox.questions.length
                                        : '-'}
                                </div>
                            )}
                        {this.props.isToolboxMeetingChecked &&
                            toolboxAssign.toolboxMeeting === true &&
                            this.checkNrFilledOutToolboxes(toolboxAssign) !==
                                0 && (
                                <div
                                    className={'border-text'}
                                    style={{
                                        backgroundColor: getColorForToolboxMeetingPercentage(
                                            this.checkNrFilledOutToolboxes(
                                                toolboxAssign
                                            ) /
                                                toolboxAssign.listParticipants
                                                    .length
                                        ),
                                        borderColor: getColorForToolboxMeetingPercentage(
                                            this.checkNrFilledOutToolboxes(
                                                toolboxAssign
                                            ) /
                                                toolboxAssign.listParticipants
                                                    .length
                                        ),
                                        width: '60px',
                                    }}
                                >
                                    {this.checkNrFilledOutToolboxes(
                                        toolboxAssign
                                    ) +
                                        '/' +
                                        toolboxAssign.listParticipants.length}
                                </div>
                            )}

                        {toolboxAssign.toolboxMeeting === true
                            ? this.checkNrFilledOutToolboxes(toolboxAssign) ===
                                  0 && (
                                  <div
                                      className={'border-text'}
                                      style={{
                                          borderColor: 'Transparent',
                                          backgroundColor: 'Transparent',
                                          color: 'black',
                                      }}
                                  >
                                      {' '}
                                      -{' '}
                                  </div>
                              )
                            : (toolboxAssign.result == null ||
                                  toolboxAssign.result.complete === false) && (
                                  <div
                                      className={'border-text'}
                                      style={{
                                          borderColor: 'Transparent',
                                          backgroundColor: 'Transparent',
                                          color: 'black',
                                      }}
                                  >
                                      {' '}
                                      -{' '}
                                  </div>
                              )}
                    </TableCell>
                </TableRow>

                {toolboxAssign.toolboxMeeting === true &&
                    toolboxAssign.listParticipants.map((p) => {
                        const toolB = toolBoxes.byId[p.toolbox.id];
                        let nrCompletedP = this.getNrCompletedInspections(p);
                        return (
                            <TableRow
                                {...childProps}
                                key={p.id}
                                style={
                                    this.state.expanded === true
                                        ? {}
                                        : { display: 'none' }
                                }
                                onClick={() => this.onItemClicked(p)}
                            >
                                <TableCell
                                    style={{ width: '5%', borderBottom: '0px' }}
                                ></TableCell>
                                <TableCell
                                    style={{
                                        width: '10%',
                                        borderBottom: '0px',
                                    }}
                                ></TableCell>
                                <TableCell style={{ width: '35%' }}>
                                    {p.extern
                                        ? p.user.fullname + ' (Extern)'
                                        : p.user.fullname}
                                </TableCell>
                                <TableCell style={{ width: '15%' }}></TableCell>
                                <TableCell style={{ width: '15%' }}>
                                    {p.result && p.result.date
                                        ? moment(p.result.date).format(
                                              'L HH:mm'
                                          )
                                        : '-'}
                                </TableCell>
                                <TableCell style={{ width: '15%' }}>
                                    <div
                                        className={'border-text'}
                                        style={
                                            p.result
                                                ? {
                                                      backgroundColor: getColorForPercentage(
                                                          nrCompletedP /
                                                              toolB.toolbox
                                                                  .questions
                                                                  .length
                                                      ),
                                                      borderColor: getColorForPercentage(
                                                          nrCompletedP /
                                                              toolB.toolbox
                                                                  .questions
                                                                  .length
                                                      ),
                                                      width: '60px',
                                                  }
                                                : {
                                                      borderColor:
                                                          'Transparent',
                                                      backgroundColor:
                                                          'Transparent',
                                                      color: 'black',
                                                  }
                                        }
                                    >
                                        {p.result
                                            ? nrCompletedP +
                                              '/' +
                                              toolB.toolbox.questions.length
                                            : '-'}
                                    </div>
                                </TableCell>
                                <TableCell
                                    style={{ width: '1%', borderBottom: '0px' }}
                                ></TableCell>
                                <TableCell
                                    style={{ width: '1%', borderBottom: '0px' }}
                                ></TableCell>
                            </TableRow>
                        );
                    })}
            </React.Fragment>
        );
    }
}

var percentColors = percentColors4;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

var getColorForToolboxMeetingPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

// export default StatisticsRowToolbox;

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(StatisticsRowToolbox);

//@flow
import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import classnames from 'classnames';
import {DropTarget} from 'react-dnd';
import classes from './DragDropContainer.module.scss'

import DragDropItem from './DragDropItem';
import DropArea from './DropArea';

type Props = {
    classes: any,
    emptyText: string,
    accepts: string,
    children: any,
    connectDropTarget: Function,
    isOver: boolean,
    onAdd: Function,
    onMove: Function,
};

type State = {
    insertIndex: number,
    uuid: string
};

class DragDropContainer extends React.Component {
    props: Props;
    state: State = {
        insertIndex: -1
    };

    handleAcceptMove = (insertIndex, data) => {
        this.setState({insertIndex: insertIndex});
    };

    componentDidMount() {
        this.setState({ uuid: uuidv4() });
    }

    render() {
        const { emptyText, accepts, children, connectDropTarget, isOver, onAdd, onMove } = this.props;
        const { insertIndex, uuid } = this.state;

        const cardChildren = React.Children.toArray(children).filter(child => child && child.type === DragDropItem).map((child, i) => {
            const dragId = child.props.data.id || child.props.id;
            return React.cloneElement(child, { dragId: dragId, key: dragId, index: i, type: uuid, accepts: accepts, onMove: onMove, onAcceptMove: this.handleAcceptMove, insertIndex: insertIndex });
        });

        if (isOver && (insertIndex >= 0 || cardChildren.length === 0)) {
            cardChildren.splice(insertIndex, 0, (<DropArea index={ insertIndex } onAdd={ onAdd } type={ accepts } key="droparea"></DropArea>));
        }

        return connectDropTarget(
            <div className={ classnames( { [classes.root]: true, [classes.empty]: (cardChildren.length === 0 && accepts) } ) }>
                {
                    cardChildren
                }
                { (cardChildren.length === 0 && accepts) && (<div className={ classes.emptyText }>{ emptyText }</div>) }
            </div>
        );
    }
}

export default _.flowRight([
    DropTarget(
        (props) => {
            return props.accepts || 'UNKNOWN'
        },
        {},
        (connect, monitor) => ({
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
        })
    ),
    
])(DragDropContainer);

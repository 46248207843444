// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {FormControl, InputAdornment, TextField} from '@mui/material';
import {editVideoBlockDraft, showSnackbar} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './VideoBlockForm.module.scss';

type Props = {
    classes: any,
    contentBlock: any,
    onChange: Function,
    update: Function,
    textfull: Function,
};

type State = {
    error: boolean,
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editVideoBlockDraft(props.contentBlock.id, key, value)),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};

class VideoBlockForm extends React.Component {
    props: Props;
    state: State = {
        value: this.props.contentBlock.videoBlock.url,
    };
    timer = null;

    /**
     * Extracts a clean embed URL for a YouTube video from various URL formats.
     * Supported formats include:
     * - Standard: "https://www.youtube.com/watch?v=VIDEO_ID"
     * - Mobile: "https://m.youtube.com/watch?v=VIDEO_ID" or "https://youtu.be/VIDEO_ID"
     * - Shorts: "https://www.youtube.com/shorts/VIDEO_ID"
     *
     * @param {string} url - The original YouTube URL.
     * @returns {string} - The canonical embed URL ("https://youtu.be/VIDEO_ID") or an empty string if not recognized.
     */
    getYoutubeUrl(url) {
        if (!url || typeof url !== 'string') return '';

        // Normalize URL to lowercase for checking; however, extraction uses original URL for case-sensitive parts.
        const lowerUrl = url.toLowerCase();

        let videoId = '';

        // Try standard YouTube watch URL: https://www.youtube.com/watch?v=VIDEO_ID
        if (lowerUrl.includes("youtube.com") && lowerUrl.includes("?v=")) {
            // Use regex to capture video id after "v="
            const match = url.match(/[?&]v=([^&]+)/);
            if (match && match[1]) {
                videoId = match[1];
            }
        }
        // Mobile or short URL: https://youtu.be/VIDEO_ID
        else if (lowerUrl.includes("youtu.be/")) {
            // Extract the portion after youtu.be/
            const match = url.match(/youtu\.be\/([^?&]+)/);
            if (match && match[1]) {
                videoId = match[1];
            }
        }
        // Shorts URL: https://www.youtube.com/shorts/VIDEO_ID
        else if (lowerUrl.includes("youtube.com/shorts/")) {
            const match = url.match(/youtube\.com\/shorts\/([^?&]+)/);
            if (match && match[1]) {
                videoId = match[1];
            }
        }

        if (videoId) {
            // Construct the canonical embed URL.
            // This ensures removal of any extra query parameters like start times or session identifiers.
            return `https://youtu.be/${videoId}`;
        }
        return '';
    }

    /**
     * Extracts a clean embed URL for a Vimeo video from various URL formats.
     * Supported formats include:
     * - Standard: "https://vimeo.com/VIDEO_ID"
     * - Embed: "https://player.vimeo.com/video/VIDEO_ID"
     *
     * @param {string} url - The original Vimeo URL.
     * @returns {string} - The canonical embed URL ("https://player.vimeo.com/video/VIDEO_ID") or an empty string if not recognized.
     */
    getVimeoUrl(url) {
        if (!url || typeof url !== 'string') return '';

        // Normalize URL to lowercase for checking.
        const lowerUrl = url.toLowerCase();

        let videoId = '';

        // If URL is already in embed format, extract the video id.
        if (lowerUrl.includes("player.vimeo.com/video/")) {
            const match = url.match(/player\.vimeo\.com\/video\/([^?&/]+)/);
            if (match && match[1]) {
                videoId = match[1];
            }
        }
        // Standard Vimeo URL: https://vimeo.com/VIDEO_ID
        else if (lowerUrl.includes("vimeo.com/")) {
            // Regex that finds a sequence of digits (Vimeo IDs are numeric)
            const match = url.match(/vimeo\.com\/(\d+)/);
            if (match && match[1]) {
                videoId = match[1];
            }
        }

        if (videoId) {
            // Return the embed URL
            return `https://player.vimeo.com/video/${videoId}`;
        }
        return '';
    }

    handleFormChange = (key) => (event) => {
        const {t} = this.props;
        var value = event.target.value;
        if (key === 'url') {
            if (value.length > 255) {
                this.props.textfull(
                    t('Text cannot be longer than 255 characters')
                );
                return;
            }
            const youtubeUrl = this.getYoutubeUrl(value);
            const vimeoUrl = this.getVimeoUrl(value);
            if (youtubeUrl === '' && vimeoUrl === '') {
                this.setState({
                    error: true,
                });
            } else {
                this.setState({
                    error: false,
                });
                value = youtubeUrl !== '' ? youtubeUrl : vimeoUrl
            }
        }

        this.setState({value});

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange();
            }

            this.props.update(key, value);
        }, 400);
    };

    getInputId(path) {
        return this.props.contentBlock.id + '-videoBlock-' + path;
    }

    render() {
        const {contentBlock, t} = this.props;

        return (
            <form className={classes.root}>
                <FormControl>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id={this.getInputId('url')}

                        onChange={this.handleFormChange('url')}
                        label={t('URL')}
                        value={this.state.value}
                        endAdornment={
                            <InputAdornment
                                position="end"
                                style={{fontSize: '14px', opacity: '0.2'}}
                            >
                                {255 - contentBlock.videoBlock.url.length}
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <div
                    style={
                        this.state.error === true
                            ? {
                                color: 'red',
                                marginTop: '-15px',
                                fontSize: '12px',
                            }
                            : {display: 'none'}
                    }
                >
                    {' '}
                    {t("Only Youtube & Vimeo video links are allowed")}
                </div>
            </form>
        );
    }
}

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation()
)(VideoBlockForm);

// @flow
import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import NavigateNext from '@mui/icons-material/NavigateNext';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import './Pagination.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    // total size of dataset
    size: number,
    // size of rows per page
    pageSize: number,
    // current page
    page: number,
    onSizeChange: (value: number) => mixed,
    onNext: () => mixed,
    onPrevious: () => mixed,
};

const Pagination = (props: Props) => {
    const sizeChoices = [];
    sizeChoices.push({ id: 10 });
    sizeChoices.push({ id: 20 });
    sizeChoices.push({ id: 50 });
    sizeChoices.push({ id: 100 });

    let from = 0;
    let to = 0;
    let total = props.size;

    from = props.pageSize * (props.page - 1) + 1;
    to = props.pageSize * props.page;

    let nextdisabled = false;
    let prevdisabled = false;
    if (props.page * props.pageSize >= props.size) {
        nextdisabled = true;
        to = props.size;
    }
    if (props.page === 1) {
        prevdisabled = true;
    }

    if (total > 10) {
        return (
            <div
                className="pagination"
                style={{
                    background:
                        '-webkit-linear-gradient(bottom, white 45%, rgba(255,255,255,0.85) 100%)',
                    bottom: 0,
                    position: 'fixed',
                    width: '100%',
                    right: 0,
                    borderTop: '1px solid #e0e0e0',
                    height: '50px',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        padding: '0 0px',
                        boxSizing: 'border-box',
                        margin: '0 auto',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Menu
                        open={false}
                        className="pagination-dropdown"
                        value={props.pageSize}
                        style={{ height: '12px' }}
                        labelStyle={{
                            height: '15px',
                            lineHeight: '12px',
                            paddingRight: '24px',
                        }}
                        iconStyle={{
                            right: '-9px',
                            top: '-19px',
                            fill: '#5A5552',
                        }}
                        selectedMenuItemStyle={{ color: '#F85104' }}
                        underlineStyle={{ borderTop: 'none' }}
                        onChange={(e, i, value) => props.onSizeChange(value)}
                    >
                        <MenuItem value={10} primaryText="10" />
                        <MenuItem value={15} primaryText="15" />
                        <MenuItem value={25} primaryText="25" />
                        <MenuItem value={50} primaryText="50" />
                        <MenuItem value={100} primaryText="100" />
                    </Menu>
                    <span className="pagination-span">
                        {from}-{to} van {total}
                    </span>

                    <IconButton disabled={prevdisabled} onClick={props.onPrevious} size="large">
                        <NavigateBefore />
                    </IconButton>
                    <IconButton disabled={nextdisabled} onClick={props.onNext} size="large">
                        <NavigateNext />
                    </IconButton>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default compose(withTranslation())(Pagination);

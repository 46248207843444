// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classes from './VideoBlockPreview.module.scss'
import ReactPlayer from "react-player";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {hasVideoToolboxAddOn} from "../../actions";

type Props = {
    block: any, classes: any
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser ? store.drafts.employees.currentUser.subscription : null;

    return {
        subscription: subscription,
    };
};

class VideoToolboxBlockPreview extends React.Component {
    constructor(props) {
        super(props);
        this.parentRef = React.createRef();
        this.state = {
            parentWidth: 0
        };
    }

    componentDidMount() {
        this.updateParentWidth();
        window.addEventListener('resize', this.updateParentWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateParentWidth);
    }

    updateParentWidth = () => {
        if (this.parentRef.current) {
            const width = this.parentRef.current.getBoundingClientRect().width;
            this.setState({parentWidth: width});
        }
    };

    render() {
        const {block, t, subscription} = this.props;
        const {parentWidth} = this.state;

        return (

            <div ref={this.parentRef} className={classes.root}>
                {block.url && hasVideoToolboxAddOn(subscription) &&
                    <ReactPlayer controls url={block.url} width={parentWidth} height={parentWidth * 9 / 16}/>}
                {!hasVideoToolboxAddOn(this.props.subscription) &&
                    <div>{t('Video Toolbox subscription is not active')}</div>}
            </div>)
    }
}

export default compose(connect(mapStateToProps, null), withTranslation())(VideoToolboxBlockPreview);

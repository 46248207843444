// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Button,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Switch
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {withTranslation} from 'react-i18next';
import classes from './TableActionHeader.module.scss';
import {compose} from "recompose";
import classnames from 'classnames';

type Props = {
    title: string,
    actions: Array<any>,
    onSearchChange: string => void,
    searchPlaceholder: string,
    searchAlwaysOpen: boolean,
    defaultSearchText: string,
    groupsmode: boolean,
    showEmployeeSwitch: boolean
};

type State = {
    actionMenuOpen: boolean,
    actionAnchorElement: any,
    searchtext: string,
    searchOpen: boolean,
    showSwitch: boolean
};

const mapStateToProps = (store, props) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

class TableActionHeader extends React.Component<Props, State> {
    props: Props;
    state: State = {
        actionMenuOpen: false,
        actionAnchorElement: null,
        searchtext: this.props.defaultSearchText || "",
        searchOpen: this.props.searchAlwaysOpen || false
    };
    searchInput = null;

    handleActionsClick = e => {
        this.setState({
            actionAnchorElement: e.currentTarget,
            actionMenuOpen: true
        });
    };

    handleRequestActionMenuClose = () => {
        this.setState({
            actionMenuOpen: false
        });
    };

    handleSearchChange = event => {
        this.setState({
            searchtext: event.target.value
        });
    };

    handleSearchEvent = event => {
        event.preventDefault();
        this.props.onSearchChange(this.state.searchtext);
    }

    handleClear = event => {
        this.setState({
            searchtext: '',
            searchOpen: false
        });
        this.props.onSearchChange('');

    };

    handleOpenSearch = event => {
        this.setState({
            searchOpen: true
        });
        this.searchInput.focus();
    };

    render() {
        const {
            t,
            actions,
            title,
            editAction,
            setPayment,
            showDeletedEmployees,
            handleEmployeeSwitchChange,
            onActionButton,
            onActionText,
            onDropdown1Button,
            onDropdown2Button,
            onDropdown1Text,
            onDropdown2Text
        } = this.props;
        var tableWidth = localStorage.getItem("tableWidthSize") || "1260px"

        return (
            <div className={classes.root}>
                <div className={classes.wrapper} style={{maxWidth: tableWidth}}>
                    <div className={this.props.widemode ? classes.content : classes.content}>
                        {title}
                        {setPayment}

                        {this.props.showEmployeeSwitch &&
                            <FormControlLabel
                                style={{display: 'flex', alignItems: 'center', width: '180px'}}
                                control={
                                    <Switch
                                        checked={
                                            showDeletedEmployees
                                        }
                                        disabled={
                                            false
                                        }
                                        onChange={
                                            handleEmployeeSwitchChange
                                        }
                                        id="idShowDeleted"
                                    />
                                }
                                label={t('Show Deleted')}
                            />
                        }

                        {this.props.onSearchChange && <IconButton className={classnames({
                            [classes.searchButton]: true,
                            [classes.searchButtonOpen]: this.state.searchOpen
                        })} onClick={this.handleOpenSearch}>

                            <SearchIcon/>
                        </IconButton>}

                        {this.props.onSearchChange && <div className={classnames({
                            [classes.searchbox]: true,
                            [classes.searchboxOpen]: this.state.searchOpen
                        })}>

                            <SearchIcon className={classnames({
                                [classes.searchIcon]: true,
                                [classes.searchIconWithText]: !this.state.searchtext.length > 0
                            })}/>

                            <IconButton className={classes.clearButton} onClick={this.handleClear}>
                                <ClearIcon/>
                            </IconButton>
                            <form onSubmit={this.handleSearchEvent}>
                                <input ref={ref => {
                                    this.searchInput = ref
                                }} type="text" name="search" placeholder={this.props.searchPlaceholder}
                                       className={classes.searchbar} value={this.state.searchtext}
                                       onChange={this.handleSearchChange}/>
                            </form>

                        </div>}

                        {editAction}
                        {actions &&
                            actions.length && [
                                <IconButton className={classes.button} onClick={this.handleActionsClick} key="button">
                                    <MoreVertIcon/>
                                </IconButton>,
                                <Paper elevation={4} key="actions">
                                    <Popover
                                        anchorEl={this.state.actionAnchorElement}
                                        open={this.state.actionMenuOpen}
                                        onClose={this.handleRequestActionMenuClose}
                                    >
                                        <List>
                                            {actions.map((action, index) => (
                                                <ListItem
                                                    button
                                                    key={index}
                                                    onClick={() => {
                                                        this.handleRequestActionMenuClose();
                                                        action.callback();
                                                    }}
                                                >
                                                    <ListItemText primary={action.title}/>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Popover>
                                </Paper>
                            ]}
                        {onActionButton && <Button
                            variant="contained"
                            color="primary"
                            className={classnames({
                                [classes.actionButton]: true,
                                // [classes.content]: true
                            })}
                            onClick={onActionButton}
                            // disableElevation
                        >
                            {t(onActionText ? onActionText : 'Add')}
                        </Button>}
                        {onDropdown1Button && <Button
                            variant="contained"
                            color="primary"
                            className={classnames({
                                [classes.onDropdownButton1]: true,
                                // [classes.content]: true
                            })}
                            onClick={onDropdown1Button}
                            // disableElevation
                        >
                            {t(onDropdown1Text)}
                        </Button>}
                        {onDropdown2Button && <Button
                            variant="contained"
                            color="primary"
                            className={classnames({
                                [classes.onDropdownButton2]: true,
                                // [classes.content]: true
                            })}
                            onClick={onDropdown2Button}
                            // disableElevation
                        >
                            {t(onDropdown2Text)}
                        </Button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withTranslation())(TableActionHeader));

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Drawer,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@mui/material';
import OverviewIcon from '@mui/icons-material/ViewList';
import CloseIcon from '@mui/icons-material/Close';
import DeployIcon from '@mui/icons-material/Send';
import LockIcon from '@mui/icons-material/Lock';
import HelpIcon from '@mui/icons-material/Help';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/AddCircle';
import {getModules} from '../lib';
import classes from './WebSideMenu.module.scss';
import { getPermissions } from '../../selectors';
import {
    openDialog,
    openSideMenu,
    closeSideMenu,
    toggleSideMenu,
    logoutWebclient,
} from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    isOpen: boolean,
    close: () => void,
    open: () => void,
    navigate: (string) => void,
    logout: () => void,
};

type State = {};

const mapStateToProps = (store) => {
    return {
        isOpen: store.ui.sideMenu.isOpen,
        permissions: getPermissions(store),
        inspectionCount: store.entities.inspections.allIds.length,
        currentEmpl: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        open: () => {
            dispatch(openSideMenu());
        },
        close: () => {
            dispatch(closeSideMenu());
        },
        toggle: () => {
            dispatch(toggleSideMenu());
        },
        navigate: (newPath) => (event) => {
            dispatch(closeSideMenu());
            dispatch(push(newPath));
        },
        openDialog: (dialogName) => {
            dispatch(closeSideMenu());
            dispatch(openDialog(dialogName));
        },
        logout: () => {
            dispatch(logoutWebclient());
        },
    };
};

class SideMenu extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const {
            close,
            isOpen,
            logout,
            navigate,
            currentEmpl,
            t,
        } = this.props;

        // speakup: True: Show SpeakUp Menu Item
        // speakup: False: Show Registratie Melden
        var speakup = false;
        if (process.env.REACT_APP_PROJECT === 'janssen') {
            speakup = true;
        }
        const modules = getModules()

        return (
            <Drawer anchor="left" open={isOpen} onClose={close}>
                <div
                    style={{
                        position: 'absolute',
                        top: '10px',
                        visibility: 'hidden',
                    }}
                    id={classes.closeMenu}
                >
                    <IconButton
                         
                        className="drawer-button"
                        onClick={this.props.close}
                    >
                        <CloseIcon className="drawer-icon" />
                    </IconButton>
                </div>
                {window.innerWidth <= 768 && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '60px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                opacity: '0.5',
                                marginBottom: '5px',
                            }}
                        >
                            {t('logged in as')}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <b>{currentEmpl && currentEmpl.fullname}</b>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FED73A',
                                width: '45px',
                                height: '6px',
                                borderRadius: '10px',
                                margin: 'auto',
                                marginTop: '10px',
                            }}
                        />
                    </div>
                )}

                <div className={classes.SideMenuHeader} id="logo">
                    {window.innerWidth > 768 ? (
                        <img
                            className={classes.logo}
                            src="/assets/images/vvw.png"
                            alt="Veilig Vakwerk"
                        />
                    ) : (
                        <img
                            className={classes.logo}
                            src="/assets/images/vvw-admin-logo.svg"
                            alt="Veilig Vakwerk"
                            style={{
                                float: 'right',
                                width: '32px',
                                height: '32px',
                            }}
                        />
                    )}
                </div>
                <List className={classes.list}>
                    <ListItem button onClick={navigate('/web/opdrachten')}>
                        <ListItemIcon>
                            <OverviewIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Assignments')} />
                    </ListItem>
                </List>
                <Divider />
                <List className={classes.list} id={classes.list}>
                    <ListItem button onClick={navigate('/web/afgerond')}>
                        <ListItemIcon>
                            <DeployIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={t('Completed assignments')}
                        />
                    </ListItem>
                    { modules.inspections && (
                        <ListItem button onClick={navigate('/web/nieuweinspectie')}>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={t('Nieuwe werkplekinspectie')}
                            />
                        </ListItem>
                    )}
                    { speakup
                    ?
                        <ListItem button onClick={navigate('/web/speakup')}>
                            <ListItemIcon>
                                <AnnouncementIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('SpeakUp')} />
                        </ListItem>
                    :
                        <ListItem button onClick={navigate('/web/incident')}>
                            <ListItemIcon>
                                <AnnouncementIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Report registration')} />
                        </ListItem>
                    }
                    { modules.documents &&
                        <ListItem button onClick={navigate('/web/documents')}>
                            <ListItemIcon>
                                <InsertDriveFileIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('Documents')} />
                        </ListItem>
                    }
                </List>
                <Divider />
                <List className={classes.list} id="list">
                    <ListItem button onClick={navigate('/web/help')}>
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Help')} />
                    </ListItem>
                    <ListItem button onClick={logout}>
                        <ListItemIcon>
                            <LockIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('Logout')} />
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SideMenu);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {goBack, push} from 'connected-react-router';
import {Dialog, DialogTitle} from '@mui/material';
import {bindActionCreators} from 'redux';
import Slide from '@mui/material/Slide';
import {
    editEmployee,
    editEmployeeDraft,
    fetchSubscriptions,
    initEmployeeDraft,
    upsertEmployee,
} from '../../../actions';
import {inProgress} from '../../../selectors';
import type {TEmployee} from '../../../reducers/employeesReducer';
import EmployeeEditCard from '../EmployeeEditCard';
import {changeFactuurEmail} from '../../../lib/api';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import getRoles from "../../../selectors/getRoles";

type Props = {
    loading: boolean,
    open: boolean,
    editemployee: TEmployee,
    goBack: () => mixed,
    onEdit: (employee: TEmployee) => mixed,
    clearDiff: () => mixed,
    pageAfterSave?: string,
    display: string,
};

type State = {
    passwordConfirm: string,
    password: string,
};

const mapStateToProps = (store, props) => {
    return {
        roles: getRoles(store),
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSave: (employee) => {
            upsertEmployee(employee)(dispatch);
            dispatch(
                push(props.pageAfterSave ? props.pageAfterSave : '/medewerkers')
            );
            if (props.pageAfterSave === '/abonnement') {
                //dispatch(push(window.location.reload()))
                dispatch(fetchSubscriptions());
                //dispatch(getCurrentEmployee());
            }
        },
        onChange: (key, value) => {
            dispatch(editEmployeeDraft(props.employee.id, key, value));
        },
        initDraft: (toolbox) => {
            dispatch(initEmployeeDraft(toolbox));
        },
        editEmployee: bindActionCreators(editEmployee, dispatch),
        goBack: () => {
            dispatch(goBack());
        },
        changeFactuurEmail: (email) => {
            changeFactuurEmail('subscription', {
                ...props.subscription,
                factuurEmail: email,
            });
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EmployeeEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        passwordConfirm: '',
        password: '',
    };

    cancelEdit = () => {
        this.props.goBack();
        if (this.props.onEdit) {
            this.props.onEdit(undefined);
        }
    };

    componentDidMount() {
        if (this.props.toolbox) {
            this.props.initDraft(this.props.employee);
        }
    }

    render() {
        //Als we geen employee hebben niets renderen
        if (!this.props.editEmployee) {
            return <div/>;
        }

        const {open, t} = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.cancelEdit}
                TransitionComponent={Transition}
                className="victor"
            >
                <DialogTitle className="title">
                    {this.props.abonneehouder ? t('Edit subscriber')
                        : (this.props.editemployee && !this.props.editemployee.extern) ? t('Edit employee') : t('Edit external employee') }
                </DialogTitle>
                <EmployeeEditCard
                    hideParents={true}
                    employee={this.props.editemployee}
                    onEdit={this.props.editEmployee}
                    onSave={this.props.onSave}
                    goBack={this.props.goBack}
                    clearDiff={this.props.clearDiff}
                    display={'employee'}
                    abonneehouder={this.props.abonneehouder}
                    changeFactuurEmail={this.props.changeFactuurEmail}
                    isAdmin={this.props.roles && this.props.roles.includes('ADMIN') ? true : false}
                    factuurEmail={this.props.factuurEmail}
                />
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(EmployeeEdit);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classes from './LinkListItem.module.scss';

type Props = {};

const LinkListItem = (props: Props) => {
    return (
        <li className={ classes.root }>
            { props.linkListIndex > 0 && <span className={ classes.seperator }>|</span> }
            <a onClick={ props.onClick } target={ props.target } href={ props.href } className={ classes.link }>{ props.children }</a>
        </li>
    );
};

export default (LinkListItem);

/**
 * Created by MarkBuikema on 6/28/2017.
 */
// @flow

import {connect} from 'react-redux';
import React from 'react';
import type {StoreState} from '../../../reducers/index';
import StatisticsToolboxCard from '../StatisticsCard/StatisticsToolboxCard';
import type {Dispatch as ReduxDispatch} from 'redux';
import {bindActionCreators} from 'redux';
import {List, Map} from 'immutable';
import {push} from 'connected-react-router';
import {
    clearAllFilters,
    filterAfgerond,
    filterCategory,
    filterIsNoToolboxMeetingChecked,
    filterIsToolboxMeetingChecked,
    filterPeriodFrom,
    filterPeriodMonth,
    filterPeriodTill,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    filterSector,
    getCurrentEmployee,
    pushCrumbPath,
    setCrumbPath,
    setViewkey,
} from '../../../actions';
import type {TStateStatisticsChartData} from '../../../reducers/StatisticsReducer';
import {loadToolboxData} from '../../../actions/statisticsActions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import {getCurrentFilterSet} from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListToolboxes from '../StatisticsList/StatisticsListToolboxes';
import {
    getFilteredAndGroupedToolboxAssigns,
    getFilteredAndGroupedToolboxMeetingsAssigns,
    getFilteredToolboxAssigns,
} from '../../../selectors';
import {fetchCSV, fetchPDF} from '../../../lib/api';
import {Menu, MenuItem} from '@mui/material';
import getRoles from '../../../selectors/getRoles';
import FilterHeader from '../../FilterHeader';
import $ from 'jquery';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import 'moment/locale/nl';
import i18n from 'i18next';

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {
    setViewkey: () => mixed,
    chartData: TStateStatisticsChartData,
    loadChartData: Function,
    params: any,
    periodType: string,
    periodYear: number,
    periodMonth: number,
    periodWeek: number,
    periodFrom: any,
    periodTill: any,
    isToolboxMeetingChecked: boolean,
    isNoToolboxMeetingChecked: boolean,
    period?: string,
    progtypes: Map<string, boolean>,
    minDuration: number,
    maxDuration: number,
    distributorFilters: Map<number, boolean>,
    deviceFilters: Map<string, boolean>,
    loadDistributors: () => mixed,
    loadDevices: () => mixed,
    loadProgtypes: () => mixed,
    goTo: (path) => mixed,
    filterPeriodType: Function,
    filterPeriodYear: Function,
    filterPeriodMonth: Function,
    filterPeriodWeek: Function,
    filterIsToolboxMeetingChecked: Function,
    filterIsNoToolboxMeetingChecked: Function,
    filterSector: Function,
    filterCategory: Function,
    filterAfgerond: Function,
    clearAllFilters: Function,
    toolboxAssigns: Array<any>,
    toolboxMeetingsAssigns: Array<any>,
    allToolboxAssigns: Array<any>,
    allToolboxMeetingAssigns: Array<any>,
    sectorFilter: number,
    categoryFilter: String,
    afgerondFilter: number,
};

type State = {
    openSector: boolean,
    openCategory: boolean,
    openAfgerond: boolean,
    openMeeting: boolean,
    anchorEl?: any,
    exportOpen: boolean,
};

const mapStateToProps = (state: StoreState) => {
    let filters = getCurrentFilterSet(state.filters);
    const subscription = state.drafts.employees.currentUser
        ? state.drafts.employees.currentUser.subscription
        : null;
    const sectors = state.entities.sectors;
    const categories = state.entities.categories;

    return {
        chartData: state.statistics.chartData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        isToolboxMeetingChecked: filters.isToolboxMeetingChecked,
        isNoToolboxMeetingChecked: filters.isNoToolboxMeetingChecked,
        sectorFilter: filters.sector,
        categoryFilter: filters.category,
        afgerondFilter: filters.afgerond,
        minDuration: filters.minduration,
        maxDuration: filters.maxduration,
        toolboxAssigns: getFilteredAndGroupedToolboxAssigns(state),
        allToolboxMeetingAssigns: getFilteredAndGroupedToolboxMeetingsAssigns(
            state
        ),
        allToolboxAssigns: getFilteredToolboxAssigns(state),
        roles: getRoles(state),
        subscription: subscription,
        sectors: sectors.allIds.map((id) => sectors.byId[id]),
        categories: categories.allIds.map((id) => categories.byId[id]),
        toolboxMeetingsAssigns: getFilteredAndGroupedToolboxMeetingsAssigns(
            state
        ).filter((toolbox) =>
            getRoles(state).includes('CUMELA') ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.cumela ===
                true) ||
            (state.drafts.employees.currentUser &&
                state.drafts.employees.currentUser.subscription.caoLeo === true)
                ? toolbox
                : toolbox.toolbox.source !== 'cumela'
        ),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadChartData: bindActionCreators(loadToolboxData, dispatch),
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        filterIsToolboxMeetingChecked: bindActionCreators(
            filterIsToolboxMeetingChecked,
            dispatch
        ),
        filterIsNoToolboxMeetingChecked: bindActionCreators(
            filterIsNoToolboxMeetingChecked,
            dispatch
        ),
        filterSector: bindActionCreators(filterSector, dispatch),
        filterCategory: bindActionCreators(filterCategory, dispatch),
        filterAfgerond: bindActionCreators(filterAfgerond, dispatch),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (breadCrumb) => {
            dispatch(setCrumbPath(breadCrumb));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
    };
};

let categories = [];

class StatisticsToolboxOverview extends React.Component<Props, State> {
    props: Props;
    state: {
        refreshed: false,
        toolboxFilter: 0,
        openSector: false,
        openCategory: false,
        openAfgerond: false,
        openMeeting: false,
        exportOpen: false,
        anchorEl: 0,
    };

    componentDidMount() {
        moment.locale(i18n.t('LOCALE'));
        //set view key
        this.props.setViewkey();
        this.props.setCrumbPath({title: 'Rapportages', crumbs: [{subTitle: i18n.t('Toolboxes'),}]});

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        if (query.tm) {
            this.props.filterIsToolboxMeetingChecked(query.tm === 'true');
        }

        this.onFilterChanged();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.periodType !== prevProps.periodType ||
            this.props.periodYear !== prevProps.periodYear ||
            this.props.periodMonth !== prevProps.periodMonth ||
            this.props.periodWeek !== prevProps.periodWeek ||
            this.props.periodFrom !== prevProps.periodFrom ||
            this.props.periodTill !== prevProps.periodTill ||
            this.props.minDuration !== prevProps.minDuration ||
            this.props.maxDuration !== prevProps.maxDuration ||
            this.props.isToolboxMeetingChecked !== prevProps.isToolboxMeetingChecked ||
            this.props.isNoToolboxMeetingChecked !== prevProps.isNoToolboxMeetingChecked ||
            this.props.sectorFilter !== prevProps.sectorFilter ||
            this.props.categoryFilter !== prevProps.categoryFilter ||
            this.props.afgerondFilter !== prevProps.afgerondFilter ||
            (this.props.toolboxAssigns.length !== prevProps.toolboxAssigns.length)
        ) {
            this.onFilterChanged();
        }
    }

    onFilterChanged = () => {
        let props = this.props;

        let path: string =
            this.props.location.pathname + '?year=' + props.periodYear;

        let period = moment().year(props.periodYear);
        let periodTill = '';
        switch (props.periodType) {
            case 'month':
                period = period.month(props.periodMonth);
                path += '&month=' + (props.periodMonth + 1);
                break;
            case 'week':
                let locale: string =
                    window.navigator.userLanguage || window.navigator.language;
                period = period
                    .week(props.periodWeek)
                    .locale(locale)
                    .weekday(0);
                path += '&week=' + props.periodWeek;
                break;
            case 'fromtill':
                //TODO: Format this
                //path = this.props.location.pathname + '?from=' + props.periodFrom + '&till=' + props.periodTill;
                if (props.periodFrom && props.periodTill) {
                    period = moment(props.periodFrom);
                    periodTill = moment(props.periodTill);
                }

                break;
            default:
                break;
        }

        if (props.periodType !== 'fromtill') {
            let periodString = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            let tm = this.props ? this.props.isToolboxMeetingChecked : false;
            let noTm = this.props
                ? this.props.isNoToolboxMeetingChecked
                : false;
            this.props.goTo(
                path + '&tm=' + tm + '&noTm=' + noTm + this.props.location.hash
            );

            // execute chart api call
            this.props.loadChartData(
                props.periodType,
                periodString,
                null,
                null,
                tm,
                noTm,
                props.sectorFilter,
                props.categoryFilter,
                props.afgerondFilter
            );
        } else if (period && periodTill) {
            let periodFrom = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            let periodEnd = periodTill.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            let tm = this.props ? this.props.isToolboxMeetingChecked : false;
            let noTm = this.props
                ? this.props.isNoToolboxMeetingChecked
                : false;
            this.props.goTo(
                path + '&tm=' + tm + '&noTm=' + noTm + this.props.location.hash
            );

            // execute chart api call
            this.props.loadChartData(
                props.periodType,
                periodFrom,
                periodEnd,
                null,
                tm,
                noTm,
                props.sectorFilter,
                props.categoryFilter,
                props.afgerondFilter
            );
        }
    };

    onItemClicked = (clickedToolbox, isToolboxMeetingChecked) => {
        let target = 'rapportages/toolbox/' + clickedToolbox.toolbox.id;

        let url = window.location.href;
        let parts = url.split('?');
        if (parts.length > 1) {
            target += '?' + parts[1];
        }
        this.props.goTo(target);

    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadCsv = () => {
        let toolboxAssigns = null;
        if (
            this.props.isToolboxMeetingChecked ||
            this.props.isNoToolboxMeetingChecked
        ) {
            toolboxAssigns = this.props.toolboxMeetingsAssigns;
        } else {
            toolboxAssigns = this.props.toolboxAssigns
                .filter((toolbox) =>
                    this.props.roles.includes('CUMELA') ||
                    (this.props.subscription &&
                        this.props.subscription.cumela === true) ||
                    (this.props.subscription &&
                        this.props.subscription.caoLeo === true)
                        ? toolbox
                        : toolbox.toolbox.source !== 'cumela'
                )
                .filter((ta) =>
                    this.props.isToolboxMeetingChecked
                        ? ta.toolboxMeeting === true
                        : true
                )
                .filter((ta) =>
                    this.props.isNoToolboxMeetingChecked
                        ? ta.toolboxMeeting === false
                        : true
                );
        }

        let assigns = List(
            toolboxAssigns !== undefined &&
            toolboxAssigns
                .filter((toolbox) =>
                    this.state && this.state.searchtext
                        ? toolbox.toolbox.title
                        .toLowerCase()
                        .indexOf(
                            this.state.searchtext.toLowerCase()
                        ) > -1
                        : true
                )
                .filter((toolbox) =>
                    this.props.sectorFilter && this.props.sectorFilter !== 0
                        ? toolbox.toolbox.sectors.includes(
                            this.props.sectorFilter
                        )
                        : true
                )
                .filter((toolbox) =>
                    this.props.categoryFilter &&
                    this.props.categoryFilter !== 'all'
                        ? toolbox.toolbox.category ===
                        this.props.categoryFilter
                        : true
                )
                .filter((toolbox) =>
                    this.props.afgerondFilter &&
                    this.props.afgerondFilter !== 0
                        ? this.props.afgerondFilter === 1
                            ? toolbox.finished !== 0
                            : toolbox.finished !== toolbox.employeeCount
                        : true
                )
                .map((item) =>
                    Object.assign(item, {
                        totalSend: item.assignList.length,
                        totalEmployees: item.employeeCount,
                        totalCompleted: item.finished,
                        individualSent: this.props.isToolboxMeetingChecked
                            ? '-'
                            : this.returnIndividualToolbox(item) > 0
                                ? 'Ja'
                                : 'Nee',
                        nrToolboxMeetings: this.props
                            .isNoToolboxMeetingChecked
                            ? '-'
                            : this.countNrToolboxMeetings(item),
                    })
                )
        );

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOX_RAPPORTAGE',
            options: {},
            data: assigns,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', i18n.t('toolbox_rapportage.csv'));
                link.click();
            })
            .catch((err) => console.log(err));
    };

    getList = () => {
        return (
            <StatisticsListToolboxes
                isToolboxMeetingChecked={
                    this.props ? this.props.isToolboxMeetingChecked : false
                }
                isNoToolboxMeetingChecked={
                    this.props ? this.props.isNoToolboxMeetingChecked : false
                }
                onItemClick={this.onItemClicked}
                searchtext={this.state ? this.state.searchtext : ''}
                sector={this.props.sectorFilter}
                category={this.props.categoryFilter}
                afgerond={this.props.afgerondFilter}
            />
        );
    };

    handleToolboxFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({toolboxFilter: event.target.value});

        if (event.target.value === 1) {
            this.setState(
                {isToolboxMeetingChecked: true},
                this.onFilterChanged
            );
            this.setState(
                {isNoToolboxMeetingChecked: false},
                this.onFilterChanged
            );
            this.props.filterIsNoToolboxMeetingChecked(false);
            this.props.filterIsToolboxMeetingChecked(true);
        } else if (event.target.value === 2) {
            this.setState(
                {isNoToolboxMeetingChecked: true},
                this.onFilterChanged
            );
            this.setState(
                {isToolboxMeetingChecked: false},
                this.onFilterChanged
            );
            this.props.filterIsToolboxMeetingChecked(false);
            this.props.filterIsNoToolboxMeetingChecked(true);
        } else {
            this.setState(
                {isNoToolboxMeetingChecked: false},
                this.onFilterChanged
            );
            this.setState(
                {isToolboxMeetingChecked: false},
                this.onFilterChanged
            );
            this.props.filterIsToolboxMeetingChecked(false);
            this.props.filterIsNoToolboxMeetingChecked(false);
        }
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openMeeting: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSector: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openCategory: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openAfgerond: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openCategory: false,
            openSector: false,
            openAfgerond: false,
            openMeeting: false,
        });
        this.setState(newState);
    };

    handleToolboxSectorFilterChange = (event) => {
        this.handleRequestClose();

        this.setState(
            {sectorFilter: event.target.value},
            this.onFilterChanged
        );
        this.props.filterSector(event.target.value);
    };

    handleToolboxCategoryFilterChange = (event) => {
        this.handleRequestClose();

        let cat = 'all';
        if (event.target.value === -1) {
            cat = 'all';
        } else {
            cat = categories[event.target.value].id;
        }

        this.setState({categoryFilter: cat}, this.onFilterChanged);
        this.props.filterCategory(cat);
    };

    handleToolboxAfgerondFilterChange = (event) => {
        this.handleRequestClose();

        this.setState(
            {afgerondFilter: event.target.value},
            this.onFilterChanged
        );
        this.props.filterAfgerond(event.target.value);
    };

    getFilterSectorValue(sectorfilter) {
        const {t} = this.props;
        let filteredSector = t('Alles');
        if (sectorfilter === 0) {
            return filteredSector;
        }

        this.props.sectors.forEach((sector) => {
            if (sector.id === sectorfilter) {
                filteredSector = sector.name;
            }
        });

        return filteredSector;
    }

    getFilterCategoryValue(catfilter) {
        const {t} = this.props;
        let filteredCat = t('Alles');
        if (catfilter === 'all') {
            return filteredCat;
        }

        this.props.categories.forEach((cat) => {
            if (cat.id === catfilter) {
                filteredCat = cat.name;
            }
        });

        return filteredCat;
    }

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    getFilterBar = () => {
        const {t} = this.props;
        let sectorIds = [];
        let sectors = [];

        let catIds = [];
        categories = [];

        this.props.toolboxAssigns.forEach((assign) => {
            if (assign.toolbox.sectors.length > 0) {
                assign.toolbox.sectors.forEach((sector) => {
                    if (!sectorIds.includes(sector)) {
                        sectorIds.push(sector);
                    }
                });
            }
            if (assign.toolbox.category.length > 0) {
                if (!catIds.includes(assign.toolbox.category)) {
                    catIds.push(assign.toolbox.category);
                }
            }
        });

        sectorIds.forEach((sector) => {
            this.props.sectors.forEach((sec) => {
                if (sector === sec.id) {
                    sectors.push(sec);
                }
            });
        });

        catIds.forEach((cat) => {
            this.props.categories.forEach((cat2) => {
                if (cat === cat2.id) {
                    categories.push(cat2);
                }
            });
        });

        let catIndex = 0;

        return (
            <div className="filterbar" style={{paddingRight: '500px'}}>
                <PeriodFilterBlock/>

                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={t('Show')}
                            filterValue={
                                this.props.isToolboxMeetingChecked
                                    ? t('Toolbox meeting')
                                    : this.props.isNoToolboxMeetingChecked
                                        ? t('Toolbox')
                                        : t('Everything')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openMeeting : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.props.isToolboxMeetingChecked
                            ? 1
                            : this.props.isNoToolboxMeetingChecked
                                ? 2
                                : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={1}
                    >
                        {t('Toolbox meeting')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxFilterChange}
                        value={2}
                    >
                        {t('Toolbox')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={'Sector'}
                            filterValue={this.getFilterSectorValue(
                                this.props.sectorFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSector : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.props.sectorFilter ? this.props.sectorFilter : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxSectorFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {sectors.map((sector) => {
                        return (
                            <MenuItem
                                onClick={this.handleToolboxSectorFilterChange}
                                key={sector.id}
                                value={sector.id}
                            >
                                {sector.subscriptionHolder === null ? t(sector.name) : sector.name}
                            </MenuItem>
                        );
                    })}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={t('Category')}
                            filterValue={this.getFilterCategoryValue(
                                this.props.categoryFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openCategory : false}
                    onClose={this.handleRequestClose}
                    value={this.props.categoryFilter}
                    onChange={this.handleToolboxCategoryFilterChange}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxCategoryFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {categories.map((category, index) => (
                        <MenuItem
                            key={index}
                            onClick={this.handleToolboxCategoryFilterChange}
                            value={catIndex++}
                        >
                            {t(category.locKey || category.name)}
                        </MenuItem>
                    ))}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={t('Completed')}
                            filterValue={
                                this.props.afgerondFilter === 0
                                    ? t('Everything')
                                    : this.props.afgerondFilter === 1
                                        ? t('Yes')
                                        : t('No')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openAfgerond : false}
                    onClose={this.handleRequestClose}
                    value={this.props.afgerondFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        style={{width: '130px'}}
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={1}
                    >
                        {t('Yes')}
                    </MenuItem>
                    <MenuItem
                        onClick={this.handleToolboxAfgerondFilterChange}
                        value={2}
                    >
                        {t('No')}
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    returnIndividualToolbox(toolboxAssign) {
        let individuals = 0;
        toolboxAssign.assignList.forEach((ta) => {
            if (ta.toolboxMeeting === false) {
                individuals++;
            }
        });
        return individuals;
    }

    countNrToolboxMeetings(toolboxAssign) {
        let countedMeetings = [];
        toolboxAssign.assignList.forEach((ta) => {
            if (ta.toolboxMeeting === true) {
                let isNew = true;
                if (countedMeetings.length > 0) {
                    countedMeetings.forEach((meeting) => {
                        if (
                            ta.toolboxMeetingChairman ===
                            meeting.toolboxMeetingChairman &&
                            this.checkSameArray(
                                ta.toolboxMeetingSubjects,
                                meeting.toolboxMeetingSubjects
                            ) &&
                            ta.toolboxMeetingDate ===
                            meeting.toolboxMeetingDate &&
                            ta.toolboxMeetingLocation ===
                            meeting.toolboxMeetingLocation
                        ) {
                            isNew = false;
                        }
                    });
                }
                if (isNew) {
                    countedMeetings.push({
                        toolboxMeetingChairman: ta.toolboxMeetingChairman,
                        toolboxMeetingSubjects: ta.toolboxMeetingSubjects,
                        toolboxMeetingDate: ta.toolboxMeetingDate,
                        toolboxMeetingLocation: ta.toolboxMeetingLocation,
                    });
                }
            }
        });
        return countedMeetings.length;
    }

    getPDF = () => {
        const {t} = this.props;
        let toolboxAssigns = null;
        if (
            this.props.isToolboxMeetingChecked ||
            this.props.isNoToolboxMeetingChecked
        ) {
            toolboxAssigns = this.props.toolboxMeetingsAssigns;
        } else {
            toolboxAssigns = this.props.toolboxAssigns
                .filter((toolbox) =>
                    this.props.roles.includes('CUMELA') ||
                    (this.props.subscription &&
                        this.props.subscription.cumela === true) ||
                    (this.props.subscription &&
                        this.props.subscription.caoLeo === true)
                        ? toolbox
                        : toolbox.toolbox.source !== 'cumela'
                )
                .filter((ta) =>
                    this.props.isToolboxMeetingChecked
                        ? ta.toolboxMeeting === true
                        : true
                )
                .filter((ta) =>
                    this.props.isNoToolboxMeetingChecked
                        ? ta.toolboxMeeting === false
                        : true
                );
        }

        //let toolboxAssigns= this.props.toolboxAssigns.filter(toolbox => this.props.roles.includes('CUMELA') || (this.props.subscription && this.props.subscription.cumela === true) || (this.props.subscription && this.props.subscription.caoLeo === true) ? toolbox : toolbox.toolbox.source !== 'cumela').filter(ta => this.props.isToolboxMeetingChecked ? ta.toolboxMeeting===true : true).filter(ta => this.props.isNoToolboxMeetingChecked ? ta.toolboxMeeting===false: true);

        let assigns = List(
            toolboxAssigns !== undefined &&
            toolboxAssigns
                .filter((toolbox) =>
                    this.state && this.state.searchtext
                        ? toolbox.toolbox.title
                        .toLowerCase()
                        .indexOf(
                            this.state.searchtext.toLowerCase()
                        ) > -1
                        : true
                )
                .filter((toolbox) =>
                    this.props.sectorFilter && this.props.sectorFilter !== 0
                        ? toolbox.toolbox.sectors.includes(
                            this.props.sectorFilter
                        )
                        : true
                )
                .filter((toolbox) =>
                    this.props.categoryFilter &&
                    this.props.categoryFilter !== 'all'
                        ? toolbox.toolbox.category ===
                        this.props.categoryFilter
                        : true
                )
                .filter((toolbox) =>
                    this.props.afgerondFilter &&
                    this.props.afgerondFilter !== 0
                        ? this.props.afgerondFilter === 1
                            ? toolbox.finished !== 0
                            : toolbox.finished !== toolbox.employeeCount
                        : true
                )
                .map((item) =>
                    Object.assign(item, {
                        totalSend: item.assignList.length,
                        totalEmployees: item.employeeCount,
                        totalCompleted: item.finished,
                        individualSent: this.props.isToolboxMeetingChecked
                            ? '-'
                            : this.returnIndividualToolbox(item) > 0
                                ? t('Yes')
                                : t('No'),
                        nrToolboxMeetings: this.props
                            .isNoToolboxMeetingChecked
                            ? '-'
                            : this.countToolboxMeetings(item),
                    })
                )
        );

        let data = [];

        assigns.forEach((item) => {
            const {t} = this.props;

            var firstDeployed = Date();
            item.assignList.forEach((record) => {
                if (record.deployed < firstDeployed) {
                    firstDeployed = record.deployed;
                }
            });

            data.push({
                toolbox: {
                    title: item.toolbox.title,
                },
                firstDeployed: firstDeployed,
                totalSend: item.assignList.length,
                totalEmployees: item.employeeCount,
                totalCompleted: item.finished,
                individualSent: this.props.isToolboxMeetingChecked
                    ? '-'
                    : this.returnIndividualToolbox(item) > 0
                        ? t('Yes')
                        : t('No'),
                nrToolboxMeetings: this.props.isNoToolboxMeetingChecked
                    ? '-'
                    : this.countNrToolboxMeetings(item).toString(),
            });
        });

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOX_RAPPORTAGE',
            options: {},
            // data: this.props.toolboxAssigns.filter(toolbox => this.props.roles.includes('CUMELA') || (this.props.subscription && this.props.subscription.cumela === true) || (this.props.subscription && this.props.subscription.caoLeo === true) ? toolbox : toolbox.toolbox.source !== 'cumela').filter(ta => this.props.isToolboxMeetingChecked ? ta.toolboxMeeting===true : true).filter(ta => this.props.isNoToolboxMeetingChecked ? ta.toolboxMeeting===false: true),
            data: data,
            info: {
                chartData: this.props.chartData,
                roles: this.props.roles,
                isCumela: this.props.subscription.cumela,
                isCaoLeo: this.props.subscription.caoLeo,
                periodType: this.props.periodType,
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
                title: this.props.isToolboxMeetingChecked
                    ? i18n.t('Toolbox meeting report')
                    : i18n.t('Toolbox report'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', i18n.t('toolboxrapportage.pdf'));
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    countToolboxMeetings() {
        let countedMeetings = [];
        this.props.allToolboxAssigns.forEach((ta) => {
            if (ta.toolboxMeeting === true) {
                let isNew = true;
                if (countedMeetings.length > 0) {
                    countedMeetings.forEach((meeting) => {
                        if (
                            ta.toolboxMeetingChairman ===
                            meeting.toolboxMeetingChairman &&
                            this.checkSameArray(
                                ta.toolboxMeetingSubjects,
                                meeting.toolboxMeetingSubjects
                            ) &&
                            ta.toolboxMeetingDate ===
                            meeting.toolboxMeetingDate &&
                            ta.toolboxMeetingLocation ===
                            meeting.toolboxMeetingLocation
                        ) {
                            isNew = false;
                        }
                    });
                }
                if (isNew) {
                    countedMeetings.push({
                        toolboxMeetingChairman: ta.toolboxMeetingChairman,
                        toolboxMeetingSubjects: ta.toolboxMeetingSubjects,
                        toolboxMeetingDate: ta.toolboxMeetingDate,
                        toolboxMeetingLocation: ta.toolboxMeetingLocation,
                    });
                }
            }
        });
        return countedMeetings.length;
    }

    checkSameArray(a, b) {
        var i = a.length;
        if (i !== b.length) return false;
        while (i--) {
            if (a[i].subject !== b[i].subject) return false;
        }
        return true;
    }

    render() {
        const {t} = this.props;
        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                widemode={true}
                searchPlaceholder={t('Search toolboxes')}
                searchAlwaysOpen={true}
                onActionButton={this.toggleExport}
                onActionText={this.state && this.state.exportOpen ? 'Close' : 'Download'}
                onDropdown1Button={this.state && this.state.exportOpen ? this.getPDF : null}
                onDropdown2Button={this.state && this.state.exportOpen ? this.downloadCsv : null}
                onDropdown1Text="PDF"
                onDropdown2Text="CSV"
            />
        );
        return (
            <div className="Statistics">
                <AppModule
                    loading={this.props.chartData.toolboxBarData ? false : true}
                    prepend={tableActionHeader}
                    hasTabs
                >
                    <StatisticsToolboxCard
                        toolboxBarData={
                            this.props.chartData.toolboxBarData
                                ? this.props.chartData.toolboxBarData
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList()}
                        totalToolboxesSend={
                            this.props &&
                            this.props.chartData.totalToolboxesSend
                        }
                        totalToolboxesCompleted={
                            this.props &&
                            this.props.chartData.totalToolboxCompleted
                        }
                        totalToolboxesNotCompleted={
                            this.props &&
                            this.props.chartData.totalToolboxNotCompleted
                        }
                    />
                    {/*<div*/}
                    {/*    style={{*/}
                    {/*        position: 'fixed',*/}
                    {/*        top: '84px',*/}
                    {/*        right: '36px',*/}
                    {/*        zIndex: 1500,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <div>*/}
                    {/*        <Fab*/}
                    {/*             */}
                    {/*            onClick={this.toggleExport}*/}
                    {/*        >*/}
                    {/*            {this.state && this.state.exportOpen ? (*/}
                    {/*                <CloseIcon />*/}
                    {/*            ) : (*/}
                    {/*                <DownloadIcon />*/}
                    {/*            )}*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={*/}
                    {/*            this.state && this.state.exportOpen*/}
                    {/*                ? {*/}
                    {/*                      marginTop: '20px',*/}
                    {/*                      marginLeft: '8px',*/}
                    {/*                      visibility: 'visible',*/}
                    {/*                      transition: 'margin-top 300ms',*/}
                    {/*                  }*/}
                    {/*                : {*/}
                    {/*                      marginTop: '0px',*/}
                    {/*                      marginLeft: '8px',*/}
                    {/*                      visibility: 'hidden',*/}
                    {/*                      transition: 'margin-top 300ms',*/}
                    {/*                  }*/}
                    {/*        }*/}
                    {/*        className={'tooltip'}*/}
                    {/*    >*/}
                    {/*        <span*/}
                    {/*            className={*/}
                    {/*                this.state && this.state.exportOpen*/}
                    {/*                    ? 'tooltiptextvisible'*/}
                    {/*                    : 'tooltiptext'*/}
                    {/*            }*/}
                    {/*            style={{ top: '87px', right: '60px' }}*/}
                    {/*        >*/}
                    {/*            {t('Download PDF file')}*/}
                    {/*        </span>*/}
                    {/*        <Fab*/}
                    {/*            color="primary"*/}
                    {/*            */}
                    {/*            onClick={this.getPDF}*/}
                    {/*        >*/}
                    {/*            <InsertIcon />*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        style={*/}
                    {/*            this.state && this.state.exportOpen*/}
                    {/*                ? {*/}
                    {/*                      marginTop: '15px',*/}
                    {/*                      marginLeft: '8px',*/}
                    {/*                      visibility: 'visible',*/}
                    {/*                      transition: 'margin-top 300ms',*/}
                    {/*                  }*/}
                    {/*                : {*/}
                    {/*                      marginTop: '0px',*/}
                    {/*                      marginLeft: '8px',*/}
                    {/*                      visibility: 'hidden',*/}
                    {/*                      transition: 'margin-top 300ms',*/}
                    {/*                  }*/}
                    {/*        }*/}
                    {/*        className={'tooltip'}*/}
                    {/*    >*/}
                    {/*        <span*/}
                    {/*            className={*/}
                    {/*                this.state && this.state.exportOpen*/}
                    {/*                    ? 'tooltiptextvisible'*/}
                    {/*                    : 'tooltiptext'*/}
                    {/*            }*/}
                    {/*            style={{ top: '145px', right: '60px' }}*/}
                    {/*        >*/}
                    {/*            {t('Download CSV file')}*/}
                    {/*        </span>*/}
                    {/*        <Fab*/}
                    {/*            color="primary"*/}
                    {/*            */}
                    {/*            onClick={this.downloadCsv}*/}
                    {/*        >*/}
                    {/*            <DescIcon />*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsToolboxOverview);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import classes from './TitleDescription.module.scss'

type Props = {
    width: any
};

type State = {};

class TitleDescription extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { itemTitle, itemDescription, width, children, ...rest } = this.props;
        return (
            <div {...rest}>
                {itemTitle && 
                    <div className={classes.itemTitle}>{itemTitle}</div>
                }
                <div className={classes.itemDescription} style={{whiteSpace: 'pre-line'}}>{itemDescription}</div>
                <div>{children}</div>
            </div>
        );
    }
}

export default (TitleDescription);

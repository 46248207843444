// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import InspectionCategory from './InspectionCategory/InspectionCategory';
import Viewer from 'react-viewer';
import './InspectionResultsView.css';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './InspectionResultsView.module.scss';

type Props = {
    inspection?: any,
    inspectionAssign?: any,
};

type State = {};

class InspectionResultsView extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { inspectionAssign, t } = this.props;

        if (
            !inspectionAssign ||
            !inspectionAssign.inspection.inspection.categories
        ) {
            return <div />;
        }

        let categories = inspectionAssign.inspection.inspection.categories;
        let globalRemark = '';

        if (
            inspectionAssign.result &&
            inspectionAssign.result.results &&
            inspectionAssign.result.complete
        ) {
            const results = inspectionAssign.result.results;

            globalRemark = inspectionAssign.result.remarks;

            categories = categories.map((category) => {
                category.items = category.items.map((item) => {
                    results.forEach((result) => {
                        if (result.id === item.id) item.result = result;
                    });
                    return item;
                });
                return category;
            });
        } else {
            return (
                <div>
                    <div className="container emptyState">
                        {t('No results yet for this workplace inspection')}&nbsp;
                        {t('Shown when workplace inspection is done')}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="container">
                    {categories.map((category, index) => (
                        <InspectionCategory
                            key={index}
                            inspectionCategory={category}
                        />
                    ))}
                </div>

                <h2
                    style={
                        globalRemark &&
                        (globalRemark.photos.length !== 0 || globalRemark.text)
                            ? {}
                            : { display: 'none' }
                    }
                >
                    {t('Workplace inspection comment')}
                </h2>
                <div className="photos">
                    {globalRemark ? (
                        globalRemark.unsafe === false ? (
                            <div
                                style={{
                                    marginTop: '8px',
                                    marginBottom: '16px',
                                }}
                            >
                                <span className={classes.inspectionGlobalRemark}>{globalRemark.text}</span>
                            </div>
                        ) : (
                            <div
                                style={{
                                    marginTop: '8px',
                                    marginBottom: '16px',
                                    color: 'red',
                                }}
                            >
                                {t('Unsafe situation')}: {globalRemark.text}
                            </div>
                        )
                    ) : (
                        t('No comment')
                    )}
                    <div
                        className="image-container"
                        style={{ marginTop: '-8px', marginLeft: '-8px' }}
                    >
                        {globalRemark &&
                            globalRemark.photos &&
                            globalRemark.photos.map((photo) => (
                                <div>
                                    <img
                                        alt="Remark"
                                        className={classes.preview}
                                        onClick={() => {
                                            this.setState({
                                                imageVisible: true,
                                                activePhotoId: photo.id,
                                            });
                                        }}
                                        src={photo.url}
                                    />
                                </div>
                            ))}

                        {globalRemark && globalRemark.photos && (
                            <Viewer
                                visible={this.state.imageVisible}
                                activeIndex={globalRemark.photos.findIndex(
                                    (p) => p.id === this.state.activePhotoId
                                )}
                                drag={false}
                                scalable={false}
                                zIndex={1305}
                                downloadable={true}
                                onClose={() => {
                                    this.setState({ imageVisible: false });
                                }}
                                onMaskClick={() => {
                                    this.setState({ imageVisible: false });
                                }}
                                images={globalRemark.photos.map((photo) => {
                                    return {
                                        src: photo.url,
                                        downloadUrl: photo.url + '.jpg',
                                    };
                                })}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    
    withTranslation()
)(InspectionResultsView);

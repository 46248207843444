// @flow
import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import moment from 'moment';
import {percentColors2} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    inspectionAssign: any,
};

const StatisticsRowEmployee = (props: Props, context: Object) => {
    let { inspectionAssign, handleRowClick, t, ...childProps } = props;

    let item = inspectionAssign.inspection || inspectionAssign.toolbox;
    let totalCorrect = 0;
    let unsafe = false;

    inspectionAssign.toolbox &&
        inspectionAssign.result &&
        inspectionAssign.result.answers.forEach((answer) => {
            if (answer.correct) {
                totalCorrect++;
            }
        });

    if (inspectionAssign.result != null && inspectionAssign.result.unsafe) {
        unsafe = true;
    }

    return (
        <TableRow
            {...childProps}
            className="selectable"
            onClick={handleRowClick}
        >
            <TableCell style={{ width: '15%' }}>
                {inspectionAssign.inspectionId
                    ? t('Workplace inspection')
                    : inspectionAssign.toolboxMeeting === true
                        ? t('Toolbox meeting')
                    : t('Toolbox')}
            </TableCell>
            <TableCell style={{ width: '30%', wordBreak: 'break-all' }}>
                {item && item.title}
            </TableCell>
            <TableCell style={{ width: '3%' }}>
                {item && item.version}
            </TableCell>
            <TableCell style={{ width: '15%' }}>
                {inspectionAssign.deployed
                    ? moment(inspectionAssign.deployed).format(t('dateTime'))
                    : '-'}
            </TableCell>
            <TableCell style={{ width: '10%' }}>
                {inspectionAssign.result &&
                inspectionAssign.result.complete &&
                inspectionAssign.result.date
                    ? moment(inspectionAssign.result.date).format(t('dateTime'))
                    : '-'}
            </TableCell>
            <TableCell style={{ width: '3%' }}>
                <div
                    className={'border-text'}
                    style={{
                        backgroundColor: getColorForPercentage(
                            inspectionAssign.result &&
                                inspectionAssign.result.complete
                                ? inspectionAssign.toolbox !== undefined
                                    ? totalCorrect === 0
                                        ? 0.5
                                        : totalCorrect /
                                          inspectionAssign.result.answers.length
                                    : inspectionAssign.result &&
                                      inspectionAssign.result.allCorrect
                                    ? 1
                                    : 0.5
                                : 0
                        ),
                        borderColor: getColorForPercentage(
                            inspectionAssign.result &&
                                inspectionAssign.result.complete
                                ? inspectionAssign.toolbox !== undefined
                                    ? totalCorrect === 0
                                        ? 0.5
                                        : totalCorrect /
                                          inspectionAssign.result.answers.length
                                    : inspectionAssign.result &&
                                      inspectionAssign.result.allCorrect
                                    ? 1
                                    : 0.5
                                : 0
                        ),
                        width:
                            inspectionAssign.result &&
                            inspectionAssign.result.complete
                                ? unsafe
                                    ? '120px'
                                    : '60px'
                                : '90px',
                        fontWeight: '300',
                    }}
                >
                    {inspectionAssign.toolbox ? (
                        inspectionAssign.result &&
                        inspectionAssign.result.complete ? (
                            totalCorrect +
                            '/' +
                            inspectionAssign.result.answers.length
                        ) : (
                            t('Not completed')
                        )
                    ) : inspectionAssign.result ? (
                        inspectionAssign.result.allCorrect ? (
                            t('Correct')
                        ) : unsafe ? (
                            <span>
                                <WarningIcon
                                    style={{
                                        width: '14px',
                                        height: '14px',
                                        position: 'relative',
                                        top: '2px',
                                        marginRight: '5px',
                                    }}
                                />
                                {t('Unsafe situation')}
                            </span>
                        ) : (
                            t('Not correct')
                        )
                    ) : (
                        t('Not completed')
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
};

var percentColors = percentColors2;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct !== 0 && pct < 1) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(withTranslation())(StatisticsRowEmployee);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import ReactPlayer from "react-player/vimeo";

type Props = {};

type State = {};

class VideoPlayer extends React.Component<Props, State> {
    props: Props = {
        videoToolbox: {
            url: "",
            name: "",
        }
    };
    state: State = {};

    render() {
        return (
            <ReactPlayer controls url={this.props.videoToolbox.url}/>
        );
    }
}

export default (VideoPlayer);

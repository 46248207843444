// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {goBack, push} from 'connected-react-router';
import {bindActionCreators} from 'redux';
import {disableAbonnee, disableEmployee, enableAbonnee, fetchAbonneesCUMELA, setCrumbPath,} from '../../actions';
import {connect} from 'react-redux';
import EnhancedTable from '../EnhancedTable';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import AppModule from '../AppModule';
import {TSubscription} from '../../reducers/subscriptionReducer';
import Slide from '@mui/material/Slide';
import moment from '../../lib/moment';
import type {TEmployee} from '../../reducers/employeesReducer';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    setCrumbPath: () => void,
    abonneesCUMELA: Array<any>,
    open: boolean,
    goBack: () => mixed,
    loading: boolean,
    abonnee?: TSubscription,
    pageAfterSave?: string,
    disableAbonnee: (abonnee: TSubscription) => void,
    disableEmployee: (employee: TEmployee) => void,
    enableAbonnee: (abonnee: TSubscription) => void,
};

type State = {
    searchtext: string,
};

const mapStateToProps = (store) => {
    const abonneesCUMELA = store.subscription.abonneesCUMELA;
    const currentUser = store.drafts.employees.currentUser;

    let abonneesById = [];
    if (abonneesCUMELA) {
        abonneesCUMELA.forEach((abonee) => {
            abonneesById[abonee.id] = abonee;
        });
    }
    return {
        abonneesCUMELA: abonneesCUMELA,
        abonneesById: abonneesById,
        currentuser: currentUser,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        disableAbonnee: bindActionCreators(disableAbonnee, dispatch),
        enableAbonnee: bindActionCreators(enableAbonnee, dispatch),
        disableEmployee: bindActionCreators(disableEmployee, dispatch),
        //  editAbonnee: bindActionCreators(editAbonnee, dispatch),
        //  clearDiff: () => {
        //      dispatch(clearDiff());
        //  },
        fetchAbonneesCUMELA: () => {
            dispatch(fetchAbonneesCUMELA());
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: 'Abonnees'}));
        },
        goBack: () => {
            dispatch(goBack());
        },
        goToDetailView: (abonneeId) => {
            dispatch(push('/abonneeCUMELA/details/' + abonneeId));
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SubscriptionsCUMELAOverview extends React.Component<Props, State> {
    state: State = {
        searchtext: '',

        columns: [
            {
                id: 'companyName',
                numeric: false,
                size: '20%',
                label: 'Bedrijfsnaam',
            },
            //{ id: 'nrEmployees', numeric: true, size: '14%', label: 'Medewerkers' },
            {
                id: 'activeEmployees',
                numeric: true,
                size: '17%',
                label: 'Actieve medewerkers',
            },
            {
                id: 'createdOn',
                numeric: false,
                size: '13%',
                label: 'Lid sinds',
            },
            {
                id: 'costs',
                numeric: true,
                size: '20%',
                label: 'Max Medewerkers',
            },
            {
                id: 'typeSubscription',
                numeric: false,
                size: '16%',
                label: 'Soort abonnement',
            },
            {
                id: 'contactPerson',
                numeric: false,
                size: '20%',
                label: 'Contact persoon',
            },
            {
                id: 'state',
                numeric: false,
                size: '120px',
                label: 'Status',
                classes: 'Status',
            },
        ],
        // showDialogChoose:false
    };

    componentDidMount() {
        const {t} = this.props;

        this.props.setCrumbPath();
        this.props.fetchAbonneesCUMELA();

        this.setState({
            columns: [
                {
                    id: 'companyName',
                    numeric: false,
                    size: '20%',
                    label: t('Company name'),
                },
                //{ id: 'nrEmployees', numeric: true, size: '14%', label: 'Medewerkers' },
                {
                    id: 'activeEmployees',
                    numeric: true,
                    size: '17%',
                    label: t('active employees'),
                },
                {
                    id: 'createdOn',
                    numeric: false,
                    size: '13%',
                    label: t('Member since'),
                },
                {
                    id: 'costs',
                    numeric: true,
                    size: '20%',
                    label: t('Max employees'),
                },
                {
                    id: 'typeSubscription',
                    numeric: false,
                    size: '16%',
                    label: t('Subscription type'),
                },
                {
                    id: 'contactPerson',
                    numeric: false,
                    size: '20%',
                    label: t('Contact person'),
                },
                {
                    id: 'state',
                    numeric: false,
                    size: '120px',
                    label: t('State'),
                    classes: 'Status',
                },
            ],
        });
    }

    abonneeStateToText = (state, trial) => {
        let text = '';
        let class_type = '';

        switch (state) {
            case 'REQUESTED':
                text = 'Inactief';
                class_type = 'INACTIVE';
                break;
            case 'CONFIRMED':
                text = 'Actief';
                class_type = 'ACTIVE';
                break;
            case 'DISABLED':
                text = 'Inactief';
                class_type = 'INACTIVE';
                break;
            default:
        }

        //check if the subscription is just a trial
        if (text === 'Actief' && trial === true) {
            text = 'Proef';
            class_type = 'TRIAL';
        }

        return <div className={'border-text ' + class_type}>{text}</div>;
    };

    mapAbonneeToRow = (abonnee) => {
        const {t} = this.props;
        return {
            ...abonnee,
            id: abonnee.id,
            companyName: abonnee.companyName,
            state: this.abonneeStateToText(abonnee.state, abonnee.trial),
            costs:
                abonnee.maxEmployees !== -1
                    ? abonnee.maxEmployees
                    : t('Infinite'),
            contactPerson: abonnee.fullNameSubscriptionHolder,
            nrEmployees: abonnee.totalEmployees,
            activeEmployees: abonnee.activeEmployees,
            createdOn: moment(abonnee.dateCreatedOn).format(t('DD-MM-YYYY')),
            typeSubscription:
                abonnee.credits_setup && !abonnee.vv_functions
                    ? t('Sneldelen only')
                    : abonnee.cumela === true
                        ? abonnee.credits_setup
                            ? t('Cumela (+ Express sharing)')
                            : t('Cumela')
                        : abonnee.caoLeo === true
                            ? abonnee.credits_setup
                                ? t(
                                    'Collective Labor Agreement for Green, Land and Infrastructure (+ Fast sharing)'
                                )
                                : t('cao Groen, Grond en Infrastructuur')
                            : abonnee.credits_setup
                                ? t('Standard (+ Express sharing)')
                                : t('Standard'),
        };
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    onRowClick = (row, event) => {
        if (row.id) {
            this.props.goToDetailView(row.id);
        }
    };

    employeeSorter = () => {
        return {
            companyName: (a, b) => {
                if (a && b) {
                    if (
                        a.companyName.toLowerCase() >
                        b.companyName.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.companyName.toLowerCase() <
                        b.companyName.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            },
            createdOn: (a, b) => {
                if (a && b) {
                    if (a.dateCreatedOn > b.dateCreatedOn) {
                        return 1;
                    }
                    if (a.dateCreatedOn < b.dateCreatedOn) {
                        return -1;
                    }
                }
                return 0;
            },
            maxEmployees: (a, b) => {
                if (a && b) {
                    if (a.maxEmployees === -1 && b.maxEmployees === -1) {
                        return 0;
                    }
                    if (a.maxEmployees === -1) {
                        return 1;
                    }
                    if (b.maxEmployees === -1) {
                        return -1;
                    }
                    if (a.maxEmployees > b.maxEmployees) {
                        return 1;
                    }
                    if (a.maxEmployees < b.maxEmployees) {
                        return -1;
                    }
                }
                return 0;
            },
            costs: (a, b) => {
                if (a && b) {
                    if (a.price > b.price) {
                        return 1;
                    }
                    if (a.price < b.price) {
                        return -1;
                    }
                }
                return 0;
            },
            typeSubscription: (a, b) => {
                if (a && b) {
                    if (a.type > b.type) {
                        return 1;
                    }
                    if (a.type < b.type) {
                        return -1;
                    }
                }
                return 0;
            },
            contactPerson: (a, b) => {
                if (a && b) {
                    if (
                        a.fullNameSubscriptionHolder.toLowerCase() >
                        b.fullNameSubscriptionHolder.toLowerCase()
                    ) {
                        return 1;
                    }
                    if (
                        a.fullNameSubscriptionHolder.toLowerCase() <
                        b.fullNameSubscriptionHolder.toLowerCase()
                    ) {
                        return -1;
                    }
                }
                return 0;
            },
            state: (a, b) => {
                if (a && b) {
                    if (a.state === 'CONFIRMED') {
                        if (b.state === 'CONFIRMED') {
                            if (a.trial === false) {
                                if (b.trial === false) return 0;
                                else return 1;
                            } else if (b.trial === false) {
                                return -1;
                            } else return 0;
                        } else if (a.trial === false) {
                            return 1;
                        } else return -1;
                    } else if (b.state === 'CONFIRMED') {
                        if (b.trial === false) {
                            return -1;
                        } else return 1;
                    } else if (a.trial === false) {
                        if (b.trial === false) return 0;
                        else return 1;
                    } else if (b.trial === false) return -1;
                    else return 0;
                }
                return 0;
            },
        };
    };

    downloadCSV = () => {
        var dataCSV = this.convertArrayOfObjectsToCSV(
            this.props.abonneesCUMELA
        );
        var data, filename, link;
        var csv = 'data:text/csv;charset=utf-8,' + dataCSV;
        if (csv == null) return;

        filename = 'abonnees.csv';

        data = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click(); //
        document.body.removeChild(link);
    };

    convertArrayOfObjectsToCSV = (data) => {
        const {t} = this.props;
        var result, ctr, keys, columnDelimiter, lineDelimiter;

        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ',';
        lineDelimiter = '\n';

        //all columns
        // keys = Object.keys(data[0]);

        //just few columns
        keys = [
            'id',
            'dateCreatedOn',
            'companyName',
            'fullNameSubscriptionHolder',
            'phoneNumberSubscriptionHolder',
            'type',
            'maxEmployees',
            'price',
            'state',
        ];

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (key === 'state') {
                    switch (item[key]) {
                        case 'REQUESTED':
                            result += t('Inactive');
                            break;
                        case 'CONFIRMED':
                            if (item['trial'] === true) {
                                result += t('Trial');
                            } else {
                                result += t('Active');
                            }
                            break;
                        case 'DISABLED':
                            result += t('Inactive');
                            break;
                        default:
                    }
                } else if (key === 'type') {
                    let subscriptionType =
                        item['credits_setup'] && !item['vv_functions']
                            ? 'Sneldelen only'
                            : item['cumela'] === true
                                ? item['credits_setup']
                                    ? 'Cumela (+ Sneldelen)'
                                    : 'Cumela'
                                : item['caoLeo'] === true
                                    ? item['credits_setup']
                                        ? 'cao Groen, Grond en Infrastructuur (+ Sneldelen)'
                                        : 'cao Groen, Grond en Infrastructuur'
                                    : item['credits_setup']
                                        ? 'Standaard (+ Sneldelen)'
                                        : 'Standaard';
                    result += subscriptionType;
                } else if (key === 'dateCreatedOn') {
                    result += moment(item['dateCreatedOn']).format(
                        'DD/MM/YYYY'
                    );
                } else {
                    result += item[key] ? item[key] : '-';
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    render() {
        const {t} = this.props;
        if (
            this.props.currentuser &&
            this.props.currentuser.roles.includes('CUMELA')
        ) {
            const {abonneesCUMELA} = this.props;
            const {columns} = this.state;

            let totalAbonnees = 0;
            let activeAbonnees = 0;
            let searchresults = [];

            if (this.props.abonneesCUMELA) {
                totalAbonnees =
                    this.props.abonneesCUMELA.length +
                    ' ' +
                    t('subscriber') +
                    (this.props.abonneesCUMELA.length === 1 ? '' : 's');
                activeAbonnees =
                    '(' +
                    abonneesCUMELA.filter(
                        (a) => a.state === 'CONFIRMED' && a.trial === false
                    ).length +
                    ' actief)';

                searchresults = abonneesCUMELA.filter((row) => {
                    return (
                        (row.companyName &&
                            row.companyName
                                .toLowerCase()
                                .indexOf(this.state.searchtext.toLowerCase()) >
                            -1) ||
                        (row.contactPerson &&
                            row.contactPerson
                                .toLowerCase()
                                .indexOf(this.state.searchtext.toLowerCase()) >
                            -1)
                    );
                });
            }

            const tableActionHeader = (
                <TableActionHeader
                    searchPlaceholder={t('Search subscribers')}
                    searchAlwaysOpen={true}
                    onSearchChange={this.handleSearch}
                    title={
                        totalAbonnees +
                        ' ' +
                        activeAbonnees +
                        (this.state.searchtext.length > 0
                            ? ' - ' + (searchresults ? searchresults.length : 0) +
                            ' ' + t(searchresults && searchresults.length === 1 ? 'searchresult' : 'searchresults')
                            : '')
                    }
                    onActionButton={this.downloadCSV}
                    onActionText="Download"
                />
            );

            return (
                <div>
                    <AppModule prepend={tableActionHeader}>
                        <EnhancedTable
                            hover
                            columns={columns}
                            onClick={this.onRowClick}
                            rows={searchresults}
                            formatter={this.mapAbonneeToRow}
                            actions={[]}
                            sorter={this.employeeSorter()}
                            emptyState={
                                this.state.searchtext.length > 0
                                    ? t('No searchresults')
                                    : t('There are no subscribers yet.')
                            }
                        />

                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        position: 'fixed',*/}
                        {/*        top: '84px',*/}
                        {/*        right: '36px',*/}
                        {/*        zIndex: 1500,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <div className={'tooltip'} style={{padding: 0}}>*/}
                        {/*        <span*/}
                        {/*            className={'tooltiptext'}*/}
                        {/*            style={{top: '60px'}}*/}
                        {/*        >*/}
                        {/*            {t('Download subscribers as CSV')}*/}
                        {/*        </span>*/}
                        {/*        <Fab*/}

                        {/*            onClick={this.downloadCSV}*/}
                        {/*        >*/}
                        {/*            <DescIcon/>*/}
                        {/*        </Fab>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </AppModule>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SubscriptionsCUMELAOverview);

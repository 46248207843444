// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import './InspectionPreview.css';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import FileUploadIcon from "@mui/icons-material/CloudUpload";
import classes from './CheckListBlockPreview.module.js.scss';

type Props = {};

const CheckListBlockPreview = (props: Props) => {
    const { block, t } = props;

    return (
        <div className={classes.root}>
            {block.listBlock.items.map((item) => {
                return (
                    <div key={item.id}>
                        <div className={'better'}>
                            <div
                                className={'actiePuntTitle'}
                                style={{ wordBreak: 'break-all' }}
                            >
                                {item.text}
                            </div>
                            {item.openQuestion &&
                                (
                                    <div className="actiePunten">
                                        <div className="openvraag">{t('Open Answer')}</div>
                                    </div>
                                )}
                            {item.photoQuestion &&
                                (
                                    <div className={classes.labelWrapper}>
                                        <FileUploadIcon />
                                        <div>{t('Upload photo')}</div>
                                    </div>
                                )
                            }
                            {item.yesnoQuestion &&
                                (
                                    <div className="actiePunten">
                                        <div className="actiePunt">{t('Yes')}</div>
                                        <div className="actiePunt">{t('No')}</div>
                                    </div>
                                )
                            }
                            {(!item.photoQuestion && !item.openQuestion && !item.yesnoQuestion) &&
                                (
                                    <div className="actiePunten">
                                    <div className="actiePunt">{t('Correct')}</div>
                                    <div className="actiePunt">{t('Not correct')}</div>
                                    <div className="actiePunt">{t('NVT')}</div>
                                    </div>
                                )
                            }

                        </div>
                        <br />
                    </div>
                );
            })}
        </div>
    );
};

export default compose(
    
    withTranslation()
)(CheckListBlockPreview);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popover,
    Switch,
    Toolbar,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide'
import Loader from '../../Loader';
import {inProgress} from '../../../selectors';
import getRoles from '../../../selectors/getRoles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {upgradeAbonnee} from '../../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './AbonnementVideoToolboxes.module.scss'
import TextField from "@mui/material/TextField";
import {TSubscription} from "../../../reducers/subscriptionReducer";
import {upgradeVideoToolboxSubscription} from "../../../lib/api";

type Props = {
    loading: boolean, onUpgraded: () => mixed, upgradeAbonnee: (abonnee: TSubscription, password: String) => void,
    prices: []
};
const mapStateToProps = (store, props) => {
    const subscription = store.drafts.employees.currentUser ? store.drafts.employees.currentUser.subscription : null;

    return {
        loading: !inProgress(store),
        roles: getRoles(store),
        subscription: subscription,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        upgradeAbonnee: (abonnee, callback) => {
            dispatch(upgradeAbonnee(abonnee, (success, error) => {
                callback(success, error);
            }))
        },
        onClose: () => {
            dispatch(push('/abonnement'));
        },
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class AbonnementVideoToolboxes extends React.Component<Props, State> {
    props: Props;
    state: State = {
        warningDialogOpen: false,
        subscription: this.props.subscription,
        videoToolboxSubscription: this.props.subscription.videoToolboxSubscriptions[this.props.subscription.videoToolboxSubscriptions.length - 1],
        menuVideoToolboxOpen: false,
        rowMenuAnchorElement: null,
        confirmationPassword: '',
        vtbAmount: 0,
        vtbPrice: 0,
        vtbOptions: [],
        noSubscriptionPrice: {id: -1, price: 0, amount: 0},
        passwordError: false,
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.prices.length === 0 && this.props.prices.length > 0) {
            const vtbOptions = this.props.prices.filter((price) => (price.subscriptionType === "VTB" && Number(price.handle.split('_')[0]) === this.props.subscription.maxEmployees)).map((price) =>
                ({amount: price.option, price: price.price})
            ).sort((a, b) => (a.amount > b.amount ? 1 : -1));
            const vtbAmount = this.state.videoToolboxSubscription.enabled ? vtbOptions.length >= 1 ? vtbOptions.find((price) => (price.amount === this.state.videoToolboxSubscription.subscriptionSize)).amount : 0 : 0;
            const vtbPrice = this.state.videoToolboxSubscription.enabled ? vtbOptions.length >= 1 ? vtbOptions.find((price) => (price.amount === this.state.videoToolboxSubscription.subscriptionSize)).price : 0 : 0;
            this.setState({
                vtbOptions,
                vtbAmount,
                vtbPrice,
                choosenPrice: this.state.videoToolboxSubscription.enabled ? vtbOptions.length >= 1 ? vtbOptions.find((price) => (price.amount === this.state.videoToolboxSubscription.subscriptionSize)) : this.state.noSubscriptionPrice : this.state.noSubscriptionPrice,
            })
        }
    }

    handleClose = (event) => {
        this.props.onClose();
        this.setState({
            subscription: this.props.subscription,
        });
    };

    requestWarningDialogClose = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    requestDialogUpgradedClose = () => {
        this.setState({
            dialogUpgraded: false,
        });
        this.props.onClose();
        this.props.onUpgraded();
        window.location.reload();
    };

    openVideoToolboxMenu = (event) => {
        this.setState({
            menuVideoToolboxOpen: true,
            rowMenuAnchorElement: event.currentTarget,
        });
    };

    handleRowMenuVideoToolboxRequestClose = (event) => {
        this.setState({menuVideoToolboxOpen: false});
    };

    handleUpgrade = (event) => {
        upgradeVideoToolboxSubscription(this.state.subscription.id, {
            ...this.state.choosenPrice,
            password: this.state.confirmationPassword
        }).then((entities) => {
            if (entities !== undefined) {
                this.setState({
                    warningDialogOpen: false,
                    dialogUpgraded: true
                })
            } else {

            }
        }).catch((error) => {
            if (error.status === 400) {
                this.setState({
                    passwordError: true
                })
            }
        })
    };

    handleVTBSubscriptionChange = (event) => {
        const defaultPrice = this.state.vtbOptions.length >= 1 ? this.state.vtbOptions[0] : 0;
        this.setState({
            videoToolboxSubscription: {
                enabled: event.target.checked
            },
            vtbAmount: event.target.checked ? this.state.vtbOptions.length >= 1 ? this.state.vtbOptions[0].amount : 0 : 0,
            vtbPrice: event.target.checked ? this.state.vtbOptions.length >= 1 ? this.state.vtbOptions[0].price : 0 : 0,
            choosenPrice: event.target.checked ? this.state.vtbOptions.length >= 1 ? this.state.vtbOptions[0] : this.state.noSubscriptionPrice : this.state.noSubscriptionPrice,
        })
    }

    handleAmountChange = (action) => (event) => {
        this.setState({
            vtbAmount: action.amount,
            vtbPrice: action.price,
            choosenPrice: action,
            menuVideoToolboxOpen: false,
        })
    }

    handleUpgradeDialog = (event) => {
        this.setState({
            subscription: {
                ...this.state.subscription,
            },
            warningDialogOpen: true,
        });
    };

    onPasswordChange = (event) => {
        this.setState({confirmationPassword: event.target.value});
    }

    render() {
        const {open, loading, t} = this.props;
        const {vtbOptions} = this.state;
        const {
            warningDialogOpen,
            dialogUpgraded,
            subscription,
            creditsAmount,
            vtbAmount,
            vtbPrice,
        } = this.state;

        const currencyFormatter = new Intl.NumberFormat(t('LOCALE'), {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: {
                        p: 0, // sets padding to 0
                    },
                }}
            >
                <Dialog
                    open={warningDialogOpen}
                    onClose={this.requestWarningDialogClose}
                >
                    <DialogTitle>{t('Request video toolbox subscription')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Request a video toolbox subscription') + ' ' + vtbAmount + ' ' + t('video toolboxes') + ' ' + t('to use per year') + '? ' + t('Yearly costs are') + ' ' + currencyFormatter.format(vtbPrice) + '. '}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.customPadding}>
                        <TextField
                            type="password"
                            required
                            label={t('Password')}
                            value={this.state.confirmationPassword}
                            onChange={this.onPasswordChange}
                            error={this.state.passwordError}
                        />
                        <div style={{flex: '1 0 0'}}/>
                        <Button
                            color="secondary"
                            onClick={this.requestWarningDialogClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button variant="contained"
                                color="primary"
                                onClick={this.handleUpgrade}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialogUpgraded}
                    onClose={this.requestDialogUpgradedClose}
                >
                    <DialogTitle>
                        {t('Your subscription had been upgraded')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('We received your subscription adjustment')}{' '}
                            <br/>
                            {t('subscription adjustments info')} <br/>
                            {t('You can get started right away.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.requestDialogUpgradedClose}
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppBar className={classes.appBar}>
                    <Toolbar disableGutters>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Video Toolbox Subscription')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.content}>
                    <Loader loading={loading}>
                        <div className={classes.abonnementContent}>
                            <div className={classes.abonnementInfoCard}>
                                <div
                                    style={{
                                        background: '#FFDD4A',
                                        height: '288px',
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            fontFamily:
                                                'filson-soft,sans-serif',
                                            lineHeight: '24px',
                                            paddingTop: '36px',
                                        }}
                                    >
                                        {t('Video Toolbox Subscription')}
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                fontSize: '64px',
                                                color: '#282D35',
                                                lineHeight: '24px',
                                                textAlign: 'center',
                                                paddingTop: '81px',
                                                fontFamily:
                                                    'filson-soft,sans-serif',
                                            }}
                                        >
                                            {currencyFormatter.format(this.state.vtbPrice)}
                                        </div>
                                        <div
                                            style={{
                                                opacity: '0.5',
                                                fontSize: '17px',
                                                color: '#282D35',
                                                lineHeight: '24px',
                                                textAlign: 'center',
                                                paddingTop: '50px',
                                            }}
                                        >
                                            {t('Yearly')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.abonnementInfoContent}>
                                <h2 style={{fontSize: '24px'}}>
                                    {t('Video Toolboxes')}
                                </h2>
                                <p
                                    style={{
                                        opacity: '0.54',
                                        marginBottom: '50px',
                                    }}
                                >
                                    {t(
                                        'Add the video toolbox subscription and decide how many video toolboxes you want to use'
                                    )}
                                </p>
                                <Divider/>
                                <div style={{display: 'block'}}>
                                    <div
                                        className={classes.pElement}
                                        style={{
                                            marginLeft: '10px', marginRight: '10px',
                                        }}
                                    >
                                        <b>{t('Video Toolbox Subscription')}</b>
                                        <div
                                            style={{
                                                opacity: '1.0', float: 'right', marginTop: '-15px', marginRight: '10px',
                                            }}
                                        >
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={this.state.videoToolboxSubscription.enabled}
                                                    onChange={this.handleVTBSubscriptionChange}
                                                />}
                                                id="videoToolboxEnable"
                                                style={{marginTop: "5px"}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Divider/>
                                {this.state.videoToolboxSubscription.enabled && <div>
                                    <div style={{display: 'block'}}>
                                        <p
                                            style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                        >
                                            <b>{t('Choose a bundle')}</b>
                                            <IconButton
                                                onClick={this.openVideoToolboxMenu}
                                                style={{
                                                    width: '40px',
                                                    float: 'right',
                                                    marginTop: '-15px',
                                                }}
                                                size="large">
                                                <ExpandMoreIcon/>
                                            </IconButton>
                                            <div
                                                style={{
                                                    color: '#0091EA',
                                                    float: 'right',
                                                }}
                                                onClick={this.openVideoToolboxMenu}
                                            >
                                                {this.state.vtbAmount}{' '}{t('Video Toolboxes')}
                                            </div>
                                        </p>
                                    </div>
                                    <Divider/>
                                </div>
                                }
                                <Button

                                    variant="contained"
                                    color="primary"
                                    className={classes.upgradeButton}
                                    onClick={this.handleUpgradeDialog}
                                    disabled={this.props.subscription.videoToolboxSubscriptions[this.props.subscription.videoToolboxSubscriptions.length - 1].enabled === this.state.videoToolboxSubscription.enabled && (!this.state.videoToolboxSubscription.enabled || this.props.subscription.videoToolboxSubscriptions[this.props.subscription.videoToolboxSubscriptions.length - 1].subscriptionSize === this.state.vtbAmount)}
                                >
                                    {t('Edit')}
                                </Button>
                            </div>
                        </div>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuVideoToolboxOpen}
                                onClose={
                                    this.handleRowMenuVideoToolboxRequestClose
                                }
                            >
                                <List>
                                    {vtbOptions !== null &&
                                        vtbOptions.map((action, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                onClick={this.handleAmountChange(
                                                    action
                                                )}
                                            >
                                                <ListItemText
                                                    primary={
                                                        action.amount +
                                                        ' ' +
                                                        t('Video Toolboxes')
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Popover>
                        </Paper>
                    </Loader>
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(AbonnementVideoToolboxes);

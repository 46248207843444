// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import {Button, Fab, Paper,} from '@mui/material';
import {AddCircle, ViewList} from '@mui/icons-material';
import GuideShortcut from './GuideShortcut';
import CollapseItem from '@mui/icons-material/ExpandLess';
import {push} from 'connected-react-router';
import {openDialog} from '../../../actions';
import classnames from 'classnames';
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import classes from './Guide.module.scss';

type Props = {
    loading: boolean,
    openDialog: (string) => void,
    navigate: (string) => void,
    vv_functions: boolean,
    showNewFeatures: boolean,
};

type State = {
    open: boolean,
};

const mapStateToProps = (store) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        openDialog: (dialogName) => {
            dispatch(openDialog(dialogName));
        },
        navigate: (newPath) => (event) => {
            dispatch(push(newPath));
        },
    };
};


class Guide extends React.Component {
    props: Props;
    state: State = {
        open: this.props.hidden && this.props.hidden ? true : false,
    };

    componentDidMount() {
        if (localStorage.getItem('dashboardGuideOpen')) {
            this.setState({
                open: JSON.parse(localStorage.getItem('dashboardGuideOpen')),
            });
        }

    }

    handleToggle = () => {
        localStorage.setItem('dashboardGuideOpen',JSON.stringify(!this.state.open));
        this.setState({
            open: !this.state.open,
        });
    };

    render() {
        const { vv_functions, showNewFeatures, t } = this.props;

        let shortcuts = [];
        shortcuts.push(
            <GuideShortcut
                key="1"
                title={t('Add new employees')}
                icon={<AddCircle className={classes.shortcutIcon} />}
                onClick={this.props.navigate('/medewerkers#new')}
            />
        );
        shortcuts.push(
            <GuideShortcut
                key="3"
                title={t('Add new toolbox')}
                icon={<AddCircle className={classes.shortcutIcon} />}
                onClick={() => {
                    this.props.openDialog('toolboxCreate');
                }}
            />
        );

        shortcuts.push(
            <GuideShortcut
                key="4"
                title={t('View toolbox overview')}
                icon={<ViewList className={classes.shortcutIcon} />}
                onClick={this.props.navigate('/toolboxen')}
            />
        );

        if (vv_functions) {
            shortcuts.push(
                <GuideShortcut
                    key="2"
                    title={t('Add project')}
                    icon={<AddCircle className={classes.shortcutIcon} />}
                    onClick={this.props.navigate('/projecten#new')}
                />
            );
            shortcuts.push(
                <GuideShortcut
                    key="6"
                    title={t('Add workplace inspection')}
                    icon={<AddCircle className={classes.shortcutIcon} />}
                    onClick={() => {
                        this.props.openDialog('inspectionCreate');
                    }}
                />
            );
        }

        let url;
        if (
            window.location.origin.includes('test') ||
            window.location.origin.includes('3000')
        ) {
            url = 'https://veiligvakwerk.acceptance.doop.works/nieuw';
        } else {
            url = 'https://veiligvakwerk.nl/nieuw';
        }

        if (vv_functions) {
            shortcuts.push(
                <GuideShortcut
                    key="7"
                    title={t('View workplace inspections overview')}
                    icon={<ViewList className={classes.shortcutIcon} />}
                    onClick={this.props.navigate('/werkplekinspecties')}
                />
            );
        }

        return (
            <div
                className={classnames({
                    [classes.rootRoot]: true,
                    [classes.collapsedRootRoot]: !this.state.open,
                })}
            >
                <Paper elevation={0}
                    className={classnames({
                        [classes.root]: true,
                        [classes.collapsedRoot]: !this.state.open,
                    })}
                >
                    <div className={classnames({
                        [classes.topSection]: true,
                        [classes.collapsedTopSection]: !this.state.open,})}>
                                <h2
                                    className={classnames({
                                        [classes.topSectionTitleText]: true,
                                        [classes.collapsedTopSectionText]: !this
                                            .state.open,
                                    })}
                                >
                                    {t('Get started right away!')}
                                </h2>
                                <p
                                    className={classnames({
                                        [classes.topSectionText]: true,
                                        [classes.collapsedTopSectionText]: !this
                                            .state.open,
                                    })}
                                >
                                    {t('Welcome at Veilig Vakwerk')}
                                </p>
                                <Fab
                                    className={classnames({
                                        [classes.closeButton]: true,
                                        [classes.collapsedCloseButton]: !this.state.open,
                                    })}
                                     
                                    onClick={this.handleToggle}
                                >
                                    {!this.state.open && (
                                        <InfoIcon/>
                                    )}
                                    <CollapseItem
                                        className={classnames({
                                            [classes.collapseItem]: true,
                                            [classes.rotate]: !this.state.open,
                                        })}
                                    />
                                </Fab>
                    </div>
                    <div
                        className={classnames({
                            [classes.bottomSection]: true,
                            [classes.collapsedBottomSection]: !this.state.open,
                        })}
                    >
                        <div className={classes.items}>{shortcuts}</div>

                        {this.props.vv_functions &&
                            this.props.vv_functions === true && (
                                <Button
                                    onClick={() => {
                                        window.open(
                                            '/assets/manuals/Handleiding_Veilig_Vakwerk_Portal.pdf'
                                        );
                                    }}
                                    className="secondaryButton"
                                >
                                    {t('More help')}
                                </Button>
                            )}
                        {this.props.vv_functions &&
                            this.props.vv_functions === true && (
                                <Button
                                     
                                    onClick={() => {
                                        window.open(
                                            '/assets/manuals/Handleiding_Veilig_Vakwerk_app.pdf'
                                        );
                                    }}
                                    className="secondaryButton"
                                >
                                    {t('App instructions')}
                                </Button>
                            )}
                        {!this.props.vv_functions && (
                            <Button
                                 
                                onClick={() => {
                                    window.open(
                                        '/assets/manuals/Handleiding_Veilig_Vakwerk_Portal_SneldelenOnly.pdf'
                                    );
                                }}
                                className="secondaryButton"
                            >
                                {t('More help')}
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                window.open(
                                    'https://www.youtube.com/watch?v=gB7XNCAce2s&list=PLaGIOGcHY6kgubIPDgaMYlFxd80DGhafo'
                                );
                            }}
                            className="secondaryButton"
                        >
                            {t('Instruction videos')}
                        </Button>
                        <Button
                            onClick={() => {
                                window.open('https://veiligvakwerk.nl/support');
                            }}
                            className="secondaryButton"
                        >
                            {t('Support')}
                        </Button>
                        <Button                             
                            variant="contained"
                            color="primary"
                            style={
                                showNewFeatures === true
                                    ? {
                                          float: 'right',
                                          marginTop: '-4px',
                                      }
                                    : { display: 'none' }
                            }
                        >{t('New functions')}
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Guide);

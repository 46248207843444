// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {getToolboxTrend} from "../../../lib/api";
import {showSnackbar} from "../../../actions";
import {Paper} from '@mui/material';
import i18n from "i18next";
import {connect} from "react-redux";
import ToolboxTrendQuestionList from "./ToolboxTrendQuestionList";
import classes from './ToolboxTrend.module.scss';
import classnames from "classnames";

type Props = {
    toolbox?: any,
};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        projects: store.entities.projects,
    };
};

class ToolboxTrendView extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentDidMount() {
        // Fetch Toolbox trend

        getToolboxTrend(this.props.toolbox.id)
            .then((entities) => {
                this.setState({
                    answerTrend: entities.body
                });
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching toolboxtrend'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    render() {
        const {toolbox} = this.props;
        const {answerTrend} = this.state;

        var totalCorrectCnt = 0;
        var totalIncorrectCnt = 0;
        if (answerTrend) {
            console.log(answerTrend);
            answerTrend.forEach(trend => {
                totalCorrectCnt += trend.correctCnt;
                totalIncorrectCnt += trend.incorrectCnt;
            })
        }
        var percentage = 0;
        if (totalIncorrectCnt + totalCorrectCnt > 0) {
            percentage = (100 * totalCorrectCnt / (totalCorrectCnt + totalIncorrectCnt)).toFixed(2);
        }

        return (
            <div>
                <div className={classnames({
                    [classes.paperCard]: true,
                    [classes.paperCardLeft]: true,
                })}>
                    <Paper elevation={4} className={classes.paper}>
                        <div className={classes.title}>{i18n.t('Correct (%)')}</div>
                        <div>
                            <div className={classes.value}>{totalCorrectCnt}</div>
                        </div>
                    </Paper>
                </div>
                <div className={classnames({
                    [classes.paperCard]: true,
                    [classes.paperCardLeft]: true,
                    [classes.paperCardRight]: true,
                })}>
                    <Paper elevation={4} className={classes.paper}>
                        <div className={classes.title}>{i18n.t('Total answers')}</div>
                        <div>
                            <div className={classes.value}>{totalIncorrectCnt + totalCorrectCnt}</div>
                        </div>
                    </Paper>
                </div>
                <div className={classnames({
                    [classes.paperCard]: true,
                    [classes.paperCardRight]: true,
                })}>
                    <Paper elevation={4} className={classes.paper}>
                        <div className={classes.title}>{i18n.t('Toolbox score')}</div>
                        <div>
                            <div className={classes.value}>{percentage}</div>
                            <div className={classes.unit}>%</div>
                        </div>
                    </Paper>
                </div>
                <div className={classes.questionsCard}>
                    <Paper elevation={4} className={classes.paper}>
                        <ToolboxTrendQuestionList
                            answerTrend={answerTrend}
                        />
                    </Paper>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),

    withTranslation()
)(ToolboxTrendView);

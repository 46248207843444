//@flow
import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'lodash';
import {DropTarget} from 'react-dnd';
import classes from './DropArea.module.scss'

type Props = {
    classes: any,
    connectDropTarget: Function,
};
type State = {};

class DropArea extends React.Component {
    props: Props;
    state: State = {};

    render() {
        const { connectDropTarget } = this.props;
        return (
            connectDropTarget(<div className={ classes.root }>
            </div>)
        );
    }
}

export default _.flowRight([
    DropTarget(
        (props) => props.type,
        {
            drop(props, monitor) {
                props.onAdd(props.index, monitor.getItem());
            }
        },
        (connect, monitor) => ({
            connectDropTarget: connect.dropTarget()
        })
    ),
    
])(DropArea);

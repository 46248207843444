// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import {TableCell} from '@mui/material';
import classes from './EnhancedTableCell.module.scss';

type Props = {};

const EnhancedTableCell = (props: Props) => {
    const { className, nopadding, onClick, children, size, noborder, hover, noHoverBackground } = props;
    const rootClasses = classnames({
        [className]: true,
        [classes.root]: true,
        [classes.noborder]: noborder,
        [classes.nopadding]: nopadding,
        [classes.overflowVisible]: noHoverBackground
    });

    return (
        <TableCell onClick={onClick} className={rootClasses} style={{ width: size }}>
            <span className={classnames({[classes.body]:true, 'may-have-tooltip': noHoverBackground})}>
                {children}
            </span>
            {!noHoverBackground &&
            <span className={classnames({[classes.hover]:true, [classes.withHover]: hover, 'may-have-tooltip': true})}>
                {children}
            </span>}
        </TableCell>
    );
};

export default (EnhancedTableCell);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {List} from 'immutable';
import moment from '../../lib/moment';
import {
    editEmployee,
    fetchEmployees,
    fetchSubscriptions,
    getCurrentEmployee,
    showSnackbar,
    upgradeCredits,
    upgradeSubscription,
    videoToolboxSubscriptionType,
} from '../../actions';
import AppModule from '../AppModule';
import TitleDescription from '../TitleDescription';
import {TEmployee} from '../../reducers/employeesReducer';
import {setCrumbPath} from '../../actions/uiActions';
import classes from './Abonnement.module.scss';
import {Button, Paper} from '@mui/material';
import {getPermissions} from '../../selectors';
import {push} from 'connected-react-router';
import EmployeeEdit from '../Employee/EmployeeEdit';
import AbonnementEdit from './AbonnementEdit/AbonnementEdit';
import AbonnementCredits from './AbonnementCredits/AbonnementCredits';
import AbonnementVideotoolboxes from './AbonnementVideoToolboxes/AbonnementVideoToolboxes';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classnames from "classnames";
import {getSubscriptionPrices} from "../../lib/api";
import i18n from "i18next";

type Props = {
    loading: boolean,
    employees: Array<any>,
    user: mixed,
    fetchEmployee: () => List<TEmployee>,
    getCurrentEmployee: () => TEmployee,
    parseImport: (file: any) => void,
    acknowledgeImport: (file: any) => void,
    clearDiff: () => void,
    onSave: (employee?: TEmployee) => void,
    editemployee: TEmployee,
    inProgress: boolean,
    diff: { removed: List<TEmployee>, added: List<TEmployee> },
};

type State = {
    showUpload: boolean,
    showDialogChoose: boolean,
    showConfirmDialog: boolean,
    currentEmpl: TEmployee,
    showUpgradeDialog: boolean,
    showCreditsDialog: boolean,
    showPaymentInfoDialog: boolean,
};

const getSubscriptionUpgrade = (subscription, subscriptionStubs) => {
    if (subscription && subscriptionStubs && subscriptionStubs.prijzen) {
        const prijzen = subscriptionStubs.prijzen;
        for (let i = 0; i < prijzen.length; i++) {
            const prijs = prijzen[i];
            if (prijs.maxEmployees > subscription.maxEmployees) {
                return prijs;
            }
        }

        const maxPrijs = prijzen[prijzen.length - 1]; // Max grootte
        maxPrijs.maxEmployees = -1;
        return maxPrijs;
    }
    return null;
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser ? store.drafts.employees.currentUser.subscription : null;

    return {
        currentEmpl: store.drafts.employees.currentUser,
        allEmployees: store.entities.employees,
        subscription: subscription,
        subscriptionUpgrade: getSubscriptionUpgrade(subscription, store.subscription.subscriptionStubs),
        permissions: getPermissions(store),
        editemployee: store.drafts.employees.editemployee,
        editAbonnement: false,
        editPaymentInfo: false,
        editVideoToolboxes: false,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCrumbPath: (companyName: string) => {
            dispatch(setCrumbPath({
                title: 'Abonnement',
            }));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        goToDialog: () => {
            dispatch(push('#dialog'));
        },
        editabonnement: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/upgrade'));
        },
        editpaymentinfo: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/payment'));
        },
        editcredits: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/credits'));
        },
        editvideotoolboxes: () => {
            dispatch(push('/abonnement/videotoolboxes'));
        },
        editEmployee: bindActionCreators(editEmployee, dispatch),
        getCurrentEmployee: bindActionCreators(getCurrentEmployee, dispatch),
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
        fetchSubscriptions: bindActionCreators(fetchSubscriptions, dispatch),
        upgradeSubscription: bindActionCreators(upgradeSubscription, dispatch),
        upgradeCredits: bindActionCreators(upgradeCredits, dispatch),
    };
};

class Abonnement extends React.Component<Props, State> {
    props: Props;
    state: State = {
        showUpgradeDialog: false,
        showCreditsDialog: false,
        showPaymentInfoDialog: false,
        showVideoToolboxesDialog: false,
        prices: [],
    };

    componentDidMount() {
        this.props.getCurrentEmployee();
        this.props.fetchEmployees();
        this.props.fetchSubscriptions();
        this.props.fetchCurrentUser();

        const {t} = this.props;
        this.props.setCrumbPath(this.props.currentEmpl && this.props.currentEmpl.subscription ? this.props.currentEmpl.subscription.companyName : t('Overview'));
        getSubscriptionPrices().then((entities) => {
            const prices = entities.body
            this.setState({prices})
        })
            .catch((error) => {
                showSnackbar(`${i18n.t('Unknown error while fetching subscription prices')}: ` + error.message)
                throw error;
            });
    }

    upgradeSubscription = () => {
        this.props.fetchCurrentUser();
    };

    editEmployee = () => {
        this.props.editEmployee(this.props.currentEmpl);
        this.props.goToDialog();
    };

    editPaymentInfo = () => {
        this.props.fetchCurrentUser();
    };

    render() {
        const {currentEmpl, allEmployees, t} = this.props;
        let subscription;
        let employeesSize;

        const paramId = this.props.match.params.id;
        const showDetailDialog = paramId === 'upgrade';
        const showCreditsDialog = paramId === 'credits';
        const showPaymentInfoDialog = paramId === 'payment';
        const showVideoToolboxesDialog = paramId === 'videotoolboxes';

        // const editAction = (<IconButton
        //     onClick={this.editEmployee}
        //     className={classes.editButton}
        //     key="edit"
        //     size="large">
        //     <EditIcon/>
        // </IconButton>);

        if (currentEmpl && currentEmpl.subscription) {
            subscription = currentEmpl.subscription;
            if (allEmployees && allEmployees.allIds) {
                employeesSize = allEmployees.allIds.length;
            } else {
                employeesSize = 0;
            }
        } else {
            return <div/>;
        }

        // const tableActionHeader = <TableActionHeader editAction={editAction}/>;

        const totalEmployees = employeesSize + ' ' + t('employee') + (employeesSize === 1 ? '' : 's');
        const activeEmployees = '(' + allEmployees.allIds
            .map((id) => allEmployees.byId[id])
            .filter((e) => e.state === 'ACTIVE').length + ' ' + t('active') + ')';

        const {permissions} = this.props;

        const creditsFriendly = subscription.credits ? subscription.credits : 0;
        const abonneeType = subscription.credits_setup && !subscription.vv_functions ? t('Sneldelen only') : subscription.cumela === true ? subscription.credits_setup ? t('Cumela met Sneldelen') : 'Cumela' : subscription.caoLeo === true ? subscription.credits_setup ? t('cao Groen, Grond en Infrastructuur met Sneldelen') : t('cao Groen, Grond en Infrastructuur') : subscription.credits_setup ? t('Standaard met Sneldelen') : t('Standard');
        let videoToolboxType
        let videoToolboxTypeNext
        if (subscription.videoToolboxSubscriptions.length >= 1) {
            videoToolboxType = videoToolboxSubscriptionType(subscription) + (subscription.videoToolboxSubscriptions[0].subscriptionSize > 0 ? (" (" + subscription.videoToolboxSubscriptions[0].subscriptionSize + " " + t('video toolboxes') + ")") : (""))
        }
        if (subscription.videoToolboxSubscriptions.length === 2) {
            videoToolboxTypeNext = t('From') + ' ' + moment(subscription.videoToolboxSubscriptions[1].startDate).add(1, 'days').format('L') + ': ' + (!subscription.videoToolboxSubscriptions[1].enabled ? t('ended') : subscription.videoToolboxSubscriptions[1].subscriptionSize + ' ' + t('video toolboxes'));
        }
        const upgradeButton = (<Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.props.editabonnement}
            style={{float: "right", width: "100%"}}
        >
            {subscription.vv_functions ? t('Edit') : t('Request subscription')}
        </Button>);

        const paymentInfoButton = (<Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.props.editpaymentinfo}
        >
            {t('Payment details')}
        </Button>);

        const buyCreditsButton = (<Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.props.editcredits}
            style={{float: "right", width: "100%"}}
        >
            {t('Upgrade credits')}
        </Button>);

        const videoToolboxButton = (<Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={videoToolboxType === t('Not available')}
            style={{float: "right", width: "100%"}}
            onClick={this.props.editvideotoolboxes}
        >
            {t('Edit')}
        </Button>);

        const changeCompanyInfoButton = (<Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{float: "right", width: "100%"}}
            onClick={this.editEmployee}
        >
            {t('Edit')}
        </Button>);

        let noOfEmployees;
        if (!subscription.vv_functions && subscription.credits_setup) {
            // TODO: No of external employees
            noOfEmployees = subscription.maxEmployees;
        } else {
            noOfEmployees = subscription.maxEmployees < 0 ? t('unlimited number of employees') : t('till') + ' ' + subscription.maxEmployees + ' ' + t('active employees');
        }

        // Internet Explorer bugfix
        if (!String.prototype.endsWith) {
            //eslint-disable-next-line
            String.prototype.endsWith = function (searchString, position) {
                let subjectString = this.toString();
                if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
                    position = subjectString.length;
                }
                position -= searchString.length;
                let lastIndex = subjectString.indexOf(searchString, position);
                return lastIndex !== -1 && lastIndex === position;
            };
        }

        let dialogEndsWith = window.location.href.endsWith('dialog');

        let companyDetails = {
            'Company name': subscription.companyName,
            'Member since': moment(subscription.dateCreatedOn, '').format('LL'),
            'Contact person': currentEmpl.fullname ? currentEmpl.fullname : '-',
            'Number of employees': subscription.vv_functions ? totalEmployees + ' ' + activeEmployees : totalEmployees,
            'Email address': currentEmpl.username,
            'Telephone number': currentEmpl.phonenumber ? currentEmpl.phonenumber : '-',
            'Invoice email': subscription.factuurEmail ? subscription.factuurEmail : '-'
        }

        return permissions.subscription.view ? (
            <AppModule overrideWidth={850} loading={false}>
                <div className="myContainer">
                    <div className={classnames({
                        [classes.sectionTitle]: true, [classes.tableCardTitle]: true,
                    })}
                         style={{marginTop: 0}}>
                        {t('Company details')}
                    </div>
                    {/*<Paper elevation={3} sx={{p: 3, mx: "auto"}}>*/}
                    {/*    <Grid container spacing={2}>*/}
                    {/*        {Object.entries(companyDetails).map(([label, value]) => (*/}
                    {/*            <Grid container item key={label} spacing={2} alignItems="center">*/}
                    {/*                <Grid item xs={2}>*/}
                    {/*                    <Typography variant="subtitle1">*/}
                    {/*                        {t(label)}:*/}
                    {/*                    </Typography>*/}
                    {/*                </Grid>*/}
                    {/*                <Grid item xs={7}>*/}
                    {/*                    <Typography variant="subtitle1">{value}</Typography>*/}
                    {/*                </Grid>*/}
                    {/*            </Grid>))}*/}
                    {/*    </Grid>*/}
                    {/*</Paper>*/}
                    <Paper elevation={3} sx={{p: 3, mx: "auto"}}>
                        <div className={classes.topPadding}>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Company name')}
                                itemDescription={subscription.companyName}
                                style={{
                                    display: "inline-block", width: "70%"
                                }}
                            />
                            <TitleDescription
                                className={classes.item}
                                itemDescription={changeCompanyInfoButton}
                                style={{display: "inline-block", width: "30%", float: "right"}}
                            />
                        </div>
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Contact person')}
                            itemDescription={currentEmpl.fullname ? currentEmpl.fullname : '-'}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Email address')}
                            itemDescription={currentEmpl.username}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Telephone number')}
                            itemDescription={currentEmpl.phonenumber ? currentEmpl.phonenumber : '-'}
                        />
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Invoice email')}
                            itemDescription={subscription.factuurEmail ? subscription.factuurEmail : '-'}
                        />
                    </Paper>
                    <div className={classnames({
                        [classes.sectionTitle]: true, [classes.tableCardTitle]: true,
                    })}>
                        <div style={{display: "inline-block", width: "100%"}}>
                            {t('Subscription details')}
                        </div>
                        {/*<div style={{display: "inline-block", width: "calc(50%)"}}>*/}
                        {/*    {upgradeButton}*/}
                        {/*</div>*/}
                    </div>
                    <Paper elevation={3} sx={{p: 3, mx: "auto"}}>
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Subscription since')}
                            itemDescription={moment(subscription.dateCreatedOn, '').format('L')}
                        />
                        {!(subscription.credits_setup && !subscription.vv_functions) && (<TitleDescription
                            className={classes.item}
                            itemTitle={t('Number of employees')}
                            itemDescription={subscription.vv_functions ? totalEmployees + ' ' + activeEmployees : totalEmployees}
                        />)}
                        {!subscription.vv_functions && (<TitleDescription
                            className={classes.item}
                            itemDescription={null}
                        />)}
                        <div className={classes.topPadding}>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Subscription type')}
                                itemDescription={<div>{abonneeType} {abonneeType.length > 30 ?
                                    <br/> : ''}({noOfEmployees})</div>}
                                style={{
                                    display: "inline-block",
                                    width: (!subscription.vv_functions || !subscription.credits_setup || (subscription.maxEmployees > 0 && subscription.maxEmployees < 250)) ? "70%" : "100%"
                                }}
                            />
                            {(!subscription.vv_functions || !subscription.credits_setup || (subscription.maxEmployees > 0 && subscription.maxEmployees < 250)) &&
                                <TitleDescription
                                    className={classes.item}
                                    itemDescription={upgradeButton}
                                    style={{display: "inline-block", width: "30%", float: "right"}}
                                />
                            }
                        </div>
                        <div className={classes.topPadding}>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Video Toolboxes')}
                                itemDescription={<div>{videoToolboxType}{videoToolboxTypeNext ?
                                    <br/> : ''}{videoToolboxTypeNext ? videoToolboxTypeNext : ''}</div>}
                                style={{
                                    display: "inline-block", width: "70%"
                                }}
                            />
                            <TitleDescription
                                className={classes.item}
                                itemDescription={videoToolboxButton}
                                style={{display: "inline-block", width: "30%", float: "right"}}
                            />
                        </div>

                        {subscription.credits_setup && (<div className={classes.topPadding}>
                            <TitleDescription
                                className={classes.item}
                                itemTitle={t('Sneldelen Credits')}
                                itemDescription={creditsFriendly}
                                style={{display: "inline-block", width: "70%"}}
                            />
                            <TitleDescription
                                className={classes.item}
                                itemDescription={buyCreditsButton}
                                style={{display: "inline-block", width: "30%", float: "right"}}
                            />
                        </div>)}
                    </Paper>
                </div>
                <EmployeeEdit
                    open={dialogEndsWith}
                    editemployee={this.props.editemployee}
                    abonneehouder={true}
                    factuurEmail={subscription.factuurEmail}
                    subscription={subscription}
                    pageAfterSave={'/abonnement'}
                />
                <AbonnementEdit
                    open={showDetailDialog}
                    subscription={subscription}
                    onUpgraded={this.upgradeSubscription}
                    prices={this.state.prices}
                />
                <AbonnementCredits
                    open={showCreditsDialog}
                    subscription={subscription}
                    onUpgraded={this.upgradeSubscription}
                    prices={this.state.prices}
                />
                <AbonnementVideotoolboxes
                    open={showVideoToolboxesDialog}
                    subscription={subscription}
                    onUpgraded={this.upgradeSubscription}
                    prices={this.state.prices}
                />
            </AppModule>) : (<AppModule loading={false}>
            <div className="myContainer">{t('No page access')}</div>
        </AppModule>);
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),

    withTranslation())(Abonnement);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {push} from 'connected-react-router';
import {List} from 'immutable';
import moment from '../../../lib/moment';
import {
    acknowledgeProjectImport,
    clearProjectDiff,
    editProject,
    parseProjectImport,
    pushCrumbPath,
    showSnackbar,
    upsertProject,
} from '../../../actions';
import ProjectDiffRow from '../../Project/ProjectOverview/ProjectDiffRow';
import AppModule from '../../AppModule';
import ProjectImportDialog from '../../ProjectImportDialog';
import {withRouter} from 'react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import {fetchInspectionAssigns} from '../../../actions/inspectionActions';
import ProjectsListOverview from './ProjectsListOverview';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from "../../../i18n";
import * as api from "../../../lib/api";
import type {TProject} from "../../../reducers/projectsReducer";

type Props = {
    loading: boolean,
    projects: Array<any>,
    parseImport: (file: any) => void,
    acknowledgeImport: (file: any) => void,
    clearDiff: () => void,
    editProject: (project?: TProject) => void,
    editproject: TProject,
    onSave: (project?: TProject) => void,
    startEditProject: (project: TProject) => void,
    inProgress: boolean,
    diff: { removed: List<TProject>, added: List<TProject> },
    location: mixed,
    goTo: (path) => mixed,
};

type State = {
    page: 0,
    rowsPerPage: 10,
    showUpload: boolean,
    showDialogChoose: boolean,
    searchtext: string,
};

const mapStateToProps = (store) => {
    let inspectionAssigns = store.entities.inspectionAssigns;

    if (inspectionAssigns) {
        inspectionAssigns = inspectionAssigns.allIds.map(
            (id) => inspectionAssigns.byId[id]
        );
    }

    return {
        diff: store.drafts.projects.diff,
        inProgress: store.drafts.projects.inProgress,
        editproject: store.drafts.projects.editproject,
        inspectionAssigns: inspectionAssigns,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        parseImport: bindActionCreators(parseProjectImport, dispatch),
        acknowledgeImport: bindActionCreators(
            acknowledgeProjectImport,
            dispatch
        ),
        editProject: bindActionCreators(editProject, dispatch),
        editToolbox: (id) => {
            dispatch(push('/Project/' + id));
        },
        newProject: () => {
            dispatch(push('/project/aanmaken'));
        },
        onSave: (project) => {
            upsertProject(project)(dispatch);
        },
        clearDiff: () => {
            dispatch(clearProjectDiff());
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        showMessage: (message) => {
            dispatch(showSnackbar(message));
        },
    };
};

class ProjectOverview extends React.Component<Props, State> {
    myList = [];
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        startPage: 0,
        startRowsPerPage: 10,
        actions: [
            {
                id: 'edit',
                label: 'Edit',
                isVisible: (id) => this.isVisible(id),
            },
        ],
        columns: [
            {
                id: 'omschrijving',
                numeric: false,
                size: '30%',
                label: 'Project/work area',
            },
            {id: 'actions', numeric: true, size: '5%', label: ''},
        ],
        showUpload: false,
        showDialogChoose: false,
        searchtext: '',
        inspectionsAssignsByProject: [],
    };

    setPage = (page) => {
        console.log("INFO: ProjectOverview page = " + page);
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ProjectsListOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    componentDidMount() {
        this.props.fetchInspectionAssigns();

        // If url params rows and page go to right page
        if (this.props.location && this.props.location.search && this.props.location.search.includes("page")) {
            const jumpToPage = new URLSearchParams(this.props.location.search).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({startPage: Number(jumpToPage)});
        }
        if (this.props.location && this.props.location.search && this.props.location.search.includes("rowsPerPage")) {
            const startRowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({startRowsPerPage: Number(startRowsPerPage)});

        }

        if (this.props.location.hash === '#new') {
            this.openChooseDialog();
        }
    }

    addProject = (project) => {
        // About to ADD a project make sure it is UNIQUE
        const projectLijst = this.props.projects && this.props.projects.filter((p) => p.omschrijving === project.omschrijving);
        const found = projectLijst && projectLijst.find((p) => p.opdrachtgever === project.opdrachtgever);
        console.log('Found: ', projectLijst);

        if (found !== undefined) {
            if (!project.id) {
                // User tried to add project that already exists
                this.props.showMessage(`${i18n.t('The project already exists')}`);
            } else {
                // User changed project without changes...do noting
                this.setState({
                    showDialogChoose: false,
                });
                this.props.showMessage(`${i18n.t('project unchanged')}`);
            }
        } else {
            api.addProject(project)
                .then((entities) => {
                    this.setState({
                        showDialogChoose: false,
                    });
                    if (project.id) {
                        this.props.showMessage(`${i18n.t('project changed')}`);
                    } else {
                        this.props.showMessage(`${i18n.t('project added')}`);
                    }

                })
                .catch((error) => {
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while adding project'
                        )}: ` + error.message
                    )
                    throw error;
                });
        }
    }

    createInspectionByProjectList(activeProjects) {
        let inspectionsAssignsByProject = [];

        activeProjects.forEach((project) => {
            if (!inspectionsAssignsByProject[project.id]) {
                inspectionsAssignsByProject[project.id] = [];
            }
        });

        inspectionsAssignsByProject['999InvullenTijdenInspectie'] = [];

        this.props.inspectionAssigns.forEach((ia) => {
            if (ia.projectId) {
                if (inspectionsAssignsByProject[ia.projectId]) {
                    inspectionsAssignsByProject[ia.projectId].push(ia);
                } else {
                    inspectionsAssignsByProject[ia.projectId] = [ia];
                }
            }
        });

        return inspectionsAssignsByProject;
    }

    getLastSentDate(inspectionAssignsList) {
        let date;
        if (inspectionAssignsList && inspectionAssignsList[0]) {
            date = inspectionAssignsList[0].deployed;
            inspectionAssignsList.forEach((ia) => {
                if (moment(date).isBefore(moment(ia.deployed))) {
                    date = ia.deployed;
                }
            });
        }
        return date;
    }

    countFinishedInspectionsPerProject(inspectionAssignsList) {
        let finished = 0;
        inspectionAssignsList.forEach((ia) => {
            if (ia.result && ia.result.complete) {
                finished++;
            }
        });

        return finished;
    }

    isVisible = (rowId) => {
        const proj = this.props.projects.find((proj) => proj.id === rowId);

        if (proj) {
            return proj.importType !== 'IMPORT';
        }
        return true;
    };

    setEditProject = (project) => {
        this.setState({
            editProject: project,
            showDialogChoose: true
        });

    }

    mapProjectToRow = (project) => ({
        ...project,
        omschrijving: project.omschrijving,
        totalWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length
            : 0,
        lastSent: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? moment(
                    this.getLastSentDate(
                        this.state.inspectionsAssignsByProject[project.id]
                    )
                ).format('YYYY-MM-DD HH:mm')
                : '-'
            : '-',
        finishedWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? this.countFinishedInspectionsPerProject(
                    this.state.inspectionsAssignsByProject[project.id]
                )
                : '0'
            : '0',
        openWPIs: this.state.inspectionsAssignsByProject[project.id]
            ? this.state.inspectionsAssignsByProject[project.id].length !== 0
                ? this.state.inspectionsAssignsByProject[project.id].length -
                this.countFinishedInspectionsPerProject(
                    this.state.inspectionsAssignsByProject[project.id]
                )
                : '0'
            : '0',
    });

    ProjectStateToText = (state) => {
        const {t} = this.props;
        switch (state) {
            case 'ADDED':
                return t('Added');
            case 'ACTIVE':
                return t('Active');
            case 'INACTIVE':
                return t('Inactive');
            default:
                return state;
        }
    };

    saveAndClose = (project) => {
        this.addProject(project);
    }

    openUploadDialog = () => {
        this.setState({
            showUpload: true,
        });
        this.closeChooseDialog();
    };

    openChooseDialog = () => {
        this.setState({
            showDialogChoose: true,
            editProject: undefined
        });
    };

    closeChooseDialog = () => {
        this.setState({
            showDialogChoose: false,
        });
    };

    handleUpload = (file: any) => {
        this.props.parseImport(file);
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    acknowledgeImport = (file: any) => {
        this.props.acknowledgeImport(file);
        this.closeChooseDialog();
        this.props.clearDiff();
    };

    hideDialogChoose = () => {
        this.props.clearDiff();
        this.setState({
            showDialogChoose: false,
        });
    };

    mapToDiffRow = (list: List<TProject>) => {
        return list.map((project) => (
            <ProjectDiffRow project={project} key={project.externalId}/>
        ));
    };

    projectSorter = () => {
        return {
            omschrijving: (a, b) => {
                a = a.omschrijving.toLowerCase();
                b = b.omschrijving.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            totalWPIs: (a, b) => {
                a = this.state.inspectionsAssignsByProject[a.id].length;
                b = this.state.inspectionsAssignsByProject[b.id].length;
                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    onItemClicked = (clickedProject) => {
        console.log('INFO: ProjectOverview: onItemClicked: page: ' + this.state.page + ' rowsPerPage: ' + this.state.rowsPerPage);

        let target = 'projecten/details/' + clickedProject.id + '?page=' + this.state.page + '&rowsPerPage=' + this.state.rowsPerPage;
        // Set Project Crumb
        this.props.pushCrumbPath({
            crumb: {
                name: i18n.t('Projects'),
                link: `/projecten?page=${this.state.page}&rows=${this.state.rowsPerPage}`,
                subTitle: clickedProject.externalId,
            }
        });
        // Set Project Detail Crumb
        // this.props.pushCrumbPath({crumb: { name: clickedProject.externalId, link: `/projecten/details/${clickedProject.id}`}});

        this.props.goTo(target);
    };

    render() {
        const {loading, projects, t} = this.props;

        const activeProjects = projects.filter((project) => project.active);
        const projectList = projects.filter((project) => project.active);

        const searchresults = activeProjects.filter((row) => {
            let searchtext = this.state.searchtext;
            if (this.state.searchtext === undefined || this.state.searchtext === null || this.state.searchtext.length === 0) {
                searchtext = '';
            }
            return (
                row.omschrijving
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1 ||
                row.opdrachtgever
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1 ||
                row.externalId
                    .toLowerCase()
                    .indexOf(searchtext.toLowerCase()) > -1
            );
        });

        if (
            activeProjects.length !== 0 &&
            this.props.inspectionAssigns.length !== 0 &&
            !this.state.inspectionsAssignsByProject[
                '999InvullenTijdenInspectie'
                ]
        ) {
            this.myList = this.createInspectionByProjectList(activeProjects);
        }

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search projects / work areas')}
                searchAlwaysOpen={true}
                onSearchChange={this.handleSearch}
                title={
                    (activeProjects ? activeProjects.length : 0) +
                    ' ' +
                    t(activeProjects && activeProjects.length === 1 ? 'project/work area' : 'projects/work areas') +
                    (this.state.searchtext.length > 0
                        ? ' - ' + (searchresults ? searchresults.length : 0) +
                        ' ' + t(searchresults && searchresults.length === 1 ? 'searchresult' : 'searchresults')
                        : '')
                }
                onActionButton={this.openChooseDialog}
            />
        );

        return (
            <div>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <ProjectImportDialog
                        open={this.state.showDialogChoose}
                        handleClose={this.hideDialogChoose}
                        onUpload={this.handleUpload}
                        diff={this.props.diff}
                        acknowledgeImport={this.acknowledgeImport}
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.mapToDiffRow}
                        editproject={this.state.editProject}
                        onSave={this.saveAndClose}
                        projects={this.props.projects}
                    />

                    {/*<div*/}
                    {/*    className={classnames({*/}
                    {/*        [classes.button]: true,*/}
                    {/*        'mui-fixed': true,*/}
                    {/*    })}*/}
                    {/*>*/}
                    {/*    <Fab*/}
                    {/*        variant="circular"*/}
                    {/*        onClick={this.openChooseDialog}*/}
                    {/*    >*/}
                    {/*        <AddIcon/>*/}
                    {/*    </Fab>*/}
                    {/*</div>*/}

                    <ProjectsListOverview
                        onItemClick={this.onItemClicked}
                        getLastSentDate={this.getLastSentDate}
                        countFinishedInspectionsPerProject={
                            this.countFinishedInspectionsPerProject
                        }
                        inspectionsAssignsByProject={this.myList}
                        projects={projectList}
                        searchtext={this.state ? this.state.searchtext : ''}
                        editThisProject={this.setEditProject}
                        setPage={this.setPage}
                        setRowsPerPage={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}
                    />
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ProjectOverview);

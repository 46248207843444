// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Dialog, DialogTitle} from '@mui/material';
import {bindActionCreators} from 'redux';
import {editProject, upsertProject} from '../../../actions';
import {inProgress} from '../../../selectors';
import type {TProject} from '../../../reducers/projectsReducer';
import ProjectEditCard from '../ProjectEditCard';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    loading: boolean,
    open: boolean,
    editproject?: TProject,
    goBack: () => mixed,
    onEdit: (project?: TProject) => mixed,
    editProject: (project: TProject) => mixed,
    onAfterSave?: (project: TProject) => mixed,
    backOnCancel?: boolean,
    onCancel: () => mixed,
};

type State = {};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSave: (project, t) => {
            // Only omschrijving or opdrachtgeven can be edited so only send these fields.
            let projectChanges = {
                id: project.id,
                omschrijving: project.omschrijving,
                opdrachtgever: project.opdrachtgever,
            };
            upsertProject(projectChanges, t)(dispatch);
            if (props.backOnCancel !== false) {
                dispatch(push('/projecten'));
            }

            if (props.onAfterSave) {
                props.onAfterSave(project);
            }
        },
        onClose: () => {
            dispatch(push('/medewerkers'));
        },
        editProject: bindActionCreators(editProject, dispatch),
        goBack: () => {
            dispatch(push('/projecten'));
        },
    };
};

class ProjectEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    cancelEdit = () => {
        if (this.props.backOnCancel !== false) {
            this.props.onEdit(undefined);
            this.props.goBack();
        }

        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    render() {
        //Als we geen projects hebben niets renderen
        if (!this.props.editproject) {
            return <div/>;
        }

        const {open, t} = this.props;

        return (
            <Dialog open={open} onClose={this.cancelEdit}>
                {this.props.backOnCancel === false && (
                    <DialogTitle>{t('Add project / work area')}</DialogTitle>
                )}
                {this.props.backOnCancel !== false && (
                    <DialogTitle>{t('Edit project')}</DialogTitle>
                )}
                <ProjectEditCard
                    project={this.props.editproject}
                    onEdit={this.props.editProject}
                    onSave={this.props.onSave}
                    goBack={this.cancelEdit}
                />
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ProjectEdit);

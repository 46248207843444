// @flow
import React from 'react';
import {TableCell, TableRow} from '@mui/material';
import {percentColors3} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    title: string,
    latestVersion: number,
    totalSend: number,
    totalEmployees: number,
    totalCompleted: number,
    logo: string,
};

const StatisticsRowToolbox = (props: Props, context: Object) => {
    let {
        title,
        latestVersion,
        totalSend,
        nrToolboxMeetings,
        individualToolbox,
        totalCompleted,
        answersCorrect,
        answersIncorrect,
        logo,
        handleRowClick,
        t,
        ...childProps
    } = props;
    let toolTipText;

    logo === '/assets/images/cumela-logo.png'
        ? (toolTipText = 'Cumela toolbox')
        : logo === '/assets/images/vvw-admin-logo.svg'
            ? (toolTipText = 'Veilig Vakwerk toolbox')
            : logo === '/assets/images/aangepast.svg'
                ? (toolTipText = 'Aangepaste toolbox')
                : (toolTipText = 'Eigen toolbox');

    return (
        <TableRow
            {...childProps}
            className="selectable"
            onClick={handleRowClick}
        >
            <TableCell style={{width: '20px'}}>
                <div className={'tooltip'} style={{padding: 0}}>
                    <span
                        className={'tooltiptext'}
                        style={
                            toolTipText === 'Cumela toolbox'
                                ? {top: '-10px', left: '65px'}
                                : toolTipText === 'Veilig Vakwerk toolbox'
                                    ? {top: '-10px', left: '50px'}
                                    : toolTipText === 'Aangepaste toolbox'
                                        ? {top: '-10px', left: '55px'}
                                        : {top: '-10px', left: '70px'}
                        }
                    >
                        {toolTipText}
                    </span>
                    <img height="20" width="20" src={logo} alt="logo"/>
                </div>
            </TableCell>
            <TableCell style={{width: '40%', wordBreak: 'break-all'}}>
                {title || '-'}
            </TableCell>
            <TableCell style={{width: '20%'}}>{latestVersion}</TableCell>
            <TableCell style={{width: '20%'}}>{individualToolbox}</TableCell>
            <TableCell style={{width: '20%'}}>{nrToolboxMeetings}</TableCell>
            <TableCell style={{width: '20%'}}>
                {' '}
                <div
                    className={'tooltip'}
                    style={{position: 'relative', display: 'inline-block'}}
                >
                    <span className={'tooltiptext'} style={{left: '-30px'}}>
                        {totalCompleted +
                            t(' of ') +
                            totalSend +
                            ' ' +
                            t('employees have completed the toolbox')}
                    </span>
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor: getColorForPercentage(totalCompleted / totalSend, answersIncorrect),
                            borderColor: getColorForPercentage(totalCompleted / totalSend, answersIncorrect),
                            width: '60px',
                        }}
                    >
                        {totalCompleted + '/' + totalSend}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};

var percentColors = percentColors3;

var getColorForPercentage = function (pct, answersIncorrect) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1 && answersIncorrect === 0) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(withTranslation())(StatisticsRowToolbox);

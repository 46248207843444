// @flow
import React from 'react';
import {AppBar, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography} from '@mui/material';
import AppModule from '../AppModule';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LanguageDropdown from '../LanguageDropdown';
import Cookies from 'js-cookie';
import {getLanguage} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {getModules} from '../lib';
import classes from './introduction.module.scss'

const locale = require('browser-locale')();

type Props = {
    toolboxId: string,
    toolbox: Array<any>,
};

type State = {
    feedbackText: string,
    questionsFinished: any,
    title: String,
    subText: String,
    image: String,
    stepIndex: number,
};

class Introduction extends React.Component<Props, State> {
    props: Props;
    //hier
    state: State = {
        stepIndex: 0,
        carrousel: null,
        buttonText: 'OVERSLAAN',
        title: 'Toolboxen',
        subText:
            'Verbeter en test je kennis door middel van interactieve multimedia toolboxen',
        image: 'url(/assets/images/toolbox-logo.png)',
        open: false,
    };

    componentDidMount() {
        const { t } = this.props;
        const modules = getModules()

        const lang =
            new URLSearchParams(window.location.search).get('language') ||
            Cookies.get('lang');

        const { i18n } = this.props;

        if (!lang) {
            const browserLanguage = getLanguage(locale);
            this.setState({ open: true });

            i18n.changeLanguage(browserLanguage);
        } else {
            i18n.changeLanguage(lang);
            Cookies.set('lang', lang, { expires: 365 });
        }

        let step2Title = 'Report registration';
        let step4SubText = 'Use Veilig Vakwerk as a reference, the information can be consulted anytime and anywhere';
        let toolboxLogo = 'toolbox-logo.png';
        let wpiLogo = 'wpi-logo.png';
        let incidentLogo = 'incident-logo.png';
        if (process.env.REACT_APP_PROJECT === 'janssen') {
            step2Title = 'SpeakUp Report';
            step4SubText = 'Use the JAJO-App as a reference, the information can be consulted anytime and anywhere';
            toolboxLogo = 'cat_jajo_icon_1.svg';
            wpiLogo = 'cat_jajo_icon_2.svg';
            incidentLogo = 'cat_jajo_icon_3.svg';
        }

        let carrousel = [];
        if (modules.inspections) {
            carrousel = [
                {title: 'Toolboxes', subText: t('Improve and test your knowledge through interactive multimedia toolboxes'), image: 'url(/assets/images/' + toolboxLogo + ')'},
                {title: 'Workplace inspections', subText: t('Easily carry out workplace inspections and substantiate shortcomings with photos and text'), image: 'url(/assets/images/' + wpiLogo + ')'},
                {title: step2Title, subText: t('Actively contribute to a safe (er) work environment by submitting reports'), image: 'url(/assets/images/' + incidentLogo + ')'},
                {title: 'Archive', subText: t(step4SubText), image: 'url(/assets/images/archief-logo.png)'},
                {title: 'Start immediately', subText: t('Log in with the email address and password you received from your employer'), image: 'url(/assets/images/start-logo.png)'}
            ];

        } else {
            carrousel = [
                {title: 'Toolboxes', subText: t('Improve and test your knowledge through interactive multimedia toolboxes'), image: 'url(/assets/images/' + toolboxLogo + ')'},
                {title: step2Title, subText: t('Actively contribute to a safe (er) work environment by submitting reports'), image: 'url(/assets/images/' + incidentLogo + ')'},
                {title: 'Archive', subText: t(step4SubText), image: 'url(/assets/images/archief-logo.png)'},
                {title: 'Start immediately', subText: t('Log in with the email address and password you received from your employer'), image: 'url(/assets/images/start-logo.png)'}
            ];
        }

        this.setState({
            carrousel: carrousel,
            stepIndex: 0,
            buttonText: 'SKIP',
            title: 'Toolboxes',
            subText: t(
                'Improve and test your knowledge through interactive multimedia toolboxes'
            ),
            image: 'url(/assets/images/toolbox-logo.png)',
        });

    }

    handleLanguageChange = () => {
        const { t } = this.props;
        const modules = getModules()

        let step2Title = 'Report registration';
        let step4SubText = 'Use Veilig Vakwerk as a reference, the information can be consulted anytime and anywhere';
        let toolboxLogo = 'toolbox-logo.png';
        let wpiLogo = 'wpi-logo.png';
        let incidentLogo = 'incident-logo.png';
        if (process.env.REACT_APP_PROJECT === 'janssen') {
            step2Title = 'SpeakUp Report';
            step4SubText = 'Use Janssen de Jong as a reference, the information can be consulted anytime and anywhere';
            toolboxLogo = 'cat_jajo_icon_1.svg';
            wpiLogo = 'cat_jajo_icon_2.svg';
            incidentLogo = 'cat_jajo_icon_3.svg';
        }

        let carrousel = null;
        if (modules.inspections) {
            carrousel = [
                {title: 'Toolboxes', subText: t('Improve and test your knowledge through interactive multimedia toolboxes'), image: 'url(/assets/images/' + toolboxLogo + ')'},
                {title: 'Workplace inspections', subText: t('Easily carry out workplace inspections and substantiate shortcomings with photos and text'), image: 'url(/assets/images/' + wpiLogo + ')'},
                {title: step2Title, subText: t('Actively contribute to a safe (er) work environment by submitting reports'), image: 'url(/assets/images/' + incidentLogo + ')'},
                {title: 'Archive', subText: t(step4SubText), image: 'url(/assets/images/archief-logo.png)'},
                {title: 'Start immediately', subText: t('Log in with the email address and password you received from your employer'), image: 'url(/assets/images/start-logo.png)'}
            ];

        } else {
            carrousel = [
                {title: 'Toolboxes', subText: t('Improve and test your knowledge through interactive multimedia toolboxes'), image: 'url(/assets/images/' + toolboxLogo + ')'},
                {title: step2Title, subText: t('Actively contribute to a safe (er) work environment by submitting reports'), image: 'url(/assets/images/' + incidentLogo + ')'},
                {title: 'Archive', subText: t(step4SubText), image: 'url(/assets/images/archief-logo.png)'},
                {title: 'Start immediately', subText: t('Log in with the email address and password you received from your employer'), image: 'url(/assets/images/start-logo.png)'}
            ];
        }

        this.setState({
            carrousel: carrousel
        });
    };

    handleNextStep = () => {
        let maxStep = this.state.carrousel.length -1;
        let nextStep = this.state.stepIndex + 1;
        if (nextStep >= maxStep) {
            nextStep = maxStep;
        }

        this.setState({
            stepIndex: nextStep,
        });
    };

    handleBackStep = () => {
        this.setState({
            stepIndex: this.state.stepIndex - 1,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.handleLanguageChange();
    };

    render() {
        const { t } = this.props;
        const {
            stepIndex,
            open,
        } = this.state;

        const title = this.state.carrousel ? this.state.carrousel[stepIndex].title : "";
        const subText = this.state.carrousel ? this.state.carrousel[stepIndex].subText : "";
        const image = this.state.carrousel ? this.state.carrousel[stepIndex].image : "";

        const carrouselLength = this.state.carrousel ? this.state.carrousel.length : 0;

        let buttonText = t('SKIP');
        if ((stepIndex + 1) === carrouselLength) {
            buttonText = t('LOGIN');
        }
        const modules = getModules()

        // window.$crisp.push(['do', 'chat:hide']);
        return (
            <div
                className="App"
                style={{
                    minWidth: '128px',
                    maxWidth: '768px',
                    margin: '0 auto',
                }}
            >
                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        <Typography
                            type="title"
                            id="headerTitle"
                            style={{ marginLeft: '25px' }}
                            className={classes.toolbarFontStyle}
                        >
                            {process.env.REACT_APP_TITLE || 'Veilig Vakwerk'}
                        </Typography>
                        <LanguageDropdown
                            className={classes.dropdown}
                            labelColor={classes.labelColor}
                            selectRootColor={classes.selectRootColor}
                            onChange={this.handleLanguageChange}
                        />
                    </Toolbar>
                </AppBar>

                <AppModule>
                    {window.innerWidth > 768 ? (
                        <div
                            id="tbExternPaperFeedback"
                            style={{ display: 'block' }}
                        >
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = 'white')
                                }
                            </div>
                            <div
                                style={{
                                    width: '703px',
                                    height: '348px',
                                    margin: 'auto',
                                    marginTop: '338px',
                                    display: 'block',
                                }}
                            >
                                <div
                                    style={{
                                        width: '250px',
                                        height: '100%',
                                        backgroundImage: image,
                                        backgroundRepeat: 'no-repeat',
                                        float: 'left',
                                    }}
                                >
                                    {stepIndex !== 0 && (
                                        <IconButton
                                            style={{
                                                float: 'left',
                                                marginTop: '100px',
                                                marginLeft: '-100px',
                                            }}
                                            onClick={this.handleBackStep}
                                        >
                                            <ChevronLeftIcon
                                                style={{
                                                    width: '36px',
                                                    height: '36px',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </div>
                                <div
                                    style={{
                                        width: '403px',
                                        float: 'right',
                                        marginLeft: '50px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <h2
                                        style={{
                                            fontSize: '32px',
                                            fontFamily:
                                                'filson-soft, sans-serif',
                                        }}
                                    >
                                        {t(title)}
                                    </h2>
                                    <p style={{ opacity: '0.5' }}>{subText}</p>
                                    <a
                                        href="/web/login"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <div
                                            style={{
                                                width: '258px',
                                                height: '52px',
                                                backgroundColor: '#272D35',
                                                marginTop: '50px',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    paddingTop: '15px',
                                                }}
                                            >
                                                {t(buttonText)}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {stepIndex !== 4 && (
                                <IconButton
                                    style={{
                                        float: 'right',
                                        marginTop: '-250px',
                                        marginRight: '-50px',
                                    }}
                                    onClick={this.handleNextStep}
                                >
                                    <ChevronRightIcon
                                        style={{
                                            width: '36px',
                                            height: '36px',
                                        }}
                                    />
                                </IconButton>
                            )}
                            <div
                                style={{
                                    marginLeft: '400px',
                                    marginTop: '-50px',
                                    display: 'block',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: '#272D35',
                                        height: '8px',
                                        width: '8px',
                                        borderRadius: '25px',
                                        opacity: stepIndex === 0 ? '1' : '0.24',
                                        float: 'left',
                                    }}
                                />
                                <div
                                    style={{
                                        backgroundColor: '#272D35',
                                        height: '8px',
                                        width: '8px',
                                        borderRadius: '25px',
                                        opacity: stepIndex === 1 ? '1' : '0.24',
                                        float: 'left',
                                        marginLeft: '10px',
                                    }}
                                />
                                <div
                                    style={{
                                        backgroundColor: '#272D35',
                                        height: '8px',
                                        width: '8px',
                                        borderRadius: '25px',
                                        opacity: stepIndex === 2 ? '1' : '0.24',
                                        float: 'left',
                                        marginLeft: '10px',
                                    }}
                                />
                                <div
                                    style={{
                                        backgroundColor: '#272D35',
                                        height: '8px',
                                        width: '8px',
                                        borderRadius: '25px',
                                        opacity: stepIndex === 3 ? '1' : '0.24',
                                        float: 'left',
                                        marginLeft: '10px',
                                    }}
                                />
                                { modules.inspections && (
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity: stepIndex === 4 ? '1' : '0.24',
                                            float: 'left',
                                            marginLeft: '10px',
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div style={{ visibility: 'hidden' }}>
                                {
                                    (document.getElementById(
                                        'html'
                                    ).style.backgroundColor = 'white')
                                }
                            </div>
                            <div
                                style={{
                                    width: '250px',
                                    height: '250px',
                                    backgroundImage: image,
                                    backgroundRepeat: 'no-repeat',
                                    margin: 'auto',
                                    marginTop: '100px',
                                }}
                            />
                            <div
                                style={{ margin: 'auto', textAlign: 'center' }}
                            >
                                <h2
                                    style={{
                                        fontSize: '32px',
                                        fontFamily: 'filson-soft, sans-serif',
                                    }}
                                >
                                    {t(title)}
                                </h2>
                                <div>
                                    {stepIndex !== 0 && (
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                left: '20px',
                                            }}
                                            onClick={this.handleBackStep}
                                        >
                                            <ChevronLeftIcon
                                                style={{
                                                    width: '36px',
                                                    height: '36px',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                    {stepIndex !== 4 && (
                                        <IconButton
                                            style={{
                                                position: 'absolute',
                                                right: '20px',
                                            }}
                                            onClick={this.handleNextStep}
                                        >
                                            <ChevronRightIcon
                                                style={{
                                                    width: '36px',
                                                    height: '36px',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </div>
                                <p
                                    style={{
                                        opacity: '0.5',
                                        width: '60%',
                                        margin: 'auto',
                                        height: '70px',
                                    }}
                                >
                                    {subText}
                                </p>
                                <div
                                    style={{
                                        margin: 'auto',
                                        display: 'block',
                                        marginTop: '20px',
                                        width: '80px',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity:
                                                stepIndex === 0 ? '1' : '0.24',
                                            float: 'left',
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity:
                                                stepIndex === 1 ? '1' : '0.24',
                                            float: 'left',
                                            marginLeft: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity:
                                                stepIndex === 2 ? '1' : '0.24',
                                            float: 'left',
                                            marginLeft: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity:
                                                stepIndex === 3 ? '1' : '0.24',
                                            float: 'left',
                                            marginLeft: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundColor: '#272D35',
                                            height: '8px',
                                            width: '8px',
                                            borderRadius: '25px',
                                            opacity:
                                                stepIndex === 4 ? '1' : '0.24',
                                            float: 'left',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </div>
                            </div>
                            <a
                                href="/web/login"
                                style={{ textDecoration: 'none' }}
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        height: '52px',
                                        backgroundColor: '#272D35',
                                        marginTop: '50px',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: 'white',
                                            textAlign: 'center',
                                            paddingTop: '15px',
                                        }}
                                    >
                                        {t(buttonText)}
                                    </p>
                                </div>
                            </a>
                        </div>
                    )}
                </AppModule>
                <Dialog open={open} onClose={this.handleClose}>
                    <DialogTitle className="title">
                        {t('Select preferred language')}
                    </DialogTitle>
                    <DialogContent>
                        <LanguageDropdown
                            onChange={this.handleClose}
                            extraKey="loginLang"
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    withTranslation()
)(Introduction);

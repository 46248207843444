// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classes from './VideoBlockPreview.module.scss'
import ReactPlayer from "react-player";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

type Props = {
    block: any, classes: any
};

class VideoBlockPreview extends React.Component {
    constructor(props) {
        super(props);
        this.parentRef = React.createRef();
        this.state = {
            parentWidth: 0
        };
    }

    componentDidMount() {
        this.updateParentWidth();
        window.addEventListener('resize', this.updateParentWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateParentWidth);
    }

    updateParentWidth = () => {
        if (this.parentRef.current) {
            const width = this.parentRef.current.getBoundingClientRect().width;
            this.setState({parentWidth: width});
        }
    };

    render() {
        const {block} = this.props;
        const {parentWidth} = this.state;

        const isNormalYoutubeLink = block.url && block.url.toLowerCase().includes("youtu") && block.url.toLowerCase().includes("?v=");
        const isMobileYoutubeLink = block.url && block.url.toLowerCase().includes("youtu") && block.url.toLowerCase().includes(".be/");
        const isShortLink = block.url && block.url.toLowerCase().includes("shorts");

        let youtubeSrc = isNormalYoutubeLink ? "https://www.youtube.com/embed/" + block.url.split('?v=')[1] : isMobileYoutubeLink ? "https://www.youtube.com/embed/" + block.url.split('.be/')[1] : (isShortLink ? "https://www.youtube.com/embed/" + block.url.split('youtube.com/shorts/')[1] : null);

        if (youtubeSrc !== null) {
            // A short link looks like this: https://youtube.com/shorts/7kdfdVswIDI?si=dRRAegY2RlmiQpjp
            // Strip ?si=* from the src to keep only: https://youtube.com/shorts/7kdfdVswIDI
            youtubeSrc = youtubeSrc.replace(/\?si=.*$/, "");
            youtubeSrc = youtubeSrc.replace(/&t=.*$/, "");
        }

        return (<div ref={this.parentRef} className={classes.root}>
            {block.url && (<ReactPlayer controls url={youtubeSrc ? youtubeSrc : block.url} width={parentWidth}
                                        height={parentWidth * 9 / 16}/>)}
        </div>)
    }
}

export default compose(connect(null, null), withTranslation())(VideoBlockPreview);
// @flow
import React from "react"; // eslint-disable-line no-unused-vars
import {connect} from "react-redux";
import {
    deleteDocument,
    documentDownloaded,
    fetchDocuments,
    processEntityForm,
    setCrumbPath,
    setViewkey,
    uploadDocument,
} from "../../actions";
import AppModule from "../AppModule";
import EnhancedTable from "../EnhancedTable";
import moment from "../../lib/moment";
import "moment/min/locales";
import {inProgress} from "../../selectors";
import TableActionHeader from "../TableActionHeader/TableActionHeader";
import ConfirmDialog from "../ConfirmDialog";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import {bytesToMB, sorter} from "./lib";
import JsFileDownloader from "js-file-downloader";
import Spinner from "../Spinner";
import classes from "./Documents.module.scss"

moment.locale(i18n.t("LOCALE"));
const queryName = 'documents';

const viewkey: string = "DOCUMENT_VIEW";

const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        documents: store.entities.document,
        employees: store.entities.employees,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchDocuments: () => {
            dispatch(fetchDocuments());
        },
        documentDownloaded: (data, state) => {
            dispatch(documentDownloaded(data, state));
        },
        onSelectFile: (file) => {
            dispatch(processEntityForm("document", uploadDocument(file)));
        },
        deleteDocument: (data) => {
            dispatch(deleteDocument(data));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: "Documents"}));
        },
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
    };
};

class Documents extends React.Component<Props, State> {
    props: Props;
    state: State = {
        columns: [],
        actions: [],
        queryData: {},
        searchtext: "",
        showDeleteDialog: false,
        downloading: false,
        showSpinner: false,
    };

    componentDidMount() {
        const {t} = this.props;
        this.props.setViewkey();
        this.props.setCrumbPath();
        this.props.fetchDocuments();

        var appUser = false;
        if (this.props.match.path === '/web/documents') {
            appUser = true;
        }
        this.setState({appUser: appUser});

        let actions = [];
        if (!appUser) {
            actions.push({id: "delete", label: t("Delete")});
        }

        this.setState({actions});

        moment.locale(i18n.t("LOCALE"));

        var columns = [];

        // General Columns for App User and KAM
        columns.push(
            {id: "date", label: "Date", size: "15%"},
            {id: "description", label: "Description", size: "55%"},
            {id: "size", label: "Size", size: "15%"});

        if (!this.state.appUser) {
            // Extra column for KAM Coordinator
            columns.push(
                {id: "downloaded", label: "Number of downloads", size: "15%"});
        }

        this.setState({columns: columns});
    }

    handleAction = (event, action) => {
        if (action.id === "delete") {
            const row = this.props[queryName].byId[action.rowId];
            this.setState({
                rowToDelete: row,
                queryData: row,
                showDeleteDialog: true,
            });
        }
    };

    mapDataToRow = (data) => {
        moment.locale(i18n.t("LOCALE"));

        return {
            ...data,
            date: !data.date ? (
                "-"
            ) : moment(data.date).format("L HH:mm"),
            size: bytesToMB(data.size) + " MB",
            description: data.name || "",
            downloaded: data.downloadCount || 0,
        };
    };
    setDownloading = (data) => {
        this.setState({downloading: data});
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    onRowClick = (row, event) => {
        this.setDownloading(true);
        const clickedRow = this.props[queryName].byId[row.id];

        new JsFileDownloader({
            url: clickedRow.url,
            filename: clickedRow.name,
        })
            .then(() => {
                this.setDownloading(false);
                this.props.documentDownloaded(clickedRow, true);
            })
            .catch(() => {
                this.setDownloading(false);
                this.props.documentDownloaded(clickedRow, false);
            });
    };

    handleAddButtonClick = () => {
        this.inputElement.click();
    };

    handleInputChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            // Test filesize
            if (event.target.files[0].size > 3000000) {
                alert(i18n.t('The selected file exceeds tha maximum of 3Mb'));

            } else {
                var newDocCnt = 1;
                if (this.props.documents) {
                    newDocCnt = 1 + this.props.documents.allIds.length;
                }
                this.setState({
                    showSpinner: true,
                    newDocCnt: newDocCnt,
                })
                this.props.onSelectFile(event.target.files[0]);
            }
        }
    };

    deleteDocument = () => {
        this.props.deleteDocument(this.state.queryData);
    };

    hideDeleteDialog = () => {
        this.setState({
            showDeleteDialog: false,
        });
    };

    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.documents && prevProps.documents) {
            if (this.props.documents.allIds.length === this.state.newDocCnt && this.state.showSpinner === true) {
                this.setState({
                    showSpinner: false
                });
            }
        }
    }

    render() {
        const {loading, t} = this.props;
        const {actions, columns, downloading, showSpinner} = this.state;

        let rows = [];

        this.props[queryName] &&
        this.props[queryName].allIds &&
        this.props[queryName].allIds.forEach((id) => {
            rows.push(this.props[queryName].byId[id]);
        });

        const searchresults = rows
            .filter((doc) => {
                let textFound = false;
                textFound = doc.name.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1;

                return (textFound);
            });

        const tableActionHeader = (
            <TableActionHeader
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search documents')}
                searchAlwaysOpen={true}
                onActionButton={this.handleAddButtonClick}
            />
        );

        return (
            <div>
                <ConfirmDialog
                    open={this.state.showDeleteDialog}
                    handleClose={this.hideDeleteDialog}
                    deletedocument={this.state.rowToDelete}
                    deleteDocumentFunction={this.deleteDocument}
                />
                {/*{*/}
                {/*    !this.state.appUser &&*/}
                {/*    <FixedAddButton onClick={this.handleAddButtonClick} tooltipText={t("Create new document")}/>*/}
                {/*}*/}
                <input
                    className={classes.hidden}
                    type="file"
                    accept=".pdf"
                    ref={(input) => (this.inputElement = input)}
                    onChange={this.handleInputChange}
                />
                <AppModule
                    loading={loading || downloading}
                    prepend={tableActionHeader}
                >

                    <EnhancedTable
                        hover
                        defaultOrderBy={"date"}
                        defaultOrder="desc"
                        columns={columns}
                        onClick={this.onRowClick}
                        rows={searchresults}
                        formatter={this.mapDataToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={sorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t("No searchresults")
                                : t("No documents yet")
                        }
                    />
                    <div>
                        {showSpinner && (
                            <div className={classes.spinnerRoot}>
                                <div className={classes.spinner}>
                                    <Spinner/>
                                </div>
                            </div>
                        )}
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(Documents);

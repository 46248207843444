// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import './ToolboxDetail.css';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@mui/material';
import {deleteToolboxAssign, pushCrumbPath, sendToolboxReminderToUser} from '../../actions';
import {formatFullName, formatV2User, percentColors4} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    loading: boolean,
    toolboxResultsOverview: any,
    toolbox: any,
    deploymentId: number
};

type State = {
    page: number,
    rowsPerPage: number,
    columns: Array<any>,
    showSendReminderDialog: boolean,
    reminderToSend: any
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        goToDetailView: (row, deploymentId) => {
            dispatch(push('/toolbox/details/' + props.toolbox.id + '/' + deploymentId + '/results/' + row.user.id + '/' + row.id));
        },
        deleteToolbox: (toolboxId: string, redirect: any, id: any) => {
            dispatch(deleteToolboxAssign(toolboxId, redirect, id, props.deploymentId));
        },
        sendReminder: (toolboxId, deploymentId, userId) => {
            dispatch(sendToolboxReminderToUser(toolboxId, deploymentId, userId));
        },
    };
};

const mapStateToProps = (store, props) => {
    const toolboxResultsOverview = props.toolboxResultsOverview;

    return {
        toolboxResultsOverview: toolboxResultsOverview,
    };
};

class ToolboxDetail extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        columns: [
            {
                id: 'employee',
                numeric: false,
                size: '70%',
                label: 'Medewerker',
            },
            {
                id: 'finished',
                numeric: false,
                size: '15%',
                label: 'Afgerond op',
            },
            {id: 'score', numeric: false, size: '15%', label: 'Score'},
        ],
        actions: [
            {
                id: 'reminder',
                label: 'Herinnering sturen',
                isVisible: (id) => this.isReminderOptionVisible(id),
            },
            {id: 'delete', label: 'Resultaat verwijderen', isVisible: true},
        ],
        showSendReminderDialog: false
    };

    isReminderOptionVisible = (rowId) => {
        let assign = this.props.toolboxResultsOverview.filter(
            (assign) => assign.id === rowId
        );
        return !assign[0].user.extern && !assign[0].completed;
    };

    componentDidMount() {
        const {t} = this.props;

        this.setState({
            columns: [
                {
                    id: 'employee',
                    numeric: false,
                    size: '70%',
                    label: t('Employee'),
                },
                {
                    id: 'finished',
                    numeric: false,
                    size: '15%',
                    label: t('Completed on'),
                },
                {id: 'score', numeric: false, size: '15%', label: t('Score')},
            ],
            actions: [
                {
                    id: 'reminder',
                    label: 'Send reminder',
                    isVisible: (id) => this.isReminderOptionVisible(id),
                },
                {id: 'delete', label: 'Delete result', isVisible: true},
            ],
        });

        if (this.props.location && this.props.location.hash && this.props.location.hash.includes("resultaten")) {
            const searchString = '?' + this.props.location.hash.split("?")[1]
            console.log("INFO: Custom searchString: " + searchString);

            if (searchString.includes("page")) {
                const jumpToPage = new URLSearchParams(searchString).get("page");
                console.log("INFO: page from SearchParams: " + jumpToPage);
                this.setState({startPage: Number(jumpToPage)});
                this.setState({page: Number(jumpToPage)});
            }
            if (searchString.includes("rowsPerPage")) {
                const startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
                console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
                this.setState({startRowsPerPage: Number(startRowsPerPage)});
                this.setState({rowsPerPage: Number(startRowsPerPage)});
            }
        }

    }

    mapToolboxToRow = (assignRow) => {
        const {t} = this.props;

        let fullName = formatV2User(assignRow.user);

        return {
            ...assignRow,
            fullnameUser: fullName, // Is this used somewhere?
            employee: assignRow.user.extern
                ? fullName +
                ' (' +
                t('external') +
                ')'
                : fullName,
            finished:
                assignRow.completed
                    ? assignRow.finishDate
                        ? moment(assignRow.finishDate, '').format('DD/MM/YYYY, HH:mm')
                        : t('Unknown')
                    : '-',
            score:
                assignRow.completed ? (
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor:
                                assignRow.totalAnswers > 1
                                    ? getColorForPercentage2(
                                        assignRow.correctAnswers /
                                        assignRow.totalQuestions
                                    )
                                    : assignRow.correctAnswers >= 1
                                        ? getColorForPercentage(1)
                                        : getColorForPercentage(0.5),
                            borderColor:
                                assignRow.totalAnswers > 1
                                    ? getColorForPercentage2(
                                        assignRow.correctAnswers /
                                        assignRow.totalQuestions
                                    )
                                    : assignRow.correctAnswers >= 1
                                        ? getColorForPercentage(1)
                                        : getColorForPercentage(0.5),
                            width: '40px',
                        }}
                    >
                        {assignRow.correctAnswers + '/' + assignRow.totalQuestions}
                    </div>
                ) : (
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor: getColorForPercentage(0),
                            borderColor: getColorForPercentage(0),
                            width: '90px',
                        }}
                    >
                        {t('Not completed')}
                    </div>
                ),
        };
    };

    setPage = (page) => {
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        this.setState({rowsPerPage: rowsPerPage});
    }

    handleAction = (event, action) => {
        if (action.id === 'reminder') {
            let assign = this.props.toolboxResultsOverview.find((assign) => assign.id === action.rowId)
            this.setState({
                reminderToSend: assign
            });
            this.showSendReminderDialog();
        } else if (action.id === 'delete') {
            const toolboxAssign = this.props.toolboxResultsOverview.find(
                (toolboxAssign) => toolboxAssign.id === action.rowId
            );

            this.setState({toolboxToDelete: toolboxAssign.id});

            this.showDeleteDialog();
        }
    };

    confirmDelete = () => {
        if (this.state.toolboxToDelete === this.props.toolboxResultsOverview[0].id) {
            if (
                this.props.toolboxResultsOverview[1] &&
                this.props.toolboxResultsOverview[1].id
            ) {
                this.props.deleteToolbox(
                    this.state.toolboxToDelete,
                    this.props.toolboxResultsOverview[1].id,
                    this.props.toolbox.id
                );
            } else {
                this.props.deleteToolbox(
                    this.state.toolboxToDelete,
                    -1,
                    this.props.toolbox.id
                );
            }
        } else {
            this.props.deleteToolbox(
                this.state.toolboxToDelete,
                false,
                this.props.toolbox.id
            );
        }

        this.setState({
            showDeleteDialog: false,
        });
    };

    showSendReminderDialog = () => {
        this.setState({
            showSendReminderDialog: true,
        });
    };

    onRowClick = (row, event) => {
        if (row.id) {
            let user = row.user;
            // Set crumb for the result view for this user: dispatch(push('/toolbox/details/' + props.toolbox.id + '/' + deploymentId + '/results/' + row.user.id + '/' + row.id));
            let page = this.state.page ? this.state.page : 0;
            let rowsPerPage = this.state.rowsPerPage ? this.state.rowsPerPage : 0;
            // set Crumb for the current location
            this.props.pushCrumbPath({
                crumb: {
                    name: this.props.toolbox.title,
                    link: `/toolbox/details/${this.props.toolbox.id}/${this.props.deploymentId}#resultaten?page=${page}&rows=${rowsPerPage}`,
                    subTitle: formatFullName(user.firstName, user.middleName, user.lastName),
                }
            });

            this.props.goToDetailView(row, this.props.deploymentId);
        }
    };

    showDeleteDialog = () => {
        this.setState({
            showDeleteDialog: true,
        });
    };

    toolboxSorter = () => {
        return {
            finished: (a, b) => {
                if (a.finishDate == null) {
                    return -1;
                }
                if (b.finishDate == null) {
                    return 1;
                }

                a = moment(a.finishDate).valueOf();
                b = moment(b.finishDate).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            score: (a, b) => {
                if (a.completed == null) {
                    return -1;
                }
                if (b.completed == null) {
                    return 1;
                }

                let A = a.correctAnswers;
                let B = b.correctAnswers;

                if (A > B) {
                    return 1;
                } else if (A < B) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    render() {
        const {toolboxResultsOverview, t} = this.props;
        const {columns, showDeleteDialog, actions} = this.state;

        if (!toolboxResultsOverview) {
            return <div/>;
        }

        return (
            <div>
                <Dialog
                    onClose={() => {
                        this.setState({showDeleteDialog: false});
                    }}
                    open={showDeleteDialog}
                >
                    <DialogTitle>{t('Delete')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Sure to Delete result?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({showDeleteDialog: false});
                            }}
                        >
                            {t('No')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.confirmDelete}>{t('Yes')}</Button>
                    </DialogActions>
                </Dialog>
                {/* Send reminder dialog */}
                <Dialog
                    open={this.state.showSendReminderDialog}
                    onClose={() => {
                        this.setState({showSendReminderDialog: false});
                    }}
                >
                    <DialogTitle>
                        {t("Send reminder?")}
                    </DialogTitle>
                    <DialogContent>
                        {this.state.reminderToSend && t('send_reminder_to_user', {user: formatV2User(this.state.reminderToSend.user)})}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    showSendReminderDialog: false,
                                });
                            }}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                this.props.sendReminder(this.props.toolbox.id, this.props.deploymentId, this.state.reminderToSend.user.id)
                                this.setState({
                                    showSendReminderDialog: false,
                                });
                            }}
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <EnhancedTable
                    hover
                    defaultOrderBy={'deployed'}
                    defaultOrder="desc"
                    columns={columns}
                    onClick={this.onRowClick}
                    rows={toolboxResultsOverview}
                    formatter={this.mapToolboxToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    sorter={this.toolboxSorter()}
                    emptyState={t('There are no results.')}
                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={this.state.startRowsPerPage}
                />
            </div>
        );
    }
}

var percentColors = percentColors4;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

var getColorForPercentage2 = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[1];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(ToolboxDetail))
);
// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Button} from '@mui/material';
import DropdownIcon from '@mui/icons-material/ArrowDropDown';
import classes from './DropdownButton.module.scss';

type Props = {
    onDropdownClick: () => any
};

type State = {};

class DropdownButton extends React.Component<Props, State> {
    props: Props;
    state: State;

    render() {

        const { onDropdownClick, onClick, children } = this.props;

        return (<div className={classes.root}>
            <Button onClick={onClick} variant="contained" color="primary" className={classes.normalButton}>
                {children}
            </Button>
            <Button  onClick={onDropdownClick} className={classes.dropdownButton}>
                <DropdownIcon className={classes.dropdownIcon} />
            </Button>
        </div>);
    }
}

export default (DropdownButton);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Card, CardContent } from '@mui/material';
import { ImageList, ImageListItem } from '@mui/material';
import BlockTypeCard from '../BlockTypeCard';
import QuestionBlock from '../QuestionBlock';
import { DragDropContainer, DragDropItem, DragDropSource } from '../DragDrop';
import {
    moveToolboxDraftQuestionBlock,
    deleteToolboxDraftQuestionBlock,
    insertToolboxDraftQuestionBlockType,
} from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ToolboxQuestionsEditor.module.scss';

type Props = {
    style: any,
    toolbox: any,
    onMoveQuestionBlock: Function,
    questionBlockTypes: Array<any>,
    questionBlockDrafts: any,
    contentBlockDrafts: any,
    onAddQuestionBlock: Function,
    onDeleteQuestionBlock: Function,
    onChange: () => any,
};

type State = {};

const mapStateToProps = (store) => {
    const questionBlockDrafts = store.drafts.questionBlocks;
    const contentBlockDrafts = store.drafts.contentBlocks;

    return {
        questionBlockDrafts: questionBlockDrafts,
        contentBlockDrafts: contentBlockDrafts,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddQuestionBlock: (index, data) =>
            dispatch(
                insertToolboxDraftQuestionBlockType(
                    props.toolbox.id,
                    data,
                    index
                )
            ),
        onMoveQuestionBlock: (fromIndex, toIndex) =>
            dispatch(
                moveToolboxDraftQuestionBlock(
                    props.toolbox.id,
                    fromIndex,
                    toIndex
                )
            ),
        onDeleteQuestionBlock: (questionBlockId) =>
            dispatch(
                deleteToolboxDraftQuestionBlock(
                    props.toolbox.id,
                    questionBlockId
                )
            ),
    };
};

class ToolboxQuestionsEditor extends React.Component<void, Props, State> {
    props: Props;
    state: State = {};

    handleAddQuestionBlock = (index, data) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onAddQuestionBlock(index, data);
    };

    handleMoveQuestionBlock = (fromIndex, toIndex) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onMoveQuestionBlock(fromIndex, toIndex);
    };

    handleDeleteQuestionBlock = (contentBlockId) => {
        if (this.props.onChange) {
            this.props.onChange();
        }
        return this.props.onDeleteQuestionBlock(contentBlockId);
    };

    render() {
        const {
            toolbox,
            questionBlockTypes,
            questionBlockDrafts,
            contentBlockDrafts,
            style,
            t,
        } = this.props;

        return (
            <div style={style} className={classes.root}>
                <Card className={classes.cardLeft}>
                    <CardContent>
                        <DragDropContainer
                            onMove={this.handleMoveQuestionBlock}
                            onAdd={this.handleAddQuestionBlock}
                            accepts="QuestionBlockType"
                            emptyText={t('Drag your question blocks here to create the questionnaire')}
                        >
                            {toolbox.toolbox.questions.map(
                                (questionBlockId, i) => (
                                    <DragDropItem
                                        key={questionBlockId}
                                        data={
                                            questionBlockDrafts[questionBlockId]
                                        }
                                    >
                                        <QuestionBlock
                                            onDelete={
                                                this.handleDeleteQuestionBlock
                                            }
                                            questionBlock={
                                                questionBlockDrafts[
                                                    questionBlockId
                                                ]
                                            }
                                            questionBlockTypes={
                                                questionBlockTypes
                                            }
                                            contentBlocks={contentBlockDrafts}
                                        />
                                    </DragDropItem>
                                )
                            )}
                        </DragDropContainer>
                    </CardContent>
                </Card>

                <Card className={classes.cardRight}>
                    <CardContent>
                        <ImageList
                            cellHeight={'auto'}
                            spacing={16}
                            className={classes.imageList}
                            cols={3}
                        >
                            {questionBlockTypes.map((questionBlockType, i) => (
                                <ImageListItem
                                    key={questionBlockType.id}
                                    cols={1}
                                >
                                    <DragDropSource
                                        data={questionBlockType}
                                        transfers="QuestionBlockType"
                                    >
                                        <BlockTypeCard
                                            onClick={() => {
                                                this.handleAddQuestionBlock(
                                                    this.props.toolbox.toolbox
                                                        .questions.length,
                                                    questionBlockType
                                                );
                                            }}
                                            blockType={questionBlockType}
                                            key={i}
                                        />
                                    </DragDropSource>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxQuestionsEditor);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import classes from "../DialogViewVideoToolbox/DialogViewVideoToolbox.module.scss";
import VideoPlayer from "../VideoPlayer";
import {closeDialog} from "../../actions";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

type Props = {
    open: boolean,
    isLoading: boolean,
    error: any,
};

type State = {};

const mapDispatchToProps = (dispatch) => {
    return {
        onClose: () => {
            dispatch(closeDialog('videoToolboxView'));
        },
    };
};

const mapStateToProps = (store) => {
    return {};
};

class DialogViewVideoToolbox extends React.Component<Props, State> {
    props: Props;
    state: State;

    handleCloseClick = () => {
        this.props.onClose();
    }

    render() {
        const {open, error, processing, t} = this.props;
        return (
            <Dialog
                className={classes.root}
                open={open}
                onClose={this.handleCloseClick}
                fullWidth={true}
            >
                <DialogTitle>{this.props.videoToolbox.name}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <VideoPlayer videoToolbox={this.props.videoToolbox}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={processing}
                        onClick={this.handleCloseClick}
                    >
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),

    withTranslation()
)(DialogViewVideoToolbox);

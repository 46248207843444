import {createSelector} from 'reselect';
import getRoles from './getRoles';

const getInspectionEntities = store => store.entities.inspections;

export const getInspections = createSelector([getInspectionEntities], inspectionEntities => {
    
    return inspectionEntities.allIds.map(id => inspectionEntities.byId[id]);
});

export const getCumelaInspections = createSelector([getInspections], inspections => {
    return inspections.filter(inspection => inspection.source === 'cumela');
});

export const getJanssendejongInspections = createSelector([getInspections], inspections => {
    return inspections.filter(inspection => inspection.source === 'Janssen de Jong Groep');
});

export const getAdminInspections = createSelector([getInspections], inspections => {
    return inspections.filter(inspection => inspection.source === 'global');
});

export const getPreviousCumelaInspectionVersionIds = createSelector([getCumelaInspections], inspections => {
    return inspections.map(inspection => inspection.prevInspectionId);
});

export const getPreviousJanssendejongInspectionVersionIds = createSelector([getJanssendejongInspections], inspections => {
    return inspections.map(inspection => inspection.prevInspectionId);
});

export const getPreviousAdminInspectionVersionIds = createSelector([getAdminInspections], inspections => {
    return inspections.map(inspection => inspection.prevInspectionId);
});

export const getPreviousInspectionVersionIds = createSelector([getInspections], inspections => {
    return inspections.map(inspection => inspection.prevInspectionId);
});

export const getVisibleInspectionVersions = createSelector(
    [getPreviousInspectionVersionIds, getPreviousCumelaInspectionVersionIds, getPreviousJanssendejongInspectionVersionIds,getPreviousAdminInspectionVersionIds, getInspections, getRoles],
    (previousInspectionVersionIds, previousCumelaInspectionVersionIds, previousJanssendejongInspectionVersionIds,  previousAdminInspectionVersionIds, inspections, roles) => {
        const filteredByNewestVersion = inspections.filter(
            inspection => !previousInspectionVersionIds.includes(inspection.id) || ((inspection.source === 'cumela' && !previousCumelaInspectionVersionIds.includes(inspection.id)) || (inspection.source === 'Janssen de Jong Groep' && !previousJanssendejongInspectionVersionIds.includes(inspection.id)) ||  (inspection.source === 'global' && !previousAdminInspectionVersionIds.includes(inspection.id)))
        );

        if (roles.includes('CUMELA')) {
            return filteredByNewestVersion;
        }

        if (roles.includes('JANSSENDEJONG')) {
            return filteredByNewestVersion;
        }

        if (roles.includes('ADMIN')) {
            return filteredByNewestVersion.filter(toolbox => toolbox.source === 'global'|| toolbox.published === 'PUBLISHED');
        }

        return filteredByNewestVersion.filter(toolbox => toolbox.published === 'PUBLISHED');
    }
);

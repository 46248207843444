// @flow
import React from 'react';
import './StatisticsInspectionCard.css';
import PropTypes from 'prop-types';
import {LinearProgress} from '@mui/material';
import $ from 'jquery';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import ChartLabel from '../../Util/ChartLabel/ChartLabel';
import 'materialize-tabs';
import {connect} from 'react-redux';
import type {StoreState} from '../../../reducers/index';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

type Props = {
    filterBar: mixed, // Filter bar component
    inspectionBarData?: Array<any>, // Chart data
    list: mixed, // List component
    totalInspectionsCorrect: number,
    totalInspectionsIncorrect: number,
    onDownloadCSV: () => mixed,
    loading: boolean,
};

const mapStateToProps = (state: StoreState) => {
    return {
        loading: state.statistics.loading,
        currentEmpl: state.drafts.employees.currentUser,
    };
};

type State = {};

class StatisticsInspectionDetailCard extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        $('ul.tabs').tabs();
    }

    getInspectionTooltip = (chartBar) => {
        const {t} = this.props;
        if (chartBar.payload && chartBar.payload.length) {
            let rows = [];
            let values = 0;
            for (let i = chartBar.payload.length - 1; i >= 0; i--) {
                let item = chartBar.payload[i];
                if (item.value) {
                    values++;
                    rows.push(
                        this.toolTipRow(item.color, item.name, item.value)
                    );
                }
            }

            return values ? (
                <div className="chart-tooltip">
                    <div className="chart-tooltip-title">
                        # {t('Workplace inspections')} {chartBar.label}
                    </div>

                    {rows}
                </div>
            ) : undefined;
        }
    };

    toolTipRow = (color: string, key: string, value: string) => {
        return (
            <div key={color} className="chart-tooltip-row">
                <div
                    className="chart-tooltip-color"
                    style={{backgroundColor: color}}
                />
                <div className="chart-tooltip-key">{key}</div>
                <div className="chart-tooltip-value">{value}</div>
            </div>
        );
    };

    getInspectionChart = () => {
        const {t} = this.props;
        return (
            <div className="chart-label-block">
                <ChartLabel
                    label={'#' + t('Completed workplace inspections')}
                    vertical
                />
                <ChartLabel label={t('Month')} horizontal/>
                <ResponsiveContainer width="100%" aspect={110 / 33}>
                    <BarChart
                        data={
                            !this.props.inspectionBarData ||
                            !this.props.inspectionBarData.length
                                ? []
                                : this.props.inspectionBarData
                        }
                        margin={{top: 20, right: 30, left: 20, bottom: 5}}
                        barCategoryGap="25"
                    >
                        <XAxis
                            dataKey="barName"
                            axisLine={false}
                            tickLine={false}
                        />
                        <YAxis
                            axisLine={false}
                            allowDecimals={false}
                            tickLine={false}
                        />
                        <CartesianGrid vertical={false}/>
                        <Tooltip
                            cursor={false}
                            isAnimationActive={false}
                            content={this.getInspectionTooltip}
                            offset={-110}
                        />
                        <Legend
                            verticalAlign="top"
                            align="right"
                            iconType="circle"
                            iconSize={10}
                            wrapperStyle={{
                                paddingBottom: "12px"
                            }}
                        />
                        <Bar
                            name={t('alright')}
                            dataKey="correct"
                            stackId="a"
                            fill="#4CCC32"
                            barSize={48}
                            animationBegin={0}
                            animationDuration={200}
                            animationEasing="linear"
                        />
                        <Bar
                            name={t('not alright')}
                            dataKey="incorrect"
                            stackId="a"
                            fill="#E22513"
                            barSize={48}
                            animationBegin={0}
                            animationDuration={200}
                            animationEasing="linear"
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    };

    getStatisticsSummary = () => {
        const {t} = this.props;
        let components = [];
        components.push(
            <div className="statistics-summary-item therapy-count" key={1}>
                <div className="statistics-summary-item-key">
                    {t('Total alright')}:&nbsp;
                </div>
                <div className="statistics-summary-item-value">
                    {this.props.totalInspectionsCorrect}
                </div>
            </div>
        );
        components.push(
            <div className="statistics-summary-item duration-sum" key={2}>
                <div className="statistics-summary-item-key">
                    {t('Total not alright')}:&nbsp;
                </div>
                <div className="statistics-summary-item-value">
                    {this.props.totalInspectionsIncorrect}
                </div>
            </div>
        );
        return components;
    };

    render() {
        return (
            <div>
                <div className="card-content">
                    <LinearProgress
                        className={
                            this.props.loading
                                ? 'statistics-loader visible'
                                : 'statistics-loader'
                        }
                        mode="indeterminate"
                    />
                    <div className="statistics-summary">
                        {this.getStatisticsSummary()}
                    </div>
                    <div className="statistics-graphs-active-graph">
                        {this.getInspectionChart()}
                    </div>
                    <div className="statistics-list">{this.props.list}</div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    withTranslation()
)(StatisticsInspectionDetailCard);

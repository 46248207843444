export const CREATE_CONTENT_BLOCK_DRAFT = 'CREATE_CONTENT_BLOCK_DRAFT';
export const EDIT_TEXT_BLOCK_DRAFT = 'EDIT_TEXT_BLOCK_DRAFT';
export const EDIT_LIST_BLOCK_DRAFT = 'EDIT_LIST_BLOCK_DRAFT';
export const EDIT_LIST_ITEM_BLOCK_DRAFT = 'EDIT_LIST_ITEM_BLOCK_DRAFT';
export const EDIT_VIDEO_BLOCK_DRAFT = 'EDIT_VIDEO_BLOCK_DRAFT';
export const EDIT_VIDEO_TOOLBOX_BLOCK_DRAFT = 'EDIT_VIDEO_TOOLBOX_BLOCK_DRAFT';
export const EDIT_CONTENT_BLOCK_DRAFT = 'EDIT_CONTENT_BLOCK_DRAFT';
export const EDIT_PHOTOS_ITEM_BLOCK_DRAFT = 'EDIT_PHOTOS_ITEM_BLOCK_DRAFT';
export const DELETE_CONTENT_BLOCK_DRAFT = 'DELETE_CONTENT_BLOCK_DRAFT';
export const MOVE_LIST_ITEM_BLOCK_DRAFT = 'MOVE_LIST_ITEM_BLOCK_DRAFT';
export const DELETE_LIST_ITEM_BLOCK_DRAFT = 'DELETE_LIST_ITEM_BLOCK_DRAFT';
export const ADD_LIST_ITEM_BLOCK_DRAFT = 'ADD_LIST_ITEM_BLOCK_DRAFT';
export const ADD_MULTIPLE_LIST_ITEM_BLOCK_DRAFT = 'ADD_MULTIPLE_LIST_ITEM_BLOCK_DRAFT';
export const MOVE_PHOTOS_ITEM_BLOCK_DRAFT = 'MOVE_PHOTOS_ITEM_BLOCK_DRAFT';
export const DELETE_PHOTOS_ITEM_BLOCK_DRAFT = 'DELETE_PHOTOS_ITEM_BLOCK_DRAFT';
export const ADD_PHOTOS_ITEM_BLOCK_DRAFT = 'ADD_PHOTOS_ITEM_BLOCK_DRAFT';
export const ADD_PHOTOS_PDF_BLOCK_DRAFT = 'ADD_PHOTOS_PDF_BLOCK_DRAFT';

export function createContentBlockDraft(data) {
    return {
        type: CREATE_CONTENT_BLOCK_DRAFT,
        payload: {data}
    }
}

export function deleteContentBlockDraft(id) {
    return {
        type: DELETE_CONTENT_BLOCK_DRAFT,
        payload: {id}
    }
}

export function editContentBlockDraft(id, key, value) {
    return {
        type: EDIT_CONTENT_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}

export function editListBlockDraft(id, key, value) {
    return {
        type: EDIT_LIST_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}

export function editListItemBlockDraft(id, index, key, value) {
    return {
        type: EDIT_LIST_ITEM_BLOCK_DRAFT,
        payload: {id, index, key, value},
    }
}

export function deleteListItemBlockDraft(id, index) {
    return {
        type: DELETE_LIST_ITEM_BLOCK_DRAFT,
        payload: {id, index}
    }
}

export function moveListItemBlockDraft(id, fromIndex, toIndex) {
    return {
        type: MOVE_LIST_ITEM_BLOCK_DRAFT,
        payload: {id, fromIndex, toIndex}
    }
}

export function addListItemBlockDraft(id) {
    return {
        type: ADD_LIST_ITEM_BLOCK_DRAFT,
        payload: {id}
    }
}

export function addMultipleListItemBlockDraft(id, items) {
    return {
        type: ADD_MULTIPLE_LIST_ITEM_BLOCK_DRAFT,
        payload: {id, items}
    }
}

export function deletePhotosItemBlockDraft(id, index) {
    return {
        type: DELETE_PHOTOS_ITEM_BLOCK_DRAFT,
        payload: {id, index}
    }
}

export function movePhotosItemBlockDraft(id, fromIndex, toIndex) {
    return {
        type: MOVE_PHOTOS_ITEM_BLOCK_DRAFT,
        payload: {id, fromIndex, toIndex}
    }
}

export function addPhotosItemBlockDraft(id) {
    return {
        type: ADD_PHOTOS_ITEM_BLOCK_DRAFT,
        payload: {id}
    }
}

export function addPhotosPDFBlockDraft(id, result) {
    return {
        type: ADD_PHOTOS_PDF_BLOCK_DRAFT,
        payload: {id, result}
    }
}

export function editPhotosItemBlockDraft(id, key, value) {
    return {
        type: EDIT_PHOTOS_ITEM_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}

export function editTextBlockDraft(id, key, value) {
    return {
        type: EDIT_TEXT_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}

export function editVideoBlockDraft(id, key, value) {
    return {
        type: EDIT_VIDEO_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}

export function editVideoToolboxBlockDraft(id, key, value) {
    return {
        type: EDIT_VIDEO_TOOLBOX_BLOCK_DRAFT,
        payload: {id, key, value},
    }
}
// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import classes from './Dashboard.module.scss';
import AppModule from '../AppModule';
import {compose} from 'recompose';
import {
    disableNewFeatures,
    fetchDashboard,
    fetchInspectionGroupAssigns,
    fetchInspections,
    fetchProjects,
    fetchToolboxGroupAssigns,
    getCurrentEmployee,
    resetNewFeatures,
    setCrumbPath,
} from '../../actions';
import * as api from '../../lib/api';
import {fetchAssignedInspectionsOverviewWithParams} from '../../lib/api';
import {getAssignedToolboxes, getPermissions} from '../../selectors';
import DashboardTotal from './DashboardTotal';
import DashboardTableCard from './DashboardTableCard/DashboardTableCard';
import classnames from 'classnames';
import {push} from 'connected-react-router';
import moment from 'moment';
import DashboardTableCardEmptyState from '../DashboardTableCardEmptyState/DashboardTableCardEmptyState';
import {openDialog} from '../../actions/uiActions';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {TEmployee} from '../../reducers/employeesReducer';
import {bindActionCreators} from 'redux';
import ConfirmDialog from '../ConfirmDialog';
import CreditsDialog from '../Abonnement/CreditsDialog/CreditsDialog';
import PaymentReminder from './PaymentReminder';
import Spinner from '../Spinner';
import {withTranslation} from 'react-i18next';
import {getModules} from "../lib";
import {V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX} from "../../lib/apiTypes";
import Guide from "./Guide";

type Props = {
    setCrumbPath: () => void,
    fetchDashboard: () => void,
    permissions: any,
    loading: boolean,
    dashboard: any,
    getCurrentEmployee: () => TEmployee,
    inspectionAssigns: any,
    toolboxAssigns: any,
    disableNewFeatures: (user: any) => void,
    resetNewFeatures: (user: any) => void,
};

type State = {
    currentEmpl: TEmployee,
    showConfirmDialog: boolean,
    showCreditsDialog: boolean,
    showUpgradeDialog: false,
    showFeaturesDialog: boolean,
    IEdialogOpened: boolean,
};

const mapStateToProps = (store) => {
    const inspections = store.entities.inspections;
    const deploymentEntities = store.entities[V2_TOOLBOX_ASSIGN_OVERVIEW_REDUX]
    const toolboxDeployments = deploymentEntities.allIds.map(id => deploymentEntities.byId[id]);

    let badgeCount = 0;
    let allincidents = 0;

    if (store.entities.incidents) {
        store.entities.incidents.allIds.forEach((id) => {
            allincidents++;
            if (store.entities.incidents.byId[id].isnew) {
                badgeCount++;
            }
        });
    }

    let newSpeakups = 0;
    let allSpeakups = 0;

    if (store.entities.speakups) {
        store.entities.speakups.allIds.forEach((id) => {
            allSpeakups++;
            if (store.entities.speakups.byId[id].isnew) {
                newSpeakups++;
            }
        });
    }

    return {
        projects: store.entities.projects,
        permissions: getPermissions(store),
        dashboard: store.dashboard.dashboard,
        loading: store.dashboard.inProgress && !store.dashboard.dashboard,
        backgroundLoading: store.dashboard.inProgress,
        currentEmpl: store.drafts.employees.currentUser,
        allincidents: allincidents,
        incidents: badgeCount,
        allSpeakups: allSpeakups,
        newSpeakups: newSpeakups,
        inspections: inspections,
        plannedToolboxes: store.dashboard.plannedToolboxes,
        deployedToolboxes: store.dashboard.deployedToolboxes,
        plannedInspections: store.dashboard.plannedInspections,
        deployedInspections: store.dashboard.deployedInspections,
        toolboxAssigns: getAssignedToolboxes(store),
        groupAssigns: store.entities.toolboxGroupAssigns,
        inspectionGroupAssigns: store.entities.inspectionGroupAssigns,
        toolboxDeployments: toolboxDeployments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        disableNewFeatures: bindActionCreators(disableNewFeatures, dispatch),
        resetNewFeatures: bindActionCreators(resetNewFeatures, dispatch),
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: 'Dashboard'}));
        },
        fetchDashboard: () => {
            dispatch(fetchDashboard());
        },
        fetchToolboxGroupAssigns: () => {
            dispatch(fetchToolboxGroupAssigns());
        },
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        fetchInspections: () => {
            dispatch(fetchInspections());
        },
        fetchInspectionGroupAssigns: () => {
            dispatch(fetchInspectionGroupAssigns());
        },
        navigate: (newPath) => (event) => {
            dispatch(push(newPath));
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        getCurrentEmployee: () => {
            dispatch(getCurrentEmployee());
        },
        goTo: (url) => {
            dispatch(push(url));
        },
    };
};

class Dashboard extends React.Component<Props, State> {
    props: Props;
    state: State = {
        showConfirmDialog: false,
        showCreditsDialog: false,
        showUpgradeDialog: false,
        showFeaturesDialog: false,
        totals: {},
    };

    componentDidMount() {
        this.props.setCrumbPath();
        //this.props.fetchDashboard();
        this.props.getCurrentEmployee();
        this.props.fetchToolboxGroupAssigns();
        this.props.fetchInspectionGroupAssigns();
        this.props.fetchInspections();
        this.props.fetchProjects();

        this.getTotals();
        this.getToolboxScheduled();
        this.getToolboxDeployed();
        this.getFullName();

        let url_string = window.location.href;
        let url = new URL(url_string);
        let c = url.searchParams.get('upgrade');
        if (c !== null) {
            this.setState({
                showCreditsDialog: true,
                tokens: parseInt(c, 10),
            });
        }
        if (
            this.props.dashboard &&
            this.props.dashboard.scheduledToolboxes &&
            this.props.dashboard.scheduledInspections
        ) {
            this.props.dashboard.scheduledToolboxes
                .concat(this.props.dashboard.scheduledInspections)
                .forEach((item) => {
                    if (moment(item.scheduled).diff(moment()) < -1000) {
                        //this.props.fetchDashboard();
                    }
                });
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.projects && this.props.inspections && (prevProps.projects !== this.props.projects || prevProps.inspections !== this.props.inspections)) {
            if (this.props.inspections && this.props.inspections.allIds && this.props.inspections.allIds.length > 0) {
                this.fetchFirstPageInspectionAssignedForDashBoard();
                this.fetchScheduledinspectionAssign();
            }
        }
    }

    fetchFirstPageInspectionAssignedForDashBoard = () => {
        let inspections = this.props.inspections

        fetchAssignedInspectionsOverviewWithParams("", 0, 5, "deployed", "desc", "")
            .then((res) => {
                let inspectionAssign = res.body._embedded.inspectionAssigns.inspectionAssignProjections;
                let listInspectionAssigns = [];
                inspectionAssign.forEach(singleInspectionAssign => {
                    listInspectionAssigns.push({
                        ...singleInspectionAssign,
                        inspection: {...inspections.byId[singleInspectionAssign.inspectionId]}
                    })
                })
                this.setState({
                    dashboardInspectionAssigned: listInspectionAssigns,
                })
            }).catch((error) => {
            throw error;
        });
    }

    getToolboxScheduled = () => {
        api.fetchToolboxScheduled()
            .then((res) => {
                this.setState({
                    toolboxScheduled: res.body
                })
            }).catch((error) => {
            throw error;
        });
    }

    getToolboxDeployed = () => {
        api.fetchToolboxDeployed()
            .then((res) => {
                this.setState({
                    toolboxDeployed: res.body
                })
            }).catch((error) => {
            throw error;
        });
    }

    getInsepctionDeployed = () => {
        api.fetchInspectionDeployed()
            .then((res) => {
                this.setState({
                    inspectionDeployed: res.body,
                })
            }).catch((error) => {
            throw error;
        });

    }

    fetchScheduledinspectionAssign = () => {
        const inspections = this.props.inspections;
        let listInspectionAssigns = []

        api.fetchAssignedInspectionsOverviewWithParams("", 0, 100, "", "", "scheduled")
            .then((res) => {
                let inspectionAssign = res.body._embedded.inspectionAssigns.inspectionAssignProjections;
                inspectionAssign.forEach(singleInspectionAssign => {
                    listInspectionAssigns.push({
                        ...singleInspectionAssign,
                        inspection: {...inspections.byId[singleInspectionAssign.inspectionId]}
                    })
                });
            }).catch((error) => {
            throw error;
        });

        api.fetchInspectionAssign()
            .then((result) => {
                let inspectionAssignGroup = result.body.body._embedded.groupassigns
                inspectionAssignGroup.forEach(group => {
                    listInspectionAssigns.push(group)
                })
            }).catch((error) => {
            throw error;
        });
        this.setState({
            scheduledInspections: listInspectionAssigns
        })
    }

    getTotals = () => {
        api.fetchTotals()
            .then((res) => {
                this.setState({
                    totals: res.body
                })
            }).catch((error) => {
            throw error;
        });
    }

    getFullName = () => {
        api.fetchFullName()
            .then((res) => {
                this.setState({
                    fullName: res.body
                })
            }).catch((error) => {
            throw error;
        });
    }

    navigateToPath = (path) => {
        this.props.goTo(path);
    }

    itemClick(row) {
        this.props.goTo(
            '/toolbox/details/' +
            row.toolboxId +
            '/' +
            row.deploymentId +
            '?from=uitgestuurd'
        );
    }

    hideUpgradeDialog = () => {
        this.setState({
            showUpgradeDialog: false,
        });
    };

    hideFeaturesDialog = () => {
        this.setState({
            showFeaturesDialog: false,
        });
    };

    showFeaturesDialog = () => {
        this.setState({
            showFeaturesDialog: true,
        });
    };

    closeCreditsDialog = () => {
        this.setState({
            showCreditsDialog: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    noNewFeaturesDisplayed(event, user) {
        this.props.currentEmpl.newFeatures = false;
        this.props.disableNewFeatures(user);
        event.stopPropagation();
    }

    resetNewFeatures(user) {
        this.props.resetNewFeatures(user);
        this.hideFeaturesDialog();
    }

    render() {
        const {
            loading,
            backgroundLoading,
            groupAssigns,
            navigate,
            openDialog,
            currentEmpl,
            t,
        } = this.props;
        const {showUpgradeDialog, showFeaturesDialog, totals} = this.state;

        const modules = getModules();

        let url;
        if (
            window.location.origin.includes('test.veiligvakwerk') ||
            window.location.origin.includes('3000')
        ) {
            url = 'https://website.test.veiligvakwerk.nl/nieuw';
        } else {
            url = 'https://veiligvakwerk.nl/nieuw';
        }

        if (!localStorage.getItem('cookies')) {
            if (this.state.showConfirmDialog === false) {
                this.showConfirmDialog();
            }
        }

        let showIEDialog =
            navigator.userAgent.indexOf('MSIE ') > -1 ||
            navigator.userAgent.indexOf('Trident/') > -1 ||
            navigator.userAgent.indexOf('Edge/') > -1;
        showIEDialog = showIEDialog
            ? sessionStorage.getItem('IEDialogOpened') !== 'true'
            : false;

        let vv_functions, credits_setup, credits;
        let showNewFeatures = true;
        let isTrial = false;
        let changePayment = true;
        let trialCreatedOn = false;
        let stripeIsSet = false;
        let trialEndsIn = 0;

        if (currentEmpl) {
            vv_functions = currentEmpl.subscription.vv_functions;
            credits_setup = currentEmpl.subscription.credits_setup;
            credits = currentEmpl.subscription.credits;
            showNewFeatures = currentEmpl.newFeatures;
            isTrial = currentEmpl.subscription.trial;
            changePayment = currentEmpl.subscription.changePayment;
            trialCreatedOn = currentEmpl.subscription.dateCreatedOn;
            stripeIsSet = currentEmpl.subscription.stripeId;
            trialEndsIn = Math.round(
                30 -
                (new Date() - new Date(trialCreatedOn)) /
                (1000 * 60 * 60 * 24)
            );
            trialEndsIn = trialEndsIn < 0 ? 0 : trialEndsIn;
        }

        if (this.state.showCreditsDialog) {
            if (this.state.tokens != null && this.props.currentEmpl) {
                if (
                    this.state.tokens <
                    this.props.currentEmpl.subscription.credits
                ) {
                    this.setState({
                        showCreditsDialog: false,
                        showUpgradeDialog: true,
                    });
                }
            }
        }

        //
        // Merge scheduledToolboxes and groupAssigns
        //
        var combinedToolboxes = [];
        if (this.state.toolboxScheduled) {
            this.state.toolboxScheduled
                .sort((e1, e2) => {
                    const date1 = moment(e1.scheduled).format('L HH:mm');
                    const date2 = moment(e2.scheduled).format('L HH:mm');
                    if (date1 === date2) {
                        return e1.toolboxTitle > e2.toolboxTitle ? 1 : -1;
                    } else {
                        return moment(e1.scheduled).diff(moment(e2.scheduled));
                    }
                })
                .slice(0, 5)
                .forEach((toolbox) => {
                    combinedToolboxes.push({
                        toolboxId: toolbox.toolboxId,
                        scheduled: toolbox.scheduled,
                        source: toolbox.source,
                        rootInspectionSource: toolbox.rootInspectionSource,
                        toolboxTitle: toolbox.toolboxTitle,
                        employeeCount: toolbox.employeeCount,
                    });
                });
        }
        if (groupAssigns) {
            groupAssigns.allIds
                .sort((e1, e2) => {
                    const date1 = moment(
                        groupAssigns.byId[e1].scheduled
                    ).format('L HH:mm');
                    const date2 = moment(
                        groupAssigns.byId[e2].scheduled
                    ).format('L HH:mm');
                    if (date1 === date2) {
                        return groupAssigns.byId[e1].toolbox.title >
                        groupAssigns.byId[e2].toolboxTitle
                            ? 1
                            : -1;
                    } else {
                        return moment(groupAssigns.byId[e1].scheduled).diff(
                            moment(groupAssigns.byId[e2].scheduled)
                        );
                    }
                })
                .slice(0, 5)
                .forEach((id) => {
                    combinedToolboxes.push({
                        toolboxId: groupAssigns.byId[id].toolbox.id,
                        scheduled: groupAssigns.byId[id].scheduled,
                        source: groupAssigns.byId[id].source,
                        rootboxSource: groupAssigns.byId[id].rootboxSource,
                        toolboxTitle: groupAssigns.byId[id].toolbox.title,
                        employeeCount:
                        groupAssigns.byId[id].usergroup.users.length,
                    });
                });
        }

        const tableActionHeader = (
            <TableActionHeader
                title={this.state.fullName ? t('Welcome') + ', ' + this.state.fullName + '.' : ''}
            />
        );
        return (
            <div className={classes.root}>
                <CreditsDialog
                    open={this.state.showCreditsDialog}
                    handleClose={this.closeCreditsDialog}
                    onCreditsBuy={this.creditsBuy}
                    creditsSetup={
                        this.props.currentEmpl
                            ? this.props.currentEmpl.subscription.credits_setup
                            : false
                    }
                />
                <Dialog
                    open={showUpgradeDialog}
                    onClose={this.hideUpgradeDialog}
                >
                    <DialogTitle>{t('Someone beat you!')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Credits already updated')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.hideUpgradeDialog}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showFeaturesDialog}
                    onClose={this.hideFeaturesDialog}
                >
                    <DialogTitle>{t('Show notification again?')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Sure to show notifications again?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button

                            onClick={() => this.resetNewFeatures(currentEmpl)}
                        >
                            {t('Yes')}
                        </Button>
                        <Button

                            onClick={this.hideFeaturesDialog}
                        >
                            {t('No')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    handleClose={this.hideConfirmDialog}
                    cookies={true}
                />
                <img
                    src={
                        '/assets/images/' +
                        (process.env.REACT_APP_DASHBOARD_LOGO || 'logo.svg')
                    }
                    alt="logo"
                    className={classes.vvlogo}
                />
                <AppModule loading={loading} prepend={tableActionHeader}>
                    {showIEDialog && this.props.openDialog('DialogIE')}
                    <div className={classes.content}>
                        {isTrial && changePayment && (
                            <PaymentReminder
                                trialEndsIn={trialEndsIn}
                                stripeIsSet={stripeIsSet}
                            />
                        )}
                        <div>
                            {this.state.toolboxScheduled &&
                                this.state.toolboxDeployed &&
                                (this.state.toolboxScheduled.length > 0 ||
                                this.state.toolboxDeployed.length > 0 ? (
                                    <Guide
                                        hidden={false}
                                        showNewFeatures={!showNewFeatures}
                                        vv_functions={vv_functions}
                                    />
                                ) : (
                                    <Guide
                                        hidden={true}
                                        vv_functions={vv_functions}
                                    />
                                ))}
                            <div
                                className={classes.newFeaturesBar}
                                style={{
                                    display:
                                        showNewFeatures === true
                                            ? 'flex'
                                            : 'none',
                                }}
                            >
                                <i className="material-icons">info</i>
                                <a
                                    className={classes.newFeaturesText}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className={classes.contentColor}>
                                        {' '}
                                        {t('New functions added')}
                                    </p>
                                </a>
                                <Button
                                    onClick={(event) =>
                                        this.noNewFeaturesDisplayed(
                                            event,
                                            currentEmpl
                                        )
                                    }
                                    className={classes.contentColor}
                                >
                                    {t('DONT SHOW AGAIN')}
                                </Button>
                            </div>
                            <div
                                className={classes.newFeaturesBar}
                                style={{
                                    display:
                                        currentEmpl &&
                                        currentEmpl.roles.includes('ADMIN')
                                            ? 'flex'
                                            : 'none',
                                    marginTop: '10px',
                                }}
                            >
                                <i className="material-icons"> info</i>
                                <a
                                    className={classes.newFeaturesText}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <p className={classes.contentColor}>
                                        {' '}
                                        {t('Sure to show notifications again?')}
                                    </p>
                                </a>
                                <Button
                                    className={classes.contentColor}
                                    onClick={this.showFeaturesDialog}
                                >
                                    {t('SHOW NOTIFICATION')}
                                </Button>
                            </div>
                            <div className={classes.tableCardTitles}>
                                <div
                                    className={classnames({
                                        [classes.sectionTitle]: true,
                                        [classes.tableCardTitle]: true,
                                    })}
                                >
                                    {t('Totals')}
                                </div>
                                <div className={classes.totals}>
                                    {modules.speakUp ? (
                                            <DashboardTotal
                                                text={t('Open Speak-Up! reports')}
                                                value={this.props.newSpeakups}
                                                units={
                                                    '/ ' + this.props.allSpeakups
                                                }
                                                small={
                                                    credits_setup && vv_functions
                                                }
                                            />

                                        )
                                        :
                                        vv_functions && (<DashboardTotal
                                            text={t('Open registration notifications')}
                                            value={this.props.incidents}
                                            units={'/ ' + this.props.allincidents}
                                            small={credits_setup && vv_functions}
                                            onClick={() => this.navigateToPath('/registratiemeldingen')}
                                        />
                                        )
                                    }
                                    <DashboardTotal
                                        text={t('Toolbox meetings')}
                                        value={totals.toolboxMeetings}
                                        units={
                                            ' in ' + new Date().getFullYear()
                                        }
                                        small={credits_setup && vv_functions}
                                        onClick={() => this.navigateToPath('/toolboxen#resultaten')}
                                    />
                                    <DashboardTotal
                                        text={t('Open toolboxes')}
                                        value={
                                            totals.totalToolboxes -
                                            totals.finishedToolboxes || 0
                                        }
                                        units={' / ' + totals.totalToolboxes}
                                        small={credits_setup && vv_functions}
                                        onClick={() => this.navigateToPath('/toolboxen#resultaten')}
                                    />
                                    {modules.inspections && vv_functions && (
                                        <DashboardTotal
                                            text={t(
                                                'Open workplace inspections'
                                            )}
                                            value={
                                                this.state.totals.totalInspections -
                                                this.state.totals.finishedInspections ||
                                                0
                                            }
                                            units={
                                                ' / ' +
                                                this.state.totals.totalInspections
                                            }
                                            small={
                                                credits_setup && vv_functions
                                            }
                                            onClick={() => this.navigateToPath('/werkplekinspecties#resultaten')}

                                        />
                                    )}
                                    {credits_setup && (
                                        <DashboardTotal
                                            text={t('Credit amount')}
                                            value={credits || 0}
                                            image={
                                                <img
                                                    height="18"
                                                    width="18"
                                                    src="/assets/images/credits_icon.png"
                                                    alt="credits logo"
                                                />
                                            }
                                            small={
                                                credits_setup && vv_functions
                                            }
                                            onClick={() => this.navigateToPath('/abonnement')}
                                        ></DashboardTotal>
                                    )}
                                </div>
                                <div className={classes.tableCardTitles}>
                                    <div
                                        className={classnames({
                                            [classes.sectionTitle]: true,
                                            [classes.tableCardTitle]: true,
                                        })}
                                    >
                                        {t('Toolboxes')}
                                    </div>
                                </div>

                                <div className={classes.tableCardItems}>
                                    <DashboardTableCard
                                        title={t('Scheduled')}
                                        buttonText={t('View all')}
                                        onButtonClick={navigate(
                                            'toolboxen#ingepland'
                                        )}
                                    >
                                        {this.state.toolboxScheduled &&
                                            this.state.toolboxScheduled.length > 0 && (
                                                <Table
                                                    className={classes.table}
                                                >
                                                    <TableBody className={classes.tableBody}>
                                                        {this.state.toolboxScheduled
                                                            .sort((e1, e2) => {
                                                                const date1 =
                                                                    moment(
                                                                        e1.scheduled
                                                                    ).format(
                                                                        'L HH:mm'
                                                                    );
                                                                const date2 =
                                                                    moment(
                                                                        e2.scheduled
                                                                    ).format(
                                                                        'L HH:mm'
                                                                    );
                                                                if (
                                                                    date1 ===
                                                                    date2
                                                                ) {
                                                                    return e1.title >
                                                                    e2.title
                                                                        ? 1
                                                                        : -1;
                                                                } else {
                                                                    return moment(
                                                                        e1.scheduled
                                                                    ).diff(
                                                                        moment(
                                                                            e2.scheduled
                                                                        )
                                                                    );
                                                                }
                                                            })
                                                            .slice(0, 5)
                                                            .map(
                                                                (
                                                                    row,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={index}
                                                                            className={classes.tableRow}
                                                                            onClick={navigate('/toolbox/details/' + row.toolboxId + '?from=ingepland')}
                                                                        >

                                                                            <TableCell
                                                                                className={classes.tableCell}
                                                                            >
                                                                                <div className={'tooltip'}
                                                                                     style={{
                                                                                         position: 'relative',
                                                                                         display: 'inline-block',
                                                                                     }}
                                                                                >
                                                                                    <span
                                                                                        className={
                                                                                            'tooltiptext'
                                                                                        }
                                                                                        style={{
                                                                                            left: '30px',
                                                                                        }}
                                                                                    >
                                                                                        {row.source ===
                                                                                        'cumela'
                                                                                            ? t(
                                                                                                'Cumela toolbox'
                                                                                            )
                                                                                            : row.source ===
                                                                                            'global'
                                                                                                ? t(
                                                                                                    'Veilig Vakwerk toolbox'
                                                                                                )
                                                                                                : row.rootboxSource ===
                                                                                                'global' ||
                                                                                                row.rootboxSource ===
                                                                                                'cumela'
                                                                                                    ? t(
                                                                                                        'Custom toolbox'
                                                                                                    )
                                                                                                    : t(
                                                                                                        'Own toolbox'
                                                                                                    )}
                                                                                    </span>
                                                                                    {row.source ===
                                                                                    'cumela' ? (
                                                                                        <img
                                                                                            className={
                                                                                                classes.cumelaLogo
                                                                                            }
                                                                                            src="/assets/images/cumela-logo.png"
                                                                                            alt="cumela"
                                                                                        />
                                                                                    ) : row.source ===
                                                                                    'global' ? (
                                                                                        <img
                                                                                            className={
                                                                                                classes.cumelaLogo
                                                                                            }
                                                                                            src="/assets/images/vvw-admin-logo.svg"
                                                                                            alt="veiligvakwerk"
                                                                                        />
                                                                                    ) : row.rootboxSource ===
                                                                                    'global' ||
                                                                                    row.rootboxSource ===
                                                                                    'cumela' ? (
                                                                                        <img
                                                                                            className={
                                                                                                classes.cumelaLogo
                                                                                            }
                                                                                            src="/assets/images/aangepast.svg"
                                                                                            alt="veiligvakwerk"
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            className={
                                                                                                classes.cumelaLogo
                                                                                            }
                                                                                            src="/assets/images/vvw-logo.svg"
                                                                                            alt="veiligvakwerk"
                                                                                        />
                                                                                    )}
                                                                                </div>

                                                                                <span
                                                                                    className={classes.tableCellTitle}>
                                                                                    {row.title}
                                                                                </span>
                                                                            </TableCell>

                                                                            <TableCell className={classes.tableCell}>
                                                                                {moment(row.scheduled).format('L HH:mm')}
                                                                            </TableCell>

                                                                            <TableCell
                                                                                className={
                                                                                    classes.tableCell
                                                                                }
                                                                            >
                                                                                {
                                                                                    row.employees && row.employees.length
                                                                                }
                                                                                &nbsp;
                                                                                {t(
                                                                                    'employees'
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            )}
                                                    </TableBody>
                                                </Table>
                                            )}

                                        {this.state.toolboxScheduled &&
                                            this.state.toolboxScheduled.length === 0 && (
                                                <DashboardTableCardEmptyState
                                                    text={t(
                                                        'No toolboxes planned yet...'
                                                    )}
                                                    actionTitle={t(
                                                        'Plan toolboxes'
                                                    )}
                                                    action={() => {
                                                        openDialog(
                                                            'toolboxDeploy',
                                                            {
                                                                initialScheduled: true,
                                                            }
                                                        );
                                                    }}
                                                />
                                            )}
                                    </DashboardTableCard>

                                    <DashboardTableCard
                                        title={t('Sent')}
                                        buttonText={t('View all')}
                                        onButtonClick={navigate(
                                            'toolboxen#resultaten'
                                        )}
                                    >
                                        {this.state.toolboxDeployed &&
                                            this.state.toolboxDeployed.length > 0 && (
                                                <Table className={classes.table}>
                                                    <TableBody className={classes.tableBody}>
                                                        {this.state.toolboxDeployed
                                                            .map((row,index) => {
                                                                    let tooltip;

                                                                    if (row.finishedCount === 1 && row.employeeCount === 1) {
                                                                        tooltip = t('The employee has completed the toolbox');
                                                                    } else if (
                                                                        row.finishedCount === 0 && row.employeeCount === 1) {
                                                                        tooltip = t('The employee didnt complete the toolbox');
                                                                    } else {
                                                                        tooltip = row.finishedCount + t(' of ') + row.employeeCount + t('employees finished the toolbox');
                                                                    }
                                                                    return (
                                                                        <TableRow key={index} className={classes.tableRow}
                                                                            // onClick={navigate('/toolbox/details/' + row.toolboxId + '/' + moment(row.deployed).valueOf() + '?from=uitgestuurd')}
                                                                            onClick={() => { this.itemClick(row);}}
                                                                        >
                                                                            <TableCell className={classes.tableCell}>
                                                                                <div className={'tooltip'}
                                                                                     style={{
                                                                                         position: 'relative',
                                                                                         display: 'inline-block',
                                                                                     }}
                                                                                >
                                                                                    <span className={'tooltiptext'}>
                                                                                        {row.source === 'cumela' ? t('Cumela toolbox')
                                                                                            : row.source === 'global' ? t('Veilig Vakwerk toolbox')
                                                                                                : row.rootboxSource === 'global' || row.rootboxSource === 'cumela'
                                                                                                    ? t('Custom toolbox')
                                                                                                    : t('Own toolbox')}
                                                                                    </span>
                                                                                    {row.source === 'cumela' ? (<img
                                                                                            className={classes.cumelaLogo}
                                                                                            src="/assets/images/cumela-logo.png"
                                                                                            alt="cumela"/>
                                                                                    ) : row.source === 'global' ? (<img
                                                                                            className={classes.cumelaLogo}
                                                                                            src="/assets/images/vvw-admin-logo.svg"
                                                                                            alt="veiligvakwerk"/>
                                                                                    ) : row.rootboxSource === 'global' || row.rootboxSource === 'cumela' ? (
                                                                                        <img
                                                                                            className={classes.cumelaLogo}
                                                                                            src="/assets/images/aangepast.svg"
                                                                                            alt="veiligvakwerk"/>
                                                                                    ) : (
                                                                                        <img
                                                                                            className={classes.cumelaLogo}
                                                                                            src="/assets/images/vvw-logo.svg"
                                                                                            alt="veiligvakwerk"/>
                                                                                    )}
                                                                                </div>
                                                                                <span className={classes.tableCellTitle}>
                                                                                    {(row.toolboxTitle) ? row.toolboxTitle.replace(/(.{50})..+/, "$1...") : ""}
                                                                                </span>
                                                                            </TableCell>

                                                                            <TableCell className={classes.tableCell}  style={{width: '22%',}}>
                                                                            {moment(row.deployed).format('L HH:mm')}
                                                                            </TableCell>

                                                                            <TableCell className={classes.tableCell}/>

                                                                            <TableCell className={classes.tableCell}>
                                                                                <div className={'tooltip'}
                                                                                    style={{position:'relative',display:'inline-block',}}
                                                                                >
                                                                                    <span className={'tooltiptext'}style={{left: '-30px',}}>
                                                                                        {tooltip}
                                                                                    </span>
                                                                                    <span
                                                                                        className={classnames(
                                                                                            {
                                                                                                [classes.finishedNoneToolboxes]: true,
                                                                                                [classes.finishedNoneToolboxes]:
                                                                                                row.finishedCount !==
                                                                                                row.employeeCount,
                                                                                                [classes.finishedToolboxes]:
                                                                                                row.finishedCount ===
                                                                                                row.employeeCount,
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        {row.finishedCount}{' '}/{' '}
                                                                                        {row.employeeCount}
                                                                                    </span>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            )}
                                                    </TableBody>
                                                </Table>
                                            )}
                                        {this.state.toolboxDeployed &&
                                            this.state.toolboxDeployed.length === 0 && (
                                                <DashboardTableCardEmptyState
                                                    text={t(
                                                        'No toolboxes have been sent out yet...'
                                                    )}
                                                    actionTitle={t(
                                                        'Sent out toolbox'
                                                    )}
                                                    action={() => {
                                                        openDialog(
                                                            'toolboxDeploy',
                                                            {
                                                                initialScheduled: false,
                                                            }
                                                        );
                                                    }}
                                                />
                                            )}
                                    </DashboardTableCard>
                                </div>

                                <div>
                                    {modules.inspections && vv_functions && (
                                        <div>
                                            <div
                                                className={
                                                    classes.tableCardTitles
                                                }
                                            >
                                                <div
                                                    className={classnames({
                                                        [classes.sectionTitle]: true,
                                                        [classes.tableCardTitle]: true,
                                                    })}
                                                >
                                                    {t('Workplace inspections')}
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    classes.tableCardItems
                                                }
                                            >
                                                <DashboardTableCard
                                                    title={t('Scheduled')}
                                                    buttonText={t('View all')}
                                                    onButtonClick={navigate(
                                                        'werkplekinspecties#ingepland'
                                                    )}
                                                >
                                                    {this.state.scheduledInspections &&
                                                        this.state.scheduledInspections.length >
                                                        0 && (
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    {this.state.scheduledInspections
                                                                        .sort((e1, e2) => {
                                                                                const date1 =
                                                                                    moment(e1.scheduled).format('L HH:mm');
                                                                                const date2 = moment(e2.scheduled).format('L HH:mm');
                                                                                if (date1 === date2) {
                                                                                    return e1.title > e2.title ? 1 : -1;
                                                                                } else {
                                                                                    return moment(e1.scheduled).diff(moment(e2.scheduled));
                                                                                }
                                                                            }
                                                                        )
                                                                        .slice(0, 5).map((row, index) => {

                                                                                return (
                                                                                    <TableRow
                                                                                        key={index}
                                                                                        className={classes.tableRow}
                                                                                        onClick={navigate('/werkplekinspectie/preview/' + row.inspection.id + '#overzicht?page=0&rowsPerPage=10')}
                                                                                    >
                                                                                        <TableCell className={classes.tableCell}
                                                                                            style={{width: '30%',}}
                                                                                        >
                                                                                            <div className={'tooltip'}
                                                                                                style={{position:'relative',display:'inline-block',}}
                                                                                            >
                                                                                                <span className={'tooltiptext'}
                                                                                                    style={{left: '0px',}}
                                                                                                >
                                                                                                    {row.inspection.source ==='cumela' ? row.inspection.source === 'cumela'
                                                                                                        : row.inspection.source === 'global' ? t('Veilig Vakwerk werkplekinspectie')
                                                                                                        : row.inspection.rootInspectionSource === 'global' || row.inspection.rootInspectionSource === 'cumela'
                                                                                                                ? t('Custom workplace inspection') : t('Own workplace inspection')}
                                                                                                </span>
                                                                                                {row.inspection.source === 'cumela' ? (<img className={classes.cumelaLogo} src="/assets/images/cumela-logo.png" alt="cumela"/>
                                                                                                ) : row.inspection.source === 'global' ? (
                                                                                                    <img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/vvw-admin-logo.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                ) : row.inspection.rootInspectionSource === 'global' || row.inspection.rootInspectionSource === 'cumela' ? (
                                                                                                    <img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/aangepast.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/vvw-logo.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                )}
                                                                                            </div>

                                                                                            <span className={classes.tableCellTitle}>
                                                                                                {row.inspection.title}
                                                                                            </span>
                                                                                        </TableCell>

                                                                                        <TableCell className={classes.tableCell} style={{width: '30%',}}>
                                                                                            {moment(row.scheduled).format('L HH:mm')}
                                                                                        </TableCell>

                                                                                        <TableCell className={classes.tableCell}/>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        )}
                                                                </TableBody>
                                                            </Table>
                                                        )}
                                                    {this.state.scheduledInspections &&
                                                        this.state.scheduledInspections.length ===
                                                        0 && (
                                                            <DashboardTableCardEmptyState
                                                                text={t(
                                                                    'No workplace inspection planned yet...'
                                                                )}
                                                                actionTitle={t(
                                                                    'Plan workplace inspection'
                                                                )}
                                                                action={() => {
                                                                    openDialog(
                                                                        'inspectionDeploy',
                                                                        {
                                                                            initialScheduled: true,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </DashboardTableCard>

                                                <DashboardTableCard
                                                    title={t(
                                                        'Sent'
                                                    )}
                                                    buttonText={t('View all')}
                                                    onButtonClick={navigate(
                                                        'werkplekinspecties#resultaten'
                                                    )}
                                                >
                                                    {this.state.dashboardInspectionAssigned &&
                                                        this.state.dashboardInspectionAssigned.length >
                                                        0 && (
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    {this.state.dashboardInspectionAssigned
                                                                        .sort((e1, e2) => {
                                                                                return -moment(e1.deployed).diff(moment(e2.deployed));
                                                                            }
                                                                        )
                                                                        .slice(0, 5).map((row, index) => {
                                                                                let project = row.project;
                                                                                if (this.props.projects.byId[row.projectId]) {
                                                                                    project = this.props.projects.byId[row.projectId];
                                                                                }
                                                                                let inspection = undefined;
                                                                            inspection = row.inspection;
                                                                                let tooltip;
                                                                                if (row.result) {
                                                                                    if (row.result.allCorrect && row.result.totalComments === 0) {
                                                                                        tooltip = t('Everything is fine no comments');
                                                                                    } else if (row.result.allCorrect && row.result.totalComments === 1) {
                                                                                        tooltip = t('Everything is fine, with comment');
                                                                                    } else if (row.result.allCorrect && row.result.totalComments > 1) {
                                                                                        tooltip = t('Everything is fine, but there are') + '' + row.result.totalComments + ' ' + t('comments');
                                                                                    } else if (!row.result.allCorrect && row.result.totalComments === 1) {
                                                                                        tooltip = t('Something is wrong, with comment');
                                                                                    } else if (!row.result.allCorrect) {
                                                                                        tooltip = t('Something is wrong, there are') + ' ' + row.result.totalComments + ' ' + t('comments') + '.';
                                                                                    }
                                                                                }

                                                                                let text = '';
                                                                                if (row.result) {
                                                                                    if (row.result.allCorrect) {
                                                                                        tooltip = t('Correct');
                                                                                        text = t('Correct');
                                                                                    } else if (!row.result.allCorrect) {
                                                                                        tooltip = t('Not correct');
                                                                                        text = t('Not correct');
                                                                                    }
                                                                                }

                                                                                return (
                                                                                    <TableRow key={index} className={classes.tableRow} onClick={navigate('/werkplekinspectie/details/' + row.id + '?from=uitgestuurd')}>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <div className={'tooltip'} style={{position:'relative', display:'inline-block',}}>
                                                                                                <span className={'tooltiptext'}
                                                                                                    style={{left: '0px',}}
                                                                                                >
                                                                                                    {inspection.source === 'cumela' ? t('Cumela workplace inspection') : inspection.source === 'global'
                                                                                                        ? t('Veilig Vakwerk werkplekinspectie')
                                                                                                            : inspection.rootInspectionSource === 'global' || inspection.rootInspectionSource === 'cumela'
                                                                                                                ? t('Custom workplace inspection')
                                                                                                            : t('Own workplace inspection')}
                                                                                                </span>
                                                                                                {inspection.source === 'cumela' ? (<img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/cumela-logo.png"
                                                                                                        alt="cumela"
                                                                                                    />
                                                                                                ) : inspection.source === 'global' ? (<img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/vvw-admin-logo.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                ) : inspection.rootInspectionSource === 'global' || inspection.rootInspectionSource === 'cumela' ? (
                                                                                                    <img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/aangepast.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img className={classes.cumelaLogo}
                                                                                                        src="/assets/images/vvw-logo.svg"
                                                                                                        alt="veiligvakwerk"
                                                                                                    />
                                                                                                )}
                                                                                            </div>
                                                                                            <span className={classes.tableCellTitle} title={inspection.title}>
                                                                                                {(inspection.title) ? inspection.title.replace(/(.{20})..+/, "$1...") : ""}
                                                                                            </span>
                                                                                        </TableCell>

                                                                                        <TableCell className={classes.tableCell} style={{width: '22%',}}>
                                                                                            {moment(row.deployed).format('L HH:mm')}
                                                                                        </TableCell>

                                                                                        <TableCell className={classes.tableCell}title={project && project.omschrijving}>
                                                                                            {project && project.omschrijving && project.omschrijving.replace(/(.{15})..+/, "$1...")}
                                                                                        </TableCell>

                                                                                        <TableCell className={classes.tableCell}>
                                                                                            {row.result && row.result.complete ? (
                                                                                                <div className={'tooltip'}
                                                                                                     style={{position: 'relative', display: 'inline-block',}}
                                                                                                >
                                                                                                    <span className={'tooltiptext'}>{tooltip}</span>
                                                                                                    <span className={classnames(
                                                                                                            {
                                                                                                                [classes.finishedCount]: true,
                                                                                                                [classes.finishedNone]:
                                                                                                                    !row.result.allCorrect,
                                                                                                                [classes.finishedAll]:
                                                                                                                row.result.allCorrect,
                                                                                                                [classes.notComplete]:
                                                                                                                    !row.result.complete,
                                                                                                            }
                                                                                                        )}
                                                                                                        style={{fontWeight: '300',}}
                                                                                                    >
                                                                                                        {text}
                                                                                                    </span>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div
                                                                                                    className={'tooltip'}
                                                                                                    style={{position: 'relative',display: 'inline-block',}}>
                                                                                                    <span
                                                                                                        className={'tooltiptext'}
                                                                                                        style={{left: '-10px',}}>
                                                                                                        {t('Workplace inspection is not yet completed')}
                                                                                                    </span>
                                                                                                    <span
                                                                                                        className={classnames(
                                                                                                            {
                                                                                                                [classes.finishedCount]: true,
                                                                                                                [classes.NotFinishedWPI]: true,
                                                                                                            }
                                                                                                        )}
                                                                                                    >
                                                                                                        {t('Not completed')}
                                                                                                    </span>
                                                                                                </div>
                                                                                            )}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        )}
                                                                </TableBody>
                                                            </Table>
                                                        )}
                                                    {this.state.dashboardInspectionAssigned &&
                                                        this.state.dashboardInspectionAssigned.length === 0 && (
                                                            <DashboardTableCardEmptyState
                                                                text={t('No workplace inspections have been sent out yet...')}
                                                                actionTitle={t('Sent out workplace inspection')}
                                                                action={() => {
                                                                    openDialog('inspectionDeploy',
                                                                        {
                                                                            initialScheduled: false,
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </DashboardTableCard>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/*}*/}

                        <div className={classes.version}>
                            <img
                                className={classes.cumelaLogo}
                                src="/assets/images/vvw-admin-logo.svg"
                                alt="Veilig Vakwerk"
                            />
                            {backgroundLoading && (
                                <div className={classes.spinnerRoot}>
                                    <div className={classes.spinner}>
                                        <Spinner/>
                                    </div>
                                    {t('Data is loading')}
                                </div>
                            )}
                            <span className={classes.veiligVakwerkLabel}>{t('Veilig Vakwerk')}</span>
                            <span className={classes.versionLabel}>{t('version')} 25.03.0</span>
                            {/* TODO: READ VERSION NUMBER FROM package.json*/}
                        </div>
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Dashboard);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {IconButton} from '@mui/material';
import {AddCircleOutline, Done, RemoveCircle} from '@mui/icons-material';
import {getPermissions, getVisibleInspectionVersions, inProgress,} from '../../../selectors';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import {
    addUserToUsergroup,
    deleteUsergroup,
    fetchEmployees,
    openDialog,
    removeUserFromUsergroup,
} from '../../../actions';
import AppModule from '../../AppModule';
import EnhancedTable from '../../EnhancedTable';
import TableActionHeaderUsergroup from '../TableActionHeaderUsergroup';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import './UsergroupDetailView.css';
import classes from './UsergroupDetailView.module.scss';

type Props = {
    loading: boolean,
    permissions: any,
};

type State = {
    columns: Array<any>,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean
    }>,
    searchtext: string
};

const mapStateToProps = (store, props) => {
    let inspections = getVisibleInspectionVersions(store);
    const allEmployees = store.entities.employees;
    const allUsergroups = store.entities.usergroups;

    return {
        page: Number,
        rowsPerPage: Number,
        allUsergroups: allUsergroups,
        usergroups: allUsergroups.allIds.map((id) => allUsergroups.byId[id]),
        usergroup: allUsergroups.byId[props.match.params.id],
        allEmployees: allEmployees,
        employees: allEmployees.allIds.map((id) => allEmployees.byId[id]),
        loading: !inProgress(store),
        inspections: inspections,
        permissions: getPermissions(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {

    return {
        deleteUsergoup: (usergroup, t) => {
            dispatch(deleteUsergroup(usergroup, t));
        },
        createUsergroup: () => {
            dispatch(openDialog('usergroupCreate'));
        },
        addUserToUsergroup: (usergroupId, employeeId) => {
            dispatch(addUserToUsergroup(usergroupId, employeeId));
        },
        removeUserFromUsergroup: (usergroupId, employeeId) => {
            dispatch(removeUserFromUsergroup(usergroupId, employeeId));
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
    };
};

class UsergroupDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        columns: [
            {id: 'number', label: 'Nr', size: '8%'},
            {id: 'firstname', label: 'Name', size: '15%'},
            {id: 'lastname', label: 'Last name', size: '15%'},
            {id: 'email', label: 'Email address', size: '20%'},
            {id: 'phonenumber', label: 'Phone number', size: '15%'},
            {id: 'state', numeric: false, size: '150px', label: 'Status', classes: 'status'},
            {id: 'icon', label: 'Member of group', size: '10%', noHoverBackground: true, noPadding: true}
        ],
        searchtext: '',
        page: 0,
        rowsPerPage: 10
    };

    mapUsergroupToRow = (user) => {
        const {t} = this.props;

        return {
            ...user,
            number: user.externalId,
            lastname: user.middlename
                ? user.middlename + ' ' + user.lastname
                : user.lastname,
            email: user.username ? user.username : user.externalusername,
            phonenumber: user.phonenumber ? user.phonenumber : '-',
            state: this.userStateToText(user.state, user.roles),
            icon: this.isMember(user) ? (
                <div className={classes.buttonContainer}>
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '-40px', top: '15px'}}
                        >
                            {t('Remove from group')}
                        </span>
                        <IconButton
                            className="button-remove-from-group"
                            onClick={() => this.handleRemoveUser(user)}
                        >
                            <Done className="icon-added-to-group"/>
                            <RemoveCircle className="icon-remove-from-group"/>
                        </IconButton>
                    </div>
                </div>
            ) : (
                <div className={classes.buttonContainer}>
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '-40px', top: '15px'}}
                        >
                            {t('Add to group')}
                        </span>
                        <IconButton onClick={() => this.handleAddUser(user)}>
                            <AddCircleOutline/>
                        </IconButton>
                    </div>
                </div>
            ),
        };
    };

    setPage = (page) => {
        console.log("INFO: ToolboxOverview page = " + page);
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ToolboxOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    isMember = (user) => {
        if (this.props.usergroup.users) {
            return this.props.usergroup.users.find((usergroup) => {
                return usergroup.id === user.id;
            });
        }
    };

    handleAddUser = (row) => {
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;
        if (usergroupId && row.id) {
            this.props.addUserToUsergroup(usergroupId, row.id);
        }
    };

    handleRemoveUser = (row) => {
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;
        if (usergroupId && row.id) {
            this.props.removeUserFromUsergroup(usergroupId, row.id);
        }
    };

    userStateToText = (state, roles) => {
        const {t} = this.props;
        let text = '';

        switch (state) {
            case 'ADDED':
                text = t('Inactive');
                break;
            case 'ACTIVE':
                text = t('Active');
                break;
            case 'INACTIVE':
                text = t('Inactive');
                break;
            default:
        }

        if (
            roles.find((element) => {
                return element === 'EXTERN';
            })
        ) {
            text = t('External');
            state = 'EXTERN';
        }

        return <div className={'border-text ' + state}>{text}</div>;
    };

    componentDidMount() {
        this.props.fetchEmployees();

        let actions = [];

        actions.push({
            id: 'delete',
            label: 'Verwijderen',
            isVisible: true,
        });

        this.setState({actions});
    }

    handleSearch = searchtext => {
        this.setState({
            searchtext: searchtext
        })
    };

    groupSorter = () => {
        return {
            number: (a, b) => {
                a = a.externalId.toLowerCase();
                b = b.externalId.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            icon: (a, b) => {
                const amember = this.isMember(a);
                const bmember = this.isMember(b);

                if (amember && !bmember) {
                    return -1;
                }
                if (!amember && bmember) {
                    return 1;
                }
                // Sort on number if same
                a = a.externalId.toLowerCase();
                b = b.externalId.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            firstname: (a, b) => {
                a = a.firstname.toLowerCase();
                b = b.firstname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            lastname: (a, b) => {
                a = a.lastname.toLowerCase();
                b = b.lastname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            email: null,
            phonenumber: null,
        };
    };

    render() {
        const {loading, allUsergroups, employees, t} = this.props;
        const {columns} = this.state;

        if (!allUsergroups) {
            return <div/>;
        }

        if (allUsergroups.length === 0 || allUsergroups.allIds.length === 0) {
            return <div/>;
        }
        const usergroupId = this.props.match.params.id
            ? parseInt(this.props.match.params.id, 10)
            : undefined;

        const usergroup = allUsergroups.byId[usergroupId];

        const tableActionHeader = <TableActionHeader searchPlaceholder={t('Search employees')}
                                                     onSearchChange={this.handleSearch} groupsmode={false}
                                                     searchAlwaysOpen={true}
                                                     title={usergroup.name ? <TableActionHeaderUsergroup
                                                         headerItem={usergroup}/> : 'Naam onbekend'}/>;

        return (
            <AppModule loading={loading} prepend={tableActionHeader}>
                <EnhancedTable
                    defaultOrderBy={'icon'}
                    defaultOrder="asc"
                    columns={columns}
                    rows={employees.filter(row => (row.fullname.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1) || (row.username && row.username.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1) || (row.externalId && row.externalId.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1))}
                    formatter={this.mapUsergroupToRow}
                    actions={[]}
                    onAction={this.handleAction}
                    sorter={this.groupSorter()}
                    emptyState={t('No employees found.')}
                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={this.state.startRowsPerPage}
                />
            </AppModule>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(UsergroupDetailView);

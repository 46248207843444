// @flow
import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import moment from 'moment';
import { percentColors5 } from '../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    inspectionAssign: any,
    key?: number,
};

const StatisticsRowInspection = (props: Props, context: Object) => {
    let { t, inspectionAssign, key, handleRowClick, ...childProps } = props;

    // kamResoled
    // Only show the kamResolved status when explicitly marked done or
    // Show status when wpi is unsafe or not everythinok
    let kamResolved = '';
    if (inspectionAssign.kamResolved) {
        kamResolved = t('Yes');
    } else if (inspectionAssign.result != null && (!inspectionAssign.result.allCorrect || inspectionAssign.result.unsafe) ) {
        kamResolved = t('No');
    }

    return (
        <TableRow
            {...childProps}
            key={props.key}
            className="selectable"
            onClick={handleRowClick}
        >
            <TableCell style={{ width: '20%' }}>
                {inspectionAssign.deployed
                    ? moment(inspectionAssign.deployed).format('L HH:mm')
                    : '-'}
            </TableCell>
            <TableCell style={{ width: '5%' }}>
                {inspectionAssign.inspection.version || '-'}
            </TableCell>
            <TableCell style={{ width: '20%', wordBreak: 'break-all' }}>
                {inspectionAssign.project &&
                inspectionAssign.project.omschrijving
                    ? inspectionAssign.project.externalId +
                      ': ' +
                      inspectionAssign.project.omschrijving
                    : '-'}
            </TableCell>
            <TableCell style={{ width: '20%', wordBreak: 'break-all' }}>
                {(inspectionAssign.activity && inspectionAssign.activity) ||
                    '-'}
            </TableCell>
            <TableCell style={{ width: '15%', wordBreak: 'break-all' }}>
                {inspectionAssign.user && inspectionAssign.user.shortname
                    ? inspectionAssign.user.shortname
                    : '- '}
            </TableCell>
            <TableCell style={{ width: '10%' }}>
                {inspectionAssign.result && inspectionAssign.result.date
                    ? moment(inspectionAssign.result.date).format(t('dateTime'))
                    : '-'}
            </TableCell>
            <TableCell style={{ width: '10%' }}>
                {inspectionAssign.result ? (
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor: getColorForPercentage(
                                inspectionAssign.result &&
                                    inspectionAssign.result.allCorrect
                                    ? 1
                                    : 0
                            ),
                            borderColor: getColorForPercentage(
                                inspectionAssign.result &&
                                    inspectionAssign.result.allCorrect
                                    ? 1
                                    : 0
                            ),
                            width: '60px',
                        }}
                    >
                        {inspectionAssign.result &&
                        inspectionAssign.result.allCorrect
                            ? t('Yes')
                            : t('No')}
                    </div>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell style={{ width: '10%'}}>
                <div className={'tooltip'} style={{position: 'relative'}}>
                    <span className={'tooltiptext'} >
                        {inspectionAssign.kamComment === null ? '-' : inspectionAssign.kamComment}
                    </span>
                    <div>
                        { kamResolved  }
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};

var percentColors = percentColors5;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[1];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(withTranslation())(StatisticsRowInspection);

// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {editVideoToolboxBlockDraft, showSnackbar} from '../../actions';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import classes from './VideoToolboxBlockForm.module.scss';

type Props = {
    classes: any,
    contentBlock: any,
    onChange: Function,
    update: Function,
    textfull: Function,
};

type State = {
    error: boolean,
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        update: (key, value) =>
            dispatch(editVideoToolboxBlockDraft(props.contentBlock.id, key, value)),
        textfull: (text) => dispatch(showSnackbar(text)),
    };
};

const mapStateToProps = (store) => {
    return {
        videoToolboxes: store.entities.videoToolboxes,
    };
};

class VideoToolboxBlockForm extends React.Component {
    props: Props;
    state: State = {
        value: this.props.contentBlock.videoToolboxBlock.id,
    };
    timer = null;

    handleVideoChange = (key) => (event) => {
        console.log("event.target: " + JSON.stringify(event.target))
        var value = event.target.value;

        this.setState({value});

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.props.onChange) {
                this.props.onChange();
            }

            this.props.update(key, value);
            console.log("url: " + this.props.videoToolboxes.byId[value].url)
            this.props.update("url", this.props.videoToolboxes.byId[value].url)
        }, 400);
    };

    getInputId(path) {
        return this.props.contentBlock.id + '-videoToolboxBlock-' + path;
    }

    render() {
        const {contentBlock, t} = this.props;

        return (
            <form className={classes.root}>
                <FormControl variant="standard">
                    <InputLabel htmlFor={this.getInputId('video')}>
                        {t('Video')}
                    </InputLabel>
                    <Select
                        fullWidth={true}
                        id={this.getInputId('video')}
                        value={this.state.value}
                        onChange={this.handleVideoChange("id")}
                    >
                        {this.props.videoToolboxes.allIds.map((id) => this.props.videoToolboxes.byId[id]).map((video) => (
                                video.active && <MenuItem
                                    key={video.id}
                                    value={video.id}>{t(video.name)}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
            </form>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(VideoToolboxBlockForm);

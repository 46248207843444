// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem
} from '@mui/material';
import {cancelGroupAssign, cancelSingleAssign, fetchScheduledToolboxesOverview, pushCrumbPath,} from '../../actions';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import {getPermissions} from '../../selectors';
import './ToolboxScheduledOverview.css';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {openDialog} from '../../actions/uiActions';
import $ from 'jquery';
import FilterHeader from '../FilterHeader';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from 'i18next';
import {V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX} from "../../lib/apiTypes";
import {toolboxScheduledOverviewInProgress} from "../../selectors/inProgress";

type Props = {
    loading: boolean,
    scheduledAssigns: [any],
    goToDetailView: (toolboxId: string) => mixed,
    fetchScheduledToolboxesOverview: () => void,
    cancelSingleAssign: (assignId: number) => void,
    cancelGroupAssign: (groupId: string) => void
};

type State = {
    page: number,
    rowsPerPage: number,
    searchtext: string,
    actions: Array<{
        id: string,
        label: string,
    }>,
    typeFilter: any,
    openSector: boolean,
    sectorFilter: any,
    openCategory: boolean,
    categoryFilter: any,
    assignIdToCancel: any
};

const mapStateToProps = (store) => {
    const sectors = store.entities.sectors;
    const categories = store.entities.categories;

    const scheduledEntities = store.entities[V2_TOOLBOX_SCHEDULED_OVERVIEW_REDUX]
    const scheduledAssigns = scheduledEntities.allIds.map(id => scheduledEntities.byId[id]);

    return {
        loading: toolboxScheduledOverviewInProgress(store) && scheduledAssigns.length === 0,
        permissions: getPermissions(store),
        scheduledAssigns: scheduledAssigns,
        sectors: sectors.allIds.map((id) => sectors.byId[id]),
        categories: categories.allIds.map((id) => categories.byId[id]),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        goToDetailView: (toolboxId, page, rowsPerPage) => {
            dispatch(
                push(
                    `/toolbox/details/${toolboxId}/#ingepland?page=${page}&rowsPerPage=${rowsPerPage}`
                )
            );
        },
        fetchScheduledToolboxesOverview: () => {
            dispatch(fetchScheduledToolboxesOverview());
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        cancelSingleAssign: (assignId: string) => {
            dispatch(cancelSingleAssign(assignId));
        },
        cancelGroupAssign: (groupId: number) => {
            dispatch(cancelGroupAssign(groupId));
        },
    };
};

let categories = [];

class ToolboxScheduledOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        searchtext: '',
        columns: [
            {id: 'logo', label: 'Type', size: '60px', noPadding: true},
            {id: 'title', label: 'Title', size: '35%'},
            {id: 'scheduled', label: 'Send out on', size: '15%'},
            {id: 'toolboxMeeting', label: 'Toolbox meeting', size: '15%'},
            {id: 'version', label: 'Version', size: '7%'},
            {id: 'employees', label: '#Employees', size: '15%'},
        ],
        actions: [{id: 'cancel', label: 'Cancel'}],
        typeFilter: 0,
        openSector: false,
        sectorFilter: 0,
        openCategory: false,
        categoryFilter: 0,
    };

    mapToolboxAssignToRow = (scheduledItem) => {
        const {t} = this.props;
        let height = 0;
        height = height + scheduledItem.employees.length * 10;
        moment.locale(i18n.t('LOCALE'));

        return {
            ...scheduledItem,
            scheduled: moment(scheduledItem.scheduled).format('l HH:mm'),
            toolboxMeeting:
                scheduledItem.toolboxMeeting === true ? 'Ja' : 'Nee',
            logo:
                scheduledItem.source === 'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '30px'}}
                        >
                            {t('Cumela toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt="Cumela logo"
                        />
                    </div>
                ) : scheduledItem.source === 'global' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '10px'}}
                        >
                            {t('Veilig Vakwerk toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt="Admin logo"
                        />
                    </div>
                ) : scheduledItem.rootboxSource === 'global' ||
                scheduledItem.rootboxSource === 'cumela' ? (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '15px'}}
                        >
                            {t('Custom toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt="Aangepast logo"
                        />
                    </div>
                ) : (
                    <div className={'tooltip'} style={{padding: 0}}>
                        <span
                            className={'tooltiptext'}
                            style={{left: '30px'}}
                        >
                            {t('Own toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt="Eigen logo"
                        />
                    </div>
                ),
            employees: scheduledItem.employees ? (
                <div className={'tooltip'} style={{padding: 0}}>
                    <span
                        className={'tooltiptext'}
                        style={{
                            width: '100px',
                            height: height,
                            left: '-65px',
                        }}
                    >
                        {scheduledItem.employees &&
                            scheduledItem.employees.map((name, index) => (
                                <div key={index}>{name}</div>
                            ))}
                    </span>
                    <div>{scheduledItem.employees.length}</div>
                </div>
            ) : (
                <div className={'tooltip'} style={{padding: 0}}>
                    <span
                        className={'tooltiptext'}
                        style={{
                            width: '100px',
                            height: height,
                            left: '-65px',
                        }}
                    >
                        {scheduledItem.groupList &&
                            scheduledItem.groupList.map((name, index) => (
                                <div key={index}>{name}</div>
                            ))}
                    </span>
                    <div>{scheduledItem.employees.length}</div>
                </div>
            ),
        };
    };

    onRowClick = (row) => {
        if (row.toolboxId) {
            // set Crumb for this tab and page
            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('Scheduled'),
                    link: `/toolboxen#ingepland?page=${this.state.page}&rows=${this.state.rowsPerPage}`,
                    subTitle: row.title,
                }
            });

            this.props.goToDetailView(row.toolboxId, this.state.page, this.state.rowsPerPage);
        }
    };

    handleScheduleButtonClick = () => {
        this.props.openDialog('toolboxDeploy', {initialScheduled: true});
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    toolboxSorter = () => {
        return {
            logo: (a, b) => {
                if (a.source > b.source) {
                    return 1;
                } else if (a.source < b.source) {
                    return -1;
                } else {
                    return 0;
                }
            },
            toolboxMeeting: (ta, tb) => {
                //let a = moment(ta.deployed || ta.scheduled) ;
                //let b = moment(tb.deployed || tb.scheduled);

                if (ta.toolboxMeeting === true && tb.toolboxMeeting === false) {
                    return 1;
                }
                if (ta.toolboxMeeting === false && tb.toolboxMeeting === true) {
                    return -1;
                }
                return 0;
            },
        };
    };

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    handleAction = (event, action) => {
        if (action.id === 'cancel') {
            this.setState({dialogOpen: true, assignIdToCancel: action.rowId});
        }
    };

    hideConfirmDialog = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    handleCancel = () => {
        const assignId = this.state.assignIdToCancel

        const assignItem = this.props.scheduledAssigns.find((assignItem) => {
            return assignItem.id === assignId
        })

        if (assignItem.employees.length === 1) {
            this.props.cancelSingleAssign(this.state.assignIdToCancel);
        } else {
            this.props.cancelGroupAssign(this.state.assignIdToCancel);
        }

        this.hideConfirmDialog();
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openType: false,
            openSector: false,
            openCategory: false,
        });
        this.setState(newState);
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({typeFilter: event.target.value});
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSector: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterSectorValue(sectorfilter) {
        const {t} = this.props;
        let filteredSector = t('Everything');
        if (sectorfilter === 0) {
            return filteredSector;
        } else if (sectorfilter === -1) {
            return 'Geen sector';
        }

        this.props.sectors.forEach((sector) => {
            if (sector.id === sectorfilter) {
                filteredSector = sector.name;
            }
        });

        return filteredSector;
    }

    handleToolboxCategoryFilterChange = (event) => {
        this.handleRequestClose();

        let cat = 'all';
        if (event.target.value === -1) {
            cat = 'all';
        } else {
            cat = categories[event.target.value].id;
        }

        this.setState({categoryFilter: cat});
    };

    handleToolboxSectorFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({sectorFilter: event.target.value});
    };

    getFilterCategoryValue(catfilter) {
        const {t} = this.props;
        let filteredCat = t('Everything');

        if (catfilter === 'all') {
            return filteredCat;
        }

        this.props.categories.forEach((cat) => {
            if (cat.id === catfilter) {
                filteredCat = cat.name;
            }
        });

        return filteredCat;
    }

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openCategory: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterBar = (total, searchresults, list) => {
        const {t} = this.props;
        let sectorIds = [];
        let sectors = [];

        let catIds = [];
        categories = [];

        let vvw = false;
        let cumela = false;
        let aangepast = false;
        let eigen = false;

        list.forEach((assign) => {
            if (
                assign.source !== 'cumela' &&
                assign.source !== 'global' &&
                assign.rootboxSource !== 'cumela' &&
                assign.rootboxSource !== 'global'
            ) {
                eigen = true;
            } else if (
                assign.source !== 'cumela' &&
                assign.source !== 'global' &&
                (assign.rootboxSource === 'cumela' ||
                    assign.rootboxSource === 'global')
            ) {
                aangepast = true;
            } else if (assign.source === 'global') {
                vvw = true;
            } else if (assign.source === 'cumela') {
                cumela = true;
            }
            if (assign.sectors.length > 0) {
                assign.sectors.forEach((sector) => {
                    if (!sectorIds.includes(sector)) {
                        sectorIds.push(sector);
                    }
                });
            }
            if (assign.category.length > 0) {
                if (!catIds.includes(assign.category)) {
                    catIds.push(assign.category);
                }
            }
        });

        sectorIds.forEach((sector) => {
            this.props.sectors.forEach((sec) => {
                if (sector === sec.id) {
                    sectors.push(sec);
                }
            });
        });

        catIds.forEach((cat) => {
            this.props.categories.forEach((cat2) => {
                if (cat === cat2.id) {
                    categories.push(cat2);
                }
            });
        });

        let catIndex = 0;
        return (
            <div className="filterbar">
                <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    {total +
                        ` ${t(total === 1 ? 'toolbox' : 'toolboxes')}` +
                        ` ${t('scheduled')}` +
                        (this.state.searchtext.length > 0
                            ? ' - ' + (searchresults ? searchresults.length : 0) +
                            ' ' + t(searchresults && searchresults.length === 1 ? 'searchresult' : 'searchresults')
                            : '')}
                </div>
                <div style={{marginLeft: '20px'}}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Everything')
                                    : this.state.typeFilter === 1
                                        ? 'Veilig Vakwerk'
                                        : this.state.typeFilter === 2
                                            ? 'CUMELA'
                                            : this.state.typeFilter === 3
                                                ? t('Edited')
                                                : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state.openType ?? false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('Veilig Vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('CUMELA')}
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={'Sector'}
                            filterValue={this.getFilterSectorValue(
                                this.state.sectorFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSector : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.state.sectorFilter ? this.state.sectorFilter : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxSectorFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {sectors.map((sector) => {
                        return (
                            <MenuItem
                                onClick={this.handleToolboxSectorFilterChange}
                                value={sector.id}
                            >
                                {sector.subscriptionHolder === null ? t(sector.name) : sector.name}
                            </MenuItem>
                        );
                    })}
                    <MenuItem
                        onClick={this.handleToolboxSectorFilterChange}
                        value={-1}
                    >
                        {t('No sector')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={t('Category')}
                            filterValue={this.getFilterCategoryValue(
                                this.state.categoryFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openCategory : false}
                    onClose={this.handleRequestClose}
                    value={this.state.categoryFilter}
                    onChange={this.handleToolboxCategoryFilterChange}
                    anchorEl={this.state ? this.state.anchorEl : 0}

                >
                    <MenuItem
                        onClick={this.handleToolboxCategoryFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>

                    {categories.map((cat) => {
                        return (
                            <MenuItem
                                onClick={this.handleToolboxCategoryFilterChange}
                                value={catIndex++}
                            >
                                {t(cat.locKey || cat.name)}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    };

    componentDidMount() {
        const {t} = this.props;

        this.props.fetchScheduledToolboxesOverview();

        if (this.props.location.hash.includes("ingepland")) {
            const searchString = '?' + this.props.location.hash.split("?")[1]

            if (searchString.includes("page")) {
                const jumpToPage = new URLSearchParams(searchString).get("page");
                this.setState({startPage: Number(jumpToPage)});
                this.setState({page: Number(jumpToPage)});
            }
            if (searchString.includes("rowsPerPage")) {
                const startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
                this.setState({startRowsPerPage: Number(startRowsPerPage)});
                this.setState({rowsPerPage: Number(startRowsPerPage)});

            }
        }
    }

    render() {
        const {
            loading,
            scheduledAssigns,
            t,
        } = this.props;
        const {columns, actions, dialogOpen} = this.state;

        const searchResults = scheduledAssigns
            .filter((row) => {
                let employeeFound = false;
                row.employees &&
                row.employees.forEach((employee) => {
                    if (
                        employee
                            .replace('  ', ' ')
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                        -1
                    ) {
                        employeeFound = true;
                    }
                });

                return (
                    employeeFound ||
                    row.title
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                );
            })
            .filter((row) => {
                if (this.state.typeFilter === 0) {
                    return true;
                } else if (this.state.typeFilter === 1) {
                    if (row.source === 'global') {
                        return true;
                    }
                } else if (this.state.typeFilter === 2) {
                    if (row.source === 'cumela') {
                        return true;
                    }
                } else if (this.state.typeFilter === 3) {
                    if (
                        row.source !== 'cumela' &&
                        row.source !== 'global' &&
                        (row.rootboxSource === 'cumela' ||
                            row.rootboxSource === 'global')
                    ) {
                        return true;
                    }
                } else if (this.state.typeFilter === 4) {
                    if (
                        row.source !== 'cumela' &&
                        row.source !== 'global' &&
                        row.rootboxSource !== 'cumela' &&
                        row.rootboxSource !== 'global'
                    ) {
                        return true;
                    }
                }
                return false
            })
            .filter((row) =>
                this.state.sectorFilter && this.state.sectorFilter !== 0
                    ? this.state.sectorFilter !== -1
                        ? row.sectors.includes(
                            this.state.sectorFilter
                        )
                        : row.sectors.length === 0
                    : true
            )
            .filter((row) =>
                this.state.categoryFilter && this.state.categoryFilter !== 'all'
                    ? row.category === this.state.categoryFilter
                    : true
            );

        const total = scheduledAssigns
            .map((t) => (t.employees ? t.employees.length : 1))
            .reduce((a, b) => a + b, 0);
        const tableActionHeader = (
            <TableActionHeader
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search toolboxes')}
                searchAlwaysOpen={true}
                title={this.getFilterBar(total, searchResults, scheduledAssigns)}
                widemode={true}
                onActionButton={this.handleScheduleButtonClick}
                onActionText="Plan toolboxes"
            />
        );

        return (
            <div>
                <Dialog
                    open={dialogOpen ?? false}
                    onClose={this.hideConfirmDialog}
                >
                    <DialogTitle>{t('Cancel toolbox')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Are you sure you want to cancel this toolbox?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={this.hideConfirmDialog}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleCancel}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    {/*<div*/}
                    {/*    className={classnames({*/}
                    {/*        [classes.button]: true,*/}
                    {/*        'mui-fixed': true,*/}
                    {/*    })}*/}
                    {/*>*/}
                    {/*    <div className={'tooltip'} style={{padding: 0}}>*/}
                    {/*        <span*/}
                    {/*            className={'tooltiptext'}*/}
                    {/*            style={{top: '60px', left: '35px'}}*/}
                    {/*        >*/}
                    {/*            {t('Plan toolboxes')}*/}
                    {/*        </span>*/}
                    {/*        <Fab*/}

                    {/*            onClick={this.handleScheduleButtonClick}*/}
                    {/*        >*/}
                    {/*            <ScheduleIcon/>*/}
                    {/*        </Fab>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <EnhancedTable
                        defaultOrderBy="scheduled"
                        defaultOrder="desc"
                        hover
                        className="click"
                        onClick={this.onRowClick}
                        columns={columns}
                        rows={searchResults}
                        formatter={this.mapToolboxAssignToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.toolboxSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t('No toolboxes have been scheduled yet.')
                        }
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}
                    />
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(ToolboxScheduledOverview))
);

// @flow
import React from 'react';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {
    Badge,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import OverviewIcon from '@mui/icons-material/ViewList';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ConstructionIcon from '@mui/icons-material/Construction';
import TaskIcon from '@mui/icons-material/Task';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ProjectIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AbonnementIcon from '@mui/icons-material/CreditCard';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {getModules} from '../lib';
import {getPermissions} from '../../selectors';
import {
    closeSideMenu,
    fetchIncidents,
    fetchSpeakups,
    getCurrentEmployee,
    logout,
    openDialog,
    openSideMenu,
    toggleSideMenu,
} from '../../actions';
import {TEmployee} from '../../reducers/employeesReducer';
import * as storage from '../../lib/storage';
import classes from './SideMenu.module.scss';
import getRoles from "../../selectors/getRoles";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import MenuIcon from "@mui/icons-material/Menu";

type Props = {
    isOpen: boolean,
    close: () => void,
    open: () => void,
    navigate: (string) => void,
    logout: () => void,
};

type State = {
    toolboxSubmenuIsOpen: boolean,
    inspectionSubmenuIsOpen: boolean,
    currentEmpl: TEmployee,
};

const mapStateToProps = (store) => {
    let badgeCount = 0;
    let newSpeakups = 0;

    if (store.entities.incidents) {
        store.entities.incidents.allIds.forEach((id) => {
            if (store.entities.incidents.byId[id].isnew) {
                badgeCount++;
            }
        });
    }
    if (store.entities.speakups) {
        store.entities.speakups.allIds.forEach((id) => {
            if (store.entities.speakups.byId[id].isnew) {
                newSpeakups++;
            }
        });
    }

    return {
        isOpen: store.ui.sideMenu.isOpen,
        permissions: getPermissions(store),
        inspectionCount: store.entities.inspections.allIds.length,
        incidents: badgeCount,
        newSpeakups: newSpeakups,
        currentEmpl: store.drafts.employees.currentUser,
        roles: getRoles(store),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        open: () => {
            dispatch(openSideMenu());
        },
        close: () => {
            dispatch(closeSideMenu());
        },
        toggle: () => {
            dispatch(toggleSideMenu());
        },
        navigate: (newPath) => (event) => {
            // dispatch(closeSideMenu());
            dispatch(push(newPath));
        },
        openDialog: (dialogName) => {
            dispatch(closeSideMenu());
            dispatch(openDialog(dialogName));
        },
        logout: () => {
            dispatch(logout());
        },
        getCurrentEmployee: () => {
            dispatch(getCurrentEmployee());
        },
        fetchIncidents: () => {
            dispatch(fetchIncidents());
        },
        fetchSpeakups: () => {
            dispatch(fetchSpeakups());
        },
    };
};

class SideMenu extends React.Component<Props, State> {
    props: Props;
    state: State = {
        inspectionSubmenuIsOpen: false,
        toolboxSubmenuIsOpen: false,
    };

    componentDidMount() {
        this.props.getCurrentEmployee();

        if (process.env.REACT_APP_PROJECT === 'janssen') {
            this.props.fetchSpeakups();

        }
        this.props.fetchIncidents();
    }

    toggleToolboxSubmenu = () => {
        this.setState({
            toolboxSubmenuIsOpen: !this.state.toolboxSubmenuIsOpen,
        });
    };

    toggleInspectionSubmenu = () => {
        this.setState({
            inspectionSubmenuIsOpen: !this.state.inspectionSubmenuIsOpen,
        });
    };

    handleCreateToolboxClick = () => {
        this.props.openDialog('toolboxCreate');
    };

    handleCreateInspectionClick = () => {
        this.props.openDialog('inspectionCreate');
    };

    handleDeployToolboxClick = () => {
        this.props.close();
        this.props.openDialog('toolboxDeploy');
    };

    handleDeployInspectionClick = () => {
        this.props.close();
        this.props.openDialog('inspectionDeploy');
    };

    render() {
        const {
            close,
            isOpen,
            logout,
            navigate,
            permissions,
            currentEmpl,
            t,
            roles,
        } = this.props;

        const modules = getModules()

        let vv_functions;

        if (currentEmpl) {
            vv_functions = currentEmpl.subscription.vv_functions;
            //isTrial = currentEmpl.subscription.trial;
            //trialCreatedOn = currentEmpl.subscription.dateCreatedOn;
            //trialEndsIn = Math.round(
            //     30 -
            //         (new Date() - new Date(trialCreatedOn)) /
            //             (1000 * 60 * 60 * 24)
            // );
            //trialEndsIn = trialEndsIn < 0 ? 0 : trialEndsIn;
            // trialEndsIn = 0;
            // locked = !isTrial || (isTrial && trialEndsIn > 0);
        }

        let isImpersonating = storage.fetchLogin().secondToken
            ? t('Switch back to admin')
            : t('Logout');

        return (
            <Drawer
                sx={{
                    width: 260,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 260,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent" anchor="left" open={isOpen} onClose={close}>
                    <div>
                        <div className={classes.topBlock}>
                            <IconButton className={classes.closeButton} onClick={this.props.close}
                                        size="large">
                                {this.props.isOpen ? <ArrowBack className={classes.menuIcon}/> :
                                    <MenuIcon className={classes.menuIcon}/>}
                            </IconButton>
                            <div className={classes.sideMenuLogo}>
                                <img src={"/assets/images/" + (process.env.REACT_APP_LOGO || "vvw.png")}
                                    alt={t("Veilig Vakwerk")}
                                    onClick={() => this.props.close()}
                                />
                            </div>

                        </div>
                        <List className={classes.list}>
                            <ListItem button onClick={navigate("/")}>
                                <ListItemIcon>
                                    <DashboardIcon className={classes.icon}/>
                                </ListItemIcon>
                                <ListItemText primary={t("Dashboard")}/>
                            </ListItem>

                            {modules.speakUp && (
                                <ListItem button onClick={navigate("/speakup")}>

                                    {this.props.newSpeakups &&
                                    this.props.newSpeakups > 0 ? (
                                        <ListItemIcon>
                                            <Badge
                                                overlap="rectangular"
                                                color="primary"
                                                badgeContent={this.props.newSpeakups}
                                                style={{marginLeft: "16px"}}
                                            >
                                                <AnnouncementIcon className={classes.icon}/>
                                            </Badge>
                                        </ListItemIcon>
                                    ) : (
                                        <ListItemIcon>
                                            <AnnouncementIcon className={classes.icon}/>
                                        </ListItemIcon>
                                    )}

                                    <ListItemText primary={t("speakUpMenuItem")}/>

                                </ListItem>
                            )}

                            {vv_functions && modules.incidents && (
                                <ListItem button onClick={navigate("/registratiemeldingen")}>

                                    {this.props.incidents &&
                                    this.props.incidents > 0 ? (
                                        <ListItemIcon>
                                            <Badge className={classes.badge}
                                                   overlap="rectangular"
                                                   color="primary"
                                                   badgeContent={this.props.incidents}
                                            >
                                                <AnnouncementIcon className={classes.icon}/>
                                            </Badge>
                                        </ListItemIcon>
                                    ) : (
                                        <ListItemIcon>
                                            <AnnouncementIcon className={classes.icon}/>
                                        </ListItemIcon>
                                    )}

                                    <ListItemText primary={t("Registration notifications")}/>

                                </ListItem>
                            )}

                            {/*{locked && <div>*/}
                            <div>
                                <ListItem button onClick={navigate("/rapportages")}>
                                    <ListItemIcon>
                                        <ShowChartIcon className={classes.icon}/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("Reports")}/>
                                </ListItem>

                                {permissions.notifications.view && (
                                    <ListItem
                                        button
                                        onClick={this.props.navigate("/")}
                                    >
                                        <ListItemIcon>
                                            <EmailIcon className={classes.icon}/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={t("Notifications")}
                                        />
                                    </ListItem>
                                )}
                            </div>
                        </List>
                        {/*{locked && <div>*/}
                        <Divider/>
                        <List className={classes.list}>
                            <ListItem button onClick={navigate("/toolboxen#overzicht")}>
                                <ListItemIcon>
                                    <ConstructionIcon className={classes.icon}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("Toolboxen")}
                                />
                            </ListItem>
                            {roles.includes("ADMIN") && (
                                <ListItem
                                    button
                                    onClick={navigate("/videotoolboxen#overzicht")}
                                >
                                    <ListItemIcon>
                                        <ConstructionIcon className={classes.icon}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t("Video toolboxes")}
                                    />
                                </ListItem>
                            )}
                        {modules.inspections && vv_functions && (
                            <List className={classes.list}>
                                <ListItem button onClick={navigate("/werkplekinspecties#overzicht")}>
                                    <ListItemIcon>
                                        <AssignmentIcon className={classes.icon}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t("Werkplekinspecties")}
                                    />
                                </ListItem>

                            </List>
                        )}
                        {!modules.speakUp && (
                            <ListItem button onClick={navigate("/taken")}>
                                <ListItemIcon>
                                    <TaskIcon className={classes.icon}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={t("Task Title")}
                                />
                            </ListItem>
                        )}
                        </List>

                    </div>
                    <Divider/>

                {permissions.employees.view && (
                    <ListItem
                        button
                        onClick={navigate("/medewerkers")}
                    >
                        <ListItemIcon>
                            <PeopleIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary={t("Employees")}/>
                    </ListItem>
                )}
                {vv_functions && permissions.projecten.view && (
                    <ListItem
                        button
                        onClick={navigate("/projecten")}
                    >
                        <ListItemIcon>
                            <ProjectIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t("Projects/work areas")}
                        />
                    </ListItem>
                )}

                {modules.documents && (
                    <ListItem button onClick={navigate("/documents")}>
                        <ListItemIcon>
                            <InsertDriveFileIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary={t("Documents")}/>
                    </ListItem>
                )}

                <Divider/>

                <List className={classes.list}>
                    {permissions.subscription.view && (
                        <ListItem button onClick={navigate("/abonnement")}>
                            <ListItemIcon>
                                <AbonnementIcon className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("Subscription")}
                            />
                        </ListItem>
                    )}
                    {permissions.abonnees.view && (
                        <ListItem button onClick={navigate("/abonnees")}>
                            <ListItemIcon>
                                <PeopleIcon className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("Subscribers")}
                            />
                        </ListItem>
                    )}
                    {permissions.abonnees.view && (
                        <ListItem button onClick={navigate("/promocodes")}>
                            <ListItemIcon>
                                <PeopleIcon className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("PromoCode")}
                            />
                        </ListItem>
                    )}
                    {permissions.abonneesCUMELA.view && (
                        <ListItem
                            button
                            onClick={navigate("/abonneesCUMELA")}
                        >
                            <ListItemIcon>
                                <PeopleIcon className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={t("Subscribers")}
                            />
                        </ListItem>
                    )}

                    {permissions.settings.view && (
                        <ListItem button onClick={navigate("/")}>
                            <ListItemIcon>
                                <SettingsIcon className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={t("Settings")}/>
                        </ListItem>
                    )}
                    {permissions.information.view && (
                        <ListItem button onClick={navigate("/")}>
                            <ListItemIcon>
                                <ErrorIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t("Information")}/>
                        </ListItem>
                    )}

                    <ListItem button onClick={navigate("/settings")}>
                        <ListItemIcon>
                            <SettingsIcon className={classes.icon}/>
                        </ListItemIcon>
                        <ListItemText primary={t("Settings")}/>
                    </ListItem>

                </List>

                <Divider/>

                <List className={classes.bottomList}>
                    <ListItem button onClick={logout}>
                        <ListItemIcon>
                            <LockIcon/>
                        </ListItemIcon>
                        <ListItemText primary={isImpersonating}/>
                    </ListItem>
                </List>

            </Drawer>

    );
    }
    }

    export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SideMenu);

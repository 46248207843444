// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {withRouter} from 'react-router';
import {getPermissions, inProgress} from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {deleteUsergroup, fetchUsergroups, openDialog, pushCrumbPath, setCrumbPath,} from '../../actions';
import ConfirmDialog from '../ConfirmDialog';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import DialogUsergroupCreate from '../DialogUsergroupCreate';
import DialogUsergroupEdit from '../DialogUsergroupEdit';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import i18n from 'i18next';

type Props = {
    loading: boolean,
    permissions: any,
    setCrumbPath: () => void,
    deleteUsergoup: (usergroup: string) => void,
    deleteGroup: void,
    createUsergroup: Function,
    editUsergroup: Function,
};

type State = {
    columns: Array<any>,
    showConfirmDialog: boolean,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    groupToDelete: String,
    editGroup: any,
};

const mapStateToProps = (store) => {
    let usergroups = store.entities.usergroups;

    return {
        allUsergroups: usergroups,
        usergroups: usergroups.allIds.map((id) => usergroups.byId[id]),
        loading: !inProgress(store),
        permissions: getPermissions(store),
        openTheDialog: store.ui.dialogs.usergroupCreate
            ? store.ui.dialogs.usergroupCreate.open
            : false,
        openEditDialog: store.ui.dialogs.usergroupEdit
            ? store.ui.dialogs.usergroupEdit.open
            : false,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
        deleteUsergoup: (usergroup, t) => {
            dispatch(deleteUsergroup(usergroup, t));
        },
        createUsergroup: () => {
            dispatch(openDialog('usergroupCreate'));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: (breadCrumb) => {
            dispatch(setCrumbPath(breadCrumb));
        },
        goToDetailView: (usergroupId, page, rowsPerPage) => {
            dispatch(push('/groep/details/' + usergroupId + '/' + page + '/' + rowsPerPage));
        },
        editUsergroup: () => {
            dispatch(openDialog('usergroupEdit'));
        },
    };
};

class Usergroup extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        actions: [],
        columns: [
            {id: 'name', label: 'Name', size: '90%'},
            {id: 'employees', label: '#Employees', size: '10%'},
        ],
    };

    setPage = (page) => {
        console.log("INFO: Usergroup page = " + page);
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: Usergroup rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    mapUsergroupToRow = (usergroup) => ({
        ...usergroup,
        employees:
            usergroup.users && usergroup.users.length
                ? usergroup.users.length
                : 0,
    });

    handleAction = (event, action) => {
        if (action.id === 'delete') {
            const group = this.props.allUsergroups.byId[action.rowId];
            if (group) {
                this.setState({groupToDelete: group});
                this.showConfirmDialog();
            }
        }
        if (action.id === 'modify') {
            this.setState({
                editGroup: action.rowId,
            });
            this.props.editUsergroup();
        }
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    handleAddButtonClick = (event) => {
        this.props.createUsergroup();
    };

    componentDidMount() {
        console.log("INFO: Usergroup: componentDidMount()");
        // If url params rows and page go to right page
        const searchString = '?' + this.props.location.hash.split("?")[1]
        console.log("INFO: Custom searchString: " + searchString);

        if (searchString.includes("page")) {
            const jumpToPage = new URLSearchParams(searchString).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({startPage: Number(jumpToPage)});
        }
        if (searchString.includes("rows")) {
            const startRowsPerPage = new URLSearchParams(searchString).get("rows");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({startRowsPerPage: Number(startRowsPerPage)});

        }

        this.props.setCrumbPath({
            title: this.props.t('Medewerkers'),
            crumbs: [{subTitle: this.props.t('Groups'),}],
        });

        let actions = [];

        actions.push(
            {
                id: 'modify',
                label: 'Edit',
                isVisible: true,
            },
            {
                id: 'delete',
                label: 'Delete',
                isVisible: true,
            }
        );

        this.setState({
            actions: actions,
        });
    }

    onRowClick = (row) => {
        if (row.id) {
            // Set crumb for the Groepen tab
            this.props.pushCrumbPath({
                crumb: {
                    name: i18n.t('Groups'),
                    link: `/medewerkers#groepen?page=${this.state.page}&rows=${this.state.rowsPerPage}`,
                    subTitle: row.name,
                }
            });

            // Set crumb for this detail view
            // dispatch(push('/groep/details/' + usergroupId + '/' + page + '/' + rowsPerPage));
            // this.props.pushCrumbPath({crumb: { name: row.name , link: `/groep/details/${row.id}`}});
            this.props.goToDetailView(row.id, this.state.page, this.state.rowsPerPage);
        }
    };

    render() {
        const {loading, usergroups, openEditDialog, t} = this.props;
        const {actions, columns} = this.state;
        const tableActionHeader = (
            <TableActionHeader
                title={
                    (usergroups ? usergroups.length : 0) +
                    ' ' +
                    t(usergroups && usergroups.length === 1 ? 'group' : 'groups')
                }
                onActionButton={this.handleAddButtonClick}
            />
        );

        if (!usergroups) {
            return <div/>;
        }

        return (
            <AppModule loading={loading} hasTabs prepend={tableActionHeader}>
                <EnhancedTable
                    hover
                    defaultOrderBy={'name'}
                    defaultOrder="asc"
                    columns={columns}
                    onClick={this.onRowClick}
                    rows={usergroups}
                    formatter={this.mapUsergroupToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    emptyState={t(
                        'No groups have been added yet, add a group by clicking the plus sign at the top right.'
                    )}

                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={this.state.startRowsPerPage}

                />

                {/*<div*/}
                {/*    className={classnames({*/}
                {/*        [classes.button]: true,*/}
                {/*        'mui-fixed': true,*/}
                {/*    })}*/}
                {/*>*/}
                {/*    <Fab*/}

                {/*        onClick={this.handleAddButtonClick}*/}
                {/*    >*/}
                {/*        <AddIcon/>*/}
                {/*    </Fab>*/}
                {/*</div>*/}

                <DialogUsergroupCreate open={this.props.openTheDialog}/>
                <DialogUsergroupEdit
                    open={openEditDialog}
                    editGroup={this.state.editGroup}
                />
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    handleClose={this.hideConfirmDialog}
                    deletegroup={this.state.groupToDelete}
                    deleteGroupFunction={this.props.deleteUsergoup}
                />
            </AppModule>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(Usergroup))
);


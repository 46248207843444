// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import {connect} from 'react-redux';
import {Fab} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {fetchVideoToolboxes, openDialog, pushCrumbPath, setCrumbPath,} from '../../../actions';
import AppModule from '../../AppModule/AppModule';
import EnhancedTable from '../../EnhancedTable/EnhancedTable';
import moment from '../../../lib/moment';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import {percentColors1} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";
import classes from './VideoToolboxOverview.module.scss';
import DialogVideoToolboxCreate from '../../DialogVideoToolboxCreate/DialogVideoToolboxCreate';
import DialogViewVideoToolbox from "../../DialogViewVideoToolbox/DialogViewVideoToolbox";

type Props = {};

type State = {};

const mapStateToProps = (store) => {
    const abonnees = store.subscription.abonnees;

    let abonneesByIdMap = new Map();
    if (abonnees) {
        abonnees.forEach((abonnee) => {
            abonneesByIdMap.set(abonnee.id, abonnee);
        });
    }

    return {
        loading: false,
        videoToolboxes: store.entities.videoToolboxes.allIds.map((id) => store.entities.videoToolboxes.byId[id]),
        openCreateDialog: store.ui.dialogs.videoToolboxCreate
            ? store.ui.dialogs.videoToolboxCreate.open
            : false,
        openViewDialog: store.ui.dialogs.videoToolboxView
            ? store.ui.dialogs.videoToolboxView.open
            : false,
        abonnees: abonneesByIdMap,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchVideoToolboxes: () => {
            dispatch(fetchVideoToolboxes())
        },
        createVideoToolbox: () => {
            dispatch(openDialog('videoToolboxCreate'));
        },
        openVideoToolbox: () => {
            dispatch(openDialog('videoToolboxView'));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({title: i18n.t('Video Toolboxes')}));
        },
    };
};

class VideoToolboxOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        columns: [
            {id: 'name', label: 'Name', size: '20%'},
            {id: 'url', label: 'URL', size: '30%'},
            {id: 'category', label: 'Category', size: '10%'},
            {id: 'subscription', label: 'Subscription', size: '20%'},
            {id: 'language', label: 'Language', size: '10%'},
            {id: 'active', label: 'Active', size: '10%'},
        ],
        actions: [
            {
                id: 'activeer',
                label: 'Activate',
                isVisible: (id) => !this.isActive(id),
            },
            {
                id: 'deactiveer',
                label: 'Deactivate',
                isVisible: (id) => this.isActive(id),
            },
        ],
        searchtext: '',
        videoToolboxView: {},
    };

    componentDidMount() {
        const {t} = this.props;

        this.props.fetchVideoToolboxes();

        this.props.setCrumbPath()
    }

    isActive = (rowId) => {
        return this.props.videoToolboxes.find((vtb) => vtb.id === rowId).active
    };

    setPage = (page) => {
        // Store page in state
        this.setState({page: page});
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({rowsPerPage: rowsPerPage});
    }

    handleAction = (event, action) => {
        if (action.id === 'activate') {

        } else if (action.id === 'deactivate') {

        }
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    activeToText = (videoToolbox) => {
        let {t} = this.props;
        let text = '';
        let colorValue = 0;

        if (videoToolbox.subscriptionId === null && videoToolbox.active) {
            text = t("Active")
            colorValue = 1
        } else if (videoToolbox.subscriptionId != null && videoToolbox.active) {
            var subscription = this.props.abonnees.get(videoToolbox.subscriptionId)
            if (subscription && subscription.addOnVideoToolbox) {
                text = t("Active")
                colorValue = 1
            } else {
                text = t("No addon")
                colorValue = 0.5
            }
        } else {
            text = t("Inactive")
        }

        return (
            <div
                className={'border-text'}
                style={{
                    backgroundColor: getColorForPercentage(colorValue),
                    borderColor: getColorForPercentage(colorValue),
                    width: '78px',
                }}
            >
                {text}
            </div>
        );
    };

    handleAddButtonClick = (event) => {
        this.props.createVideoToolbox();
    };

    getCompanyName = (videoToolbox) => {
        var companyName = ""
        if (videoToolbox.subscriptionId) {
            console.log(videoToolbox.subscriptionId)
            var company = this.props.abonnees.get(videoToolbox.subscriptionId)
            if (company) {
                companyName = company.companyName + " (" + videoToolbox.subscriptionId + ")"
            }
        }
        return companyName
    };

    mapVideoToolboxToRow = (videoToolbox) => {
        const {t} = this.props;

        moment.locale(i18n.t('LOCALE'));

        return {
            ...videoToolbox,
            title: videoToolbox.name,
            url: videoToolbox.url,
            category: videoToolbox.category,
            subscription: this.getCompanyName(videoToolbox),
            language: t(videoToolbox.language),
            active: this.activeToText(videoToolbox),
        };
    };

    onRowClick = (row, event) => {
        this.state.videoToolboxView = row;
        this.props.openVideoToolbox();
    };

    videoToolboxSorter = () => {
        return {
            name: (a, b) => {
                a = a.name.toLowerCase();
                b = b.name.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            url: (a, b) => {
                a = a.url.toLowerCase();
                b = b.url.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            category: (a, b) => {
                if (a.category > b.category) {
                    return 1;
                } else if (a.category < b.category) {
                    return -1;
                } else {
                    return 0;
                }
            },
            subscription: (a, b) => {
                a = this.getCompanyName(a);
                b = this.getCompanyName(b);

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            }
        };
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openType: false,
            openSector: false,
            openCategory: false,
        });
        this.setState(newState);
    };

    showCreateDialog = () => {
        this.setState({showCreateDialog: true})
    }

    render() {
        const {loading, t} = this.props;
        const {
            actions,
            columns,
            toolboxErrorDialogText,
            toolboxErrorDialogOpen,
        } = this.state;

        let videoToolboxes = this.props.videoToolboxes || [];

        const searchresults = videoToolboxes
            .filter((videoToolbox) => {
                let textFound = false;

                return (
                    videoToolbox.name
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                    ||
                    videoToolbox.url
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                    ||
                    videoToolbox.category
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                    ||
                    this.getCompanyName(videoToolbox)
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                );
            });

        const tableActionHeader = (
            <TableActionHeader
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search toolboxes')}
                searchAlwaysOpen={true}
                title={"Video Toolboxes"}
                widemode={true}
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <ConfirmDialog
                        open={this.state.showConfirmDialog}
                        handleClose={this.hideConfirmDialog}
                        deletetoolbox={this.state.toolboxToDelete}
                        deleteToolboxFunction={this.props.deleteToolbox}
                    />

                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <div className={'tooltip'} style={{padding: 0}}>
                            <span
                                className={'tooltiptext'}
                                style={{top: '60px'}}
                            >
                                {t('New videotoolbox')}
                            </span>
                            <Fab onClick={this.handleAddButtonClick}>
                                <AddIcon/>
                            </Fab>
                        </div>
                    </div>
                    <EnhancedTable
                        hover
                        defaultOrderBy='name'
                        defaultOrder="asc"
                        columns={columns}
                        onClick={this.onRowClick}
                        rows={searchresults}
                        formatter={this.mapVideoToolboxToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.videoToolboxSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                    'No video toolboxes have been added yet, add a toolbox by clicking the plus sign at the top right.'
                                )
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                    <DialogVideoToolboxCreate
                        open={this.props.openCreateDialog}
                    />
                    <DialogViewVideoToolbox
                        open={this.props.openViewDialog}
                        videoToolbox={this.state.videoToolboxView}
                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 0.5) {
        color = percentColors[1]
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation())(VideoToolboxOverview))
);
